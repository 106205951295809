import React, { useEffect, useState } from "react";
import CoursesList from "./CoursesList";
import API from '../../../api';
import { Row, Col, Input } from "antd";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    filterSelector: {
        width: '100%',
        paddingBottom: '1em',
    },
    label: {
        paddingTop: '1em',
    }
});

const CoursePage = () => {
    const classes = useStyles();
    const [courses, setCourses] = useState([]);
    const [LoadingTable, setLoadingTable] = useState(true);
    const [valueSearch, setValueSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const getCourses = (search = null, pageCurrent = null, pageSize = null) => {
        let searchText = search != null ? search : valueSearch;

        if (pageCurrent != null) {
            setCurrentPage(pageCurrent);
        }

        API.get(`/api/get_courses_list?search=${searchText}&page=${pageCurrent}&per_page=${pageSize}`).then(({ data }) => {
            setCourses(data.courses)
            setLoadingTable(false);
        });
    };

    let getDataPaginate = (page, filters = {}, sorter = {}, extra = {}) => {
        getCourses(null, page.current, page.pageSize);
    };

    let handleSearchInput = (valueSearch) => {
        setLoadingTable(true);
        setValueSearch(valueSearch);
        getCourses(valueSearch);
    };

    const handleDownload = (url, filename) => {

        fetch(`${url}`)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;

                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => alert("oh no!"));
    }

    useEffect(() => {
        setLoadingTable(true);
        getCourses();
    }, []);

    return (
        <>
            <Row gutter={[0, 10]} justify="end">
                <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                    <Input.Search
                        allowClear
                        placeholder="Search"
                        className={classes.filterSelector}
                        onSearch={handleSearchInput}
                    />
                </Col>
            </Row>

            <CoursesList
                courses={courses}
                getDataPaginate={getDataPaginate}
                LoadingTable={LoadingTable}
                handleDownload={handleDownload}
                getCourses={getCourses}
                currentPage={currentPage}
            />
        </>
    );
};

export default CoursePage;
