import React, { useEffect, useState } from 'react'
import { Button, Col, message, Row, Select, Spin, Form, Modal } from 'antd'
import debounce from "lodash/debounce";
import API from "../../../../api";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ComparativeTable from "./ComparativeTable";

const { useForm } = Form;

const { Option } = Select;
const UserMerge = ({ setActiveKey }) => {


    const [form] = useForm();
    const [dataTableCompare, setDataTableCompare] = useState();
    const [loading, setLoading] = useState(false);
    const [disableVerify, setDisableVerify] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [disabledMergeButtons, setDisabledMergeButtons] = useState(false);

    let [lastFetchId, setLastFetchId] = useState(0);

    let [users, setUsers] = useState({
        data: [],
        value: [],
        fetching: false,
    });

    const pinStyle = {
        position: "absolute",
        zIndex: "40",
        paddingTop: "10%",
        height: "80%",
        width: "90%",
        backgroundColor: "#eee",
    };

    const getUsers = () => {
        API.get("/api/user/getUsers/loginAs")
            .then(({ data }) => {
                let result_users = data.users.filter((option) => option.status != "merged").map((user) => ({
                    text: `${user.name} (${user.username})`,
                    value: user.key,

                }));
                setUsers({ data: result_users, fetching: false });
            })
            .catch(() => { });
    };

    let searchUser = (value) => {
        setLastFetchId(lastFetchId + 1);
        setUsers({ data: [], fetching: true });

        API.get(`/api/user/getUsers/loginAs/${value}`)
            .then(({ data }) => {
                if (data.users.length > 0) {
                    let result_users = data.users.filter((option) => option.status != "merged").map((user) => ({
                        text: `${user.name} (${user.username})`,
                        value: user.key,

                    }));
                    setUsers({ data: result_users, fetching: false });
                } else {
                    setUsers({ data: [], fetching: false });
                    Modal.warning({ content: "User not found" });
                }
            })
            .catch(() => { });
    };

    searchUser = debounce(searchUser, 500);

    useEffect(() => {
        getUsers();
    }, []);

    const { fetching, value } = users;

    const handleConfirm = (values) => {
        let valuesPrincipalForm = form.getFieldValue();
        let secondaryId = valuesPrincipalForm.secondaryId.key;
        let primaryId = valuesPrincipalForm.primaryId.key;

        Modal.confirm({
            title: 'Are you sure you want to permanently merge these accounts?  There is no way to restore a merge account.',
            icon: <ExclamationCircleOutlined style={{ color: "#ecd62a" }} />,
            onOk() {
                setDisabledMergeButtons(true);

                message.loading({
                    content: "Merging accounts...",
                    key: "merging_accounts",
                    duration: 0,
                });

                API.post(`api/merge_users`, {
                    secondaryId: secondaryId,
                    primaryId: primaryId,
                    otherParams : values
                }).then(({ data }) => {
                    setDisabledMergeButtons(false)
                    message.destroy("merging_accounts");

                    let contentMessage = "The accounts were merged.";
                    if (!data) {
                        contentMessage = "The accounts were not merged. Error ocurred.";
                    }

                    Modal.confirm({
                        title: contentMessage,
                        onOk() {
                            form.resetFields();
                            setDisableVerify(false);
                            setDataTableCompare([])
                        }
                    });

                }).catch();
            }
        });
    }

    const handleVerify = (form) => {

        form.validateFields()
            .then(() => {
                let values = form.getFieldValue();
                let secondaryId = values.secondaryId?.key;
                let primaryId = values.primaryId?.key;

                if (secondaryId === primaryId) {
                    Modal.error({
                        title: `You cannot merge an account into the same account.`,
                        onOk() {
                            form.resetFields();
                        }
                    });
                } else {
                    setLoadingData(true);
                    setDisableVerify(true);

                    API.post(`api/getUsersInfoMerge`, {
                        secondaryId: secondaryId,
                        primaryId: primaryId
                    })
                        .then(async (data) => {
                            setDataTableCompare(data.data);
                            setLoadingData(false);
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {

                        })
                }
            })
            .catch((info) => {
            });
    }

    const handleCancel = () => {
        form.resetFields();
        setDataTableCompare([]);
        setDisableVerify(false)
    }

    return (
        <>
            <Form form={form} layout="vertical">
                <Row gutter={[15, 15]} align="middle">
                    <Col xs={24} md={11}>
                        <Form.Item
                            label="Secondary account - This account will be deleted following the merging."
                            name="secondaryId"
                            className="hide_rule_message"
                            rules={[{ required: true }]}
                            style={{fontWeight:"bold"}}
                        >
                            <Select
                                showSearch labelInValue value={value} placeholder="Select user..."
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                filterOption={false} onSearch={searchUser} style={{ width: "100%" }}
                            >
                                {users.data.map((d) => (
                                    <Option key={d.value}>{d.text}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={10}>
                        <Form.Item label="Primary account - This account will remain after merging."
                            name="primaryId"
                            className="hide_rule_message"
                            rules={[{ required: true }]}
                            style={{fontWeight:"bold"}}
                        >
                            <Select
                                showSearch labelInValue
                                value={value}
                                placeholder="Select user..."
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                filterOption={false} onSearch={searchUser} style={{ width: "100%" }}
                            >
                                {users.data.map((d) => (
                                    <Option key={d.value}>{d.text}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3} style={{ textAlign: "bottom" }}>
                        <Button type="primary"
                            disabled={disableVerify}
                            onClick={() => { handleVerify(form) }}>
                            Verify
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Spin spinning={loadingData} tip="Loading..." size="large" style={pinStyle}></Spin>

            <Row>
                <ComparativeTable
                    dataTableCompare={dataTableCompare}
                    handleConfirm={handleConfirm}
                    handleCancel={handleCancel}
                    disabledMergeButtons={disabledMergeButtons} />
            </Row>
        </>
    )
}

export default UserMerge
