import React from 'react'
import { Input, Form, Modal, Select, Radio, Row, Col, Button } from 'antd';
import RichTextEditor from '../../../components/RichTextEditor';
const { Option } = Select;

const FormLogonPopup = ({ roles, visible, handleOk, handleCancel, textArea, setTextArea, form, edit, showDelete }) => {
    return (
        <Modal
            title={edit ? "Update Logon Popup" : "Add New Logon Popup"}
            centered
            width="70%"
            visible={visible}
            onOk={handleOk} onCancel={handleCancel}
            footer={<div>
                {edit ? <Button
                    type="primary" danger
                    style={{ float: "left" }}
                    onClick={() => {
                        showDelete(form.getFieldValue("id"));
                    }}>
                    Delete
                    </Button> : null}
                <Button onClick={() => { handleCancel() }} >Cancel</Button>
                <Button onClick={() => { handleOk() }} type="primary">OK</Button>
            </div>}
        >
            <Form layout="vertical" form={form}>
                <Form.Item name="id" noStyle>
                    <Input type="hidden"></Input>
                </Form.Item>
                <Form.Item name="headline">
                    <Input placeholder="Headline" size="large"></Input>
                </Form.Item>
                <Row className="ant-form-item">
                    <Col span={24} className="ant-form-item-label">
                        <label>Text:</label>
                    </Col>
                    <Col span={24} className="ant-form-item-control" >
                        <RichTextEditor value={textArea} setValue={setTextArea} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={16}>
                        <Form.Item name="display_to" label="Display this message to:">
                            <Select mode="multiple"
                                showSearch
                                placeholder="All users"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }>
                                {roles.map((v, i) => <Option key={i} value={v.key}>{v.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="active" label="This Popup is:">
                            <Radio.Group>
                                <Radio value={true}>
                                    Activated
                                    </Radio>
                                <Radio value={false}>
                                    Not Activated
                                    </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default FormLogonPopup
