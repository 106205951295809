import React from "react";
import { Route, Redirect } from "react-router-dom";
import _ from "lodash";
const PrivateRoute = ({ children, ...rest }) => {
    const isAuthenticated = localStorage.getItem("jwt-token") ? true : false;
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (isAuthenticated) {
                    if (location.pathname === "/admin") {
                        let nisl_data = localStorage.getItem("nisl-data");
                        if (nisl_data) {
                            nisl_data = JSON.parse(nisl_data);
                            if (_.intersection(["nisl_manager", "administrator", "coach"], nisl_data.roles_un).length === 0) {
                                return <Redirect
                                to={{
                                    pathname: "/",
                                    state: { from: location },
                                }}
                                />
                            }
                        }
                    }
                    return children;
                } else {
                    return <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                }
            }
            }
        />
    );
};
export default PrivateRoute;
