import React, { useEffect, useState} from "react";
import { CalendarFull } from "../../assets/icons/CalendarFull";
import EventsUser from "../../pages/Courses/EventsUser";
import "./UserSearch.less";
import {Modal, Row, Col, Table, Empty} from "antd";
import  { UserSchedule }  from "../../Hooks/UserSchedule"
import { useHistory, useLocation  } from "react-router-dom";
import  Header from "../../components/user/Header";
import HeaderTitle from "../../components/user/HeaderTitle";



const UserSearch = ({valuesUserSearch, isVisibleModal, setIsVisibleModal, dataCohort, form}) => {

        const history = useHistory();
        const location = useLocation();
        const { bodyEvents, dataUserSchedule, showEvents, getScheduleUser, setShowEvents}  = UserSchedule();
        const [bodyEventsCohort, setBodyEventsCohort] = useState();
        const [dataEventCohort, setDataEventCohort] = useState();

        useEffect(()=>{
            let cohorts_arr = dataCohort.flatMap(cohort => cohort?.cohort_id_psql)
            dataCohort.forEach(element => {
                if (element.show_attendance_report) {
                    getScheduleUser(element.course_key, element.user_id, cohorts_arr, element.cohort_id_psql)
                }
            });
        // eslint-disable-next-line
        },[dataCohort])

        const columns = [
            {
              title: (Object.keys(dataCohort).length > 1 ?
                <span style={{ fontSize: "18px", fontWeight:"bold",color:"#313777" }}>&nbsp;Enrolled in these cohorts</span>
                : <span style={{ fontSize: "18px", fontWeight:"bold",color:"#313777" }}>&nbsp;Enrolled in this cohort</span>),
                fixed: 'left',
              render:(value)=>{
                return (
                  <span>
                      <p></p>
                      {!value.delivery_model.is_virtual && value.cohort_city != null & value.cohort_state != null ?
                       <p className="span-cohorts" style={{color:"#313777"}}>&nbsp;{value.cohort_city}, {value.cohort_state} &#8226; {value.cohort_name}</p>
                      :<p className="span-cohorts" style={{color:"#313777"}}>&nbsp;{value.cohort_name}</p> }
                      <p>&nbsp;{value.next_session} {value.time_show}</p>
                  </span>
                )
              }
            },
            {
                fixed: 'left',
                render:(value)=>{
                    return (
                      <span>
                          <p></p>
                          <p className="span-cohorts">Course: <span style={{color:"rgb(179, 147, 54)"}}>{value.course_name}</span></p>
                          <p>Facilitator: {value.facilitator}</p>
                      </span>
                    )
                }
            },
            {
                title: 'Roster',
                align: "center",
                render: (value) => {
                    return (
                        <>
                        <a  style={{ padding: "0px" }} rel="noopener noreferrer" target='_blank' className="navlink-header" href={`/dashboard/cohort/${value.cohort_id_psql}/event/${value?.active_unit}/unit/${value?.active_unit}/cohort/${value.cohort_id}`} ><img src="/roster-icon.png" alt="" style={{ width: "50px" }} /></a>
                        </>
                    )
                }
            },
            {
                title: 'Attendance',
                align: "center",
                render: (value) => {
                    return (
                        value.show_attendance_report ?
                            (<div style={{ background: "#003263", borderRadius: "7px", width: "46px", height: "46px", margin: "auto" }}>
                                <CalendarFull style={{ fontSize: "50px", padding: "5px 6px" }}
                                    onClick={async () => {
                                        setBodyEventsCohort(bodyEvents[value.cohort_id_psql])
                                        setDataEventCohort(dataUserSchedule[value.cohort_id_psql])
                                        setShowEvents(true)
                                        let event_scroll = document.querySelector(".ant-modal-body .initial_scroll")
                                        if (event_scroll !== null) {
                                            event_scroll.scrollIntoView()
                                        }
                                    }}
                                />
                                 <EventsUser showModal={showEvents}
                                    onCancel={() => {
                                        setShowEvents(false);
                                    }}
                                    bodyEvents={bodyEventsCohort}
                                    infoUserSchedule={dataEventCohort}
                                    setIsVisibleModal={setIsVisibleModal}
                                    setShowEvents={setShowEvents}
                                    />
                            </div>

                            )
                            : (
                                <div style={{ background: "#003263", borderRadius: "7px", width: "46px", height: "46px", margin: "auto" }}>
                                    <CalendarFull style={{ fontSize: "50px", padding: "5px 6px" }}
                                        onClick={async () => {
                                            history.push(`/dashboard/cohort/${value.cohort_id_psql}/event/${value?.active_unit}/unit/${value?.active_unit}/cohort/${value.cohort_id}`);
                                            if(location.pathname.includes('event')){
                                                history.go(0)
                                            }
                                            setIsVisibleModal(false);
                                        }}
                                    />
                                </div>
                            )
                    )
                }
            },
            {
                title: "Participant View of Attendance",
                align: "center",
                className: "column_text_attendance",
                render: (value)=>{
                    return (
                        value.show_attendance_report && dataUserSchedule[value.cohort_id_psql]?.avg_attendance ?
                            (<span style={{color:"#313777",fontSize:"13px"}}>The facilitator has marked this participant in <br/>attendance for {dataUserSchedule[value.cohort_id_psql]?.avg_attendance?.total_attendance} of {dataUserSchedule[value.cohort_id_psql]?.completed_units?.length} completed units.
                                <br/>({dataUserSchedule[value.cohort_id_psql]?.avg_attendance?.percentaje_attendance}% attendance)</span>)
                                    : <span style={{color:"#313777",fontSize:"13px"}}>no participant view</span>
                    )
                }
            },
        ];

        return (
            <Modal className="modal-search"
            title={ <HeaderTitle user={valuesUserSearch}></HeaderTitle>}
                visible={isVisibleModal} width="90%" footer={null} onCancel={() => {
                    form.resetFields();
                    setIsVisibleModal(false);
                }}>
                <Header user={valuesUserSearch} />
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={[5, 0]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <br /><Table locale={{
                                    emptyText:
                                        <Empty imageStyle={{ display: "none" }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                            <span style={{ fontSize: "28px", color: "#0051a0", lineHeight: "20vh", height: "20vh" }}>
                                                This participant has not been enrolled in a cohort.
                                            </span>
                                        }
                                        />
                                }} dataSource={dataCohort} className='table-search' columns={columns} pagination={false} />
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </Modal>
        )
}

export default UserSearch;
