import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionTypes from '../../store/actions/actionTypes';
import { Layout, Spin } from "antd";
import { pinStyle } from "../Dashboard/Dashboard.style";
import { useLocation } from "react-router-dom";


const { Content } = Layout;
const Communities = ({doMenuOpen}) => {
    const [loadCommunities, setLoadCommunities] = useState(false);
    const { token, userID } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_CMS_URL } = process.env;
    let url_iframe = `${REACT_APP_CMS_URL}/communities/?token=${token}`;
    useEffect(() => {
        doMenuOpen(true);
    // eslint-disable-next-line
    },[])
    let query = useQuery();

    let url_community = query.get("url_iframe");
    if (url_community !== null) {
        let arr_url = url_community.split("communities/");

        if(arr_url[1] !== undefined){
            url_iframe = `${REACT_APP_CMS_URL}/communities/?nisl_id=${userID}&slug=${arr_url[1]}`;
            let url_redirect = query.get("url_redirect");
            if (url_redirect){
                url_iframe += `&url_redirect=${url_redirect}`;
            }
        }
    }

    return (
        <>
            <Content className="site-layout-background layout-portal-content">
                <div className="layout-portal-main-container">
                    {!loadCommunities ? (
                        <Spin
                            tip="Loading..."
                            size="large"
                            style={pinStyle}
                        ></Spin>
                    ) : null}
                    <iframe
                        src={url_iframe}
                        frameBorder={"0"}
                        onLoad={() => setLoadCommunities(true)}
                        title="communities"
                        className="style-frame"
                    />
                </div>
            </Content>
        </>
    );
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const mapDispatchToProps = dispatch => ({
    doMenuOpen: active => dispatch({type: actionTypes.SET_MENU_OPEN, active: active}),
});

export default connect(null, mapDispatchToProps)(Communities);
