import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Row, Col, Select, Checkbox, Radio, Divider, Button } from "antd";
import API from "../../../../api";
import { loginWith } from "../../../../functions/loginWith";
import moment from "moment";
import TextareaAutosize from 'react-textarea-autosize';
import Districts from "../../../../components/Districts";
import Schools from "../../../../components/Schools";
import { active_page, disabled_page, update_page } from "../../../../functions/ControlPages";
import MaskedInput from 'antd-mask-input';
import { OPTION_STATUS } from "../../../../config/selectOptions";
import { SENIOR_ADMIN } from "../../../../config/roles";

const CreateUser = ({ visible, onCancel, onCreate, onEdit, edit, values, valuesCreate, deleteUser, showStaffContractor, setShowStaffContractor, setChangeForm }) => {

    const ENTER_KEY = 'Enter';
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const styLabels = {
        margin: '2px 0px',
        padding: '0px'
    };
    const styBodyModal = {
        paddingTop: '0px'
    };

    const { id: id_user, roles: user_roles } = JSON.parse(localStorage.getItem("user-data"))
    const [privilegesDisabled, setPrivilegesDisabled] = useState(false);
    const [rolesForm, setRolesForm] = useState([]);
    const [rolesWithFilter, setRolesWithFilter] = useState([]);
    const [coursesForm, setCoursesForm] = useState();
    const [courseFormDefault, setCourseFormDefault] = useState([]);
    const [cohortFormDefault, setCohortFormDefault] = useState('');
    const [statesForm, setStatesForm] = useState();
    const [certificationsForm, setCertificationsForm] = useState([]);
    const [lastCohort, setLastCohort] = useState('');
    const [loginLogs, setLoginLogs] = useState('');
    const [textBtnAddress, setTextBtnAddress] = useState("address");
    const [stateCoordinatorComponent, setStateCoordinatorComponent,] = useState("");
    const [jobFunctions, setJobFunctions] = useState([]);
    const [emailAssign, setEmailAssign] = useState("");
    const [ext, setExt] = useState("");
    const [stateId, setStateId] = useState(0);
    const [district, setDistrict] = useState(0);
    const [school, setSchool] = useState();
    const [iu, setIu] = useState(null);
    const [isSuperAdmin, setSuperAdmin] = useState();
    const [ppid, setPpid] = useState(false);
    const [updatePageControl, setUpdatePageControl] = useState(false);
    const [allCourses, setAllCourses] = useState([]);
    const [originalData, setOriginalData] = useState(false);

    useEffect(() => {
        if (user_roles?.find(v => v.slug === SENIOR_ADMIN.slug)) {
            setSuperAdmin(true);
        } else {
            setSuperAdmin(false);
        }

        if (!edit) {
            setCoursesForm(allCourses.filter((option) => option.active).map(e => ({
                label: `(${e.number.replace("cohort", "")}) ${e.label}`,
                value: e.value
            })))

            form.resetFields()
            loadEditForm(valuesCreate);
            setCohortFormDefault('');
            setLastCohort('');
            setRolesWithFilter(rolesForm?.filter(role => !role.hide || (isSuperAdmin && (role.slug === "administrator" || role.slug === SENIOR_ADMIN.slug))))

        } else {

            let user_courses = [];
            allCourses.forEach(function(course) {
                if(values.courses.includes(course.value) || course.active === true) {
                    user_courses.push(course);
                }
            })

            setCoursesForm(user_courses.map(e => ({
                label: `(${e.number.replace("cohort", "")}) ${e.label}`,
                value: e.value
            })))

            active_page(`user/edit/${values.id}`)
            setRolesWithFilter(rolesForm?.filter(role => !role.hide || values.roles.includes(role.value) || (isSuperAdmin && (role.slug === "administrator" || role.slug === SENIOR_ADMIN.slug))))
            if (id_user === values.id) {
                setPrivilegesDisabled(true);
            } else {
                setPrivilegesDisabled(false);
            }
            form.resetFields()
            setOriginalData(values)
            loadLoginLogs(values);
            loadEditForm(values);
            setCourseFormDefault([]);
            let facilitatorNational = rolesForm.find(v => v.slug === "facilitator_national")
            setShowStaffContractor(values.roles.includes(facilitatorNational?.value))
            handleChange(values.courses, allCourses, values.default_course);
            setLastCohort(values.cohorts[0]?.name);
            const otherCohorts = JSON.parse(JSON.stringify(values.cohorts));
            setCohortValueDefault(otherCohorts.splice(1));
            setUpdatePageControl(true);
        }

        getJobFunctions()

        const cleanup = () => {
            if (edit) {
                disabled_page(`user/edit/${values.id}`);
            }
        }

        window.onbeforeunload = (event) => {
            event.preventDefault()
            cleanup()
        }
        // eslint-disable-next-line
    }, [edit, values, valuesCreate, rolesForm]);

    let getJobFunctions = () => {
        API.get("api/job_functions").then(({ data }) => {
            setJobFunctions(data)
        })
    }

    const component =
        <Form.Item name="states_coordinator">
            <Select
                mode="multiple"
                showSearch
                placeholder="States Director"
                filterOption={(input, option) =>
                    option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                }
                options={statesForm}
            ></Select>
        </Form.Item>;

    let loadLoginLogs = (values) => {
        let logs = "";
        if (values.login_logs) {
            let arr = values.login_logs.slice(0, 3);
            logs = arr.map(element => {
                if (element.status) {
                    return (<span>ACTIVE logged on {moment(element.created_at).format("YY-MM-DD hh:mm a")} ET - IP {element.ip} {element.platform} {element.version_platform} with {element.browser} {element.version_browser}<br></br></span>)
                } else {
                    return (<span>CLOSED logged off {moment(element.updated_at).format("YY-MM-DD hh:mm a")} ET - IP {element.ip} {element.platform} {element.version_platform} with {element.browser} {element.version_browser}<br></br></span>)
                }

            })
        }

        setLoginLogs(logs)
    }


    const isStateCoordinator = (e) => {
        if (e.target.checked) {
            setStateCoordinatorComponent(component);
        } else {
            setStateCoordinatorComponent("");
        }
    };

    const setCohortValueDefault = (cohorts) => {
        const cohs = cohorts.map((cohort) => cohort.name).join(", ");
        setCohortFormDefault(cohs);
    }

    let loadEditForm = (values) => {
        setEmailAssign(values?.email_assign)
        setExt(values?.phone_extension)
        setStateId(values?.state_id)
        setDistrict(values.district_id)
        setSchool(values.school)
        values.district = ""
        values.school = ""
        if (values.iu !== undefined && values.iu !== null && values?.state_living?.code === "PA") {
            setIu(values.iu)
        } else {
            setIu(null)
        }
        setPpid(values?.state_living?.code.includes("PA"));
        if (values.state_coordinator) {
            setStateCoordinatorComponent(component);
        } else {
            setStateCoordinatorComponent();
        }
    };

    const getSelectOptions = () => {
        API.get("/api/getOptions")
            .then(({ data }) => {
                setRolesForm(data.roles);
                setStatesForm(data.states);

                setCoursesForm(data.courses.filter((option) => option.active).map(e => ({
                    label: `(${e.number.replace("cohort", "")}) ${e.label}`,
                    value: e.value
                })))

                setAllCourses(data.courses);

                setCertificationsForm(data.certifications);
                valuesCreate.roles = [parseInt(data.defaultRole.value)];
                valuesCreate.default_course = parseInt(data.defaultCourse.value);
                valuesCreate.courses = [parseInt(data.defaultCourse.value)];
                valuesCreate.status = 'active';
                handleChange(valuesCreate.courses, data.courses);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const handleChange = (values_courses, coursesData = [], default_course_param = null) => {
        var defaultCourse = [];
        let default_course = {};
        var courses = coursesForm;

        if (courses === undefined) {
            courses = coursesData;
        }

        courses.forEach(course => {

            if (course.value === values_courses[values_courses.length - 1]) {
                default_course = course
            }

            values_courses.forEach((value) => {
                if (course.value === value) {
                    defaultCourse.push(course);
                }
            }
            )
        });
        setCourseFormDefault(defaultCourse);

        if (default_course_param !== null) {
            form.setFieldsValue({ ...form.getFieldsValue(), "default_course": default_course_param })
        } else {
            form.setFieldsValue({ ...form.getFieldsValue(), "default_course": default_course.value })
        }
    }

    let handleChangeAddress = () => {
        let row_with_info_address = document.querySelector("#AddressInfo");
        if (row_with_info_address.style.display === "none") {
            setTextBtnAddress("address");
            row_with_info_address.style.display = "flex";
        } else {
            row_with_info_address.style.display = "none";
            setTextBtnAddress("address");
        }
    }

    useEffect(() => {
        getSelectOptions();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        if (updatePageControl) {
            const interval = setInterval(() => {
                update_page(`user/edit/${values.id}`);
            }, 300000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [updatePageControl]);

    let revertState = async (modalWarning) => {
        let state_id = originalData.state_id;
        let district_id = originalData.district_id;
        setStateId(state_id);
        setDistrict(district_id);
        await form.setFieldsValue({ ...form.getFieldsValue(), 'state_id': state_id, 'district_id': district_id })
        await handleSaveFunction()
        await modalWarning.destroy();
    }

    let selectNewState = async (modalWarning) => {
        await modalWarning.destroy();
    }

    let clearDistrictAndSave = async (modalWarning) => {
        await setDistrict(null);
        await handleSaveFunction(true)
        await modalWarning.destroy();
    }
    let handleSaveHook = ()=>{
        const modalWarning = Modal.warning();
        modalWarning.update({
            width: '50%',
            title: 'The new State does not match the District currently saved for this account. Do you want to:',
            content: (
                <>
                    <Radio.Group>
                        <Radio onChange={() => revertState(modalWarning)} value={1} >Revert back to the prior State?</Radio>
                        <br/>
                        <Radio onChange={() => clearDistrictAndSave(modalWarning)} value={2}>Clear the district and leave it blank?</Radio>
                        <br/>
                        <Radio onChange={() => selectNewState(modalWarning)} value={3}>Select a new District that is in the new State?</Radio>
                    </Radio.Group>
                </>
            )
        });
    }

    let handleSaveFunction = (allow_district_empty = false) => {

        form.validateFields()
            .then((values) => {
                values.username = values.username.toLowerCase();
                values.email = values.email.toLowerCase();
                let { id, first_name, last_name, job_title, job_function, phone, email, state_id, district_id, school_name,  ppid } = values;
                if(district_id === null && allow_district_empty === false && edit === true) {
                    return handleSaveHook()
                }

                let current_user = JSON.parse(localStorage.getItem("user-data")).id;

                if (current_user === id) {
                    localStorage.setItem('user-data', JSON.stringify(
                        { ...JSON.parse(localStorage.getItem("user-data")), ...{ id, first_name, last_name, job_title, job_function, phone, phone_extension: ext, email, email_assign: emailAssign, state_id, district_id, district: district_id, school_name, school: school_name, ppid } }));
                }

                if (!edit) {
                    onCreate(values, form);
                } else {
                    onEdit(values, form);
                    setUpdatePageControl(false);
                }
            })
            .catch((info) => {
                console.log("info", info);
            });
    }
    return (
        <>
        <Modal
            destroyOnClose={true}
            visible={visible}
            title={edit ? `User Profile - ID ${values.nisl_id}` : "Create new user"}
            okText={edit ? "Update" : "Create"}
            centered
            cancelText="Cancel"
            cancelButtonProps={{ danger: true }}
            onCancel={() => {
                setCourseFormDefault([]);
                handleChange(valuesCreate.courses);
                if (!edit) {
                    onCancel(valuesCreate, form, edit)
                } else {
                    onCancel(values, form, edit)
                    setUpdatePageControl(false);
                }
            }}
            width="92%"
            okButtonProps={{ type: "primary" }}
            bodyStyle={styBodyModal}
            footer={
                <div>
                    {edit && isSuperAdmin ? (
                        <Button
                            type="primary"
                            danger
                            style={{ float: "left" }}
                            onClick={() => {
                                deleteUser(values.id);
                            }}>
                            Delete
                        </Button>
                    ) : ""}

                    <Button
                        onClick={() => {
                            setCourseFormDefault([]);
                            handleChange(valuesCreate.courses);
                            if (!edit) {
                                onCancel(valuesCreate, form, edit)
                            } else {
                                onCancel(values, form, edit)
                                setUpdatePageControl(false);
                            }
                        }}>
                        Cancel
                    </Button>
                    {(edit && !values.roles.includes(SENIOR_ADMIN.id)) ? (<Button className="bg-close-login-as bg-warning"
                        onClick={() => {
                            loginWith(values.id)
                            setUpdatePageControl(false);
                        }}>
                        Login As
                    </Button>) : ''}

                    <Button
                        type="primary"
                        onClick={() => {
                            handleSaveFunction()
                        }}>
                        OK
                    </Button>
                </div>
            }
        >
            <Form
                onKeyUp={(e) => {
                    if (e.key === ENTER_KEY) {
                        handleSaveFunction()
                    }
                }}
                form={form}
                style={{ display: "unset" }}
                initialValues={(edit) ? values : valuesCreate}
                name="user" layout="inline"
                onFieldsChange={() => {
                    setChangeForm(true)
                }}
            >
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={5}>
                        <Form.Item name="id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                            className="hide_rule_message"
                            rules={[{ required: true }]}
                            name="username"
                        >
                            <Input placeholder="Username" disabled={edit}  />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={5}>
                        <Form.Item
                            className="hide_rule_message"
                            rules={(!edit) ? [{ required: true }] : ""}
                            name="password"
                            >
                            <Input autoComplete="new-password" placeholder="Password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={2}>
                        <Form.Item
                            name="honorific">
                            <Input placeholder="Honorific" />
                        </Form.Item>
                    </Col>
                    {ppid && <Col xs={24} sm={12} md={12} lg={8} xl={2}>
                        <Form.Item
                            name="ppid">
                            <Input placeholder="PPID" />
                        </Form.Item>
                    </Col>}
                    <Col xs={24} sm={12} md={12} lg={8} xl={5}>
                        <Form.Item
                            className="hide_rule_message"
                            rules={[{ required: true }]}
                            name="first_name">
                            <Input placeholder="First Name" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={5}>
                        <Form.Item
                            className="hide_rule_message"
                            rules={[{ required: true }]}
                            name="last_name">
                            <Input placeholder="Last Name" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={14} md={14} lg={10} xl={6}>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, type: 'email', message: 'The input is not valid E-mail.' }]}
                        >
                            <Input placeholder="Email" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={11} md={11} lg={7} xl={6}>
                        <Row >
                            <Col span={5} style={{ lineHeight: "normal" }}>
                                {(emailAssign !== "" && emailAssign !== undefined && emailAssign !== null) ? `${emailAssign} email` : ""}
                            </Col>

                            <Col span={11}>
                                <Form.Item name="phone" >
                                    <MaskedInput placeholder="Phone" autoComplete="new-password" mask={'000-000-0000'} name="phone" />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item name="phone_extension">
                                    <Input placeholder="Ext" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={13} md={13} lg={9} xl={5}>
                        <Form.Item name="job_title">
                            <Input placeholder="Job Title" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={14} md={14} lg={10} xl={5}>
                        <Form.Item className="job_function" name="job_function" style={{ width: "110%" }}>
                            <Select
                                placeholder="Job Function"
                                optionFilterProp="children"
                                options={jobFunctions}

                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={14} md={14} lg={10} xl={2} >
                        <Button style={{ padding: "5px 10px 5px 10px", float: "right", marginRight: "15px" }} type="primary" onClick={handleChangeAddress} >{textBtnAddress}</Button>
                    </Col>

                </Row>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px", display: "none" }} id="AddressInfo">
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="address1">
                            <Input placeholder="Address1" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="address2">
                            <Input placeholder="Address2" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="city">
                            <Input placeholder="City" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="ZIPcode">
                            <Input placeholder="ZIP code" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={12} md={12} lg={7} xl={5}>
                        <Form.Item
                            className="hide_rule_message"
                            rules={[{ required: true }]}
                            name="state_id"
                        >
                            <Select
                                showSearch
                                onChange={(value, item) => {
                                    setStateId(value);
                                    setDistrict("");
                                    let values = form.getFieldsValue();
                                    values.district_id = null;
                                    values.school_name = null;
                                    form.setFieldsValue(values)
                                    if (item?.label === "Pennsylvania") {
                                        setPpid(true);
                                    } else {
                                        setIu(null);
                                        setPpid(false);
                                    }
                                }}
                                placeholder="State"
                                options={statesForm}
                                optionFilterProp="children"
                                autoComplete="new-password"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }

                            ></Select>
                        </Form.Item>
                    </Col>
                    {(iu !== null && iu !== undefined) ?
                        <Col xs={24} sm={12} md={12} lg={1} xl={1}>
                            <div style={{ display: "flex", alignItems: "center", height: "90%" }}>IU: {iu}</div>
                        </Col> : ""}
                    <Col xs={24} sm={15} md={15} lg={11} xl={9}>
                        <Districts form={form} stateId={stateId} district={district} setDistrict={setDistrict} setSchool={setSchool} />
                    </Col>
                    <Col xs={24} sm={15} md={15} lg={11} xl={9}>
                        <Schools form={form} district_id={district} school={school} setSchool={setSchool} />
                    </Col>
                </Row>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item name="district_as_entered" label={"(old) District"}>
                            <Input placeholder="District as entered" disabled={true} />
                        </Form.Item>
                    </Col>

                    <Col xs={8} sm={8} md={8} lg={8} xl={8} >
                        <Form.Item name="school_as_entered" label={"(old) School"} >
                            <Input placeholder="School as entered" disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} >
                        <Form.Item name="profile_updated" label={"Profile updated"} >
                            <Input placeholder="Profile updated" disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={14} md={14} lg={10} xl={24}>
                        <Form.Item name="roles"
                            rules={[{ required: true, message: 'Accounts must include at least one Account Priv. Please add Participant or some other Account Priv and then try again.' }]}
                        >
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Priv"
                                options={rolesWithFilter}
                                optionFilterProp="children"
                                autoComplete="new-password"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }
                                onChange={(a, v) => {
                                    if (!isSuperAdmin && v.find(m => m.slug === SENIOR_ADMIN.slug || m.slug === "administrator")) {
                                        Modal.error({
                                            title: '',
                                            content: 'Only a Senior Admin may add Administrator or Senior Admin privs to an account.',
                                        });

                                        let values = form.getFieldsValue();

                                        v.forEach((rol, index) => {
                                            if (rol.slug === SENIOR_ADMIN.slug || rol.slug === "administrator") {
                                                values.roles = values.roles.filter((role) => role !== rol.value);
                                            }
                                        });

                                        form.setFieldsValue(values)
                                    } else {
                                        if (v.find(m => m.slug === "facilitator_national")) {
                                            setShowStaffContractor(true);
                                        } else {
                                            setShowStaffContractor(false);
                                        }
                                    }
                                }}
                                disabled={privilegesDisabled ? true : false}
                            ></Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={14} md={14} lg={10} xl={24} style={{ paddingTop: "0px", paddingBottom: "10px" }}>
                        <Form.Item name="courses">
                            <Select
                                showSearch mode="multiple" placeholder="Course Privileges"
                                optionFilterProp="children" autoComplete="new-password"
                                onChange={handleChange} options={coursesForm}
                                onDeselect={(value) => {
                                    if (value === form.getFieldValue("default_course")) {
                                        form.setFieldsValue({
                                            default_course: form.getFieldValue("courses")[0]
                                        })
                                    }
                                }}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }

                            ></Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={12} md={12} lg={4} xl={4} className="user_state" style={{ paddingRight: '5px' }}>
                        <Form.Item name="status">
                            <Select options={OPTION_STATUS.filter(option => option.value !== 'all_status')} placeholder='Status' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={4} xl={4} className="rich_editor" >
                        <Form.Item
                            valuePropName="checked"
                            name="state_coordinator"
                        >
                            <Checkbox onChange={isStateCoordinator}>
                                Is State Director
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={10} lg={8} xl={5}>
                        {stateCoordinatorComponent}
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={4} xl={2}>
                    <Form.Item
                            valuePropName="checked"
                            name="internal"
                        >
                            <Checkbox>
                                internal
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={4} xl={10} className='course_bookmark'>
                        <Form.Item
                            label="Course Bookmark:"
                            name="default_course">
                            <Select
                                options={courseFormDefault}
                                showSearch
                                placeholder="Course Bookmark"
                                autoComplete="new-password"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }

                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {showStaffContractor ?
                    <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                        <Col xs={24} sm={16} md={10} lg={9} xl={6}>
                            <Form.Item name="staff_contractor" rules={[{ required: true, message: 'You must select either Staff or Contractor.' }]}>
                                <Radio.Group>
                                    <Radio value={"staff"}>Staff</Radio>
                                    <Radio value={"contractor"}>Contractor</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={10} lg={9} xl={10}>
                            <Form.Item name="certifications">
                                <Select placeholder={"Certifications"} mode="multiple">
                                    {certificationsForm.map(v => <Select.Option value={v.id} key={v.id}>{v.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row> : null
                }
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item name="notes">
                            <TextArea placeholder='Notes' rows={2}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" style={styLabels} plain>
                    Update info
                </Divider>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="created_at">
                            <Input
                                disabled={true}
                                placeholder="User created at"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="created_by">
                            <Input
                                disabled={true}
                                placeholder="User created by"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="updated_at">
                            <Input
                                disabled={true}
                                placeholder="User modify at"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="updated_by">
                            <Input
                                disabled={true}
                                placeholder="User modify by"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item label="Cohort:">
                            <Input
                                disabled={true}
                                placeholder="Cohort"
                                value={lastCohort}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={16} xl={16}>
                        {loginLogs}
                    </Col>
                </Row>
                <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item label="Other Cohorts:">
                            <TextareaAutosize
                                disabled={true}
                                value={cohortFormDefault}
                                className={'width100'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
        </>
    );
};

export default CreateUser;
