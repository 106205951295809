import React, { useEffect, useState } from "react"
import API from "../api";
import {Select,Modal,Form} from "antd";

let Districts = ({form, stateId, district, setDistrict, setSchool, rules, label, multiple = false})=>{

    let [districts,setDistricts] = useState([]);

    useEffect(()=>{
        getDistricts()
        //eslint-disable-next-line
    },[stateId])


    let getDistricts = () => {
        if(Array.isArray(stateId)){
            API.post(`api/districts_by_states`, {
                states: stateId,
                district : district
            }).then(({data})=>{
                appendDistricts(data)
            })
        } else if(stateId){
            API.get(`api/districts_by_state/${stateId}/${district}`).then(({data})=>{
                appendDistricts(data)
        })
        }

    }

    let appendDistricts = (data) => {
        setDistricts(data.districts)
        let values =  form.getFieldsValue();
        if(district && !isNaN(district) && data.append){
            values.district_id=district
        }else{
            values.district_id = null
            values.school_name = null
        }
        form.setFieldsValue(values)
    }

    useEffect(() => {
        if (district && districts.length > 0) {
            let values =  form.getFieldsValue();
            if(district && !isNaN(district)){
                values.district_id=district;
            }else{
                values.district_id = null
                values.school_name = null
            }
            form.setFieldsValue(values);
        }
    }, [district, districts, form]);

    return (
        <>
          <Form.Item name="district_id" label={label ? label : ""} rules={rules ? rules : ""}>
           <Select
                mode={multiple ? 'multiple' : ''}
                showSearch
                options={districts}
                placeholder="District"
                optionFilterProp="children"
                autoComplete="new-password"
                allowClear={true}
                filterOption={(input, option) =>
                    option.label.toLowerCase().substr(0,input.length).indexOf(input.toLowerCase()) >= 0
                }
                onChange={async(value)=>{
                    let values =  form.getFieldsValue();
                    if(values.state_id === null || values.state_id === "" || !values.state_id || values.state_id?.length === 0){
                        values.district_id=null;
                        values.school_name=null;
                        Modal.warning({
                            content: "Select your state first, then district, then (if applicable) school.",
                        });
                    }else{
                        values.district_id=value;
                    }
                    setDistrict(value)
                    setSchool(0)
                    values.school = null;
                    values.school_name = null;
                    form.setFieldsValue(values)
                }}
            ></Select>
            </Form.Item>
        </>
    );
}
export default Districts;
