import React, { useState, useEffect } from "react";
import {
    Form,
    Layout,
    Tabs,
    Row,
    Col,
    Button,
    Modal,
    Tag,
    Tooltip,
    Table,
    Spin,
    Space,
    Typography,
} from "antd";
import API from "../../api";
import {
    DeleteOutlined,
    PlaySquareOutlined,
    FilePdfOutlined,
    EditOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import ReactPlayer from "react-player";
import SupportTopicForm from "./SupportTopicForm";
import SupportTabForm from "./SupportTabForm";
import DragSortingTable from "../../components/TableDraggable";
import { createUseStyles } from "react-jss";
const { Text } = Typography;
const useStyles = createUseStyles({
    iconSize: {
        fontSize: "18px"
    },
    deleteColor: {
        color: "red"
    },
    modalPlayer:{
        '& .ant-modal-content': {
            background: "none",
            boxShadow: "none",
        },
        '& .ant-modal-header':{
            borderBottom: "2px solid #b39336",
            backgroundColor: "white",
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            textAlign: "center",
        },
        '& .ant-modal-title':{
            color: "#b39336",
        },
        '& .ant-modal-close': {
            color: "#b39336",
            fontWeight: "normal",
        }

    },
    modalPlayerMask:{
        backgroundColor: "white",
    },
    modalPlayerCloseIcon:{
        border: "1px solid #b39336",
        padding: "4px 16px",
        lineHeight: "25px",
        position: "fixed",
        top: "7px",
        left: "5px",
    },
    supportTitle:{
        textAlign: "center",
        color: "rgb(179, 147, 54)",
        fontWeight: "bold",
        alignSelf: "flex-end",
        padding: "5px",
    },
})

const { Content } = Layout;
const { TabPane } = Tabs;

const Support = () => {
    const classes = useStyles();
    const { roles_un } = JSON.parse(localStorage.getItem("nisl-data"));
    const [showAdminOptions, setShowAdminOptions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorException, setErrorException] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [s3Uri, setS3Uri] = useState("");
    const [modalNewTopicStateHandler, setModalNewTopicStateHandler] = useState(false);
    const [modalEditTopicStateHandler, setModalEditTopicStateHandler] = useState(false);
    const [modalSettingsTabStateHandler, setModalSettingsTabStateHandler] = useState(false);
    const [modalNewTabStateHandler, setModalNewTabStateHandler] = useState(false);
    const [modalEditTabStateHandler, setModalEditTabStateHandler] = useState(false);
    const [modalVideoPlayerStateHandler, setModalVideoPlayerStateHandler] = useState();
    const [formAddTab] = Form.useForm();
    const [formEditTab] = Form.useForm();
    const [formAddTopic] = Form.useForm();
    const [formEditTopic] = Form.useForm();
    const [videoPlayerUrl, setVideoPlayerUrl] = useState("");
    const [pdfInSizeLimit, setPdfInSizeLimit] = useState(true);
    const [videoInSizeLimit, setVideoInSizeLimit] = useState(true);
    const [session, setSession] = useState();
    const [topic, setTopic] = useState();
    const [pdf, setPdf] = useState(null);
    const [video, setVideo] = useState(null);
    const [pdfChanged, setPdfChanged] = useState(false);
    const [videoChanged, setVideoChanged] = useState(false);
    const [topicId, setTopicId] = useState();
    const [progress, setProgress] = useState(0);
    const [topicModalTitle, setTopicModalTitle] = useState(0);

    const [tabs, setTabs] = useState();
    const [items, setItems] = useState();
    const [tabId, setTabId] = useState();

    const [tabName, setTabName] = useState();
    const [hasAccess, setHasAccess] = useState();

    useEffect( () => {
        getTabs();
        if (roles_un.includes("administrator") && !showAdminOptions) {
            setShowAdminOptions(true);
        }
    }, []);

    useEffect(() => {
        getTopics();
    },[tabs])

    const columnsTopicTable = [
        {
            title: "Topic Name",
            dataIndex: "name",
            key: "name",
            width: "69%",
        },
        {
            title: "Files",
            dataIndex: "files",
            key: "files",
            width: "25%",
            align: "right",
            render: (files,record) => {
                return (
                    <>
                        {files?.video && (
                            <a>
                                <Tag
                                    key={files.id}
                                    onClick={() => {
                                        setTopicModalTitle(record.name);
                                        setModalVideoPlayerStateHandler(true);
                                        setVideoPlayerUrl(s3Uri + files.video);
                                    }}
                                    icon={<PlaySquareOutlined />}
                                    style={{minWidth: "100px", textAlign: "center"}}
                                >
                                    play video
                                </Tag>
                            </a>
                        )}
                        {files?.pdf && (
                            <a
                                key={files.id}
                                href={s3Uri + files.pdf}
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                <Tag
                                    icon={<FilePdfOutlined />}
                                    style={{minWidth: "100px", textAlign: "center"}}
                                >
                                    view pdf
                                </Tag>
                            </a>
                        )}
                    </>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            width: "6%",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {showAdminOptions && (
                            <>
                                <Tooltip placement="topLeft" title={`Last modfication ${record.latsUpdate.slice(0,-17)} by ${record.lastUpdater}`}>
                                    <Space>
                                        <EditOutlined
                                            onClick={() => {
                                                setTopicId(record.key);
                                                setSession(record.tabId);
                                                setTopic(record.name);
                                                record.files.pdf ? setPdf({
                                                    uid: record.files.pdf.slice(0,-4),
                                                    name: record.name+record.files.pdf.slice(-4),
                                                    status: 'done',
                                                    response: 'Server Pdf Error 500',
                                                    url: s3Uri + record.files.pdf,
                                                }) : setPdf(null);
                                                record.files.video ? setVideo({
                                                    uid: record.files.video.slice(0,-4),
                                                    name: record.name+record.files.video.slice(-4),
                                                    status: 'done',
                                                    response: 'Server Video Error 500',
                                                    url: s3Uri + record.files.video,
                                                }): setVideo(null);
                                                formEditTopic.resetFields();
                                                setModalEditTopicStateHandler(true);
                                            }}
                                            className={classes.iconSize}
                                        />
                                        <DeleteOutlined onClick={() => deleteItem(record.key)} className={[classes.iconSize, classes.deleteColor]}/>
                                    </Space>
                                </Tooltip>

                            </>
                        )}
                    </>
                );
            },
        },
    ];

    const columnTabsTable = [
        {
            key: "name",
            dataIndex: "name",
        },
        {
            title: "Public visibility",
            key: "publicVisibility",
            align: "right",
            render: (record) => {
                return (
                    <>
                        <Tag>{record.hasAccess}</Tag>
                    </>
                );
            },
        },
        {
            key: "actions",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        {showAdminOptions && (
                                <>
                                <Tooltip placement="topLeft" title={`Last modfication ${record.latsUpdate.slice(0,-17)} by ${record.lastUpdater}`}>
                                    <Space >
                                        <EditOutlined
                                            onClick={() => {
                                                setTabId(record.key);
                                                setHasAccess(record.hasAccess);
                                                setTabName(record.name);
                                                formEditTab.resetFields();
                                                setModalSettingsTabStateHandler(false);
                                                setModalEditTabStateHandler(true);

                                            }}
                                            className={classes.iconSize}
                                        />
                                        <DeleteOutlined onClick={() => deleteTab(record.key)} className={[classes.iconSize, classes.deleteColor]} />
                                    </Space>
                                </Tooltip>

                            </>
                        )}
                    </>
                );
            },
        },
    ];

    const callBackDrag = (source,target) => {
        API.post("api/support/session/item/sequence", {
            source_id: source.key,
            target_id: target.key,
        })
        .then((data) => {
            console.log(data.data)
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
        });
    };
    const callBackDragTabs = (source,target) => {
        API.post("api/support/session/sequence", {
            source_id: source.key,
            target_id: target.key,
        })
        .then((data) => {
            getTabs();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {});
    };

    const getTabs = () => {
        setLoading(true);
        API.post("api/support/session/state", { is_active: true })
            .then((data) => {
                setTabs(data.data.support_sessions.map((el) => {
                    return {
                        key: el.id,
                        name: el.name,
                        hasAccess: el.has_access,
                        sequence: el.sequence,
                        lastUpdater: el.first_name +' '+el.last_name,
                        latsUpdate: el.updated_at,
                    };
                }));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTopics = () => {
        setLoading(true);
        API.post("api/support/session/item/state", { is_active: true })
            .then((data) => {
                setItems(data.data.support_items.map((el) => {
                    return {
                        key: el.id,
                        name: el.name,
                        lastUpdater: el.first_name +' '+el.last_name,
                        latsUpdate: el.updated_at,
                        tabId: el.support_session_id,
                        files: {
                            pdf: el.pdf,
                            video: el.video
                        }
                    };
                }));
                setS3Uri(data.data.s3_uri);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteItem = (id) => {
        console.log('aca la peticion');
        Modal.confirm({
            title: 'Are you sure you want to delete this permanently?',
            icon: <ExclamationCircleOutlined style={{color: "#ecd62a"}} />,
            onOk() {
                API.post("api/support/session/item/" + id, { is_active: false })
                .then((data) => {
                    console.log(data);
                    setItems(items.filter(el=>el.key !== data.data.support_item.id));
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {});
            }
        });
    };

    const editItem = (values) => {
        setLoading(true);
        const formEditItem = new FormData();
        formEditItem.append('pdf_change', pdfChanged);
        formEditItem.append('video_change', videoChanged);
        typeof values.pdf !== "undefined" && values.pdf !== "undefined" && formEditItem.append("file_pdf", values.pdf.file);
        typeof values.video !== "undefined" && values.video !== "undefined" && formEditItem.append("file_video", values.video.file);

        formEditItem.append("support_session_id", values.session);
        formEditItem.append("name", values.topic);

        API.post("api/support/session/item/" + topicId, formEditItem, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        })
        .then((data) => {
            setModalEditTopicStateHandler(false);
            const resItem = data.data.support_item;
            setItems(
                items.reduce( (acc,el) => {
                    if( el.key === resItem.id){
                        ;
                        return [...acc, {
                            key: resItem.id,
                            name: resItem.name,
                            lastUpdater: resItem.first_name +' ' +resItem.last_name,
                            latsUpdate: resItem.updated_at,
                            tabId: parseInt(resItem.support_session_id),
                            files: {
                                pdf: resItem.pdf,
                                video: resItem.video
                            }
                        }]
                    }else{
                        return [...acc, el];
                    }
                },
                [],
                )
            );
            setProgress(0);
            setLoading(false);
        })
        .catch((error) => {
            if(error?.response?.status === 413) {
                setLoading(false);
                setErrorMessage("Payload Too Large");
            }
        })
        .finally(() => {});
    };

    const editTab = (values) => {
        API.post("api/support/session/" + tabId, {
            name: values.tab,
            has_access: values.has_access,
        })
        .then((data) => {
            setModalEditTopicStateHandler(false);
            const resTab = data.data.support_session;
            setTabs(
                tabs.reduce( (acc,el) => {
                    if(el.key === resTab.id){
                        return [...acc, {
                            key: resTab.id,
                            name: resTab.name,
                            hasAccess: resTab.has_access,
                            sequence: resTab.sequence,
                            lastUpdater: resTab.first_name +' '+el.last_name,
                            latsUpdate: resTab.updated_at,
                        }]
                    }else{
                        return [...acc, el];
                    }
                },
                [],
                )
            );

        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
        });
    };

    const storageItem = (values) => {
        setLoading(true);
        let formData = new FormData();
        typeof values.pdf !== "undefined" && values.pdf !== "undefined" && formData.append("file_pdf", values.pdf.file);
        typeof values.video !== "undefined" && values.video !== "undefined" && formData.append("file_video", values.video.file);
        formData.append("support_session_id", values.session);
        formData.append("name", values.topic);

        API.post("api/support/session/item", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        })
            .then((data) => {
                formAddTopic.resetFields();

                const resItem = data.data.support_item
                setItems([
                    {
                        key: resItem.id,
                        name: resItem.name,
                        lastUpdater: resItem.first_name +' '+resItem.last_name,
                        latsUpdate: resItem.updated_at,
                        tabId: parseInt(resItem.support_session_id),
                        files: {
                            pdf: resItem.pdf,
                            video: resItem.video
                        }
                    },
                    ...items,
                ]);
                setModalNewTopicStateHandler(false);
                setLoading(false);
                setProgress(0);
            })
            .catch((error) => {
                if(error?.response?.status === 413) {
                    setLoading(false);
                    setErrorMessage("Payload Too Large");
                }
            })
            .finally(() => {

            });
    };

    const storageTab = (values) => {
        const tabData = new FormData();
        tabData.append("name", values.tab);
        tabData.append("has_access", values.has_access);
        API.post("api/support/session", tabData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((data) => {
                formAddTab.resetFields();
                const resTab = data.data.support_session;
                setTabs([
                    {
                        key: resTab.id,
                        name: resTab.name,
                        hasAccess: resTab.has_access,
                        sequence: resTab.sequence,
                        lastUpdater: resTab.first_name +' '+resTab.last_name,
                        latsUpdate: resTab.updated_at,
                    },
                    ...tabs,
                ]);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setModalNewTopicStateHandler(false);
            });
    };

    const handleCancelNewTab = () => {
            setModalSettingsTabStateHandler(true);
            setModalNewTabStateHandler(false);
    };

    const handleOkNewTab = () => {
        formAddTab.submit();
        setModalSettingsTabStateHandler(true);
        setModalNewTabStateHandler(false);
    }

    const handleCancelNewTopic = () => {
        formAddTab.resetFields();
        setModalNewTopicStateHandler(false);
        setErrorMessage("");
    }

    const handleCancelEditTopic = () => {
        setModalEditTopicStateHandler(false);
        setErrorMessage("");
    };

    const deleteTab = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this permanently?',
            icon: <ExclamationCircleOutlined style={{color: "#ecd62a"}} />,
            onOk() {
                API.post("api/support/session/" + id,{ is_active: false })
                    .then((data) => {
                        setTabs(tabs.filter((el) => el.key !== data.data.support_session.id));
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {});
            }
        });
    };

    return (
        <>
            <Content className="container-fluid site-layout-background layout-portal-content">
                <br />
                <Row>
                    <Col
                        span={8}
                        style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                        {tabs && showAdminOptions && (
                            <Button
                                type={"primary"}
                                onClick={() =>
                                    setModalNewTopicStateHandler(true)
                                }
                            >
                                Add Topic
                            </Button>

                        )}
                    </Col>
                    <Col
                        span={8}
                        className={classes.supportTitle}
                    >
                        Support
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }} />
                </Row>
                <Row justify="center">
                    <Text strong>
                        For assistance with the NCEE Portal, contact the helpdesk between the hours fo 8am to 6pm M-F (ET) at helpdesk@ncee.org or 855-443-6359.
                    </Text>
                </Row>
                <Row
                    justify="center"
                    style={{ height: "100%", padding: "15px" }}
                >
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                { !tabs ?
                                    <Spin tip="Loading..." style={{ minHeight: "80vh" }}>
                                        <div type="info" style={{ height: "80vh" }}></div>
                                    </Spin> :
                                    <Tabs
                                        tabBarExtraContent = { tabs && showAdminOptions &&
                                            <SettingOutlined
                                                    onClick={() =>
                                                        setModalSettingsTabStateHandler(
                                                            true
                                                        )
                                                    }
                                                />
                                            }

                                        defaultActiveKey="1"
                                        style={{ marginTop: "20px" }}
                                        tabPosition="top"
                                        animated="bottom"
                                        type="card"
                                        className="tabs-support"
                                    >
                                        {tabs &&
                                            tabs.map((tab) => {
                                                return (
                                                    <TabPane
                                                        tab={tab.name}
                                                        key={tab.key}
                                                    >
                                                        {
                                                            items && (
                                                                showAdminOptions ?
                                                                    <DragSortingTable
                                                                        dataTable={{
                                                                            data: items.filter(item => {
                                                                                return item.tabId === tab.key;
                                                                            }),
                                                                            columns: columnsTopicTable,
                                                                        }}
                                                                        showHeader={false}
                                                                        pagination={false}
                                                                        callBackDrag={
                                                                            showAdminOptions && callBackDrag
                                                                        }
                                                                    /> :
                                                                    <Table
                                                                    showHeader={false}
                                                                    columns={columnsTopicTable}
                                                                    dataSource={items.filter(item => {
                                                                        return item.tabId === tab.key;
                                                                    })}
                                                                    pagination={false}
                                                                />
                                                            )

                                                        }
                                                    </TabPane>
                                                );
                                            })
                                        }
                                    </Tabs>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {modalNewTopicStateHandler && (
                    <Modal
                        key="newTopic"
                        visible={modalNewTopicStateHandler}
                        title="Add Topic"
                        onCancel={() => !loading && handleCancelNewTopic()}
                        onOk={!loading && formAddTopic.submit}
                        width={690}
                        okButtonProps={{ disabled: !pdfInSizeLimit || !videoInSizeLimit || loading}}
                        cancelButtonProps={{ disabled: loading}}
                    >
                        <Spin tip={`Loading...progress: ${progress}%`} spinning={loading}>
                            <SupportTopicForm
                                sessionOptions = { tabs }
                                name = "new_topic_session"
                                form = {formAddTopic}
                                errorException = {errorException}
                                errorMessage = {errorMessage}
                                onFinish = {storageItem}
                                callBackUploadPdf = {(bool) => setPdfInSizeLimit(bool) }
                                callBackUploadVideo = {(bool) => setVideoInSizeLimit(bool)}
                            />
                        </Spin>
                    </Modal>
                )}
                {modalEditTopicStateHandler && (
                    <Modal
                        key="EditTopic"
                        visible={modalEditTopicStateHandler}
                        title="Edit Topic"
                        onCancel={() => !loading && handleCancelEditTopic()}
                        onOk={formEditTopic.submit}
                        width={690}
                        okButtonProps={{ disabled: !pdfInSizeLimit || !videoInSizeLimit || loading}}
                        cancelButtonProps={{ disabled: loading}}
                    >
                        <Spin tip={`Loading...progress: ${progress}%`} spinning={loading}>
                            <SupportTopicForm
                                sessionOptions = { tabs }
                                name="edit_topic_session"
                                form={formEditTopic}
                                errorException={errorException}
                                errorMessage={errorMessage}
                                onFinish={editItem}
                                session={session}
                                topic={topic}
                                video={video}
                                pdf = { pdf || null}
                                callBackUploadPdf={(bool) => { setPdfInSizeLimit(bool); setPdfChanged(true)}}
                                callBackUploadVideo={(bool) => { setPdfInSizeLimit(bool); setVideoChanged(true)}}
                            />
                        </Spin>
                    </Modal>
                )}
                {modalVideoPlayerStateHandler && (
                    <Modal
                        key="videoPlayer"
                        visible={modalVideoPlayerStateHandler}
                        title={topicModalTitle}
                        onCancel={() => setModalVideoPlayerStateHandler(false)}
                        width={'50%'}
                        footer=""
                        maskClosable={false}
                        className={classes.modalPlayer}
                        maskStyle={{backgroundColor: "#f5f5f5"}}
                        closeIcon={<div className={classes.modalPlayerCloseIcon}>DONE</div>}
                    >
                        <ReactPlayer
                            width='100%'
                            height='100%'
                            url={videoPlayerUrl}
                            controls={true} />
                    </Modal>
                )}
                {modalSettingsTabStateHandler && (
                    <Modal
                        transitionName="none"
                        maskTransitionName="none"
                        key="settingTabs"
                        visible={modalSettingsTabStateHandler}
                        title="Setting Tabs"
                        onCancel={() => setModalSettingsTabStateHandler(false)}
                        onOk={() => {
                            setModalNewTabStateHandler(true);
                            setModalSettingsTabStateHandler(false);
                        }}
                        width={690}
                        okText="Add Tab"
                        cancelText="Close"
                    >
                        <DragSortingTable
                            pagination={false}
                            dataTable={{ data: tabs, columns: columnTabsTable }}
                            loading={loading}
                            callBackDrag={callBackDragTabs}
                            showHeader={false}
                        />
                    </Modal>
                )}
                {modalNewTabStateHandler && (
                    <Modal
                        transitionName="none"
                        maskTransitionName="none"
                        key="newTab"
                        visible={modalNewTabStateHandler}
                        title="New Tabs"
                        onCancel={handleCancelNewTab}
                        onOk={handleOkNewTab}
                        width={690}
                    >
                        <SupportTabForm
                            name="new_tab_session"
                            form={formAddTab}
                            errorException={errorException}
                            errorMessage={errorMessage}
                            onFinish={storageTab}
                        />
                    </Modal>
                )}
                {modalEditTabStateHandler && (
                    <Modal
                        transitionName="none"
                        maskTransitionName="none"
                        key="editTab"
                        visible={modalEditTabStateHandler}
                        title="Edit Tab"
                        onCancel={() => {
                            setModalSettingsTabStateHandler(true);
                            setModalEditTabStateHandler(false);
                        }}
                        onOk={() => {
                            formEditTab.submit();
                            setModalSettingsTabStateHandler(true);
                            setModalEditTabStateHandler(false);
                        }}
                        width={690}
                    >
                        <SupportTabForm
                            name="Edit_tab_session"
                            form={formEditTab}
                            errorException={errorException}
                            errorMessage={errorMessage}
                            onFinish={editTab}
                            hasAccess={hasAccess}
                            tabName={tabName}
                        />
                    </Modal>
                )}
            </Content>
        </>
    );
};

export default Support;
