
import React, { useRef, useState } from "react";
import API from "../api";
import { arrayColumn } from "../functions/arrayColumn.js"
import moment from "moment";
import { Row, Col, Divider } from "antd";

export const UserSchedule = () => {

    const SUBTRACT_EVENTS = 2;

    let [bodyEvents, setBodyEvents] = useState({});
    let [dataUserSchedule, setDataUserSchedule] = useState([]);
    let [userScheduleModal, setUserScheduleModal] = useState(false);
    let [showEvents, setShowEvents] = useState();
    let [setFromUse] = useState();
    let last_cohort = useRef();

    let getScheduleUser = (course, user_id = null, cohorts_arr = [], cohort_id = null) => {
        API.get(`api/user_schedule/${course}/${(user_id !== null) ? user_id : ""}/${(cohort_id !== null) ? cohort_id : ""}`).then(({ data }) => {
            if (data.show_user_schedule !== false){
                let today = moment(new Date())
                let events = setNextEventClass(data.events);
                let year = "";
                let body = events.map((element) => {
                    let change_of_year=null;
                    if(element.start_date !== null && year !== moment(element.start_date).format("YYYY")){
                        year = moment(element.start_date).format("YYYY");
                        change_of_year = <Row gutter={[0, 5]} style={{ backgroundColor: "#fff", fontWeight: "bold" }}>
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <div style={{ textAlign: "center", height: "35px" }}>
                                    <span style={{ fontSize: "22px" }}>
                                        {year}
                                    </span>
                                </div>
                            </Col>
                            <Col className="year_blank" xs={24} sm={24} md={12} lg={20} xl={20}>
                                <div style={{ textAlign: "center", height: "35px" }}>
                                </div>
                            </Col>
                        </Row>
                    }

                    let div_calendar = <><Row gutter={[0, 5]} key={element.id} className={(element.scroll === true) ? "initial_scroll" : ""}>
                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            <div
                                style={{ textAlign: 'center', height: "55px" }}
                                className="day-info"
                            >
                                <div style={{ lineHeight: "17px" }}>
                                    <span style={{ fontSize: "13px" }}>
                                        { (element.start_date !== null) ? moment(element.start_date).format("ddd").toUpperCase() : " " }
                                    </span>{" "}
                                    <br />
                                    <span style={{ fontSize: "18px" }}>
                                        { (element.start_date !== null) ? moment(element.start_date).format("DD") : "TBD" }
                                    </span>
                                    <br />
                                    <span style={{ fontSize: "13px" }}>
                                        { (element.start_date !== null) ? moment(element.start_date).format("MMMM") : ""}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                           { (element?.is_makeup) ?
                            <div
                                className="column_makeups"
                                style={{
                                    padding: "0px 10%",
                                    height: "55px",
                                    fontSize : "12px"
                                }}
                            >

                                {element.unit_id !== "orientation" ? <span> Unit { (element.type_event.slug !== "hours" ) ? element.unit_id : element.event_id}
                                { (element.type_event.slug !== "hours" ) ? (element.day !== null) ? ` - Day  ${element.day}`: "" : " " }
                                {" "}
                                {element.start_time !== null && element.start_time !== undefined ? "at " + element.start_time : " " }
                                {element.start_time !== null && element.start_time !== undefined ? " - " : ""}
                                {element.end_time !== null && element.end_time !== undefined  ? element.end_time : " " }
                                {" "}
                                { (element.utc !== null && ((element.start_time !== null && element.start_time !== undefined) || (element.end_time !== null && element.end_time !== undefined)))
                                ? element.utc : "" }</span>: "Orientation"}
                                <br/>
                                <span>make-up in cohort: {element?.cohort?.name}</span>
                                <br/>
                                <span>facilitator: {element.facilitators?.length ? element.facilitators.map((fac) => fac?.username).join(", ") : "TBD"}</span>
                            </div>
                            : <div
                                className="column_pdf"
                                style={{
                                    padding: "15px",
                                    paddingLeft:'10%',
                                    height: "55px",
                                    fontSize : "17px"
                                }}
                            >

                                {element.unit_id !== "orientation" ? <span> Unit { (element.type_event.slug !== "hours" ) ? element.unit_id : element.event_id}
                                { (element.type_event.slug !== "hours" ) ? (element.day !== null) ? ` - Day  ${element.day}`: "" : " " }
                                {" "}
                                {element.start_time !== null && element.start_time !== undefined ? "at " + element.start_time : " " }
                                {element.start_time !== null && element.start_time !== undefined ? " - " : ""}
                                {element.end_time !== null && element.end_time !== undefined  ? element.end_time : " " }
                                {" "}
                                { (element.utc !== null && ((element.start_time !== null && element.start_time !== undefined) || (element.end_time !== null && element.end_time !== undefined)))
                                ? element.utc : "" }</span>: "Orientation"}
                                </div>
                           }
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={12} lg={12}>
                            <div
                                className="column_attendance"
                                style={{
                                    height: "55px",
                                    fontSize: "17px",
                                    paddingTop: "2.8px",
                                    paddingBottom: "2.8px",
                                    paddingRight: "0px",
                                    paddingLeft: "0px",
                                }}
                            >
                                <div style={{ width:"100%" ,float : "right" }}>
                                    {
                                        element.unit_id !== "orientation" ?
                                            element?.attendance.attendance && moment(element.attendance.date).isSameOrBefore(today) ?
                                                (moment(element.attendance.date).isSameOrBefore(today) && element.attendance.attendance === "1") ?
                                                    <div className="attendance_mark" style={{ color: "rgb(91, 160, 55)", fontSize :"16px", marginTop: "13px"}}>
                                                        Your facilitator marked you as in attendance.
                                                    </div>
                                                : element.attendance.date !== "" && (moment(element.attendance.date).isSameOrBefore(today) && element.attendance.attendance === "0") ?
                                                    <div className="attendance_mark red" style={{ fontWeight: "bold", fontSize :"16px" , color :"#f31515", marginTop: "13px" }}>
                                                        Your facilitator has marked you as not in attendance.
                                                    </div>
                                                    : ""
                                            :
                                                (moment(element.start_date).isBefore(today)) ?
                                                    <div className="attendance_mark" style={{ color: "#022647", fontSize :"16px", marginTop: "13px"}}>
                                                        Your facilitator has not yet recorded attendance.
                                                    </div>
                                                : ""
                                        : ""
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Divider className="divider-user-schedule" style={{ margin :"0px" }}/>
                    </>

                    if (cohorts_arr.includes(parseInt(element.cohort_id))) {
                        last_cohort = element.cohort_id;
                    }
                    if(change_of_year !== null){
                        return [change_of_year, <Divider className="divider-user-schedule" style={{ margin :"0px" }}/>,div_calendar];
                    }else{
                        return [div_calendar];
                    }
                });
                if(cohorts_arr.length === 0) {
                    setBodyEvents(body)
                    setDataUserSchedule(data)
                }else{
                    setBodyEvents(prevBody => ({  ...prevBody, [last_cohort]: body }))
                    setDataUserSchedule(prevData =>({...prevData, [last_cohort]: data}))
                }
                setUserScheduleModal(true)
            }else{
                setUserScheduleModal(false)
            }
        }).catch((response)=>{
            setUserScheduleModal(false)
        });
    };

    let setNextEventClass = (events) => {
        let next = null;
        let events_from = null
        let new_events = events.filter(e => e.start_date !== null);
        let start_dates = arrayColumn(new_events, "start_date")
        let today = moment(new Date()).format("YYYY-MM-DD");
        next = start_dates.map(function (date) {
            return moment(date, "YYYY-MM-DD");
        }).findIndex(function (m) { return m.isSameOrAfter(today); });

        let index_from_event = next - SUBTRACT_EVENTS;

        if (start_dates[index_from_event] !== undefined) {
           events_from = moment(start_dates[index_from_event], "YYYY-MM-DD");
        }

        events.map(event => {
            if (events_from !== null && event?.start_date !== null && event?.start_date !== undefined && event?.start_date === events_from?.format("YYYY-MM-DD HH:mm:ss")) {
                event.scroll = true;
            } else {
                event.scroll = false
            }
            return event;
        })
        return events
    }

    let setFrom = (value) => {
        setFromUse(value)
    }
    return {
        bodyEvents,
        dataUserSchedule,
        userScheduleModal,
        showEvents,
        getScheduleUser,
        setShowEvents,
        setFrom
    }
};



