import React from 'react';
import { Row, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { EXECUTIVE_COACHING } from '../../../../config/courses';

const useStyles = createUseStyles({
    blue: {
        color: "#0051a0",
    },
    black: {
        color: "#002345",
    },
    bold: {
        fontWeight: "bold",
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '6em',
    },
    solid: {
        color: '#0051a0',
        backgroundColor: '#0051a0',
        width: '0.5em',
        height: '0.5em',
        borderRadius: '0.25em',
        margin: '0.5em 0.25em',
    },
    deleteColor: {
        color: "red",
    },
    space: {
        whiteSpace: 'pre-wrap',
    },
});


const CohortInfo = ({ ...props }) => {
    const classes = useStyles(props);
    const city = props.cohortInfo.cohortCity ? `${props.cohortInfo.cohortCity},` : '';
    return (
        !props.loading ? <>
            {props.printable && <Row align='center' className={`${classes.black} ${classes.bold}`}>
                {`Sign In Sheet`}
            </Row>}
            <Row className={`${classes.black} ${classes.bold}`}>
                {!props.cohortInfo?.cohortDeliveryInfo?.is_virtual ?
                    `${city} ${props.cohortInfo.cohortCodeState} * ${props.cohortInfo.cohortName}`
                    : `${props.cohortInfo.cohortName}`}
            </Row>
            {props.fromSurvey ? <>
                <Row className={`${classes.bold}`}>
                    {`Unit ${props.unit} - beginning ${props.isExtended ? props.minDateExt : props.startDate} and ending ${props.isExtended ? props.maxDateExt : props.endDate}`}
                </Row>
                <Row className={`${classes.bold}`}>
                    {`Facilitator: ` }
                    {props.cohortInfo.facilitators?.map((el) => `${el.firstName} ${el.lastName}`).join(', ') || 'UNASSIGNED'}
                </Row></>

            : ""}
            <Row className={`${classes.blue} ${classes.bold}`}>
                {`${props.cohortInfo.course}`}
            </Row>
            {props.printable && <Row className={`${classes.blue} ${classes.bold}`}>
                {`Unit ${props.eventInfo.eventId}`}
            </Row>}
            <Row className={classes.blue}>
                {parseInt(props.cohortInfo?.courseId) !== EXECUTIVE_COACHING ?  `${props.cohortInfo.totalParticipants || 0} participants`.concat(props.hasMakeups ? ` + ${props.cohortInfo.totalMakeups || 0} guests added` : '')
                : props.cohortInfo.totalParticipants === 1 ? `${props.cohortInfo.totalParticipants} coachee`  : `${props.cohortInfo.totalParticipants} coachees`
                }
                {(props.cohortInfo.totalMakeups && props.hasMakeups) ? <span className={classes.space}> (red <CloseOutlined className={`${classes.deleteColor}`} /> below)</span> : ''}
            </Row>
            <Row className={classes.blue}>
                <span className={`${classes.black} ${classes.space}`}>Delivery: </span> {`${props.cohortInfo.deliveryModel}`}
            </Row></> : <Spin spinning={true} className={classes.center} />

    )
}

CohortInfo.defaultProps = {
    printable: false,
    hasMakeups: false,
}
export default CohortInfo;
