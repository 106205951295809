import React from "react";
import Icon from "@ant-design/icons";

const CommunitiesOutlinedSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 21.579 15.638"
    >
        <g
            id="Grupo_21"
            data-name="Grupo 21"
            transform="translate(-834.337 -1251.059)"
        >
            <g
                id="Grupo_14"
                data-name="Grupo 14"
                transform="translate(841.941 1251.059)"
            >
                <path
                    id="Trazado_14"
                    data-name="Trazado 14"
                    d="M846.441,1258.29a3.636,3.636,0,1,1,3.249-3.616,3.454,3.454,0,0,1-3.249,3.616Zm0-5.513a1.941,1.941,0,1,0,1.532,1.9,1.746,1.746,0,0,0-1.532-1.9Z"
                    transform="translate(-843.189 -1251.059)"
                    fill="currentColor"
                />
            </g>
            <g
                id="Grupo_15"
                data-name="Grupo 15"
                transform="translate(839.464 1259.307)"
            >
                <path
                    id="Trazado_15"
                    data-name="Trazado 15"
                    d="M850.579,1268.052h-9.207a.86.86,0,0,1-.857-.8c-.07-1-.137-1.994-.2-2.975a3.185,3.185,0,0,1,2-2.98,10.859,10.859,0,0,1,7.313,0,3.176,3.176,0,0,1,2.012,2.987l-.2,2.971a.86.86,0,0,1-.857.8Zm-8.407-1.718h7.605l.148-2.17a1.486,1.486,0,0,0-.882-1.254,9.17,9.17,0,0,0-6.141,0,1.488,1.488,0,0,0-.875,1.251c.046.717.1,1.444.145,2.171Z"
                    transform="translate(-840.306 -1260.661)"
                    fill="currentColor"
                />
            </g>
            <g
                id="Grupo_16"
                data-name="Grupo 16"
                transform="translate(839.464 1259.307)"
            >
                <path
                    id="Trazado_16"
                    data-name="Trazado 16"
                    d="M850.579,1268.052h-9.207a.86.86,0,0,1-.857-.8c-.07-1-.137-1.994-.2-2.975a3.185,3.185,0,0,1,2-2.98,10.859,10.859,0,0,1,7.313,0,3.176,3.176,0,0,1,2.012,2.987l-.2,2.971a.86.86,0,0,1-.857.8Zm-8.407-1.718h7.605l.148-2.17a1.486,1.486,0,0,0-.882-1.254,9.17,9.17,0,0,0-6.141,0,1.488,1.488,0,0,0-.875,1.251c.046.717.1,1.444.145,2.171Z"
                    transform="translate(-840.306 -1260.661)"
                    fill="currentColor"
                />
            </g>
            <g
                id="Grupo_17"
                data-name="Grupo 17"
                transform="translate(851.064 1259.547)"
            >
                <path
                    id="Trazado_17"
                    data-name="Trazado 17"
                    d="M858.053,1266.7h-3.666a.43.43,0,1,1,0-.859h3.265l.149-2.165a1.592,1.592,0,0,0-.966-1.4,8.134,8.134,0,0,0-2.6-.473.43.43,0,0,1,.006-.859h.009a8.969,8.969,0,0,1,2.879.525,2.448,2.448,0,0,1,1.532,2.264l-.176,2.565a.431.431,0,0,1-.43.4Z"
                    transform="translate(-853.81 -1260.941)"
                    fill="currentColor"
                />
            </g>
            <g
                id="Grupo_18"
                data-name="Grupo 18"
                transform="translate(850.192 1253.46)"
            >
                <path
                    id="Trazado_18"
                    data-name="Trazado 18"
                    d="M855.09,1259.009a2.6,2.6,0,1,1,2.292-2.578,2.452,2.452,0,0,1-2.292,2.578Zm0-4.3a1.747,1.747,0,1,0,1.433,1.719,1.6,1.6,0,0,0-1.433-1.719Z"
                    transform="translate(-852.795 -1253.854)"
                    fill="currentColor"
                />
            </g>
            <g
                id="Grupo_19"
                data-name="Grupo 19"
                transform="translate(834.337 1259.547)"
            >
                <path
                    id="Trazado_19"
                    data-name="Trazado 19"
                    d="M838.612,1266.7h-3.667a.428.428,0,0,1-.428-.4l-.176-2.566a2.446,2.446,0,0,1,1.528-2.263,8.985,8.985,0,0,1,2.883-.526.449.449,0,0,1,.437.423.429.429,0,0,1-.423.436,8.123,8.123,0,0,0-2.6.474,1.591,1.591,0,0,0-.965,1.4l.148,2.165h3.266a.43.43,0,0,1,0,.859Z"
                    transform="translate(-834.337 -1260.941)"
                    fill="currentColor"
                />
            </g>
            <g
                id="Grupo_20"
                data-name="Grupo 20"
                transform="translate(835.475 1253.46)"
            >
                <path
                    id="Trazado_20"
                    data-name="Trazado 20"
                    d="M837.954,1259.009a2.6,2.6,0,1,1,2.3-2.578,2.45,2.45,0,0,1-2.3,2.578Zm0-4.3a1.747,1.747,0,1,0,1.436,1.719,1.6,1.6,0,0,0-1.436-1.719Z"
                    transform="translate(-835.662 -1253.854)"
                    fill="currentColor"
                />
            </g>
        </g>
    </svg>
);

export const CommunitiesOutlined = (props) => (
    <Icon component={CommunitiesOutlinedSvg} {...props} />
);
