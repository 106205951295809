import React from "react";
import { Space,Button,Table,Switch } from "antd";
import  moment  from "moment";
import "../UserModules/Users/users.less";

const ListsBanner = ({ObjectBanners,handleEdit,handleDelete}) => {
    const columns = [
        {
            title: ("Banner Text"),
            dataIndex: "text",
            className :"title_banner",
            rowKey: "text",
            render : (record) =>{
                return ( <div style={{ width : "400px", textAlign: "center" }}  dangerouslySetInnerHTML={{
                    __html: (record),
                }}></div>)
            }
        },
        {
            title: "Status",
            rowKey: "status",
            dataIndex: "active",
            width: 110,
            render: (text,record) =>
                <>
                    <Switch
                        onClick={() => {
                            handleDelete(record.id);
                        }}
                        defaultChecked={record.active}
                        checked={record.active}
                    />
                </>

        },
        {
            title: "Created",
            dataIndex: "created_at",
            width: 110,
            rowKey: "created_at",
            align: "center",
            className : "column_without_padding",
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {record.who_created.last_name} <br /> {moment(value).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },

        {
            title: "Activated",
            width: 110,
            rowKey: "who_activated",
            dataIndex: "who_activated",
            align: "center",
            className : "column_without_padding",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {value.last_name}
                            <br />
                            {moment(record.activated_at).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "De-Activated",
            dataIndex: "who_deactivated",
            rowKey: "who_deactivated",
            width: 110,
            align: "center",
            className : "column_without_padding",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {value.last_name}
                            <br />
                            {moment(record.activated_at).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
       {
        defaultSortOrder: "descend",
        title : "Modified",
        dataIndex: "who_updated",
        width: 110,
        rowKey: "who_updated",
        align: "center",
        className : "column_without_padding",
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        render: (value, record) => {
            if(value){
                return (
                    <span>
                        {value.last_name}
                        <br />
                        {moment(record.updated_at).format("YY-MM-DD hh:mm")}
                    </span>
                )
            }else{
                return null;
            }
        }
    },
    {
        title: "Action",
        rowKey: "action",
        fixed: "right",
        width: "5%",
        align: "center",
        className :"column_without_padding table_action",
        render: (text, record) => (
            <>
                <Space size="middle">
                    <Button
                        type="primary"
                        onClick={() => {
                            handleEdit(record);
                        }}
                    >
                        Edit
                    </Button>
                </Space>
            </>
        ),
    },
    ]
    return (
        <>
            <Table    columns={columns} dataSource={ObjectBanners}></Table>
        </>
    );
};
export default ListsBanner;
