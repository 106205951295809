import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Layout, Menu, Dropdown, Avatar, Row, Col, Modal } from "antd";
import {
    LogoutOutlined,
    UserOutlined,
    LockedOutlined,
} from "./../../assets/icons";
import { HelpdeskOutlined } from "../../assets/icons/HelpdeskOutlined";
import { CalendarUser } from "../../assets/icons/CalendarUser";
import { SettingOutlined } from "@ant-design/icons";
import defaultImage from "./../../assets/img/faces/face-0.jpg";
import { getUserData } from "../../functions/getUserData";
import PolicyModal from "../PolicyModal";
import API from "../../api";
import checkImage from "../../functions/checkImageExist";
import ChangePassword from "../../components/ChangePassword";

const { Header } = Layout;
const dropdownStyle = {
    margin: "0px 18px",
    padding: "4px 0",
    position: "absolute",
    right: "-13px",
    lineHeight: "initial",
    height: "100%",
};

export const logOut = async () => {
    let user_master = JSON.parse(localStorage.getItem("master-user"));
    if (user_master !== null) {
        await API.get(`/api/user/loginas/${user_master.key}/${true}`).then(
            ({ data }) => {
                localStorage.removeItem("master-user");
            }
        );
    }

    await API.post(`/api/logout`).then(({ data }) => { console.log(data) });
    await localStorage.clear();
    await sessionStorage.clear();
    await window.location.replace("/login");
};

const HeaderComponent = ({ children }) => {
    const { pathname, search } = useLocation();
    const history = useHistory();
    const { avatar: avatarImg, first_name, last_name, email } = JSON.parse(
        localStorage.getItem("user-data")
    );

    let { roles_un } = JSON.parse(localStorage.getItem("nisl-data"));
    const { token } = JSON.parse(localStorage.getItem("nisl-data"));
    const [userImage, setUserImage] = useState(avatarImg || defaultImage);
    const [nameUser, setNameuser] = useState(`${first_name} ${last_name}`);
    const [emailUser, setEmailUser] = useState(email);
    const [showAdminOptions, setShowAdminOptions] = useState(false);
    const [visibilityPolicyModal, setVisibilityPolicyModal] = useState(false);
    const [showModalToResetPass, setShowModalToResetPass] = useState(false);

    const jwtToken = localStorage.getItem("jwt-token");
    useEffect(() => {
        if (history.location.state) {
            const state = history.location.state;
            if (state.message) {
                Modal[state.message.type]({ content: state.message.message });
            }
        }
    }, [history.location.state]);
    useEffect(() => {
        window.document.addEventListener("storageChanged", () => {
            const userData = JSON.parse(localStorage.getItem("user-data"));
            if (userData.avatar) {
                setUserImage(userData.avatar);
            }
            setNameuser(`${userData.first_name} ${userData.last_name}`);
            setEmailUser(userData.email);
        });
        if (search === "?updatedata") {
            if (token) {
                getUserData(token, setNameuser);
            }
        }
        return () => {
            if (pathname !== window.location.pathname) {
                localStorage.setItem("lastPageVisited", pathname);
            }
        }
    }, [pathname, jwtToken, token, search]);

    useEffect(() => {
        window.addEventListener('message', function (e) {
            if (e.origin === process.env.REACT_APP_CMS_URL || e.origin === process.env.REACT_APP_PORTAL_URL) {
                if (e.data.clickIframe) {
                    eventFire(document.querySelector("#nameUserMenu"), 'click');
                }
            }
        }, false);
        checkImage(userImage, () => { }, () => {
            setUserImage(defaultImage);
        })
    }, [userImage]);
    function eventFire(el, etype) {
        if (el) {
            if (el.fireEvent) {
                el.fireEvent('on' + etype);
            } else {
                var evObj = document.createEvent('Events');
                evObj.initEvent(etype, true, false);
                el.dispatchEvent(evObj);
            }
        }
    }
    if (roles_un.includes("administrator") && !showAdminOptions) {
        setShowAdminOptions(true);
    }

    const dropDownMenu = (
        <Menu inlineIndent={100}>
            <Menu.Item key="name" id="nameUserMenu">
                <div style={{ textAlign: "center", margin: "10px 30px" }}>
                    <strong>{nameUser}</strong>
                    <br />
                    {emailUser}
                </div>
            </Menu.Item>

            <Menu.Item key="0" onClick={() => history.replace("/profile")}>
                <span style={{ padding: "0" }}>
                    <UserOutlined /> Profile
                </span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
                key="1"
                onClick={() => setShowModalToResetPass(true)}
            >
                <span style={{ padding: "0" }}>
                    <LockedOutlined /> Reset Password
                </span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
                onClick={() => {
                    setVisibilityPolicyModal(true);
                }}
                key="2"
            >
                <span style={{ padding: "0" }}>
                    <CalendarUser /> Attendance Policy
                </span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
                key="3"
                onClick={() => window.location.replace("/support")}
            >
                <span style={{ padding: "0" }}>
                    <HelpdeskOutlined /> Support
                </span>
            </Menu.Item>
            <Menu.Divider />
            {showAdminOptions ? (
                <Menu.Item key="4" onClick={() => history.replace("/admin")}>
                    <span style={{ padding: "0" }}>
                        <SettingOutlined /> Admin
                    </span>
                </Menu.Item>
            ) : null}
            {showAdminOptions ? <Menu.Divider /> : null}
            <Menu.Item key="4" onClick={() => logOut()}>
                <span style={{ padding: "0" }}>
                    <LogoutOutlined /> Logout
                </span>
            </Menu.Item>
        </Menu>
    );
    return (
        <Header
            className="site-layout-background"
            style={{
                padding: "0px",
                height: "63px",
                borderBottom: "#dadada",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                backgroundColor: "#ddd"
            }}
        >
            <Row style={{ height: "100%" }}>
                <Col
                    xs={19} sm={22} md={22} lg={22} xl={22}
                    style={{ height: "100%", lineHeight: "initial" }}
                    flex="auto"
                >
                    {children}
                </Col>
                <Col
                    xs={5} sm={2} md={2} lg={2} xl={2}
                    style={{ height: "100%" }} flex="70px">
                    <Dropdown overlay={dropDownMenu} trigger={["click"]}>
                        <button
                            type="button"
                            className="link-button"
                            style={dropdownStyle}
                        >
                            <Avatar src={userImage} onError={function (error) {
                            }} style={{ border: "1.2px solid #c3c3c3", width: "43px", height: "43px" }} />
                        </button>
                    </Dropdown>
                </Col>
            </Row>
            <PolicyModal
                visible={visibilityPolicyModal}
                setVisible={setVisibilityPolicyModal}
            />

            {showModalToResetPass &&
                <ChangePassword
                    visible={showModalToResetPass}
                    setVisible={setShowModalToResetPass}
                    fromWhere={"profileMenu"}
                />
            }

        </Header>
    );
};

export default HeaderComponent;
