import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Row, Select, Spin, Form, Modal } from 'antd'
import debounce from "lodash/debounce";
import API from "../../../../api";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { useForm } = Form;

const { Option } = Select;
const ChangeUsername = ({ setActiveKey }) => {
    let [users, setUsers] = useState({
        data: [],
        value: [],
        fetching: false,
    });

    const [form] = useForm();

    let [lastFetchId, setLastFetchId] = useState(0);

    const getUsers = () => {
        API.get("/api/user/getUsers/loginAs")
            .then(({ data }) => {
                let result_users = data.users.filter((option) => option.status === "active").map((user) => ({
                    text: `${user.name} (${user.username})`,
                    value: user.key,
                }));
                setUsers({ data: result_users, fetching: false });
            })
            .catch(() => { });
    };

    let searchUser = (value) => {
        setLastFetchId(lastFetchId + 1);
        setUsers({ data: [], fetching: true });

        API.get(`/api/user/getUsers/loginAs/${value}`)
            .then(({ data }) => {
                if (data.users.length > 0) {
                    let result_users = data.users.filter((option) => option.status === "active").map((user) => ({
                        text: `${user.name} (${user.username})`,
                        value: user.key,
                    }));
                    setUsers({ data: result_users, fetching: false });
                } else {
                    setUsers({ data: [], fetching: false });
                    Modal.warning({ content: "User not found" });
                }
            })
            .catch(() => { });
    };

    searchUser = debounce(searchUser, 500);

    useEffect(() => {
        getUsers();
    }, []);

    const onFinish = (values) => {

        API.post("api/change_username", {
            "id_user": values.username ? values.username.value : null,
            "new_username": values.new_username
        }).then(({ data }) => {

            if (data.error === true) {
                Modal.warning({
                    content: data.message
                })
            } else {
                Modal.success({
                    title: "Username changed succesfully. Note that the user's email address has also be changed to match the new username.",
                    onOk() {
                        setActiveKey("1");
                        localStorage.setItem("activeTabAdminModule", "1");
                        const event = new Event("loadUsers");
                        window.document.dispatchEvent(event);
                    }
                });
                form.resetFields();
            }

        }).catch((e) => {
            console.log(e);
        });
    }

    const { fetching, value } = users;
    return (
        <>
            <Form onFinish={onFinish} form={form} layout="vertical">
                <Row gutter={[15, 15]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Username"
                            name="username"
                        >
                            <Select
                                showSearch labelInValue value={value} placeholder="Select user..."
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                filterOption={false} onSearch={searchUser} style={{ width: "100%" }}
                            >
                                {users.data.map((d) => (
                                    <Option key={d.value}>{d.text}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label="New Username" name="new_username">
                            <Input placeholder="New username" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[15, 15]}>
                    <Col span={2}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ChangeUsername
