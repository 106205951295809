import React, { useEffect, useState } from 'react';
import BomForm from "./Form"
import BomList from "./List"
import { Row,Col,Button,Modal,message } from "antd";
import API from "../../../api";

function Bom() {
    const [showForm,setShowForm] = useState(false);
    const [course,setCourse] = useState();
    const [initialDefaultCourse,setInitialDefaultCourse] = useState();

    const onFinish = (values,form) => {
        message.loading({
            content: "Uploading material status...",
            key: "material_status",
            duration: 0,
        });

        let formData = new FormData();
        formData.append('materials', values.materials?.file);
        formData.append('course', values.course);
        API.post("api/import_material_status", formData ,{  headers: {
            'Content-Type': 'multipart/form-data'
          }}).then(({data})=>{
            message.destroy("material_status");
            Modal.success({
                content: 'file uploaded successfully.',
                onOk:()=> {
                    setShowForm(false)
                },
            });
            form.resetFields()
        }).catch(({response})=>{

            message.destroy("material_status");
            console.log(response);
        });

    };

    const getDefaultCourse = () => {
        API.get("api/courses/get/default-course")
            .then(({ data }) => {
                setCourse(Number(data.default_course))
                setInitialDefaultCourse(Number(data.default_course))
            })
            .catch(console.log);
    }

    useEffect(()=>{
        getDefaultCourse()
    },[])
    return (
        <div>
            { (!showForm) ?
            <Row gutter={[0, 10]} >
                <Col xs={24} sm={24} md={24} lg={24} xl={14}>

                        <Button
                            type="primary"
                            onClick={() => {
                                setShowForm(true)
                                setCourse(initialDefaultCourse)
                            }}
                        >
                            Add New
                        </Button>
                    </Col>
                </Row> : ""
            }
        {(showForm) ? <BomForm setShowForm={setShowForm} onFinish={onFinish} course={course}/> : <BomList setShowForm={setShowForm} setCourse={setCourse}/>}
        </div>
    );
}

export default Bom;
