import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { Select } from "antd";
import "./calendar.less";
import API from "../../../api";



const CalendarComponent = React.memo(({ handleDateClick = () => { }, fecthEvents = () => { }, calendar = null, handleEventClick = () => { }, unitSchedule = null,changeYear=()=>{},defaultYear }) => {
    let [calendarHeight, setCalendarHeight] = useState(window.innerHeight - 146);
    let [yearsOptions,setYearsOptions] =useState([]);

    useEffect(() => {
        let height = window.innerHeight - 146;
        if (!localStorage.getItem("master-user")) {
            height = window.innerHeight - 146;
        } else {
            height = window.innerHeight - 146 - 35;
        }
        if (unitSchedule !== null) {
            height = height - 42;
            setCalendarHeight(height);
        } else {
            setCalendarHeight(height);
        }
        getYears()
    }, [unitSchedule])

    let getYears = () =>{
        API.get(`/api/get-years`)
        .then(({data})=>{
            setYearsOptions(data)
        })
    }

    return <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        hiddenDays={[0]}
        height={calendarHeight}
        eventClick={handleEventClick}
        viewDidMount={(...props) => {
            setTimeout(() => {
                let currentday = document.querySelector(`td[data-date='${moment().format("YYYY-MM-DD")}']`)
                if (currentday) {
                    setTimeout(() => {
                        document.querySelector(".fc-scroller.fc-scroller-liquid-absolute").scroll(0, document.querySelector(".fc-day-today")?.offsetTop)
                    }, 1500);
                    currentday.scrollIntoView();
                }
            }, 100);
        }}
        dateClick={handleDateClick}
        lazyFetching={true}
        events={fecthEvents}
        ref={calendar}
        headerToolbar={
            {
                start: 'title',
                center: '',
                end: 'year today prev,next'
            }
        }
        customButtons= {{
            year: {
                text: <Select
                        value={defaultYear.current}
                        style={{ width: 140 }}
                        options={yearsOptions}
                        onChange={(value) => {changeYear(value)}}
                />
            },
          }}
    />;
})


export default CalendarComponent
