import React, { useState } from "react";
import { Layout, Spin } from "antd";
import { pinStyle } from "../../Dashboard/Dashboard.style";
const { Content } = Layout;

const SharedPis = () => {
    const [loadSharedPis, setLoadSharedPis] = useState(false);
    const { token } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_CMS_URL, REACT_APP_URL } = process.env;

    return (
        <Content className="site-layout-background layout-portal-content">
            <div className="layout-portal-main-container">
                {!loadSharedPis ? (
                    <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
                ) : null}
                <iframe
                    src={`${REACT_APP_CMS_URL}/shared-pis/?token=${token}&redirect_app=${REACT_APP_URL}/shared-pis`}
                    frameBorder={"0"}
                    onLoad={() => setLoadSharedPis(true)}
                    title="dashboard-cohort"
                    className="style-frame"
                />
            </div>
        </Content>
    );
};

export default SharedPis;
