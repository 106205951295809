import React from "react";
import Icon from "@ant-design/icons";

const LogoutOutlinedSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 13.425 13.729"
    >
        <g
            id="Grupo_11"
            data-name="Grupo 11"
            transform="translate(1890.134 705.273) rotate(180)"
        >
            <g
                id="Grupo_6"
                data-name="Grupo 6"
                transform="translate(1881.645 693.931)"
            >
                <g
                    id="Grupo_4"
                    data-name="Grupo 4"
                    transform="translate(4.482 0)"
                >
                    <path
                        id="Trazado_7"
                        data-name="Trazado 7"
                        d="M1893.279,704.55a.589.589,0,0,1-.477-.936l2.577-3.541-2.577-3.542a.589.589,0,0,1,.953-.694l2.829,3.889a.591.591,0,0,1,0,.693l-2.829,3.887a.586.586,0,0,1-.476.243Z"
                        transform="translate(-1892.69 -695.595)"
                        fill="currentColor"
                    />
                </g>
                <g
                    id="Grupo_5"
                    data-name="Grupo 5"
                    transform="translate(0 3.889)"
                >
                    <path
                        id="Trazado_8"
                        data-name="Trazado 8"
                        d="M1892.984,703.373h-7.311a.589.589,0,1,1,0-1.179h7.311a.589.589,0,1,1,0,1.179Z"
                        transform="translate(-1885.084 -702.194)"
                        fill="currentColor"
                    />
                </g>
            </g>
            <g
                id="Grupo_7"
                data-name="Grupo 7"
                transform="translate(1876.709 691.544)"
            >
                <path
                    id="Trazado_9"
                    data-name="Trazado 9"
                    d="M1877.3,705.273a.589.589,0,0,1-.589-.589v-12.55a.589.589,0,1,1,1.179,0v12.55a.589.589,0,0,1-.589.589Z"
                    transform="translate(-1876.709 -691.544)"
                    fill="currentColor"
                />
            </g>
            <g
                id="Grupo_10"
                data-name="Grupo 10"
                transform="translate(1876.709 691.544)"
            >
                <g
                    id="Grupo_8"
                    data-name="Grupo 8"
                    transform="translate(0 12.55)"
                >
                    <path
                        id="Trazado_10"
                        data-name="Trazado 10"
                        d="M1885.186,714.019H1877.3a.589.589,0,1,1,0-1.179h7.888a.589.589,0,0,1,0,1.179Z"
                        transform="translate(-1876.709 -712.84)"
                        fill="currentColor"
                    />
                </g>
                <g id="Grupo_9" data-name="Grupo 9">
                    <path
                        id="Trazado_11"
                        data-name="Trazado 11"
                        d="M1885.186,692.723H1877.3a.589.589,0,1,1,0-1.179h7.888a.589.589,0,0,1,0,1.179Z"
                        transform="translate(-1876.709 -691.544)"
                        fill="currentColor"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const LogoutOutlined = (props) => (
    <Icon component={LogoutOutlinedSvg} {...props} />
);
