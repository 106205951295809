import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Form, Input, Radio, Select, Switch, Modal, Row } from 'antd';
import _ from 'lodash';
import API from '../../../api';
import moment from 'moment-timezone/moment-timezone';

import  refreshCohortSchedule  from "../../../functions/cohorts/refreshCohortSchedule";

const Settings = ({form, cohortBuilderFields,  configsCohortBuilder, currentCohort, selectCourses,edit,setShowQualityAssurance,
    setShowWebinarSection, setShowOrientation,setSubCourses,setNameCohort, selectDistrict, setIsExecutiveCourse, EXECUTIVE_COACHING }) => {
    const [subUnits, setSubUnits] = useState(configsCohortBuilder.default_subunits)
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(true);
    const [courses, setCourses] = useState([]);
    const [deliveryModels, setDeliveryModel] = useState([]);
    const [states, setStates] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [showNislDrive, setShowNislDrive] = useState(true);
    const [showForceEnd, setShowForceEnd] = useState(false);
    const [textForceEnd, setTextForceEnd] = useState();

    const onChangeChecked = (list) => {
        list.sort();
        let units_enabled = [];

        list.forEach((item) => {
            units_enabled = [...units_enabled, ...subUnits[item].units];
        });

        let form_values = form.getFieldsValue();

        form_values.cohort_schedule.forEach((unit, index) => {
            if (unit.unit !== null && unit.unit !== undefined) {
                let html_element = document.querySelector(
                    `#principal_unit${unit.unit}`
                );
                if (html_element != null) {
                    if (units_enabled.includes(unit.unit.toString())) {
                        html_element.style.display = "block";
                    } else {
                        html_element.style.display = "none";
                    }
                }
            }
        });

        setCheckedList(list);
        setSubCourses(list.map(v => "course" + (v + 1)).join(","))
        setIndeterminate(!!list.length && list.length < subUnits.length);
        setCheckAll(list.length === subUnits.length);
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? subUnits?.map((v, i) => i) : []);
        setSubCourses(e.target.checked ? subUnits?.map((v, i) => "course" + (i + 1)).join(",") : "")
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    let handleCourseChange = (value) => {

        API.get(`api/course_sections/${value}`).then(({ data }) => {
            setSubUnits(data.section_units)
            let units = _.flatMap(data.section_units, 'units');
            let cohort_schedule = units.map(v => ({ unit: v, event_type: "2_day", cohort_schedule_sub: [] }))
            let values = form.getFieldsValue();

            setCheckedList(data.section_units?.map((v, i) => i))
            setSubCourses(data.section_units?.map((v,i) => "course" + (i + 1)).join(","))


            if(data.key  === `cohort${EXECUTIVE_COACHING}`){
                setIsExecutiveCourse(true);
            }else{
                setIsExecutiveCourse(false);
                values.cohort_schedule = cohort_schedule;
            }


            form.setFieldsValue(values)
            refreshCohortSchedule(form,units)

        });
    }

    let handleForceEnd = (value) =>{

        let values = form.getFieldsValue();

        //Verify if the cohort doesn't have events > now
        if(values?.cohort_schedule && value.includes("yes"))
        {
            let now = moment().format("YY-MM-DD");
            let allowForceEnd = true;

            values.cohort_schedule.forEach((unit, index) => {
                if (unit.cohort_schedule_sub.length === 0 && unit.start_date != null) {
                    if (moment(unit.start_date).format("YY-MM-DD") > now) {
                       allowForceEnd = false;
                    }
                } else {
                    unit.cohort_schedule_sub.map((event, index) => {
                        if (event.start_date_sub_unit !== null) {
                            if (moment(event.start_date_sub_uni).format("YY-MM-DD") > now) {
                                allowForceEnd = false;
                            }
                        }
                    })
                }
            });

            if(allowForceEnd === false){

                values.force_end = null ;
                form.setFieldsValue(values)

                Modal.error({
                    title: 'Error',
                    content: 'You cannot Force End a cohort while there are scheduled events in the future. \n Please remove the future events before using Force End.',
                });

                return
            }
        }

        refreshCohortSchedule(form,null, currentCohort)
        setShowForceEnd(value.includes("yes"))
        setTextForceEnd("")
    }


    useEffect(() => {

        setShowNislDrive(currentCohort.has_suite === "yes" ? true : false);
        setShowForceEnd(currentCohort.force_end?.includes("yes"))
        setShowOrientation(currentCohort.orientation?.length > 0 ? true : false);
        setCheckAll(currentCohort?.default_subunits?.length === currentCohort?.sub_courses?.length);
        setIndeterminate(currentCohort?.default_subunits?.length !== currentCohort?.sub_courses?.length && currentCohort?.sub_courses?.length < currentCohort?.default_subunits?.length);
        setCheckedList(currentCohort.sub_courses?.map((v, i) => v - 1))

        setShowQualityAssurance(["edp_pss", "edp_pss_virtual2", "edp_pss_virtualm", 'edp_materials_virtual2_nisl', 'edp_materials_virtualm_nisl', 'edp_materials_nisl'].includes(currentCohort.delivery_model))
        setShowWebinarSection(!["edp_pss", "edp_pss_virtual2", "edp_pss_virtualm", 'edp_materials_virtual2_nisl', 'edp_materials_virtualm_nisl', 'edp_materials_nisl'].includes(currentCohort.delivery_model))
        setDeliveryModel(cohortBuilderFields.find(v => v.slug === "delivery_model")?.options.filter((option) => option.value === currentCohort.delivery_model || !option.hide))

        //set courses edit cohort
        setCourses(cohortBuilderFields.find(v => v.slug === "course")?.options.filter((option) => option.value === currentCohort.course || option.active))

        if(currentCohort.force_end_log !== null && currentCohort.force_end?.includes("yes")){
            if(currentCohort.force_end_log?.author){
                let date = moment(currentCohort.force_end_log.created_at)
                setTextForceEnd(`${date.format("MM/DD/YY H:mm")} ${currentCohort.force_end_log.author.name}`);
            }
        }
        // eslint-disable-next-line
    }, [currentCohort, form]);

    useEffect(() => {
        if (edit && currentCohort.course) {
            API.get(`api/course_sections/${currentCohort.course}`).then(({ data }) => {
                setSubUnits(data.section_units)
            });
        }
        // eslint-disable-next-line
    }, [currentCohort, edit])

    useEffect(() => {
        //set courses new cohort
        setCourses(cohortBuilderFields.find(v => v.slug === "course")?.options.filter((option) => option.active))

        setStates(cohortBuilderFields.find(v => v.slug === "state")?.options);
        setPlatforms(cohortBuilderFields.find(v => v.slug === "platform")?.options);
        // eslint-disable-next-line
    }, [cohortBuilderFields]);



    return (
        <>
            <Col xs={24}>
                <Form.Item
                    rules={[{ required: true, message: 'The cohort must have a title.' }]}
                    name={"name"}>
                    <Input placeholder={"Enter Title Here"} onChange= {(e) =>{setNameCohort(e.target.value)}}/>
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item name={"course"}>
                    <Select onChange={handleCourseChange} placeholder={" Course -"} options={courses} />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item name={"delivery_model"}>
                    <Select placeholder={"- Delivery Model -"} onChange={(currentValue) => {
                        if (["edp_pss", "edp_pss_virtual2", "edp_pss_virtualm", 'edp_materials_virtual2_nisl', 'edp_materials_virtualm_nisl', 'edp_materials_nisl'].includes(currentValue)) {
                            setShowQualityAssurance(true);
                            setShowWebinarSection(false)
                        }else{
                            setShowWebinarSection(true)
                            setShowQualityAssurance(false);
                        }
                    }} options={deliveryModels} />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item name={"city"}>
                    <Input placeholder={"City"} />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item name={"state"}>
                    <Select
                    showSearch
                    placeholder={"- State -"}
                    options={states}
                    filterOption={(input, option) =>
                        option.label.toLowerCase().substr(0,input.length).indexOf(input.toLowerCase()) >= 0
                      }
                    onChange={(currentValue) => {
                        form.setFieldsValue({ ...form.getFieldsValue(), "district": "" })
                        form.setFieldsValue({ ...form.getFieldsValue(), "district_id": "" })
                    }}/>
                </Form.Item>
            </Col>
            <Col xs={24} md={9}>
                <Form.Item name={"contract"}>
                    <Input placeholder={"Contract"} />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item name={"has_suite"} label={"Client has GSuite?"}>
                    <Radio.Group onChange={({ target }) => setShowNislDrive(target.value === "yes" ? true : false)}>
                            <Radio value="yes">Yes</Radio>
                            <Radio value="no">No</Radio>
                            <Radio value="some">Some Participants</Radio>
                        </Radio.Group>
                </Form.Item>
            </Col>
            <Col flex="auto">
                <Row justify="end">
                    <Form.Item name={"is_internal"} label={"Internal?"}>
                        <Checkbox.Group>
                            <Checkbox value="is_internal"></Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </Row>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item name={"collaboration"} label={"Collaboration"}>
                    <Checkbox.Group>
                        <Checkbox value="communities">Communities</Checkbox>
                        {showNislDrive ? <Checkbox value="nisl_drive">NISL Drive</Checkbox> : null}
                    </Checkbox.Group>
                </Form.Item>
            </Col>

            {showNislDrive ?
                <Col xs={24} >
                    <Form.Item name={"link_folder"}>
                        <Input placeholder="Link to this cohort's cohort folder (folder name convention = NISL EDP Cohort Folder for Bedford, OH 01)" />
                    </Form.Item>
                </Col>
                : null}
            <Col xs={24}>
                <Form.Item name={"material_status"}>
                    <Input placeholder="Materials Status" />
                </Form.Item>
            </Col>
            <Col xs={24} >
                <Form.Item name={"welcome_email"} label="Include location and date information with Welcome email?">
                    <Switch />
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item name={"sub_course"} label={"Sub-Course"} >
                    <Checkbox style={{display:"none"}} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>Toogle All</Checkbox>
                    <Checkbox.Group name={"sub_course"} onChange={onChangeChecked} value={checkedList}>
                        {subUnits?.map((v, i) => (
                            <React.Fragment key={i}>
                                <Checkbox value={i} key={i} >{v.name} (
                                    {
                                        v.units.join(", ")
                                    }
                                    )</Checkbox>
                                <br />
                            </React.Fragment>
                        ))}
                    </Checkbox.Group>
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Row>
                    <Col>
                        <Form.Item name={"force_end"} label={"Force end"} >
                            <Checkbox.Group onChange={handleForceEnd}>
                                <Checkbox value="yes">yes</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                    <Col style={{marginTop:"5px"}}>
                        {textForceEnd}
                    </Col>
                </Row>
            </Col>

            {showForceEnd ?
                <Col xs={24} >
                    <Form.Item name={"reason"}>
                        <Input.TextArea placeholder={"Reason"} />
                    </Form.Item>
                </Col>
                : null}
            <Col xs={24} md={12}>
                <Form.Item name={"webinar_url"} >
                    <Input placeholder={"webinar URL"} />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item name={"platform"} >
                    <Select placeholder="- Platform -">
                        {platforms?.map(v => (
                            <Select.Option key={v.value} value={v.value}>{v.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item name={"meeting_id"} >
                    <Input placeholder={"Meeting ID"} />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item name={"password_meeting"} >
                    <Input placeholder="Password Meeting" />
                </Form.Item>
            </Col>

        </>
    )
}
export default Settings



