import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Modal } from "antd";
import FilterFacilitatorUtilization from './FilterFacilitatorUtilization';
import FacilitatorReport from '../../DataLink/FacilitatorReport';
import API from "../../../../api";
import moment from "moment"
//const { useForm } = Form;


const FacilitatorUtilization = ({ isVisibleFacilitatorUtilization, setIsVisibleFacilitatorUtilization }) => {
    const [isVisibleFilter, setIsVisibleFilter] = useState(false);
    const [dataStaff, setDataStaff] = useState([])
    const [dataContract, setDataContract] = useState([])
    const [dataFilter, setDataFilter] = useState([])

    const getDataFacilitatorUtilization = () =>
    {
            API.get(`api/get_facilitator_utilization`).then(({ data }) => {
              setDataStaff(data.facilitators_staff);
              setDataContract(data.facilitators_contractor);
            });


    }

    const exportFacilitatorUtilization = () =>
    {
        let file_date = moment().format('YYYY-MM-DD hh_mm a')
        let filename = "National Facilitator Utilization Report "+file_date+".xlsx";
        API.get(`api/export-facilitator-utilization?filters=${JSON.stringify(dataFilter)}`,{
            responseType: "blob",
            headers: {"Content-Type": "application/vnd.ms-excel" }
            }).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
        });


    }

    useEffect(() => {
        getDataFacilitatorUtilization();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Modal
                visible={isVisibleFacilitatorUtilization}
                title="Facilitator Utilization Report"
                centered
                onCancel={() => setIsVisibleFacilitatorUtilization(false)}
                width="80%"
                footer={
                    <>
                        <Button type="primary" onClick={() => setIsVisibleFacilitatorUtilization(false)}>
                            Close
                        </Button>
                    </>
                }
                bodyStyle={{
                    paddingTop: "12px",
                    maxHeight: "45em",
                    overflow: "scroll",
                }}
            >
                <Row justify="end">
                    <Col span={8} style={{ textAlign: "right" }}>
                        <Button
                        onClick={() => {
                            exportFacilitatorUtilization();
                        }}
                        >Export</Button> &nbsp;
                        <Button onClick={() => setIsVisibleFilter(true)}>Filter</Button> &nbsp;
                    </Col>
                </Row>
                <FacilitatorReport dataStaff={dataStaff} dataContract={dataContract} />
            </Modal>
            <FilterFacilitatorUtilization setDataContract={setDataContract} setDataStaff={setDataStaff} setDataFilter={setDataFilter} isVisibleFilter={isVisibleFilter} setIsVisibleFilter={setIsVisibleFilter} />
        </>
    )
}

export default FacilitatorUtilization
