import React, { useEffect, useState } from "react"
import API from "../api";
import {Select,Modal,Form} from "antd";

let Schools = ({form,district_id,school,setSchool, label, multiple})=>{

    let [schools,setSchools] = useState([]);

    useEffect(()=>{
        getSchools()
        //eslint-disable-next-line
    },[district_id])


    let getSchools = () => {
        if (district_id) {
            API.get(`api/district_schools/${district_id}`).then(({data})=>{
                setSchools(data)
                let values = form.getFieldsValue()
                if (school && school !== undefined) {
                    values.school_name = school;
                    form.setFieldsValue(values)
                }
            })
        }

    }

    return (
        <>
         <Form.Item name={"school_name"} label={label ? label : ""} >
            <Select
                showSearch
                mode={multiple ? "multiple" : ""}
                options={schools}
                optionFilterProp="children"
                autoComplete="new-password"
                placeholder="School"
                allowClear={true}
                filterOption={(input, option) =>option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0   }
                onChange={()=>{
                    let values = form.getFieldsValue();
                    if(!values.district_id || values.district_id === null || values.district_id === ""){
                        Modal.warning({
                            content: "Please select your state first, then your district, then (if applicable) your school.",
                        });
                        values.school_name = null;
                        form.setFieldsValue(values)
                    }
                    setSchool(0)
                }}
            />
        </Form.Item>
        </>
    );
}
export default Schools;
