import {
    Checkbox
} from 'antd';
import React from 'react';
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    /* Customize the label (the container) */
    container: {
        display: "block",
        cursor: "pointer",
        textAlign: "center",
        boxSizing: "border-box",
        height: "16px",
        width: "16px",
        marginTop: "-6px",
        "& input": {
            /*  Hide the browser's default checkbox  */
            position: "absolute",
            opacity: 0,
            cursor: "pointer",
            height: "16px",
            width: "16px",
            top: "0",
            left: "0",
            /* Show the checkmark when checked */
            "&:checked": {
            "& ~ $checkmark": {
                /* When the checkbox is checked, add a blue background */
                backgroundColor: props => props.backgroundColor,
                "&:after": {
                    display: "block"
                }
            }
            }
        },

        "& $checkmark": {
            "&:after": {
                width: "16px",
                height: "16px",
                content: props => props.content,
                fontSize: "9px",
                color: "#fff",
                borderRadius: "3px",
                textAlign: "center",
                marginLeft: "-1px",
            }
        },

      /* On mouse-over, add a grey background color */
    "&:hover": {
        "& input": {
            "& ~ $checkmark": {
            }
        }
    }
    },

    checkmark: {
        position: "absolute",
        height: "16px",
        width: "16px",
        "&:after": {
            content: '""',
            position: "absolute",
            display: "none",
            top: "3px",
            left: 0,
        },
        borderRadius: "3px",
        border: "1px solid #479320",
        top: 0,
        left: 0,
    }
  });

const TernalCheckBox = ({children, ...props}) => {
    const classes = useStyles(props);
    const handleFirstCheckbox = () => {
        callBack(1, (props.defaultFirstChecked));
    }
    const handleSecondCheckbox = () => {
        callBack(2, (props.defaultSecondChecked ));
    }
    const callBack = (checkBoxId, value) => {
        props.callBack(checkBoxId, !value)
    }


    return (
        <>
            {
                props.withSecondCheck || props.defaultSecondChecked ?
                            <label className={`${classes.container}  ant-checkbox `}>
                                <input onChange={ handleSecondCheckbox } type="checkbox" checked={ props.defaultSecondChecked }/>
                                <span className={ classes.checkmark } />
                            </label>
                    : <Checkbox disabled={props.blockCheckbox} onChange={ handleFirstCheckbox } checked={ props.defaultFirstChecked } />
            }
        </>
    )
}

TernalCheckBox.defaultProps = {
    backgroundColor: "#479320",
    content: '"I/S"'
}

export default TernalCheckBox;
