import React from 'react'
import { Modal, Button, Row, Col, Spin, Layout, Space } from 'antd'
import API from "../../../api";
import { createUseStyles } from "react-jss";
import './cohorts.less'

const { Content } = Layout;
const useStyles = createUseStyles({
    largeButton: {
        height: "2em",
        fontSize: "11px",
        background: "#fff",
        color: "#0051a0",
        width: "80%",
        lineHeight: "1em",
    },
    container: {
        '& Button': {
            width: '80%',
            height: '40px',
            marginTop: '0.1em',
        },
    },
});
const UserAccountsReport = (props) => {

    //const history = useHistory();
    const classes = useStyles();


    const exportDataUser = (name, download_filename) => {
       let filename = ''
        API.get(`api/date-export-reports?filename=${name}`)
            .then(({ data }) => {
                filename = `${download_filename} ${data}.xlsx`;
            });
        API.get(`api/download-user-account?filename=${name}`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <>
        <Modal
            visible={props.showUsersAttendanceReport}
            title="User Account Data"
            centered
            width="60%"
            onCancel={() => props.setShowUsersAttendanceReport(false)}
            footer={null}
        >
            <Content className={classes.container}>
                <Spin spinning={false} delay={500}>
                    <Space direction="vertical" size={10} style={{  display: 'flex'}}>
                        <Row>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8} >
                                <Button
                                    type="primary"
                                    className={classes.largeButton}
                                    onClick={() => exportDataUser('User Account Details', 'User Account Details')}
                                >
                                    <Space direction="vertical" size={1}>
                                        <div>All</div>
                                        <div>Users</div>
                                    </Space>
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={16} lg={16} xl={16} >
                                Download an XLS with all Users, including Feedback Diagnositc Users, with Inactive/Merged.
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8} >
                                <Button
                                    type="primary"
                                    onClick={() => exportDataUser('User Account Exclude Users Feedback', 'All users excluding Feedback Diagnostic users')}
                                    className={classes.largeButton}
                                >
                                    <Space direction="vertical" size={1}>
                                        <div>Excluding</div>
                                        <div>Diagnostic users</div>
                                    </Space>
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={16} lg={16} xl={16} >
                                Download an XLS with all Users, excluding Feedback Diagnostic users, with Inactive/Merged.
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8} >
                                <Button
                                    type="primary"
                                    onClick={() => exportDataUser('User Account Exclude Feedback Inactive Merge', 'Excluding Feedback Diagnostic users, excluding Inactive/Merged')}
                                    className={classes.largeButton}
                                >
                                    <Space direction="vertical" size={1}>
                                        <div>Excluding Diagnostic </div>
                                        <div>Inactive/Merged users</div>
                                    </Space>
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={16} lg={16} xl={16} >
                                Download an XLS with all Users, excluding Feedback Diagnostic users, excluding Inactive/Merged.
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8} >
                                <Button
                                    type="primary"
                                    onClick={() => exportDataUser('Users Account Feedback Diagnostics', 'Feedback Diagnostic users only')}
                                    className={classes.largeButton}
                                >
                                    <Space direction="vertical" size={1}>
                                        <div>Feedback Diagnostic</div>
                                    </Space>
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={16} lg={16} xl={16} >
                            Download an XLS with Feedback Diagnostic users only, with Inactive/Merged.
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8} >
                                <Button
                                    type="primary"
                                    onClick={() => exportDataUser('Users Account Feedback Exclude Inactive Merged', ' Feedback Diagnostic users excluding Inactive/Merged')}
                                    className={classes.largeButton}
                                >
                                    <Space direction="vertical" size={1}>
                                        <div>Feedback Diagnostic</div>
                                        <div>Without Inactive/Merged</div>
                                    </Space>
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={16} lg={16} xl={16} >
                                Download an XLS with Feedback Diagnostic users only, excluding Inactive/Merged.
                            </Col>
                        </Row>
                    </Space>
                </Spin>
            </Content>
          </Modal>
        </>
    )
}


export default UserAccountsReport

