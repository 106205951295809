import React, { useState } from "react";
import { Spin } from "antd";
import { pinStyle } from "../Dashboard/Dashboard.style";

const SelfPacedLearning = () => {
    let [loadSelfPacedLearning, setLoadSelfPacedLearning] = useState(false);
    const { token } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_CMS_URL } = process.env;
    return (
        <>
            {!loadSelfPacedLearning ? (
                <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
            ) : null}
            <iframe
                src={`${REACT_APP_CMS_URL}/courses/?token=${token}&from_app=no&needs_a_handle=no`}
                frameBorder={"0"}
                title="foundations-self-paced-learning"
                onLoad={() => {
                    setLoadSelfPacedLearning(true);
                }}
                className="style-frame-tabs"
            />
        </>
    );
};

export default SelfPacedLearning;
