import React, { useEffect, useState } from "react";
import { Card, Row, Col, Layout, Select, Spin, Button, Modal } from "antd";
import API from "../../../../api";
import debounce from "lodash/debounce";
import { loginWith } from "../../../../functions/loginWith";

const { Content } = Layout;
const { Option } = Select;

const LoginAs = () => {
    let [users, setUsers] = useState({
        data: [],
        value: [],
        fetching: false,
    });

    let [lastFetchId, setLastFetchId] = useState(0);
    let [loginWithValues, setLoginWith] = useState(0);

    const getUsers = () => {
        API.get("/api/user/getUsers/loginAs")
            .then(({ data }) => {
                let result_users = data.users.filter((option) => option.status === "active").map((user) => ({
                    text: `${user.name} (${user.username})`,
                    value: user.key,
                    hasSeniorAdmin: (user.roles.some(item => item.slug === "senior_admin" || item.slug === "administrator") ? true : false),
                }));
                setUsers({ data: result_users, fetching: false });
            })
            .catch(() => {});
    };

    let searchUser = (value) => {
        setLastFetchId(lastFetchId + 1);
        setUsers({ data: [], fetching: true });

        API.get(`/api/user/getUsers/loginAs/${value}`)
            .then(({ data }) => {
                if (data.users.length > 0) {
                    let result_users = data.users.filter((option) => option.status === "active").map((user) => ({
                        text: `${user.name} (${user.username})`,
                        value: user.key,
                        hasSeniorAdmin: (user.roles.some(item => item.slug === "senior_admin" || item.slug === "administrator") ? true : false),
                    }));
                    setUsers({ data: result_users, fetching: false });
                } else {
                    setUsers({ data: [], fetching: false });
                    Modal.warning({content: "User not found"});
                }
            })
            .catch(() => {});
    };

    searchUser = debounce(searchUser, 500);

    useEffect(() => {
        getUsers();
    }, []);

    let handleChange = (value) => {
        setLoginWith(value);
    };

    let loginWithUser = () => {
        loginWith(loginWithValues.key)
    }

    const { fetching, value } = users;
    return (
        <Content className="site-card-wrapper">
            <Row
                gutter={[0, 10]}
                justify="center"
                align="middle"
                style={{ height: "100%" }}
            >
                <Col className="gutter-row" lg={10} md={12} xs={24} sm={16}>
                    <Card
                        hoverable
                        headStyle={{ textAlign: "center" }}
                        title="Login as"
                        bordered={false}
                        bodyStyle={{ textAlign: "center" }}
                    >
                        <Select
                            showSearch
                            labelInValue
                            value={value}
                            placeholder="Select user..."
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={searchUser}
                            onChange={(a, v) => {
                                if (v.hasSeniorAdmin) {
                                    Modal.error({
                                        title: '',
                                        content: 'LoginAs is blocked on Administrator and Senior Admin accounts.',
                                    });
                                } else {
                                    handleChange(v);
                                }
                            }}
                            style={{ width: "100%" }}
                        >
                            {users.data.map((d) => (
                                <Option key={d.value} hasSeniorAdmin={d.hasSeniorAdmin}>{d.text}</Option>
                            ))}
                        </Select>
                        <Button
                            onClick={loginWithUser}
                            style={{ marginTop: "5px" }}
                            type="primary"
                        >
                            Login As
                        </Button>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};

export default LoginAs;
