import React from "react";
import { Layout } from "antd";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Readings from "./Readings";
import SelfPacedLearning from "./SelfPacedLearning";
import Videos from "./Videos";
import WebBasedResources from "./WebBasedResources";

const { Content } = Layout;

const Foundations = () => {
    let { path } = useRouteMatch();

    return (
        <Content className="site-layout-background layout-portal-content">
            <div className="layout-portal-main-container">
                <Switch>
                    <Route exact path={path}>
                        <Redirect to={`${path}/readings`} />
                    </Route>
                    <Route path={`${path}/readings`}>
                        <Readings />
                    </Route>
                    <Route path={`${path}/self-paced-learning`}>
                        <SelfPacedLearning />
                    </Route>
                    <Route path={`${path}/videos`}>
                        <Videos />
                    </Route>
                    <Route path={`${path}/web-based-resources`}>
                        <WebBasedResources />
                    </Route>
                </Switch>
            </div>
        </Content>
    );
};

export default Foundations;
