import React from "react";
import { Table, Button } from "antd";
import moment from "moment";

const SettingsList = ({ settings, getDataPaginate, LoadingTable, currentPage, editSetting, handleRunQuery }) => {

    const columns = [
        {
            title: "Description",
            dataIndex: "description",
            width: '35%',
            rowKey: "id",
            render: (value, record) => {
                return <span> {value} </span>
            }
        },
        {
            title: "Value",
            dataIndex: "valueTable",
            width: '50%',
            rowKey: "valueTable",
            render: (value, record) => {
                return <span> {value} </span>
            }
        },
        {
            title: "Modified",
            dataIndex: "updated_at",
            width: "10%",
            rowKey: "updated_at",
            padding: 10,
            sorter: true,
            defaultSortOrder: "descend",
            align: "center",
            className: "column_without_padding",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {record?.who_update} <br /> {moment(value).format("YY-MM-DD H:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Action",
            rowKey: "void",
            fixed: "right",
            align: "center",
            width: '5%',
            render: (text, record) => {
                if (record.type === 'query') {
                    return (
                        <Button type="primary" onClick={() => { handleRunQuery(record); }}>
                            Run
                        </Button>
                    )
                } else {
                    return (
                        <Button type="primary" onClick={() => { editSetting(record); }}>
                            Edit
                        </Button>
                    )
                }
            },
        },
    ];

    return (
        <div>
            <Table
                className="table_settings"
                columns={columns}
                dataSource={settings}
                loading={LoadingTable}
                onChange={getDataPaginate}
                rowClassName={() => "responsive-row"}
                pagination={{
                    current: currentPage,
                    defaultPageSize: 10,
                    total: settings.total,
                    showSizeChanger: false,
                    showQuickJumper: false,
                }}
            />
        </div>
    );
}

export default SettingsList;
