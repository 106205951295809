import React from "react";
import {
    Row,
    Col,
    Button,
    Modal
} from "antd";
import csvExample from "../../../../../assets/files/example.csv";
export function ModalCSVHelp({ visible, setVisible }) {
    return (
        <Modal
            title="Documentation"
            visible={visible}
            centered
            style={{ padding: 0 }}
            width="80%"
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
            footer={[
                null,
                <Button key="submit" type="primary" onClick={() => setVisible(false)}>
                    OK
                </Button>,
            ]}
        >
            <div>
                <h5>Required columns</h5>
                <Row>
                    <Col xs={24} md={4}>
                        <ul>
                            <li>username</li>
                            <li>email</li>
                        </ul>
                    </Col>
                </Row>
                <h5>Custom columns avalaible</h5>
                <Row>
                    <Col xs={12} sm={7} md={4}>
                        <ul>
                            <li>password</li>
                            <li>first_name</li>
                            <li>last_name</li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={7} md={4}>
                        <ul>
                            <li>job_title</li>
                            <li>phone</li>
                            <li>school</li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={7} md={4}>
                        <ul>
                            <li>district</li>
                            <li>state</li>
                        </ul>
                    </Col>
                </Row>
                <h5>Notes</h5>
                <Row>
                    <Col xs={24}>
                        <strong>Passwords</strong>
                        <p>A string that contains user passwords. We have different options for this case:</p>
                        <ul>
                            <li>If you don't create a column for passwords: passwords will be generated automatically</li>
                            <li>If you create a column for passwords: </li>
                            <ul>
                                <li>If type of users is new: if cell is empty, passwords will be generated automatically; if cell has a value, it will be used</li>
                                <li>If type of users is existent: if cell is empty, password won't be updated; if cell has a value, it will be used</li>
                            </ul>
                        </ul>
                    </Col>
                </Row>
                <h5>CSV example</h5>
                <Row>
                    <Col xs={24} md={12}>
                        Download here: <a href={csvExample} download>here</a>
                    </Col>
                </Row>

            </div>
        </Modal>
    );
}
