import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Modal, Checkbox, Form, Select } from "antd";
import API from '../../../../api'
const { useForm } = Form;

const FilterFacilitatorUtilization = ({setDataFilter, setDataStaff, setDataContract, isVisibleFilter, setIsVisibleFilter}) => {
    const [form] = useForm();
    const { roles_un } = JSON.parse(localStorage.getItem("nisl-data"));
    const [deliveryModels, setDeliveryModels] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [states, setStates] = useState([]);
    const [facilitators, setFacilitators] = useState([]);
    const [coFacilitators, setCoFacilitators] = useState([]);
    const [facCoFacilitators, setFacCoFacilitators] = useState([]);
    let units = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    useEffect(() => {
        API.get("api/filter-facilitator-utilization").then(({data}) => {
            if (data.cohorts) {
                setCohorts(data.cohorts);
            }
            if (data.delivery_models) {
                setDeliveryModels([{label: "All", value: "all" } ,...data.delivery_models]);
            }
            if (data.state) {
                setStates(data.state);
            }
            if (data.facilitators) {
                setFacilitators(data.facilitators);
            }
            if (data.cofacilitators) {
                setCoFacilitators(data.cofacilitators);
            }
            if (data.fac_cofac_schedule) {
                setFacCoFacilitators(data.fac_cofac_schedule);
            }
            if (data.current_filter) {
                form.setFieldsValue(data.current_filter.filter);
            }
        })
        form.setFieldsValue({retain_filter:true});
    // eslint-disable-next-line
    }, [])

    const saveFilters = (values) => {
        let request = null;
        let save_filter = false;
        if(values.retain_filter === true){
            save_filter = true;
        }
        request = API.post("api/user/saveFilters", {
            type_filters: "facilitator_utilization_report",
            filters: values,
            save_filters: save_filter
        });
        setDataFilter(values);
        request
            .then(({ data }) => {
                //filtersearch
                API.get(`api/get_facilitator_utilization?filters=${JSON.stringify(values)}`).then(({ data }) => {
                    setDataStaff(data.facilitators_staff);
                    setDataContract(data.facilitators_contractor);
                    setIsVisibleFilter(false);
                  });
            })
            .catch(({ response: { data } }) => { console.log(data) })

    }

    return (
        <>
            <Modal
                visible={isVisibleFilter}
                title="FILTER"
                centered
                width="80%"
                onCancel={() => setIsVisibleFilter(false)}
                footer={
                    <>
                        <Button onClick={() => setIsVisibleFilter(false)}>
                            CLOSE
                        </Button>
                        <Button onClick={() => {
                            // setIsVisibleFilter(false);
                            form.resetFields();
                            form.setFieldsValue({retain_filter:true});
                        }}>
                            CLEAR
                        </Button>
                        <Button type="primary" onClick={() => {
                            // setIsVisibleFilter(false);
                            form.validateFields().then(saveFilters).catch(console.log)
                        }}>
                            OK
                        </Button>
                    </>
                }
                bodyStyle={{
                    paddingTop: "12px"
                }}
            >
                <Form
                    form={form}
                    layout={"vertical"}
                >
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Form.Item label="Show cohort types" name="delivery_model" >
                                <Select
                                    mode={"multiple"} allowClear
                                    placeholder="Please select" options={deliveryModels}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } optionFilterProp="label"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Show states" name="states" >
                                <Select
                                    mode={"multiple"} allowClear
                                    placeholder="Please select" options={states}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } optionFilterProp="label"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Show units" name="units" >
                                <Select mode={"multiple"} allowClear placeholder="Please select" >
                                    {units.map((v, i) => <Select.Option value={i + 1} key={i}>{i + 1}</Select.Option>)}

                                </Select>
                            </Form.Item>
                        </Col>
                        {!roles_un.find((v) => v === "facilitator_national" || v === "facilitator_local" || v === "facilitator_local_inc") ?
                            <Col span={12}>
                                <Form.Item label="Show the schedule for (facilitating or co-facilitating)" name="schedule_facilitator_cofacilitator" >
                                    <Select
                                        mode={"multiple"} allowClear
                                        placeholder="Please select"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } optionFilterProp="children" options={facCoFacilitators}
                                    />
                                </Form.Item>
                            </Col>
                            : null}
                        {!roles_un.find((v) => v === "facilitator_national" || v === "facilitator_local" || v === "facilitator_local_inc") ?
                            <Col span={12}>
                                <Form.Item label="Show facilitators" name="facilitator" >
                                    <Select
                                        mode={"multiple"} allowClear
                                        placeholder="Please select"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } optionFilterProp="children" options={facilitators}
                                    />
                                </Form.Item>
                            </Col>
                            : null}
                        {!roles_un.find((v) => v === "facilitator_national" || v === "facilitator_local" || v === "facilitator_local_inc") ?
                            <Col span={12}>
                                <Form.Item label="Show co-facilitators" name="co_facilitator" >
                                    <Select
                                        mode={"multiple"} allowClear
                                        placeholder="Please select"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } optionFilterProp="children" options={coFacilitators}
                                    />
                                </Form.Item>
                            </Col>
                            : null}
                        <Col span={12}>
                            <Form.Item label="Show cohorts" name="cohorts" >
                                <Select
                                    mode={"multiple"} allowClear
                                    placeholder="Please select" options={cohorts}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } optionFilterProp="label" />
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ textAlign: "center" }}>
                            <Form.Item name="retain_filter" valuePropName="checked">
                                <Checkbox>retain all these filters for your next login</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

export default FilterFacilitatorUtilization
