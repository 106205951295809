import API from "../../api";
export const handleUpload = (formValues) => {
    const formData = new FormData();

    if (formValues.csv_file) {
        formData.append("csv_file", formValues.csv_file.file);
    }
    if (formValues.type_upload === "cohorts") {
        if (!formValues.cohorts) {
            return Promise.resolve({
                data: {
                    errors: { cohorts: ["The cohorts field is required."] },
                    response: false
                }
            });
        }
        formData.append("cohorts[]", formValues.cohorts);
    }
    if (formValues.type_upload === "courses") {
        if (formValues.privileges.length > 0) {
            formValues.privileges.forEach((v) => formData.append("privileges[]", v));
        } else {
            return Promise.resolve({
                data: {
                    errors: { cohorts: ["The privileges field is required."] },
                    response: false
                }
            });
        }
    }

    if (formValues.roles.length > 0) {
        formValues.roles.forEach((v) => formData.append("roles[]", v));
    }else{
        return Promise.resolve({
            data: {
                errors: { cohorts: ["Accounts must include at least one Account Priv. Please add Participant or some other Account Priv and then try again."] },
                response: false
            }
        });
    }

    formData.append("send_credentials", formValues.send_credentials);
    formData.append("send_updated_data", formValues.send_updated_data);
    formData.append("type_upload", formValues.type_upload);
    // Hacer la peticion
    return API.post("api/user/import-csv", formData);
};
