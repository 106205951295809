import React, { useEffect, useState } from "react";
import "./../Login/login.less";
import { useHistory, useParams } from "react-router-dom";
import { Layout, Button, Row, Col, Form, Input, Modal } from "antd";
import imageLogo from "../../assets/img/logo.png";
import API from "../../api";
import { alertStyle, formNewPasswordStyle } from "./NewPassword.styles";
import { EyeOutlined, EyeInvisibleOutlined, CheckOutlined } from "@ant-design/icons";

const { Content } = Layout;

const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
};

const rules = {
    password: [{ required: true, message: "Please input your password." }],
    repeat_password: [
        { required: true, message: "Please confirm your password." },
        ({ getFieldValue }) => ({
            validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                }
                return Promise.reject(
                    "The two passwords that you entered do not match."
                );
            },
        }),
    ],
};

const NewPassword = ({ recover = false }) => {

    const [form] = Form.useForm();

    const history = useHistory();
    const { token } = useParams();
    const {
        location: { state },
    } = history;
    let showCancel = true;
    const styleTextRule = {
        color: "red",
        marginLeft: '10px'
    }

    const [textValidator, setTextValidator] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isMin12, setIsMin12] = useState(false);
    const [isMin2Lower, setIsMin2Lower] = useState(false);
    const [isMin2Uper, setIsMin2Uper] = useState(false);
    const [isMin2Chars, setIsMin2Chars] = useState(false);
    const [isMin2Number, setIsMin2Number] = useState(false);

    const redColor = "red";
    const whiteColor = "rgba(0, 0, 0, 0.65)";
    const greenColor = "green";

    const [colorChars, setColorChars] = useState(whiteColor);
    const [colorUpper, setColorUpper] = useState(whiteColor);
    const [colorLower, setColorLower] = useState(whiteColor);
    const [colorNumbers, setColorNumbers] = useState(whiteColor);
    const [colorSpecial, setColorSpecial] = useState(whiteColor);

    const [validated, setValidated] = useState(false);
    if (state) {
        if (state.first_time) {
            showCancel = false;
        }
    }
    const handleDone = () => {
        let redirect = localStorage.getItem("lastPageVisited");
        history.replace(redirect);
    };
    const validateSecurity = (value) => {

        if (isAdmin) {
            let cont = 0;
            let regexMinLower = /(?:.*[a-z]){2}/;
            let regexMinUpper = /(?:.*[A-Z]){2}/;
            let regexTwoNumber = /(?:.*\d){2}/;
            let regexSpecial = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{2}/;

            if (value.length >= 12) {
                setColorChars(greenColor);
                setIsMin12(true);
                cont++;
            } else {
                setColorChars(redColor);
                setIsMin12(false);
                cont--;
            }

            if (regexMinLower.test(value)) {
                setColorLower(greenColor);
                setIsMin2Lower(true);
                cont++;
            } else {
                setColorLower(redColor);
                setIsMin2Lower(false);
                cont--;
            }

            if (regexMinUpper.test(value)) {
                setColorUpper(greenColor);
                setIsMin2Uper(true);
                cont++;
            } else {
                setColorUpper(redColor);
                setIsMin2Uper(false);
                cont--;
            }

            if (regexTwoNumber.test(value)) {
                setColorNumbers(greenColor);
                setIsMin2Number(true);
                cont++;
            } else {
                setColorNumbers(redColor);
                setIsMin2Number(false);
                cont--;
            }

            if (regexSpecial.test(value)) {
                setColorSpecial(greenColor);
                setIsMin2Chars(true);
                cont++;
            } else {
                setColorSpecial(redColor);
                setIsMin2Chars(false);
                cont--;
            }

            if (cont === 5) {
                validatePasswordHistory(value);
                setTextValidator("");
            } else {
                setTextValidator("The password does not meet all the requirements shown above.");
            }
        }
    }

    const validatePasswordHistory = (pass) => {
        if (isAdmin) {
            API.post("api/validatePasswordHistoryRecover", { pass: pass, tokenUser: token }).then(({ data }) => {
                if (data === true) {
                    setTextValidator("You cannot reuse this password.");
                    form.resetFields();
                    setIsMin12(false);
                    setIsMin2Lower(false);
                    setIsMin2Uper(false);
                    setIsMin2Number(false);
                    setIsMin2Chars(false);

                    setColorChars(redColor);
                    setColorLower(redColor);
                    setColorUpper(redColor);
                    setColorNumbers(redColor);
                    setColorSpecial(redColor);
                } else {
                    setTextValidator("");
                }
            })
        }
    }

    const onFinish = (values, history, recover, token) => {

        values.isAdmin = isAdmin;

        API.post(`api/recover-password/${token}`, values)
            .then(({ data }) => {
                if (data.response === true) {

                    history.replace("/login", {
                        message: {
                            type: "success",
                            message: "Your password has been successfully changed.",
                        },
                    });
                }
            })
            .catch((err) => {
                Modal.error({ content: err.response.data.response });
            });
    };

    useEffect(() => {
        if (!validated) {
            API.get(`api/validateUserRecover/${token}`)
                .then(({ data }) => {
                    setValidated(true);
                    if (data.response === true) {
                        setIsAdmin(data.isAdmin)
                    }
                })
                .catch((err) => {
                    Modal.error({ content: err.response.data.response });
                    history.replace("/login");
                });
        }
    })

    return (
        <Layout className="login-layout" style={{ height: "100%" }}>
            <Row justify="center" align="middle" style={{ height: "100%" }}>
                <Col className="gutter-row" lg={8} md={12} xs={24} sm={16}>
                    <Content>
                        <Row justify="center">
                            <Col span={13}>
                                <img
                                    src={imageLogo}
                                    className="login-layout-logo"
                                    alt="login"
                                />
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={24}>
                                <div style={alertStyle}>
                                    <p style={{ padding: '10px' }} >
                                        <strong>
                                            Enter your new password below.
                                        </strong>
                                    </p>
                                    {isAdmin ? (
                                        <p style={{ padding: '0 10px 10px' }} >
                                            The password must have: <br />

                                            <ul>
                                                <li style={{ color: colorChars, fontWeight: isMin12 ? "bold" : "normal" }}>a minimum of 12 characters {isMin12 ? <CheckOutlined /> : <></>}</li>
                                                <li style={{ color: colorUpper, fontWeight: isMin2Uper ? "bold" : "normal" }}>at least two upper case characters {isMin2Uper ? <CheckOutlined /> : <></>}</li>
                                                <li style={{ color: colorLower, fontWeight: isMin2Lower ? "bold" : "normal" }}>at least two lower case characters {isMin2Lower ? <CheckOutlined /> : <></>}</li>
                                                <li style={{ color: colorNumbers, fontWeight: isMin2Number ? "bold" : "normal" }}>at least two numbers {isMin2Number ? <CheckOutlined /> : <></>}</li>
                                                <li style={{ color: colorSpecial, fontWeight: isMin2Chars ? "bold" : "normal" }}>at least two special characters {isMin2Chars ? <CheckOutlined /> : <></>}</li>
                                            </ul>

                                            Do not re-use a prior password.
                                        </p>
                                    ) : <></>
                                    }
                                    <p style={styleTextRule}>{textValidator}</p>
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Form
                                    form={form}
                                    style={formNewPasswordStyle}
                                    name="basic"
                                    onFinish={(values) =>
                                        onFinish(
                                            values,
                                            history,
                                            recover,
                                            token
                                        )
                                    }
                                    onFinishFailed={onFinishFailed}
                                >

                                    <Form.Item
                                        name="password"
                                        rules={rules.password}
                                    >
                                        <Input placeholder="New Password" onKeyUp={(e) => validateSecurity(e.target.value)} onBlur={(e) => validatePasswordHistory(e.target.value)} iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)} />
                                    </Form.Item>
                                    <Form.Item
                                        name="repeat_password"
                                        dependencies={["password"]}
                                        rules={rules.repeat_password}
                                    >
                                        <Input placeholder="Confirm New Password" iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)} />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button htmlType="submit" ghost >
                                            Reset Password
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Content>
                </Col>
            </Row>
        </Layout >
    );
};

export default NewPassword;
