export const OPTION_STATUS = [
    {'value': 'active', 'label': 'Active'},
    {'value': 'inactive', 'label': 'Inactive'},
    {'value': 'pending', 'label': 'Pending'},
    {'value': 'merged', 'label': 'Merged'},
    {'value': 'all_status', 'label': 'All Accounts'},
];


export const OPTIONS_INTERNAL = [
    {'value': true, 'label': 'Include Internal'},
    {'value': false, 'label': 'Exclude Internal'},
];
