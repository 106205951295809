import React, { useState } from "react";
import { pinStyle } from "../Dashboard/Dashboard.style";
import { Spin } from "antd";

const Readings = () => {
    let [loadReadings, setLoadReadings] = useState(false);
    const { REACT_APP_CMS_URL } = process.env;
    const { token } = JSON.parse(localStorage.getItem("nisl-data"));
    return (
        <>
            {!loadReadings ? (
                <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
            ) : null}
            <iframe
                src={`${REACT_APP_CMS_URL}/nisl_pages/foundations-readings/?token=${token}&from_app=no&needs_a_handle=no`}
                frameBorder={"0"}
                title="foundations-readings"
                className="style-frame"
                onLoad={() => {
                    setLoadReadings(true);
                }}
            />
        </>
    );
};

export default Readings;
