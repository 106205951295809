import React from 'react'
import { Modal, Button, Row, Col, Spin, Layout, Space } from 'antd'
import API from "../../../api";
import { createUseStyles } from "react-jss";
import './cohorts.less'

const { Content } = Layout;
const useStyles = createUseStyles({
    largeButton: {
        height: "2em",
        fontSize: "11px",
        background: "#fff",
        color: "#0051a0",
        width: "80%",
        lineHeight: "1em",
    },
    container: {
        '& Button': {
            width: '80%',
            height: '40px',
            marginTop: '0.1em',
        },
    },
    blue: {
        color: "#0051a0",
    },
    black: {
        color: "#002345",
    },
    bold: {
        fontWeight: "bold",
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '6em',
    },
    solid: {
        color: '#0051a0',
        backgroundColor: '#0051a0',
        width: '0.5em',
        height: '0.5em',
        borderRadius: '0.25em',
        margin: '0.5em 0.25em',
    },
    deleteColor: {
        color: "red",
    },
    space: {
        whiteSpace: 'pre-wrap',
    },
});
const AttendanceData = (props) => {

    const { token } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_CMS_URL } = process.env;

    const classes = useStyles();

    const exportAttendance = (file, downloadName = false) => {
        let filename = `${file}.xlsx`;

        API.get(`api/date-export-reports?&filename=${file}`)
            .then(({ data }) => {
                if(downloadName !== false){
                    filename = `${downloadName} ${data}.xlsx`
                }
            });
        API.get(`api/download-attendance-on-report/${filename}`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        });
    }

    const exportAttendancePre2019 = () => {
        let url = `${REACT_APP_CMS_URL}/download-report/?report=portal_attendance_download_pre-2019&token=${token}`;
        window.location.href = url;
    }

    const exportAttendanceDetails = () => {
        let request = null;
        let filename = "EXT Session Absent Details.xlsx";
        request = API.get(`api/date-export-reports?&filename=FacilitatorSchedule`);
        request
            .then(({ data }) => {
                filename = "EXT Session Absent Details " + data + ".xlsx";
            });
        API.get(`api/download-attendance-ext-report`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        });
    }
    return (
        <>
            <Modal
                visible={props.showAttendanceData}
                title="Attendance  Data"
                centered
                width="60%"
                onCancel={() => props.setShowAttendanceData(false)}
                footer={null}
            >
                <Content className={classes.container}>
                    <Spin spinning={false} delay={500}>
                        <Space direction="vertical" size={10} style={{ display: 'flex' }}>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={()=>{exportAttendance('Attendance Data 2021-2022', 'Attendance Data 2021-on')}}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Attendance</div>
                                            <div>Data 2021-on</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Download an XLS with attendance data that's been recorded in the portal, January, 2021 - present. This report is generated once each day, at midnight.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={()=>{exportAttendance('Attendance Data 2019-2020', 'Attendance Data 2019-on')}}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Attendance</div>
                                            <div>Data 2019-2020</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Download an XLS with attendance data that's been recorded in the portal, January, 2019 - December, 2021. This report is generated once each day, at midnight.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={exportAttendancePre2019}>
                                        <Space direction="vertical" size={1}>
                                            <div>Attendance</div>
                                            <div>Data pre-2019</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Download an XLS with attendance data that's been recorded in the portal, May 2016 - December 2018. This report is generated once each day, at midnight.
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={exportAttendanceDetails}>
                                        <Space direction="vertical" size={1}>
                                            <div>Ext Attendance</div>
                                            <div>Details</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Participants marked absent for 1 or more Ext session are considered absent for the unit. This XLS shows which Ext session/s the participant was marked absent for. This data only is relevant for EXT units, not 2-day units.
                                </Col>
                            </Row>
                        </Space>
                    </Spin>
                </Content>
            </Modal>
        </>
    )
}


export default AttendanceData

