import React, { useState } from "react";
import { Layout, Spin } from "antd";
import { pinStyle } from "../../Dashboard/Dashboard.style";
const { Content } = Layout;

const ActionLearning = () => {
    const [loadActionLearnig, setLoadActionLearnig] = useState(false);
    const { token, cohort, roles_un, userID, alp } = JSON.parse(
        localStorage.getItem("nisl-data")
    );

    const { canSharedWithMe, courses_cohorts } = JSON.parse(
        localStorage.getItem("info-shared-me")
    );
    let jwt_alp = alp.token
    const { REACT_APP_PORTAL_URL, REACT_APP_URL } = process.env;

    return (
        <Content className="site-layout-background layout-portal-content">
            <div className="layout-portal-main-container">
                {!loadActionLearnig ? (
                    <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
                ) : null}
                <iframe
                    src={`${REACT_APP_PORTAL_URL}/?view=actionlearningproject_ncee&token=${token}&canSeeSharedWithMe=${canSharedWithMe}&cohort=${cohort}&roles=${roles_un}&alp=${jwt_alp}&userID=${userID}&redirect_app=${REACT_APP_URL}/action-learning&canSeeSharedWithMe_courses_cohorts=${courses_cohorts}`}
                    frameBorder={"0"}
                    onLoad={() => setLoadActionLearnig(true)}
                    title="action-learning"
                    className="style-frame"
                />
            </div>
        </Content>
    );
};

export default ActionLearning;
