import React, { useEffect } from "react";
import "./login.less";

import axios from "axios";
import https from "https";
import { Layout, Row, Col, Form, Input, Button, Modal } from "antd";
import imageLogo from "../../assets/img/logo.png";
import { loginFormStyle } from "./login.styles";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Item } = Form;

// const rules = {
//     username: [{ required: true, message: "Please input your username." }],
//     password: [{ required: true, message: "Please input your password." }],
// };

const onFinish = (values, history) => {
    localStorage.clear();
    sessionStorage.clear();
    const agent = new https.Agent({
        rejectUnauthorized: false,
    });
    axios
        .post(`${process.env.REACT_APP_BACKEND}/api/login`, values, {
            httpAgent: agent,
        })
        .then(({ data: response, status }) => {
            if (!response.error) {
                localStorage.setItem("jwt-token", response.token);
                localStorage.setItem("user-data", JSON.stringify(response.user));
                localStorage.setItem(
                    "nisl-data",
                    JSON.stringify(response.data)
                );
                if (response.first_time) {
                    localStorage.setItem("first_login", "true");
                }

                localStorage.setItem("date-last-update-pass", response.lastDateChangedPassword);

                window.location.replace("/");
            } else {
            }
        })
        .catch((err) => {
            console.log(err.response);
            if (err.response) {
                const response = err.response.data;
                if (response.username) {
                    error(response.username[0]);
                    return;
                }
                if (response.password) {
                    error(response.password[0]);
                    return;
                }
                if (response.data){
                    switch (response.data.error_log) {
                        case "Error password":
                            error(`The password entered does not match\n
                                the password saved for this username.
                                Try tapping the Show password box so
                                you can see the password that you
                                entered. Note that passwords are case
                                sensitive. If the error message persists,
                                use the Forgot Password link below.`);
                            break;
                        case "Error user":
                            error(`No such username exists on this system.
                            The username is typically your email
                            address. Please try again. If the error
                            message persists, call the NCEE
                            Helpdesk tollfree at 855-443-6359 or email
                            us at helpdesk@ncee.org`);
                            break;
                        case "Error data send":
                                error(`No such username exists on this system.
                                The username is typically your email
                                address. Please try again. If the error
                                message persists, call the NCEE
                                Helpdesk tollfree at 855-443-6359 or email
                                us at helpdesk@ncee.org`);
                            break;
                        case "User Merged" :
                                error(`This user account was merged into another account.
                                Please contact the NCEE Helpdesk at helpdesk@ncee.org or 855-443-6359 between 8 am and 6 pm (Eastern).`);
                                break;
                        case "User Inactive" :
                                error(`This user account is Inactive.
                                Please contact the NCEE Helpdesk at helpdesk@ncee.org or 855-443-6359 between 8 am and 6 pm (Eastern).`);
                                break;
                        case "User Pending" :
                                error(`This user account is pending review by administrators.
                                Please contact the NCEE Helpdesk at helpdesk@ncee.org or 855-443-6359 between 8 am and 6 pm (Eastern).`);
                                break;
                        case "General Error" :
                                error(`Your account does not have NCEE portal login privs. It is currently set as a Feedback Diagnostics account. We apologize for the inconvenience. Please contact the NCEE Helpdesk at helpdesk@ncee.org or 855-443-6359 between 8 am and 6 pm (Eastern).`)
                                break;
                        default:
                            break;
                    }
                }
            } else {
                error("Login Error #7.  We apologize for the inconvenience. Please contact the NCEE Helpdesk at helpdesk@ncee.org or 855-443-6359 between 8 am and 6 pm (Eastern).")
            }
        });
};

const onFinishFailed = (errorInfo) => {
    warning("Autentication failed. Please try again.")
};

const warning = (errorInfo) => {
    Modal.warning({
        content: errorInfo,
    });
};

const error = (text) => {
    Modal.error({
        title: "",
        content: text,
    });
};

const Login = () => {
    const history = useHistory();
    let params = useParams();
    let { msg } = params
    if (msg) {
        if (msg === "SessionExpired") {
            error(`Your session has expired. Please log in again. For help with accessing the NCEE portal and its resources, contact the NCEE Helpdesk at helpdesk@ncee.org or 855-443-6359 between 8 am and 6 pm (Eastern).`);
        } else {
            error(`${msg}`);
        }
    }


    useEffect(() => {
        if (history.location.state) {
            const state = history.location.state;
            if (state.message) {
                Modal[state.message.type]({ title: "", content: state.message.message })
            }
        }
    }, [history.location.state])

    return (
        <Layout className="login-layout">
            <Row justify="center" align="middle" style={{ height: "100%" }}>
                <Col className="gutter-row" lg={8} md={12} xs={24} sm={16}>
                    <Content>
                        <Row justify="center">
                            <Col span={13}>
                                <img
                                    src={imageLogo}
                                    className="login-layout-logo"
                                    alt="login"
                                />
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={22} style={{ textAlign: "center" }}>
                                <Form
                                    style={loginFormStyle}
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={(values) => onFinish(values, history)}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Item
                                        name="username"
                                    >
                                        <Input placeholder="Username" />
                                    </Item>

                                    <Item
                                        name="password"
                                    >
                                        <Input.Password placeholder="Password" iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)} />
                                    </Item>

                                    <Item>
                                        <Button htmlType="submit" ghost>
                                            Login
                                        </Button>
                                    </Item>
                                    <Item>
                                        <NavLink to="/forgot-password" className="forgotPassword">
                                            Forgot Password?
                                        </NavLink>
                                    </Item>
                                </Form>
                            </Col>
                        </Row>
                    </Content>
                </Col>
            </Row>
        </Layout>
    );
};

export default Login;
