import API from "../api";

export const getAccessVeo = async () => {
    try {
        const { token } = JSON.parse(localStorage.getItem("nisl-data"));
        let response = await API.post("api/getVeoUrl", {
            token_cms: token,
        });
        return response.data.urlIframeVeo;
    } catch (error) {
        console.log(error);
    }
};
