import React, { useForm, useState } from 'react';
import { Row, Layout, Col, Table, Space, Button, Avatar, Form, Radio } from 'antd';
import { createPhoneMask } from "../../../../functions/createPhoneMask";

const { Content } = Layout;

const ComparativeTable = ({ dataTableCompare, handleConfirm, handleCancel, disabledMergeButtons }) => {

    const [formMerge] = Form.useForm();
    const labelsCheck = ['name', 'job_title', 'job_function', 'phone', 'email', 'school', 'state', 'district'];
    const fieldsForm = [
        { name: ["name"], value: "primary" },
        { name: ["job_title"], value: "primary" },
        { name: ["job_function"], value: "primary" },
        { name: ["phone"], value: "primary" },
        { name: ["email"], value: "primary" },
        { name: ["school"], value: "primary" },
        { name: ["state"], value: "primary" },
        { name: ["district"], value: "primary" }
    ];

    const columns = [
        {
            title: "",
            dataIndex: "label",
            rowKey: "label",
            width: '7em',
            render: (value, record) => {
                if (record.label == "avatar") {
                    return <>
                    </>
                } else {
                    return value;
                }
            }
        },
        {
            title: "Secondary User",
            dataIndex: "secondary",
            rowKey: "secondary",
            width: '18.5em',
            render: (value, record) => {
                if (record.label === "avatar") {
                    return <>
                        <Row align="middle">
                            <Col span="3">
                                <Avatar src={value.avatar} onError={function (error) {
                                }} style={{ border: "1.2px solid #c3c3c3", width: "43px", height: "43px" }} />
                            </Col>

                            <Col span="21">
                                Created by: {value.created_data} + last modified by {value.updated_data}
                            </Col>
                        </Row>
                    </>
                } else if (record.label === "phone") {
                    return <Row align="end">
                        <Col>{createPhoneMask(value?.phone)} {value?.extension}</Col>
                    </Row>
                } else {
                    if (labelsCheck.includes(record.key)) {
                        return <Row align="end">
                            <Col> {value} </Col>
                        </Row>
                    } else {
                        return value;
                    }
                }
            }
        },
        {
            title: "Choose Profile Data",
            dataIndex: "choose_profile",
            rowKey: "choose_profile",
            width: '5em',
            align: "center",
            render: (value, record) => {
                if (labelsCheck.includes(record.key)) {
                    return (
                        <Form.Item
                            style={{ marginBottom: "0px" }}
                            name={record.key}
                        >
                            <Radio.Group>
                                <Radio value="secondary"></Radio>
                                <Radio value="primary"></Radio>
                            </Radio.Group>
                        </Form.Item>
                    )
                } else {
                    return value;
                }
            }
        },
        {
            title: "Primary User",
            dataIndex: "primary",
            rowKey: "primary",
            width: '30em',
            render: (value, record) => {
                if (record.label == "avatar") {
                    return <>
                        <Row align="middle">
                            <Col span="2">
                                <Avatar src={value.avatar} onError={function (error) {
                                }} style={{ border: "1.2px solid #c3c3c3", width: "43px", height: "43px" }} />
                            </Col>

                            <Col span="22">
                                Created by: {value.created_data} + last modified by {value.updated_data}
                            </Col>
                        </Row>
                    </>
                } else if (record.label == "phone") {
                    return <>
                        {createPhoneMask(value?.phone)} {value?.extension}
                    </>
                } else {
                    return value;
                }
            }
        },
    ];

    return (
        <Content>
            {dataTableCompare?.length > 0 &&
                <>
                    <Form form={formMerge}
                        onFinish={handleConfirm}
                        fields={fieldsForm}
                    >
                        <Table
                            rowClassName={() => "responsive-row"}
                            columns={columns}
                            dataSource={dataTableCompare}
                            pagination={false} sticky />
                        <br />

                        <Row justify="middle">
                            <Col xs={24} md={3} style={{ textAlign: "left" }}>
                                <Space direction="horizontal">
                                    <Button disabled={disabledMergeButtons}
                                        type="primary"
                                        onClick={() => { handleCancel() }}>
                                        Cancel
                                    </Button>
                                    <Button disabled={disabledMergeButtons}
                                        type="primary"
                                        htmlType="submit">
                                        Merge
                                    </Button>
                                </Space>
                            </Col>
                            <Col md={21}>
                                <span style={{ color: "red", fontWeight: "bold" }}>
                                    The Secondary account will be deleted after merging. The Primary account will be the only account visible after the merge. Profile info and Annotations do not get merged into the Primary account. Cohort and attendance, ALps, Diagnostics, Feedback Diagnostics, Course Privs, and Journal Note all get merged into the Primary account.
                                </span>
                            </Col>
                        </Row>
                    </Form>
                </>
            }
        </Content >
    )
}

export default ComparativeTable;
