import React from "react";
import { Layout } from "antd";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Cohorts from "./Cohorts/Cohorts";
import Advisers from "./Advisers";
import Calendar from "./Calendar/Calendar";
import Downloads from "./Downloads";
import QueryPage from "./QueryPage";
import Roster from "./Cohorts/Roster/Roster"
const { Content } = Layout;

const Dashboard = (setCollapsed) => {
    let { path } = useRouteMatch();

    return (
        <Content className="site-layout-background layout-portal-content">
            <div className="layout-portal-main-container">
                <Switch>
                    <Route path={`${path}/cohort/:cohortId(\\d+)/event/:eventId/unit/:unitId/cohort/:cohort`}>
                        <Roster/>
                    </Route>
                    <Route exact path={path}>
                        <Redirect to={`${path}/cohorts`} />
                    </Route>
                    <Route path={`${path}/cohorts`}>
                        <Cohorts />
                    </Route>
                    <Route path={`${path}/adviser`}>
                        <Advisers />
                    </Route>
                    <Route path={`${path}/calendar`}>
                        <Calendar />
                    </Route>
                    <Route path={`${path}/downloads`}>
                        <Downloads />
                    </Route>
                    <Route path={`${path}/query-page`}>
                        <QueryPage />
                    </Route>
                </Switch>
            </div>
        </Content>
    );
};

export default Dashboard;
