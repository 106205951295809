import React from 'react'
import { Input, Form, Modal, Radio, Row, Col,Button } from 'antd';
import RichTextEditor from '../../../components/RichTextEditor';


const FormLogonPopup = ({ visible, handleOk, handleCancel, textArea, setTextArea, form, edit, showDelete }) => {
    return (
        <Modal
            title={edit ? "Update Participant Notification" : "Add New Participant Notification"}
            centered
            width="70%"
            visible={visible}
            onOk={handleOk} onCancel={handleCancel}
            footer={
                <div>
                    <Button
                        type="primary"
                        danger
                        style={{ float: "left" }}
                        onClick={() => {
                            showDelete(form.getFieldValue("id"));
                        }}>
                        Delete
                    </Button>
                    <Button
                        onClick={() => {
                            handleCancel();
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleOk();
                        }}>
                        OK
                    </Button>
                </div>
            }
        >
            <Form layout="vertical" form={form}>
                <Form.Item name="id" noStyle>
                    <Input type="hidden"></Input>
                </Form.Item>
                <Form.Item name="headline">
                    <Input placeholder="Headline" size="large"></Input>
                </Form.Item>
                <Row className="ant-form-item">
                    <Col span={24} className="ant-form-item-label">
                        <label>Text:</label>
                    </Col>
                    <Col span={24} className="ant-form-item-control" >
                        <RichTextEditor value={textArea} setValue={setTextArea} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={8}>
                        <Form.Item name="active" label="This Popup is:">
                            <Radio.Group>
                                <Radio value={true}>
                                    Activated
                                    </Radio>
                                <Radio value={false}>
                                    Not Activated
                                    </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default FormLogonPopup
