import React, { useEffect, useState } from 'react'
import CohortBuilderForm from './CohortBuilderForm'
import CohortList from './CohortList'
import { Form, message, Modal } from 'antd'
import getCohorts from '../../../functions/cohorts/getCohorts'
import getForm from '../../../functions/cohorts/getForm'
import API from '../../../api';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';


const { useForm } = Form;

const CohortBuilder = (props) => {
    const [fieldsCohortBuilder, setFieldsCohortBuilder] = useState([]);
    const [configsCohortBuilder, setConfigsCohortBuilder] = useState([]);
    const [cohortData, setCohortData] = useState([]);
    const [displayForm, setDisplayForm] = useState(false);
    const [currentCohort, setCurrentCohort] = useState({})
    const [valueSearch, setValueSearch] = useState("");
    const [sorterColumn, setSorterColumn] = useState("created_at");
    const [sorterOrder, setSorterOrder] = useState("desc");
    const [LoadingTable, setLoadingTable] = useState(false);
    const [filterSearch, setFilterSearch] = useState(false);
    const [finishing, setFinishing] = useState(false);
    const [edit, setEdit] = useState(false);
    const [ready, setReady] = useState(false);
    const [courses, setCourses] = useState(false);
    const [meetingTypes, setMeetingTypes] = useState([]);
    const [form] = useForm();
    const [cohortId, setCohortId] = useState(0);

    const [district, setDistrict] = useState([]);
    const [valuesChanged, setValuesChanged] = useState(false);
    const [visibleFilterCohortBuilder, setVisibleFilterCohortBuilder] = useState(false);
    const [filterSearchValues, setFilterSearchValues] = useState((localStorage.getItem('filterCohortBuilder') ? JSON.parse(localStorage.getItem('filterCohortBuilder')) : false));
    const [textFilter, setTextFilter] = useState(() => {
        if (JSON.parse(localStorage.getItem('filterCohortBuilder')) != null) {
            return 'Filters have been set.';
        } else {
            return '';
        }
    });

    const [textTotalCohorts, setTextTotalCohorts] = useState("");
    const [extraClassBtn, setExtraClassBtn] = useState("no_force_selected");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCoach, setIsCoach] = useState(false);

    const { roles_un } = JSON.parse(localStorage.getItem("nisl-data"));

    const onFinish = (values, sub_courses) => {
        setFinishing(true);
        message.loading({
            content: "Saving cohort...",
            key: "saving_cohort",
            duration: 0,
        });
        let values_form = { ...values };
        // return;
        if (values_form?.orientation.length === 0 || values_form?.orientation[0]?.event_type === "n/a") {
            delete values_form.orientation;
        }

        if (values_form?.orientation) {
            values_form = {
                ...values, orientation: [{
                    ...values.orientation[0],
                    start_date: values.orientation[0]?.start_date ? values.orientation[0]?.start_date.format("YYYY-MM-DD") : undefined,
                    end_date: values.orientation[0]?.end_date ? values.orientation[0]?.end_date.format("YYYY-MM-DD") : undefined,
                    start_time: values.orientation[0]?.start_time ? values.orientation[0]?.start_time.format("HH:mm:ss") : undefined,
                    end_time: values.orientation[0]?.end_time ? values.orientation[0].end_time.format("HH:mm:ss") : undefined
                }]
            }
        }

        setValuesChanged(false);
        formatDateAndTime(values_form);

        API.post('api/cohort', { edit, ...values_form, sub_courses }).then(({ data }) => {
            getCohorts().then(({ data }) => {
                message.destroy("saving_cohort");
                setFinishing(false);
                setDisplayForm(false);
                setCohortData(data.cohorts);
                form.resetFields();
            }).catch((err) => {
                console.log("error saving cohort", err);
                message.destroy("saving_cohort");
                Modal.error({
                    title: 'Failed to save cohort',
                    content: 'Please verify the information entered.',
                });
            })
        }).catch((err) => {
            console.log("error saving cohort", err);
            message.destroy("saving_cohort");
            Modal.error({
                title: 'Failed to save cohort',
                content: 'Please verify the information entered.',
            });
        })
    };

    const formatDateAndTime = (values_form) => {
        values_form.cohort_schedule.forEach(unit => {
            if (unit.event_type === "ext" && unit.cohort_schedule_sub.length !== 0 && unit.unit !== null && unit.unit !== undefined) {
                unit.cohort_schedule_sub.forEach((sub_unit, index) => {
                    sub_unit.start_date_sub_unit = sub_unit?.start_date_sub_unit ? sub_unit.start_date_sub_unit.format('YYYY-MM-DD') : '';
                    sub_unit.end_date_sub_unit = sub_unit?.end_date_sub_unit ? sub_unit.end_date_sub_unit.format('YYYY-MM-DD') : '';
                    sub_unit.start_time_sub_unit = sub_unit?.start_time_sub_unit ? sub_unit.start_time_sub_unit.format("HH:mm:ss") : '';
                    sub_unit.end_time_sub_unit = sub_unit?.end_time_sub_unit ? sub_unit.end_time_sub_unit.format("HH:mm:ss") : '';
                })
            } else {
                unit.start_date = unit?.start_date ? unit.start_date.format('YYYY-MM-DD') : '';
                unit.end_date = unit?.end_date ? unit.end_date.format('YYYY-MM-DD') : '';
                unit.start_time = unit?.start_time ? unit.start_time.format("HH:mm:ss") : '';
                unit.end_time = unit?.end_time ? unit.end_time.format("HH:mm:ss") : '';
            }
        });
    }

    useEffect(() => {
        getForm().then(({ data }) => {
            setFieldsCohortBuilder(data.fields);
            setConfigsCohortBuilder(data.config);
        }).catch()

        getCourses()

        getMetetingTypes()
    }, []);

    useEffect(() => {
        setLoadingTable(true);
        generalGetCohorts();
        getOptionsDistricts();

        if (roles_un.includes("administrator") || roles_un.includes("senior_admin")) {
            setIsAdmin(true);
        }

        if (roles_un.includes("coach")) {
            setIsCoach(true);
        }

    }, []);

    const handleSearch = (search, values = null, form = null) => {

        setLoadingTable(true)
        setValueSearch(search);

        let empty_form = true;
        form.getFieldsValue("filter", input => {
            if (input.touched === true && form.getFieldValue(input.name[0]) != '') {
                empty_form = false;
            }
        });

        if (empty_form) {
            setTextFilter("");
            setExtraClassBtn("no_force_selected")
        } else {
            localStorage.setItem("filterCohortBuilder", JSON.stringify(values));
            localStorage.setItem('filterCohortBuilderSave', true)
            setTextFilter("Filters have been set.");
            setExtraClassBtn("force_selected")
        }

        generalGetCohorts(search, values);
    }

    let getCohortsPaginate = (current, pageSize, column, order) => {
        setLoadingTable(true)
        API.get(`api/cohort-builder/cohorts?page=${current}&per_page=${pageSize}&column=${column}&order_by=${order}`).then(({ data }) => {
            setCohortData(data.cohorts);
            setLoadingTable(false)
        });
    }


    const handleSearchInput = (value) => {

        setLoadingTable(true)
        setValueSearch(value);

        let filterAplied;

        if ((!roles_un.includes("administrator") && !roles_un.includes("senior_admin") && roles_un.includes("coach"))) {
            filterAplied = { course: [179] };
        }

        if (value && value !== null && value !== undefined && value !== "") {
            API.get(`api/cohort/search/${value}?column=${sorterColumn}&order_by=${sorterOrder}&filter=${JSON.stringify(filterAplied)}`).then(({ data }) => {
                setFilterSearch(true);
                setCohortData(data.cohorts);
                setLoadingTable(false)
            });
        } else {
            generalGetCohorts();

            /*getCohorts().then(({ data }) => {
                setCohortData(data.cohorts);
                setLoadingTable(false)
            }).catch()*/
        }
    }

    let getDataPaginate = (page, filters = {}, sorter = {}, extra = {}) => {

        let change_order = false;

        if (extra.action === "sort" && !sorter.column !== undefined && sorter.order !== undefined) {
            change_order = true;
            setSorterColumn(sorter.field);
            setSorterOrder(sorter.order);
        }

        let column = change_order ? sorter.field : sorterColumn;
        let order = change_order ? sorter.order : sorterOrder;

        generalGetCohorts(null, null, column, order, page.current, page.pageSize);
    };

    let generalGetCohorts = (search = null, filter = null, sorterColumn = null, sorterOrder = null, pageCurrent = null, pageSize = null) => {

        let filterAplied = filter != null ? filter : JSON.parse(localStorage.getItem('filterCohortBuilder'));

        if ((!roles_un.includes("administrator") && !roles_un.includes("senior_admin") && roles_un.includes("coach"))) {
            filterAplied = { course: [179] };
        }

        let searchText = search != null ? search : valueSearch;

        API.get(`api/cohort-builder/cohorts?search=${searchText}&column=${sorterColumn}&order_by=${sorterOrder}&page=${pageCurrent}&per_page=${pageSize}&filter=${JSON.stringify(filterAplied)}`).then(({ data }) => {
            setFilterSearch(true);
            setCohortData(data.cohorts);
            setLoadingTable(false)
            setTextTotalCohorts(data.cohorts.total.toLocaleString() + ' of ' + data.totalCohorts.toLocaleString() + ' cohorts')
        });
    }
    let getCourses = () => {
        API.get("/api/courses/get/cohort-builder").then(({ data }) => {
            setCourses(data)
        })
    }

    let getOptionsDistricts = () => {
        API.get(`api/info-districts`).then(({ data }) => {
            setDistrict(data.response);
        });
    }

    const handleCancel = (form) => {
        // Validate that the fields have not changed
        if (valuesChanged) {
            Modal.confirm({
                title: "Do you want to cancel?",
                icon: <ExclamationCircleOutlined />,
                okButtonProps: { type: "primary" },
                okText: "Yes",
                cancelText: "No",
                cancelButtonProps: { danger: true },
                onOk() {
                    form.resetFields();
                    setDisplayForm(false);
                    setValuesChanged(false);
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        } else {
            form.resetFields();
            setDisplayForm(false);
        }
    };

    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this permanently? this cannot be reversed.',
            icon: <ExclamationCircleOutlined style={{ color: "#ecd62a" }} />,
            onOk() {
                API.delete(`api/cohort/${id}`).then(() => {
                    getCohorts().then(({ data }) => {
                        setDisplayForm(false);
                        setCohortData(data.cohorts);
                    }).catch()
                }).catch();
            }
        });
    }

    let disabledDate = (current) => {
        return current && current < moment().subtract(1, 'days');
    }

    let getMetetingTypes = () => {
        API.get("/api/meeting_types").then(({ data }) => {
            setMeetingTypes(data)
        })
    }

    return (
        <div>
            {!displayForm ?
                <CohortList
                    cohortData={cohortData} setDisplayForm={setDisplayForm}
                    setCurrentCohort={setCurrentCohort} handleSearch={handleSearch}
                    handleSearchInput={handleSearchInput}
                    LoadingTable={LoadingTable}
                    configsCohortBuilder={configsCohortBuilder}
                    getDataPaginate={getDataPaginate}
                    setEdit={setEdit}
                    ready={ready}
                    setReady={setReady}
                    disabledDate={disabledDate}
                    setCohortId={setCohortId}
                    visibleFilterCohortBuilder={visibleFilterCohortBuilder}
                    setVisibleFilterCohortBuilder={setVisibleFilterCohortBuilder}
                    filterSearchValues={filterSearchValues}
                    textFilter={textFilter}
                    setTextFilter={setTextFilter}
                    textTotalCohorts={textTotalCohorts}
                    extraClassBtn={extraClassBtn}
                    isAdmin={isAdmin}
                    isCoach={isCoach}
                /> : null}
            {displayForm ?
                <CohortBuilderForm
                    form={form} onFinish={onFinish}
                    setDisplayForm={setDisplayForm}
                    currentCohort={currentCohort}
                    cohortBuilderFields={fieldsCohortBuilder}
                    onCancel={handleCancel}
                    configsCohortBuilder={configsCohortBuilder}
                    edit={edit}
                    ready={ready}
                    setReady={setReady}
                    onDelete={handleDelete}
                    disabledDate={disabledDate}
                    finishing={finishing}
                    selectCourses={courses}
                    selectDistrict={district}
                    valuesChanged={valuesChanged}
                    setValuesChanged={setValuesChanged}
                    meetingTypes={meetingTypes}
                    cohortId={cohortId}
                    isAdmin={isAdmin}
                    isCoach={isCoach}
                /> : null}
        </div>
    )
}

export default CohortBuilder
