import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Row, Col, Select, Checkbox, Radio, DatePicker, Divider, Button} from "antd";
import API from "../../../../api";
import Districts from "../../../../components/Districts";
import Schools from "../../../../components/Schools";
import moment from 'moment';
import { OPTION_STATUS, OPTIONS_INTERNAL } from "../../../../config/selectOptions";

const FilterUser = ({ visible, onCancel, values, filterSearchData, setFilterSearchValues,  setTextFilter }) => {

    const [form] = Form.useForm();
    const styLabels = {
        margin: '2px 0px',
        padding: '0px'
    };
    const styBodyModal = {
        paddingTop: '0px'
    };
    const styleRow = {
        paddingTop: "5px",
        paddingBottom: "5px"
    }

    const ENTER_KEY = 'Enter';

    const [privilegesDisabled] = useState(false);

    useEffect(() => {
        getJobFunctions()
        //eslint-disable-next-line
    }, []);

    const [rolesForm, setRolesForm] = useState(1);
    const [coursesForm, setCoursesForm] = useState();
    const [cohortsForm, setCohortsForm] = useState();
    const [browsersForm, setBrowsersForm] = useState();
    const [ipsForm, setIpsForm] = useState();
    const [osForm, setOsForm] = useState();
    const [courseFormDefault, setCourseFormDefault] = useState([]);
    const [updatedbyForm, setUpdatedbyForm] = useState();
    const [createdbyForm, setCreatedbyForm] = useState();
    const [statesForm, setStatesForm] = useState();
    const [requiredState] = useState(false);
    const [stateId, setStateId] = useState(0);
    const [district, setDistrict] = useState(0);
    const [school, setSchool] = useState();
    const [jobFunctions, setJobFunctions] = useState([]);
    const [
        stateCoordinatorComponent,
        setStateCoordinatorComponent,
    ] = useState("");
    const [voidCertificateBy, setVoidCertificateBy] = useState();
    const [createdCertificateBy, setCreatedCertificateBy] = useState();

    const component =
        <Form.Item name="states_coordinator">
            <Select
                mode="multiple"
                showSearch
                placeholder="States Director"
                filterOption={(input, option) =>
                    option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                }
                options={statesForm}
            ></Select>
        </Form.Item>;

    let getJobFunctions = (selected_value) => {
        API.get("api/job_functions").then(({ data }) => {
            setJobFunctions(data)
        })
    }

    const getSelectOptions = () => {
        API.get("/api/getOptions")
            .then(({ data }) => {

                //Filter the roles to show on the priv list
                let filterRoles = data.roles?.filter(role => !role.hide || role.slug === "administrator" || role.slug === "senior_admin");

                setRolesForm(filterRoles);

                setStatesForm(data.states);
                setUpdatedbyForm(data.updatedBy);
                setCreatedbyForm(data.createdBy);
                if (data.courses.length !== undefined) {
                    setCoursesForm(data.courses.map(e => ({
                        label: `(${e.number.replace("cohort", "")}) ${e.label}`,
                        value: e.value
                    })));

                    setCourseFormDefault(data.courses.map(e => ({
                        label: `(${e.number.replace("cohort", "")}) ${e.label}`,
                        value: e.value
                    })));
                }

                if (data.cohorts.length !== undefined) {
                    setCohortsForm(data.cohorts.map(e => ({
                        label: e.label,
                        value: e.value
                    })));
                }
                if (data.browsers.length !== undefined) {
                    setBrowsersForm(data.browsers.map(e => ({
                        label: e.label,
                        value: e.value
                    })));
                }
                if (data.ips.length !== undefined) {
                    setIpsForm(data.ips.map(e => ({
                        label: e.label,
                        value: e.value
                    })));
                }
                if (data.os.length) {
                    setOsForm(data.os.map(e => ({
                        label: e.label,
                        value: e.value
                    })));
                }

            })
            .catch((error) => {
                console.log(error);
            });
    };

    const isStateCoordinator = (e) => {
        if (e.target.checked) {
            setStateCoordinatorComponent(component);
        } else {
            setStateCoordinatorComponent("");
        }
    };

    const getUserListFilter = (search, typeFilter) => {
        API.get(`api/getUserListFilter?search=${search}&typeFilter=${typeFilter}`).then(({ data }) => {

            if (typeFilter === "whoCreateCertificate") {
                setCreatedCertificateBy(data);
            }

            if (typeFilter === "whoVoidCertificate") {
                setVoidCertificateBy(data);
            }
        });
    }

    useEffect(() => {

        if (visible) {
            if (localStorage.getItem('filter_users') == null) {
                getUserListFilter("", "whoCreateCertificate");
                getUserListFilter("", "whoVoidCertificate");
                setTextFilter("");
            } else {
                let filterValues = JSON.parse(localStorage.getItem('filter_users'));

                filterValues.create_certificate_start = filterValues.create_certificate_start ? moment(filterValues.create_certificate_start) : "";
                filterValues.create_certificate_end = filterValues.create_certificate_end ? moment(filterValues.create_certificate_end) : "";
                filterValues.void_at = filterValues.void_at ? moment(filterValues.void_at) : "";
                filterValues.void_end = filterValues.void_end ? moment(filterValues.void_end) : "";
                filterValues.created_at = filterValues.created_at ? moment(filterValues.created_at) : '';
                filterValues.created_at_end = filterValues.created_at_end ? moment(filterValues.created_at_end) : '';
                filterValues.updated_at = filterValues.updated_at ? moment(filterValues.updated_at) : '';
                filterValues.updated_at_end = filterValues.updated_at_end ? moment(filterValues.updated_at_end) : '';
                filterValues.login_at = filterValues.login_at ? moment(filterValues.login_at) : '';
                filterValues.login_at_end = filterValues.login_at_end ? moment(filterValues.login_at_end) : '';
                filterValues.profile_updated = filterValues.profile_updated ? moment(filterValues.profile_updated) : '';
                filterValues.profile_updated_at_end = filterValues.profile_updated_at_end ? moment(filterValues.profile_updated_at_end) : '';

                getUserListFilter(filterValues.created_by, "whoCreateCertificate");
                getUserListFilter(filterValues.void_by, "whoVoidCertificate");

                form.setFieldsValue(filterValues);
            }
        }

        getSelectOptions();
        getJobFunctions();
        // eslint-disable-next-line
    }, [visible]);

    let handleFilter = () => {
        form.validateFields()
        .then((values) => {

            let dateTimeFields = [
                'profile_updated',
                'profile_updated_at_end',
                'created_at',
                'updated_at',
                'created_at_end',
                'updated_at_end',
                'create_certificate_start',
                'create_certificate_end',
                'void_at',
                'void_end',
                'login_at',
                'login_at_end',
            ];

            dateTimeFields.map(field => values[field] ? values[field] = values[field].format('yyyy-MM-DD HH:mm') : '')
            setFilterSearchValues(values)
            filterSearchData(values, form)
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    return (
        <Modal
            visible={visible}
            title={"Filter Users List"}
            centered
            cancelText="Cancel"
            cancelButtonProps={{ danger: true }}
            onCancel={() => {
                onCancel(values, form, null)

            }}
            width="92%"
            okButtonProps={{ type: "primary" }}
            bodyStyle={styBodyModal}
            footer={
                <div>


                    <Button
                        onClick={() => {
                            onCancel(values, form, null)
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type="secundary"
                        onClick={() => {
                            form.resetFields();
                            if (JSON.parse(localStorage.getItem('filter_users')) != null) {
                                localStorage.removeItem('filter_users');
                            }
                            setDistrict(0);
                            localStorage.setItem('filter_save', false)
                            setFilterSearchValues(false);
                            handleFilter()
                        }}>
                        Clear
                    </Button>
                    <Button
                        type="primary"
                        onClick={ handleFilter }>
                        Done
                    </Button>
                </div>
            }
        >
            <Form
                 onKeyUp={(e) => {
                    if (e.key === ENTER_KEY) {
                        handleFilter()
                    }
                }}
                form={form}
                style={{ display: "unset" }}
                name="filter" layout="inline">
                <Row gutter={[0, 8]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={4}>
                        <Form.Item
                            name="id">
                            <Input placeholder="ID"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item
                            name="username"
                        >
                            <Input placeholder="Username" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={2}>
                        <Form.Item
                            name="honorific">
                            <Input placeholder="Honorific" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item
                            name="first_name">
                            <Input placeholder="First Name" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item
                            name="last_name">
                            <Input placeholder="Last Name" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={14} md={14} lg={10} xl={6}>
                        <Form.Item
                            name="email"
                        >
                            <Input placeholder="Email" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={11} md={11} lg={7} xl={6}>
                        <Form.Item name="phone">
                            <Input placeholder="Phone" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={13} md={13} lg={9} xl={6}>
                        <Form.Item name="job_title">
                            <Input placeholder="Job Title" autoComplete="new-password" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={14} md={14} lg={10} xl={6}>
                        <Form.Item className="job_function" name="job_function">
                            <Select
                                mode='multiple'
                                placeholder="Job Function"
                                optionFilterProp="children"
                                options={jobFunctions}
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={[0, 10]} id="AddressInfo" style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="address1">
                            <Input placeholder="Address1" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="address2">
                            <Input placeholder="Address2" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="city">
                            <Input placeholder="City" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="ZIP code">
                            <Input placeholder="ZIP code" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item
                            validateStatus={requiredState ? "error" : ""}
                            name="state_id">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="State"
                                options={statesForm}
                                optionFilterProp="children"
                                autoComplete="new-password"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }
                                onChange={(value, item) => {
                                    setStateId(value);
                                    setDistrict(0);
                                    let values = form.getFieldsValue();
                                    values.district_id = null;
                                    values.school_name = null;
                                    form.setFieldsValue(values)
                                }}
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={15} md={15} lg={11} xl={9}>
                        <Form.Item name="district">
                            <Districts form={form} multiple={false} stateId={stateId} district={district} setDistrict={setDistrict} setSchool={setSchool} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={15} md={15} lg={11} xl={9}>
                        <Schools form={form} multiple={false} district_id={district} school={school} setSchool={setSchool} />
                    </Col>
                </Row>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item name="district">
                            <Input placeholder="District as entered" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item name="school">
                            <Input placeholder="School as entered" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                     <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item name="profile_updated">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                 }}
                                format={"yyyy-MM-DD HH:mm"}
                                placeholder="Profile updated from [date]"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item name="profile_updated_at_end">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                 }}
                                format={"yyyy-MM-DD HH:mm"}
                                placeholder="Profile updated to [date]"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12}>
                        <Form.Item name="roles">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Priv"
                                options={rolesForm}
                                optionFilterProp="children"
                                autoComplete="new-password"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }
                                disabled={privilegesDisabled}
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} >
                        <Form.Item name="courses">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Course Privileges"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                options={coursesForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item name="cohorts" placeholder='Cohort'>
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Cohort"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                options={cohortsForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item name="notes">
                            <Input placeholder='Notes' rows={2} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={4} xl={4} className="user_state" style={{paddingRight: '5px'}}>
                        <Form.Item name="status">
                            <Select options={OPTION_STATUS} placeholder='Status'/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={4} xl={4} className="rich_editor" >
                        <Form.Item
                            valuePropName="checked"
                            name="state_coordinator"
                        >
                            <Checkbox onChange={isStateCoordinator}>
                                Is State Director
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={10} lg={8} xl={5}>
                        {stateCoordinatorComponent}
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                    <Form.Item name="internal">
                        <Select options={OPTIONS_INTERNAL} placeholder='Internal'/>
                    </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={9} >
                        <Form.Item
                            label="Bookmark:"
                            name="default_course"
                            style={{ marginRight : '8px' }}
                            >
                            <Select
                                showSearch
                                placeholder="Bookmark"
                                autoComplete="new-password"
                                optionFilterProp="children"
                                options={courseFormDefault}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" style={styLabels} plain>
                    Update info
                </Divider>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="created_at">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                 }}
                                placeholder="User created from [date]"
                                format={"yyyy-MM-DD HH:mm"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="created_by">
                            <Select
                                showSearch
                                placeholder="User created By"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                options={createdbyForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="updated_at">
                            <DatePicker
                                showTime= {{
                                   format: "HH:mm"
                                }}
                                placeholder="User modify from [date]"
                                format={"yyyy-MM-DD HH:mm"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="updated_by">
                            <Select
                                showSearch
                                placeholder="User modified By"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                options={updatedbyForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="created_at_end">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                 }}
                                placeholder="User created to [date]"
                                format={"yyyy-MM-DD HH:mm"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}></Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="updated_at_end">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                }}
                                placeholder="User modify to [date]"
                                format={"yyyy-MM-DD HH:mm"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}></Col>
                </Row>

                <Divider orientation="left" style={styLabels} plain>
                    Certificate info
                </Divider>

                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="created_certificate_by">
                            <Select
                                showSearch
                                placeholder="Certificate created By"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                onInputKeyDown={(e) => getUserListFilter(e.target.value, 'whoCreateCertificate')}
                                options={createdCertificateBy}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="create_certificate_start">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                }}
                                placeholder="Certificate created from [date]"
                                format={"yyyy-MM-DD HH:mm"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="create_certificate_end">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                }}
                                placeholder="Certificate created to [date]"
                                format={"yyyy-MM-DD HH:mm"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[0, 10]} style={styleRow}>

                    <Col xs={24} sm={12} md={12} >
                        <Form.Item name="courses_certificates">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Course for which the certificate was issue"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                options={coursesForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item name="cohorts_certificates">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Cohort in which the certificate was issue"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                options={cohortsForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="void_by">
                            <Select
                                showSearch
                                placeholder="Certificate Void By"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                onInputKeyDown={(e) => getUserListFilter(e.target.value, 'whoVoidCertificate')}
                                options={voidCertificateBy}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="void_at">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                }}
                                placeholder="Certificate Void from [date]"
                                format={"yyyy-MM-DD HH:mm"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="void_end">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                }}
                                placeholder="Certificate void to [date]"
                                format={"yyyy-MM-DD"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                </Row>


                <Divider orientation="left" style={styLabels} plain>
                    Session info
                </Divider>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="login_at">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                }}
                                placeholder="Last login from [date]"
                                format={"yyyy-MM-DD HH:mm"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="so">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Operating System"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                options={osForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="isp">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="ISP"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                options={ipsForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                    </Col>
                </Row>
                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="login_at_end">
                            <DatePicker
                                showTime= {{
                                    format: "HH:mm"
                                }}
                                placeholder="Last login to [date]"
                                format={"yyyy-MM-DD HH:mm"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="browser">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Browser"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                options={browsersForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={12}>
                        <Form.Item name="sesion" label="Session:">
                            <Radio.Group>
                                <Radio value={true}>Logged In</Radio>
                                <Radio value={false}>Logged Out</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default FilterUser;
