import React, { useState, useEffect } from "react";
import {Form,Input,Modal,Row,Col,Select,Checkbox,Button,Tag} from "antd";
import API from "../../api";
import "./Cohorts/cohorts.less";
import FormItem from "antd/lib/form/FormItem";


const DashboardFilter = ({ setLoading = () => {}, setCurrentFilter, getData,currentFilter = "{}", filterType, calendarRef = null, setFromDashboard = null, setSearchValue = ()=>{} }) => {

    const [form] = Form.useForm();
    const [checkedPast, setCheckedPast] = useState(false);
    const styBodyModal = {
        paddingTop: '0px'
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [coursesForm, setCoursesForm] = useState();
    const [statesForm, setStatesForm] = useState();
    const [facilitatorsForm, setFacilitatorsForm] = useState();
    const [cofacilitatorsForm, setCoFacilitatorsForm] = useState();
    const [unitsForm, setUnitsForm] = useState();
    const [cohortsForm, setCohortsForm] = useState();
    const [deliveryModelsForm,setDeliveryModelsForm] = useState();
    const [participantsForm,setParticipantsForm] = useState();
    const [producerForm,setProducerForm] = useState();
    const [qualityForm,setQualityForm] = useState();
    const [dataYears,setDataYears] = useState();
    const [ALPForm,setALPForm] = useState();
    const [scheduleForm,setScheduleForm] = useState();
    const [CoFaForm,setCoFaForm] = useState();
    const [isSelectColorContract, setSelectColorContract] = useState(false);
    const [isSelectColorMaterials, setSelectColorMaterials] = useState(false);
    const [stateCoursesComponent,setStateCoursesComponent,] = useState("");
    const [stateFacilitadorComponent,setStateFacilitadorComponent,] = useState("");
    const [stateUnitsComponent,setStateUnitsComponent,] = useState("");
    const [statesComponent,setStatesComponent,] = useState("");
    const [stateCohortsComponent,setStateCohortsComponent,] = useState("");
    const [stateDeliveryModelsComponent,setStateDeliveryModelsComponent,] = useState("");
    const [stateCoFacilitadorComponent,setStateCoFacilitadorComponent,] = useState("");
    const [stateCoFaComponent,setStateCoFaComponent,] = useState("");
    const [stateALPComponent,setStateALPComponent,] = useState("");
    const [stateScheduleComponent,setStateScheduleComponent,] = useState("");
    const [stateProducerComponent,setStateProducerComponent,] = useState("");
    const [stateQualityComponent,setStateQualityComponent,] = useState("");
    const [stateParcipantComponent,setStateParticipantComponent,] = useState("");
    const [meetingTypesForm,setMeetingTypesForm] = useState();
    const [yearsCurrent,setYearsCurrent] = useState(2015);
    const [defaultYear,setDefaultYear] = useState(2015);
    const [stateMeetingTypesComponent,setStateMeetingTypesComponent,] = useState("");
    const [extraClassBtn, setExtraClassBtn] = useState(
        currentFilter !== undefined && currentFilter !== null && currentFilter !== "{}" && currentFilter !== '{"retain_filter":true}' && JSON.parse(localStorage.getItem("checked-past")) === false
            ? "no_force_selected" : "force_selected"
    );

    const [classFilter, setClassFilter] = useState("no_force_selected");

    const [retainFilter, setRetainFilter] = useState();
    const ENTER_KEY = 'Enter';

    const showModal = () => {
        setIsModalVisible(true);
        hideSelect();
        window.scrollTo(0, 0);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const isRetainFilter = (e) => {
        if (e.target.checked) {
            setRetainFilter(true);
        }else{
            setRetainFilter(false);
        }
    };

    const saveFilters = (values) => {
        let request = null;
        let save_filter = false;
        if (values.from_clear) {
            values = {};
            setClassFilter("no_force_selected");
        } else if (!values.from_clear) {
            setClassFilter("force_selected");
            if (values.retain_filter === true) {
                save_filter = true;
            }

            values.years = yearsCurrent;

            if (checkedPast) {
                values.check_past = checkedPast;
            }
        }


        request = API.post("api/user/saveFilters", {
            type_filters: filterType,
            filters: values,
            save_filters: save_filter
        });
        setLoading(true);
        request
            .then( ({ data }) => {
                //filtersearch
                setIsModalVisible(false);
                if(filterType === "cohort"){
                    let from = checkedPast ? "past" : "today";
                    setFromDashboard(from);
                    setCurrentFilter.current = values;
                    setLoading(true);
                    getData(false, true,null)
                    let table_dasboard =document.querySelector(".ant-table-body");
                    if (from === "past") {
                        setExtraClassBtn("force_selected");
                    }
                    if(table_dasboard){
                        table_dasboard.scrollTop=0
                    }

                } else if (filterType === "calendar"){
                    setCurrentFilter(JSON.stringify(values));
                    API.get(`api/calendar_date/`).then(({data})=>{
                        if(data?.start && data?.start !== false){
                            calendarRef.current.getApi().gotoDate(data?.start);
                        }
                    });
                    //calendarRef.current.getApi().refetchEvents();
                }

            })
            .catch((response) => { console.log(response) })

    }


    useEffect(() => {
        getSelectOptions();
        form.setFieldsValue({retain_filter:true});
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        if(validJson(currentFilter)){
            if (( Object.keys(JSON.parse(currentFilter)).length > 2 && currentFilter !== null && currentFilter !== "{}" && currentFilter !== '{"retain_filter":true}') || checkedPast !== false) {
                setExtraClassBtn("force_selected");
            } else {
                setExtraClassBtn("no_force_selected");
            }
        }
        // eslint-disable-next-line
    },[currentFilter, checkedPast]);


    let validJson = (payload) =>{
        try {
           JSON.parse(payload);
           return true;
        } catch (e) {
            return false;
        }
    }

    let setYears = (value) => {
        setYearsCurrent(value)
    }
    const hideSelect = () => {
        setStateCoursesComponent(component_courses);
        setStateFacilitadorComponent(component_facilitador);
        setStateUnitsComponent(component_units);
        setStateCoFacilitadorComponent(component_cofacilitador);
        setStateCohortsComponent(component_cohorts);
        setStatesComponent(component_states);
        setStateCoFaComponent(component_cofac);
        setStateDeliveryModelsComponent(component_delivery_models);
        setStateScheduleComponent(component_schedule);
        setStateALPComponent(component_coach);
        setStateProducerComponent(component_webinar_producer);
        setStateQualityComponent(component_quality);
        setStateParticipantComponent(component_participant);
        setStateMeetingTypesComponent(component_meeting_type);

        let facilitator_local = false;
        JSON.parse(localStorage.getItem('user-data')).roles.map(key => {
            if(key.id === 16 || key.id === 36) {
                facilitator_local = true;
            }

          })

        if(facilitator_local){
            setStateFacilitadorComponent("");
            setStateCoFacilitadorComponent("");
            setStateScheduleComponent("");
            setStateCoFaComponent("");
        }
    }

    const changeColor = () => {
        if(form.getFieldValue('contract') !== '' && form.getFieldValue('contract') !== undefined && form.getFieldValue('contract') !== null){
            setSelectColorContract(true);
        }else{
            setSelectColorContract(false);
        }

        if(form.getFieldValue('materials') !== '' && form.getFieldValue('materials') !== undefined && form.getFieldValue('materials') !== null){
            setSelectColorMaterials(true);
        }else{
            setSelectColorMaterials(false);
        }

    };

      function tagRender(props) {
        const { label, closable, onClose } = props;

        return (
          <Tag color='rgb(255, 242, 204)' closable={closable} onClose={onClose} style={{ marginRight: 3 , color :'#333'}}>
            {label}
          </Tag>
        );
      }
    const component_courses =
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form.Item
            name="courses"
            label="Show cohorts with this course/s"
            style={{ marginBottom: '12px'}}
        >
        <Select showSearch mode="multiple" placeholder="Any Value" optionFilterProp="children" options={coursesForm}
            tagRender={tagRender} filterOption={(input, option) =>option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0   }
            ></Select>
        </Form.Item>
    </Col>;

    const component_delivery_models =
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form.Item name="types"
        label="Show cohorts using this delivery model"
        style={{ marginBottom: '12px'}}>
            <Select showSearch mode="multiple" placeholder="Any value" options={deliveryModelsForm}
                optionFilterProp="children" tagRender={tagRender}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) === 0 }
            ></Select>
        </Form.Item>
    </Col>;

    const component_meeting_type =
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form.Item name="meeting_types"
        label="Show sessions using this meeting type"
        style={{ marginBottom: '12px'}}>
            <Select showSearch mode="multiple" placeholder="Any value" options={meetingTypesForm}
                optionFilterProp="children" tagRender={tagRender}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) === 0 }
            ></Select>
        </Form.Item>
    </Col>;

    const component_facilitador=
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form.Item
            name="facilitators"
            label="Show sessions assigned to this facilitator"
            style={{ marginBottom: '12px'}}
            >
            <Select
            mode="multiple"
            showSearch
            placeholder="Any value"
            options={facilitatorsForm}
            optionFilterProp="children"
            tagRender={tagRender}
            filterOption={(input, option) =>
                option.label
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
            }

            ></Select>
        </Form.Item>
    </Col>;

    const component_units =
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
    <Form.Item name="units"
        label="Show sessions in this unit/s"
        style={{ marginBottom: '12px'}}>
        <Select
               mode="multiple"
               showSearch
               placeholder="Any value"
               options={unitsForm}
               optionFilterProp="children"
               tagRender={tagRender}
               filterOption={(input, option) =>
                   option.label
                       .toLowerCase()
                       .indexOf(input.toLowerCase()) === 0
               }

            ></Select>
        </Form.Item>
        </Col>;

    const component_cohorts =
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
    <Form.Item name="cohorts"
        label="Show sessions for this cohort"
        style={{ marginBottom: '12px'}}>
                        <Select
                                showSearch
                                mode="multiple"
                                placeholder="Any value"
                                options={cohortsForm}
                                optionFilterProp="children"
                                tagRender={tagRender}
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }
                                onChange={() => {
                                    setCheckedPast(true)
                                  }}

                            ></Select>
                        </Form.Item>
    </Col>;

    const component_states =
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
    <Form.Item name="states"
    label="Show cohorts in this state/s"
    style={{ marginBottom: '12px'}}>
    <Select
           showSearch
           mode="multiple"
           placeholder="State"
           options={statesForm}
           optionFilterProp="children"
           tagRender={tagRender}
           filterOption={(input, option) =>
               option.label
                   .toLowerCase()
                   .indexOf(input.toLowerCase()) === 0
           }

        ></Select>
    </Form.Item>
    </Col>;

     const component_cofac =
     <Col xs={24} sm={12} md={12} lg={12} xl={12}>
     <Form.Item name="facilitators_cofac"
        label="Sessions for this person as facilitator, co-facilitator, producer, or quality support"
        style={{ marginBottom: '12px'}}>
        <Select

                mode="multiple"
                placeholder="Any Value"
                options={CoFaForm}
                optionFilterProp="children"
                tagRender={tagRender}
                filterOption={(input, option) =>
                    option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                }


            ></Select>
        </Form.Item>
    </Col>;

    const component_cofacilitador =
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form.Item name="co_facilitators"
            label="Show sessions assigned to this co-facilitator"
            style={{ marginBottom: '12px'}}
        >
            <Select

                showSearch
                mode="multiple"
                placeholder="Any value"
                options={cofacilitatorsForm}
                optionFilterProp="children"
                tagRender={tagRender}
                filterOption={(input, option) =>
                    option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                }

            ></Select>
        </Form.Item>
    </Col>;

    const component_coach =
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form.Item name="coaches_alp"
        label="Show sessions assigned to this Action Learning project Adviser"
        style={{ marginBottom: '12px'}}>
            <Select mode="multiple" placeholder="Any Value" options={ALPForm}
                optionFilterProp="children" tagRender={tagRender} filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) === 0 }>
            </Select>
        </Form.Item>
        </Col>;

    const component_schedule =
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
    <Form.Item name="schedule"
        label="Sessions as facilitator, co-facilitator, producer, quality support, or ALp Adviser"
        style={{ marginBottom: '12px'}}>
            <Select mode="multiple" placeholder="Any Value" options={scheduleForm}
                optionFilterProp="children" tagRender={tagRender}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) === 0 }
                ></Select>
            </Form.Item>
    </Col>;

    const component_participant =
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form.Item name="participants"
        label="Show the sessions for the cohort/s in which this participant is enrolled"
        style={{ marginBottom: '12px'}}>
            <Select showSearch placeholder="Any Value" optionFilterProp="children"
                options={participantsForm} tagRender={tagRender}
                filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            ></Select>
        </Form.Item>
    </Col>;

    const component_webinar_producer=
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form.Item name="webinar_producer"
            label="Show sessions assigned to this Producer"
            style={{ marginBottom: '12px'}}
        >
            <Select mode="multiple" placeholder="Any Value" optionFilterProp="children"
            tagRender={tagRender} options = {producerForm}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0   }
            ></Select>
        </Form.Item>
    </Col>;

    const component_quality=
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Form.Item name="qualities"
            label="Show sessions assigned to this Quality Support"
            style={{ marginBottom: '12px'}}
        >
            <Select mode="multiple" placeholder="Any Value" optionFilterProp="children"
            tagRender={tagRender} options = {qualityForm}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0   }
            ></Select>
        </Form.Item>
    </Col>;

    const getSelectOptions = () => {
        API.get(`/api/filter-cohort?type_filter=${filterType}`)
            .then(({ data }) => {

                let newQualityForm = [...data.qualities, {value: 'na', label: "n/a", key: null, name: " ", address1: null,address2: null,ZIPcode:null}]
                setCoursesForm(data.courses);
                setStatesForm(data.states);
                setFacilitatorsForm(data["facilitators"]);
                setCoFacilitatorsForm(data.co_facilitators);
                setUnitsForm(data.units);
                setCohortsForm(data.cohorts);
                setDeliveryModelsForm(data.delivery_models);
                setParticipantsForm(data.participant);
                setProducerForm(data.producer);
                setQualityForm(newQualityForm);
                setALPForm(data.alp);
                setScheduleForm(data.schedule);
                setCoFaForm(data.facilitators_cofac);
                setDataYears(data.years);
                setMeetingTypesForm(data.meeting_types);

                if(data.filters !== undefined){
                    if(data.filters.length > 0) {
                        setClassFilter("force_selected");
                    }
                    if(data.filters[0]?.filter?.years?.length !== undefined){
                        setDefaultYear(data.filters[0]?.filter?.years)
                    }

                    if(data.filters[0]?.filter?.check_past === true){
                        setCheckedPast(data.filters[0]?.filter?.check_past)
                    }

                    if(Object.keys(data.filters[0]?.filter || []).length > 2){
                        setExtraClassBtn("force_selected")
                    }
                    if(data.filters[0]?.filter?.courses?.length !== undefined){
                        form.setFieldsValue({"courses": data.filters[0]?.filter?.courses})
                    }

                    if(data.filters[0]?.filter?.courses?.length !== undefined){
                        form.setFieldsValue({"courses": data.filters[0]?.filter?.courses})
                    }
                    if(data.filters[0]?.filter?.facilitators !== undefined){
                        form.setFieldsValue({"facilitators": data.filters[0]?.filter?.facilitators})
                    }
                    if(data.filters[0]?.filter?.units!== undefined && data.filters[0]?.filter?.units?.length > 0){
                        form.setFieldsValue({"units": data.filters[0]?.filter?.units})
                    }
                    if(data.filters[0]?.filter?.states!== undefined && data.filters[0]?.filter?.states?.length > 0){
                        form.setFieldsValue({"states": data.filters[0]?.filter?.states})
                    }
                    if(data.filters[0]?.filter?.cohorts !== undefined && data.filters[0]?.filter?.cohorts?.length > 0){
                        form.setFieldsValue({"cohorts": data.filters[0]?.filter?.cohorts})
                    }
                    if(data.filters[0]?.filter?.types !== undefined && data.filters[0]?.filter?.types?.length > 0){
                        form.setFieldsValue({"types": data.filters[0]?.filter?.types})
                    }
                    if(data.filters[0]?.filter?.co_facilitators !== undefined){
                        form.setFieldsValue({"co_facilitators": data.filters[0]?.filter?.co_facilitators})
                    }
                    if(data.filters[0]?.filter?.facilitators_cofac !== undefined){
                        form.setFieldsValue({"facilitators_cofac": data.filters[0]?.filter?.facilitators_cofac})
                    }
                    if(data.filters[0]?.filter?.contract !== undefined){
                        form.setFieldsValue({"contract": data.filters[0]?.filter?.contract})
                    }
                    if(data.filters[0]?.filter?.schedule !== undefined){
                        form.setFieldsValue({"schedule": data.filters[0]?.filter?.schedule})
                    }
                    if(data.filters[0]?.filter?.webinar_producer !== undefined){
                        form.setFieldsValue({"webinar_producer": data.filters[0]?.filter?.webinar_producer})
                    }
                    if(data.filters[0]?.filter?.qualities !== undefined){
                        form.setFieldsValue({"qualities": data.filters[0]?.filter?.qualities})
                    }
                    if(data.filters[0]?.filter?.materials !== undefined){
                        form.setFieldsValue({"materials": data.filters[0]?.filter?.materials})
                    }
                    if(data.filters[0]?.filter?.participants !== undefined){
                        form.setFieldsValue({"participants": data.filters[0]?.filter?.participants})
                    }
                    if(data.filters[0]?.filter?.coaches_alp !== undefined){
                        form.setFieldsValue({"coaches_alp": data.filters[0]?.filter?.coaches_alp})
                    }
                    if(data.filters[0]?.filter?.meeting_types !== undefined){
                        form.setFieldsValue({"meeting_types": data.filters[0]?.filter?.meeting_types})
                    }

                    if(data.filters[0]?.filter?.cohort_name !== undefined){
                        setSearchValue(data.filters[0].filter.cohort_name)
                    }
                }

                if(form.getFieldValue('contract') !== '' && form.getFieldValue('contract') !== undefined && form.getFieldValue('contract') !== null){
                    setSelectColorContract(true);
                }
                if(form.getFieldValue('materials') !== '' && form.getFieldValue('materials') !== undefined && form.getFieldValue('materials') !== null){
                    setSelectColorMaterials(true);
                }

           })
            .catch((error) => {
                console.log(error);
            });
    };

    let handleFilter = (from_clear = false) => {
        form.validateFields()
        .then((values) => {
            values.from_clear = from_clear;
            saveFilters(values, "today");
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    return (
        <>
            <Button
                type={"primary"}
                className={`filter_btn ${extraClassBtn} ${classFilter}`}
                style={(filterType === "calendar") ?
                    { height: "32px", lineHeight: "1.2", top: "2px", marginBottom: "5px" }
                : {}}
                onClick={showModal}
            >
                Filter
            </Button>
            <Modal
                 visible={isModalVisible}
                 title = "Training Session Filters"
                 centered
                 onCancel={handleCancel}
                 width="80%"
                 okButtonProps={{type:"primary"}}
                 bodyStyle={styBodyModal}
                 footer={
                     <Row align='middle'>
                         <Col span={11}>
                            {
                                (filterType === "cohort") ?
                                    <span>include past sessions, dating back to&nbsp;&nbsp;</span>
                                : ""
                            }
                        </Col>
                        <Col  span={3}>
                            {
                                (filterType === "cohort") ?
                                        <Form><FormItem style={{ marginBottom: '0px'}} name="years">
                                            <Select
                                                style={{ width: '100%', textAlign:"center" }}
                                                defaultValue={defaultYear}
                                                options={dataYears}
                                                onSelect={setYears}>
                                            </Select>
                                        </FormItem></Form>
                                : ""
                            }
                        </Col>
                        <Col span={2} style={{textAlign:"center"}}>
                            {
                                (filterType === "cohort") ?
                            <Form>
                                <FormItem style={{ marginBottom: '0px'}} name="check_past">
                                <Checkbox
                                    checked={checkedPast}
                                    onChange={({target}) => {
                                        setCheckedPast(target.checked);
                                       // localStorage.setItem("checked-past", target.checked);
                                    }}
                                />
                                </FormItem>
                                </Form>
                                : ""
                            }
                        </Col>
                        <Col span={8} >
                            <Button onClick={()=>{ handleCancel() }}> Cancel </Button>
                            <Button type="secundary" onClick={async () => {
                                    await form.resetFields();
                                    await setExtraClassBtn("no_force_selected");
                                    await form.setFieldsValue({retain_filter:true});
                                    await setCheckedPast(false);
                                    await localStorage.setItem("checked-past", false);
                                    await setDefaultYear(2015);
                                    if (filterType === "calendar") {
                                        console.log(calendarRef.current)
                                        console.log(calendarRef.current.getApi().today())
                                        console.log(filterType);
                                    }
                                    handleFilter(true)
                                }}> Clear </Button>
                            <Button type="primary"
                                onClick={async () => {
                                        await setExtraClassBtn("force_selected");
                                        await handleFilter()
                                    }}>
                                Done
                            </Button>
                        </Col>


                     </Row>
                 }
            >

            <Form form={form} layout="vertical"
             onKeyUp={async (e) => {
                if (e.key === ENTER_KEY) {
                    await setExtraClassBtn("force_selected");
                    await handleFilter()
                }
            }}
            >
            <Row>
            <Col span={12} offset={8}>
                <Form.Item style={{ marginTop: '-26px' }} valuePropName="checked" name="retain_filter" style={{ marginBottom: '12px'}}>
                    <Checkbox
                        onChange={isRetainFilter}>
                        retain all these filters for your next login
                    </Checkbox>
                </Form.Item>
            </Col>
            </Row>
            <Row gutter={[20, 2]}>
                    {stateCohortsComponent}
                    {stateParcipantComponent}
                    {stateCoursesComponent}
                    {stateFacilitadorComponent}
                    {stateUnitsComponent}
                    {stateCoFacilitadorComponent}
                    {statesComponent}
                    {stateScheduleComponent}
                    {stateDeliveryModelsComponent}
                    {stateCoFaComponent}
                    {stateMeetingTypesComponent}
                    {stateALPComponent}

                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Show cohorts associated with this contract" name="contract" style={{ marginBottom: '12px'}}>
                        <Input className={isSelectColorContract ? "background-input": ""} placeholder="Search for contract" onBlur={() => {changeColor()}}/>
                        </Form.Item>
                    </Col>
                    {stateProducerComponent}
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Show cohorts with this order number or materials status text" name="materials" style={{ marginBottom: '12px'}}>
                            <Input className={isSelectColorMaterials ? 'background-input': ''} placeholder="Search for materials" onBlur={() => {changeColor()}}
                            />
                        </Form.Item>
                    </Col>

                    {stateQualityComponent}

            </Row>
            </Form>
            </Modal>
        </>
        );
}

export default React.memo(DashboardFilter);
