import React from "react";
import { Upload, Modal, Button, message } from 'antd';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from "react";
import API from "../api";
import { createUseStyles } from "react-jss";

const { confirm } = Modal;

const useStyles = createUseStyles({
    uploadListInline: {
        float: 'left',
        width: '60%',
        marginBottom: '8px',
    },
});


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const ImageCertificateCourse = ({ imageUrl, imageName, courseId, getCourses }) => {

    const classes = useStyles();
    const { REACT_APP_BACKEND } = process.env;
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState(false);
    const [fileList, setFileList] = useState(() => {
        if (imageUrl != null) {
            return [
                {
                    uid: '-1',
                    name: imageName,
                    status: 'done',
                    url: imageUrl,
                },
            ];

        } else {
            return [];
        }
    });

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ file, fileList }) => {
        if (file.status === "done") {
            getCourses(null, 1, 10);
        }

        setFileList(fileList);
    }

    const handleRemove = (file) => {

        return new Promise((resolve, reject) => {
            confirm({
                title: "Do you want to remove the certificate template for this course?",
                icon: <ExclamationCircleOutlined />,
                okButtonProps: { type: "primary" },
                okText: "Yes",
                cancelText: "No",
                onOk() {
                    API.post(`api/update_certificate_image`, {
                        courseId: courseId
                    }).then(() => {
                        getCourses(null, 1, 10);
                    }).catch();
                    resolve(true);
                },
            });
        });
    }

    let beforeUpload = (file) => {
        const isPNG = file.type === 'image/png';

        if (!isPNG) {
          message.error(`${file.name} is not a png file`);
        }

        return isPNG || Upload.LIST_IGNORE;
      };


    const uploadButton = (
        <Button type="primary" icon={<UploadOutlined />}>Click to choose file</Button>
    );

    return (
        <>
            <Upload
                name="image_certificate"
                action={`${REACT_APP_BACKEND}/api/update_certificate_image`}
                headers={{
                    Authorization:
                        "Bearer " + localStorage.getItem("jwt-token"),
                }}
                data={{
                    "courseId": courseId
                }}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={handleRemove}
                fileList={fileList}
                className={classes.uploadListInline}
                accept=".png"
                beforeUpload={beforeUpload}

            >
                {fileList.length === 1 ? null : uploadButton}
            </Upload>

            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
                width="50%"
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );

};

export default ImageCertificateCourse;
