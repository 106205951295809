import React, { useEffect, useState, useRef } from "react";
import { Table, Row, Space, Input, Col, Button } from "antd";
import moment from "moment";
import API from "../../../../api";
import { loginWith } from "../../../../functions/loginWith";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    filterSelector: {
        width: '100%',
        paddingBottom: '1em',
    },
    label: {
        paddingTop: '0.1em',
    }
});

const PendingUsers = () => {

    const columns = [
        {
            title: "Existing Account ID",
            dataIndex: "new_id",
            rowKey: "new_id",
            render: (value, record) => {
                if (record?.duplicated_user?.nisl_id) {
                    return (
                        <span>
                            {record?.duplicated_user?.nisl_id}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Existing Account Username",
            dataIndex: "new_username",
            rowKey: "new_username",
            render: (value, record) => {
                if (record?.duplicated_user?.username) {
                    return (
                        <button className={"link-button"}
                            style={{ color: "#0051a0" }}
                            onClick={() => { loginWith(record.duplicated_user.id) }}>
                            {record?.duplicated_user?.username}
                        </button>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "New Account ID",
            dataIndex: "old_cms_id",
            rowKey: "old_cms_id",
            render: (value, record) => {
                if (record?.new_user?.nisl_id) {
                    return (
                        <span>
                            {record?.new_user?.nisl_id}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "New Account Username",
            dataIndex: "old_username",
            rowKey: "old_username",
            render: (value, record) => {
                if (record?.new_user?.username) {
                    return (
                        <span>
                            {record?.new_user?.username}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "State",
            dataIndex: "new_state",
            rowKey: "new_state",
            render: (value, record) => {
                if (record?.duplicated_user?.state_living) {
                    return (
                        <span>
                            {record?.duplicated_user?.state_living?.code}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Name",
            dataIndex: "name",
            rowKey: "name",
            render: (value, record) => {
                if (record?.new_user?.first_name) {
                    return (
                        <span>
                            {record?.new_user?.first_name}  {record?.new_user?.last_name}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "New Account Created",
            dataIndex: "created_at",
            rowKey: "created_at",
            align: "center",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {record?.who_create?.last_name} <br /> {moment(value).format("YY-MM-DD H:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            rowKey: "status",
            align: "center",
            render: (value, record) => {
                if (record.new_user.deleted_at !== null) {
                    return (
                        <span>
                            Deleted <br />
                            {record?.new_user.who_delete?.last_name} <br /> {moment(record.deleted_at).format("YY-MM-DD H:mm")}
                        </span>
                    )
                } else {
                    if (record?.new_user?.status !== 'pending') {
                        return (
                            <span>
                                Changed to {record?.new_user?.status} <br />
                                {record?.who_changed?.last_name} <br /> {moment(record.updated_at).format("YY-MM-DD H:mm")}
                            </span>
                        )
                    } else {
                        return `Pending`;
                    }
                }
            }
        },
    ];

    const classes = useStyles();
    const [valueSearch, setValueSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);


    const [textTotalPending, setTextTotalPending] = useState();

    const [loadingTable, setLoadingTable] = useState(false);

    const [dataPending, setDataPending] = useState([]);

    const getPendingUsers = (search = null, pageCurrent = null, pageSize = null) => {
        let searchText = search != null ? search : valueSearch;

        if (pageCurrent != null) {
            setCurrentPage(pageCurrent);
        }

        API.get(`/api/getPendingUsers?search=${searchText}&page=${pageCurrent}&per_page=${pageSize}`).then(({ data }) => {
            setDataPending(data.list)
            setLoadingTable(false);
            setTextTotalPending(data.list.total.toLocaleString() + ' of ' + data.totalPending.toLocaleString() + ' pending users')
        });
    };

    useEffect(() => {
        setLoadingTable(true)
        getPendingUsers();
    }, []);

    let handleSearchInput = (valueSearch) => {
        setLoadingTable(true);
        setValueSearch(valueSearch);
        getPendingUsers(valueSearch);
    };

    let getDataPaginate = (page, filters = {}, sorter = {}, extra = {}) => {
        getPendingUsers(null, page.current, page.pageSize);
    };

    return (
        <>
            <Row gutter={[0, 10]} justify="end">
                <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                    <Input.Search
                        allowClear
                        placeholder="Search"
                        className={classes.filterSelector}
                        onSearch={handleSearchInput}
                    />
                </Col>
            </Row>

            <Row className={classes.label}>
                {textTotalPending}
            </Row>

            <Table columns={columns}
                onChange={getDataPaginate}
                rowClassName={() => "responsive-row"}
                pagination={{
                    current: currentPage,
                    defaultPageSize: 50,
                    total: dataPending.total,
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}
                loading={loadingTable}
                dataSource={dataPending.data} />
        </>
    );

}

export default PendingUsers;
