import React, { useEffect, useState } from 'react'
import { Modal, Form, Col, Row, Input, Button } from 'antd'
import { Spin } from "antd";
import API from "../api";
import validator from 'validator';
import { EyeOutlined, EyeInvisibleOutlined, CheckOutlined } from "@ant-design/icons";

const ChangePassword = ({ visible, setVisible, fromWhere }) => {


    const { roles_un } = JSON.parse(localStorage.getItem("nisl-data"));
    const [form] = Form.useForm();
    const [textValidator, setTextValidator] = useState();
    const [disabledButtonSave, setDisabledButtonSave] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [isMin12, setIsMin12] = useState(false);
    const [isMin2Lower, setIsMin2Lower] = useState(false);
    const [isMin2Uper, setIsMin2Uper] = useState(false);
    const [isMin2Chars, setIsMin2Chars] = useState(false);
    const [isMin2Number, setIsMin2Number] = useState(false);

    const redColor = "red";
    const whiteColor = "rgba(0, 0, 0, 0.65)";
    const greenColor = "green";

    const [colorChars, setColorChars] = useState(whiteColor);
    const [colorUpper, setColorUpper] = useState(whiteColor);
    const [colorLower, setColorLower] = useState(whiteColor);
    const [colorNumbers, setColorNumbers] = useState(whiteColor);
    const [colorSpecial, setColorSpecial] = useState(whiteColor);

    const [disableNewPass, setDisableNewPass] = useState(true);
    const [disableCurrentPass, setDisableCurrentPass] = useState(false);

    const pinStyle = {
        position: "absolute",
        zIndex: "40",
        height: "60%",
        width: "60%",
        backgroundColor: "#eee",
        paddingTop: "20%"
    };

    const styleTextRule = {
        color: "red"
    }

    const rules = {
        password: [{ required: true, message: "Please input your password." }],
        current_password: [{ required: true, message: "Please input your current password." }],
        repeat_password: [
            { required: true, message: "Please confirm your password." },
            ({ getFieldValue }) => ({
                validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        "The two passwords that you entered do not match."
                    );
                },
            }),
        ],
    };

    const validateSecurity = (value) => {

        if (isAdmin) {
            let cont = 0;
            let regexMinLower = /(?:.*[a-z]){2}/;
            let regexMinUpper = /(?:.*[A-Z]){2}/;
            let regexTwoNumber = /(?:.*\d){2}/;
            let regexSpecial = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{2}/;

            if (value.length >= 12) {
                setColorChars(greenColor);
                setIsMin12(true);
                cont++;
            } else {
                setColorChars(redColor);
                setIsMin12(false);
                cont--;
            }

            if (regexMinLower.test(value)) {
                setColorLower(greenColor);
                setIsMin2Lower(true);
                cont++;
            } else {
                setColorLower(redColor);
                setIsMin2Lower(false);
                cont--;
            }

            if (regexMinUpper.test(value)) {
                setColorUpper(greenColor);
                setIsMin2Uper(true);
                cont++;
            } else {
                setColorUpper(redColor);
                setIsMin2Uper(false);
                cont--;
            }

            if (regexTwoNumber.test(value)) {
                setColorNumbers(greenColor);
                setIsMin2Number(true);
                cont++;
            } else {
                setColorNumbers(redColor);
                setIsMin2Number(false);
                cont--;
            }

            if (regexSpecial.test(value)) {
                setColorSpecial(greenColor);
                setIsMin2Chars(true);
                cont++;
            } else {
                setColorSpecial(redColor);
                setIsMin2Chars(false);
                cont--;
            }

            if (cont === 5) {
                validatePasswordHistory(value);
                setTextValidator("");
            } else {
                setDisabledButtonSave(true);
                setTextValidator("The password does not meet all the requirements shown above.");
            }
        }
    }

    const validatePasswordHistory = (pass) => {
        API.post("api/validatePasswordHistory", { pass: pass }).then(({ data }) => {
            if (data === true) {
                setTextValidator("You cannot reuse this password.");
                setDisabledButtonSave(true);
            } else {
                setTextValidator("");
                setDisabledButtonSave(false)
            }
        })
    }

    const saveChange = (values) => {
        if (isAdmin) {
            values.saveHistoric = true;
        }

        setDisabledButtonSave(true);
        API.put("api/user/change-password", values).then(({ data }) => {
            setVisible(false)
            localStorage.setItem("date-last-update-pass", data.lastDateChangedPassword);

            Modal.info({
                title: "",
                content: "Password updated succesfully",
            })
        })
    }

    const validateCurrentPassword = (value) => {

        if (value.length >= 4) {
            API.post("api/validateCurrentPassword", { currentPass: value }).then(({ data }) => {
                if (data === true) {
                    setDisableNewPass(false);
                    setTextValidator("");
                    setDisableCurrentPass(true)
                } else {
                    setDisableNewPass(true)
                    setTextValidator("Current Password is not correct.");
                }
            })
        }
    }

    let formItemStyle = { marginBottom: "2px" }

    useEffect(() => {
        if (roles_un.includes("administrator") || roles_un.includes("senior_admin")) {
            setIsAdmin(true)
            setDisabledButtonSave(true);
        }
        // eslint-disable-next-line
    }, visible)

    return (
        <Modal
            visible={visible}
            title="CHANGE PASSWORD"
            width="60%"
            bodyStyle={{ height: "400px" }}
            closable={false}
            maskClosable={false}
            maskStyle={{ opacity: "1" }}
            footer={[
                <div>
                    {fromWhere === "profileMenu" ? (
                        <Button style={{ marginRight: "10px" }} onClick={() => {
                            setVisible(false)
                        }}>
                            Cancel
                        </Button>
                    ) : <></>
                    }
                    <Button type="primary" disabled={disabledButtonSave} onClick={() => {
                        form.validateFields().then(values => {
                            saveChange(values);
                        })
                    }}>
                        Save
                    </Button>
                </div>
            ]}
        >
            <Form
                autoComplete="off"
                name="change_pass"
                form={form}
                colon={false}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 10 }}
            >
                {isAdmin ? (
                    <Row justify="center">
                        <Col xs={24} md={24}>
                            <>
                                Admin passwords expire after 45 days. These password rules apply only to Admin accounts. The password must have: <br />

                                <ul>
                                    <li style={{ color: colorChars, fontWeight: isMin12 ? "bold" : "normal" }}>a minimum of 12 characters {isMin12 ? <CheckOutlined /> : <></>}</li>
                                    <li style={{ color: colorUpper, fontWeight: isMin2Uper ? "bold" : "normal" }}>at least two upper case characters {isMin2Uper ? <CheckOutlined /> : <></>}</li>
                                    <li style={{ color: colorLower, fontWeight: isMin2Lower ? "bold" : "normal"}}>at least two lower case characters {isMin2Lower ? <CheckOutlined /> : <></>}</li>
                                    <li style={{ color: colorNumbers, fontWeight: isMin2Number ? "bold" : "normal" }}>at least two numbers {isMin2Number ? <CheckOutlined /> : <></>}</li>
                                    <li style={{ color: colorSpecial, fontWeight: isMin2Chars ? "bold" : "normal" }}>at least two special characters {isMin2Chars ? <CheckOutlined /> : <></>}</li>
                                </ul>

                                Do not re-use a prior password.
                                <br /><br />
                            </>
                        </Col>
                    </Row>
                ) : <></>
                }

                <Row justify="center">

                    <Col lg={24} xs={24}  >
                        <Form.Item name={"current_password"} label="Current Password" style={formItemStyle}
                            rules={rules.current_password}
                        >
                            <Input.Password disabled={disableCurrentPass} placeholder="Current Password" onKeyUp={(e) => validateCurrentPassword(e.target.value)} iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)} />
                        </Form.Item>
                        <Form.Item name={"password"} label="New Password" style={formItemStyle}
                            rules={rules.password}
                        >
                            <Input.Password disabled={disableNewPass} placeholder="New Password" onKeyUp={(e) => validateSecurity(e.target.value)} iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)} />
                        </Form.Item>

                        <Form.Item name={"repeat_password"} label="Confirm New Password" style={{ ...formItemStyle }}
                            dependencies={["password"]}
                            rules={rules.repeat_password}
                        >
                            <Input.Password disabled={disableNewPass} placeholder="Confirm New Password" iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)} />
                        </Form.Item>
                        <br />
                    </Col>
                </Row>

                <Row>
                    <div style={styleTextRule}>{textValidator}</div>
                </Row>
            </Form>
        </Modal>
    )
}

export default ChangePassword


