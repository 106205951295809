import React, { useEffect, useState, useRef } from "react";
import CertificateList from "./CertificateList";
import API from '../../../api';
import { message, Space, Row, Input, Col, Button, Modal } from "antd";
import VoidCertificate from './VoidCertificate';
import moment from "moment";
import { createUseStyles } from "react-jss";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FilterUser from "../UserModules/Users/FilterUsers";

const useStyles = createUseStyles({
    filterSelector: {
        width: '30em',
    },
});

const CertificatePage = () => {

    const [certificates, setCertificates] = useState([]);
    const [modalvisible, setModalvisible] = useState(false);
    const [valuesData, setData] = useState({});
    const [LoadingTable, setLoadingTable] = useState(false);
    const [sorterColumn, setSorterColumn] = useState("updated_at");
    const [sorterOrder, setSorterOrder] = useState("desc");
    const inputValueSearch = useRef()
    const { roles: user_roles } = JSON.parse(localStorage.getItem("user-data"))
    const [canSeeVoidButton, setCanSeeVoidButton] = useState();
    const [textSearch, setTextSearch] = useState(null);
    const [extraClassBtn, setExtraClassBtn] = useState("no_force_selected");
    const [visibleFilterCertificates, setVisibleFilterCertificates] = useState();
    const [textTotalCertificates, setTextTotalCertificates] = useState();
    const [filterSearchValues, setFilterSearchValues] = useState((localStorage.getItem('filter_users') ? JSON.parse(localStorage.getItem('filter_users')) : false));
    const [textFilter, setTextFilter] = useState(() => {
        if (JSON.parse(localStorage.getItem('filter_users')) != null && JSON.parse(localStorage.getItem("filter_save")) !== false) {
            return '(Filters have been set)';

        } else {
            return '';
        }
    });

    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState(1);

    const getCertificates = (search = null, filter = null, sorterColumn = null, sorterOrder = null, pageCurrent = null, pageSize = null) => {

        setLoadingTable(true);

        let filtersApplied = filter != null ? filter : filterSearchValues;
        let column = sorterColumn != null ? sorterColumn : "updated_at";

        if (pageCurrent != null) {
            setCurrentPage(pageCurrent);
        }

        API.get(`api/participantCertificate?search=${search}&column=${column}&order_by=${sorterOrder}&page=${pageCurrent}&per_page=${pageSize}&filter=${JSON.stringify(filtersApplied)}`)
            .then(({ data }) => {
                setCertificates(data.certificates)
                setLoadingTable(false);
                setTextTotalCertificates(data.certificates.total.toLocaleString() + ' of ' + data.totalCertificates.toLocaleString() + ' certificates')
                setBtnState(data.certificates.total, data.totalCertificates)
            })
    };

    let setValuesFilter = (valuesFilter) => {
        setFilterSearchValues(JSON.parse(localStorage.getItem('filter_users')));
    }

    const handleSearch = (value) => {
        getCertificates(value);
        setTextSearch(value);
    };

    const handleCancelVoid = () => {
        setModalvisible(false);
    }

    const handleOnVoidCertificate = (values) => {
        const dataToVoid = {
            idToVoid: values.id,
            first_name: values.participant.first_name,
            last_name: values.participant.last_name,
            cohort: values.cohort.name,
            cohortId: values.cohort.id,
            userId: values.participant.id
        }
        setData(dataToVoid);
        setModalvisible(true);
    }

    const handleOnSaveVoidCertificate = (form, values, valuesRegister) => {

        const reasonVoid = values.void_reason;
        const userId = valuesRegister.userId;
        const cohortId = valuesRegister.cohortId;

        message.loading({
            content: "Saving Reason...",
            key: "saving_reason",
            duration: 0,
        });

        API.post("/api/save_void_certificate",
            {
                cohortId,
                userId,
                reasonVoid,
            })
            .then(({ data }) => {
                setModalvisible(false);
                form.resetFields();
                getCertificates(null, null, null, null, 1, null);
                message.destroy("saving_reason");
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handlePrintCertificate = (values) => {
        const nameUser = `${values.participant.first_name} ${values.participant.last_name}`;
        const cohortName = `${values.cohort.name}`;

        API.get(`api/reissue_certificate/${values.id}`,
            {
                responseType: "blob",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
            }).then(response => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `Certificates for ${nameUser} Cohort ${cohortName} - ${moment(new Date()).format('M-D-YY h_mm a')}.pdf`);
                document.body.appendChild(link);
                link.click();
            })
    }

    let getDataPaginate = (page, filters = {}, sorter = {}, extra = {}) => {

        let change_order = false;

        if (extra.action === "sort" && !sorter.column !== undefined && sorter.order !== undefined) {
            change_order = true;
            setSorterColumn(sorter.field);
            setSorterOrder(sorter.order);
        }

        let column = change_order ? sorter.field : sorterColumn;
        let order = change_order ? sorter.order : sorterOrder;

        getCertificates(null, null, column, order, page.current, page.pageSize);
    };

    const handleExportXls = () => {
        setLoadingTable(true);
        let filtersApplied = filterSearchValues;
        API.get(`api/export_certificate?search=${textSearch}&column=${sorterColumn}&order_by=${sorterOrder}&filter=${JSON.stringify(filtersApplied)}`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            setLoadingTable(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const formatDate = moment(new Date()).format('YYYY-MM-DD hh_mm a');
            link.setAttribute("download", `User Certificate Details ${formatDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    };

    const handleRestoreCertificate = (userId, cohortId) => {
        Modal.confirm({
            title: "Do you want to restore this certificate?",
            icon: <ExclamationCircleOutlined />,
            okButtonProps: { type: "primary" },
            okText: "Yes",
            cancelText: "No",
            cancelButtonProps: { danger: true },
            onOk() {
                API.post("/api/active_restore_certificate",
                    {
                        userId,
                        cohortId
                    })
                    .then(({ data }) => {
                        getCertificates(null, null, null, null, 1, null);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const handleFilter = (values, form) => {
        setLoadingTable(true)

        localStorage.setItem("filter_users", JSON.stringify(values));
        localStorage.setItem('filter_save', true)
        let empty_form = false;

        form.getFieldsValue("filter", input => {
            if (input.touched === true && form.getFieldValue(input.name[0]) !== '' && form.getFieldValue(input.name[0]) !== null) {
                empty_form = true;
            }
        });

        if (empty_form) {
            localStorage.setItem("filter_users", JSON.stringify(values));
            localStorage.setItem('filter_save', true)
            setTextFilter("(Filters have been set)");
            setExtraClassBtn("force_selected")
        } else {
            localStorage.removeItem('filter_users');
            localStorage.setItem('filter_save', false)
            setTextFilter("");
            setExtraClassBtn("no_force_selected")
        }
        setVisibleFilterCertificates(false);

        getCertificates(null, values);
    };

    const onCancelFilter = (values, form, emptyval) => {
        setVisibleFilterCertificates(false);
    };

    let setBtnState = (a, b) => {
        if (a !== b) {
            setExtraClassBtn("force_selected")
        } else {
            setExtraClassBtn("no_force_selected")
        }
    }

    useEffect(() => {

        if (user_roles?.find(v => v.slug === "certificates")) {
            setCanSeeVoidButton(true);
        } else {
            setCanSeeVoidButton(false);
        }

        getCertificates();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Row>
                <Col flex="300px">
                    <Space direction="horizontal">
                        <Button
                            type="primary"
                            onClick={() => {
                                handleExportXls();
                            }}
                        >
                            Export
                        </Button>
                    </Space>
                </Col>
                <Col flex="auto">
                    <Row justify="end">
                        <Space direction="horizontal" align="end">

                            <Button
                                type="primary"
                                className={`filter_btn ${extraClassBtn}`}
                                onClick={() => {
                                    setVisibleFilterCertificates(true)
                                    let filters = JSON.parse(localStorage.getItem('filter_users'));
                                    if (filters && Object.keys(filters).length < 1) {
                                        setTextFilter("");
                                    }
                                    setValuesFilter();
                                }}
                            >
                                Filter
                            </Button>

                            <Input.Search
                                ref={inputValueSearch}
                                placeholder="Search"
                                onSearch={handleSearch}
                                className={classes.filterSelector}
                                allowClear
                            />
                        </Space>
                    </Row>
                </Col>
            </Row>

            <Row className={classes.label}>
                {textTotalCertificates} {textFilter}
            </Row>

            <FilterUser
                visible={visibleFilterCertificates}
                onCancel={onCancelFilter}
                values={[]}
                exportData={false}
                filterSearchData={handleFilter}
                valuesFilter={[]}
                setFilterSearchValues={setFilterSearchValues}
                filterSearchValues={filterSearchValues}
                setTextFilter={setTextFilter}
            />

            <CertificateList
                certificates={certificates}
                voidCertificate={handleOnVoidCertificate}
                printCertificate={handlePrintCertificate}
                LoadingTable={LoadingTable}
                getDataPaginate={getDataPaginate}
                canSeeVoidButton={canSeeVoidButton}
                restoreCertificate={handleRestoreCertificate}
                currentPage={currentPage}
            />

            <VoidCertificate
                modalvisible={modalvisible}
                onCancel={handleCancelVoid}
                onSaveVoid={handleOnSaveVoidCertificate}
                valuesData={valuesData}
            />
        </>
    );
};

export default CertificatePage;
