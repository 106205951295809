import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Select, Spin, Checkbox } from "antd";
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import API from "../../../api";

const { confirm } = Modal;

const Assignments = ({ form, currentCohort, edit, ready, handleFormChange, unitsFacilitators, unitsGeneral, showQualityAssurance, showWebinarSection, showOrientation, diffOnDays, setUnitsFacilitators }) => {
    const [lastFetchId, setLastFetchId] = useState(0);
    // Form Lists
    const [listFacilitators, setListFacilitators] = useState([]);
    const [listCoFacilitators, setListCoFacilitators] = useState([]);
    const [listWebinars, setListWebinars] = useState([]);
    const [listAlpCoachs, setListAlpCoachs] = useState([]);
    const [listQualities, setListQualities] = useState([]);
    const [listParticipants, setListParticipants] = useState([]);
    const [allUnitsFacilitators, setAllUnitsFacilitators] = useState([]);
    const [allUnitsWebinars, setAllUnitsWebinars] = useState([]);
    const [allUnitsCoFacilitators, setAllUnitsCoFacilitators] = useState([]);
    const [showDividedUnits, setShowDividedUnits] = useState(false);

    const [facilitators, setFacilitators] = useState({
        data: [],
        valueFacilitators: [],
        fetchingFacilitators: false,
    });
    const [participants, setParticipants] = useState({
        data: [],
        valueParticipants: [],
        fetchingParticipants: false,
    });
    const [coFacilitators, setCoFacilitators] = useState({
        data: [],
        valueCoFacilitators: [],
        fetchingCoFacilitators: false,
    });
    const [webinars, setWebinars] = useState({
        data: [],
        valueWebinars: [],
        fetchingWebinars: false,
    });
    const [alpCoachs, setAlpCoachs] = useState({
        data: [],
        valueAlpCoachs: [],
        fetchingAlpCoachs: false,
    });
    const [qualities, setQualities] = useState({
        data: [],
        valueQualities: [],
        fetchingQualities: false,
    });

    // Validation States
    const { fetchingParticipants, valueParticipants } = participants;
    const { fetchingFacilitators, valueFacilitators } = facilitators;
    const { fetchingCoFacilitators, valueCoFacilitators } = coFacilitators;
    const { fetchingWebinars, valueWebinars } = webinars;
    const { fetchingAlpCoachs, valueAlpCoachs } = alpCoachs;
    const { fetchingQualities, valueQualities } = qualities;

    const [edpCourses, setEdpCourses] = useState([]);
    const [nislCourses, setNislCourses] = useState([]);

    const [allCourses, setAllCourses] = useState([]);


    const addList = (setList, list, schedule = false) => {
        setList([
            ...list,
            {
                name: list[list.length - 1]
                    ? Number(list[list.length - 1].name) + 1
                    : 0,
                key: list[list.length - 1]
                    ? Number(list[list.length - 1].key) + 1
                    : 0,
                isListField: true,
                fieldKey: list[list.length - 1]
                    ? Number(list[list.length - 1].fieldKey) + 1
                    : 0,
            },
        ]);
    };

    useEffect(() => {
        if (ready) {
            //Get courses types
            API.get(`api/get_edp_nisl_courses`).then(({ data }) => {
                setEdpCourses(data.edpCourses);
                setNislCourses(data.nislCourses);
            });
        }
        let values = form.getFieldsValue();
        handleFormChange({ cohort_schedule: {} }, values);
        // eslint-disable-next-line
    }, [ready, form]);

    useEffect(() => {
        try {
            let countUnitsWebinar = 0;
            let countUnitsFacilitator = 0;
            if (currentCohort.cohort_schedule) {
                let units = [...currentCohort.cohort_schedule, ...currentCohort.orientation]
                units.forEach((v) => {
                    if (v?.event_type === "2_day") {
                        if (
                            v.start_date !== null &&
                            v.start_date !== undefined &&
                            v.end_date !== null &&
                            v.end_date !== undefined
                        ) {
                            countUnitsWebinar += diffOnDays(
                                v.start_date,
                                v.end_date
                            );
                            countUnitsFacilitator++;
                        } else {
                            countUnitsWebinar++;
                            countUnitsFacilitator++;
                        }
                    }
                    if (v?.event_type === "ext") {
                        if (
                            v.cohort_schedule_sub &&
                            v.cohort_schedule_sub?.length > 0
                        ) {
                            countUnitsWebinar += v.cohort_schedule_sub.length;
                            countUnitsFacilitator += v.cohort_schedule_sub.length;
                        } else {
                            countUnitsWebinar++;
                            countUnitsFacilitator++;
                        }
                    }
                    if (v?.event_type === "split") {
                        countUnitsWebinar++;
                        countUnitsFacilitator++;
                    }
                });
            }


            let webinars_info = formatWebinars(currentCohort, countUnitsWebinar);
            let cofacilitators = formatCofacilitators(currentCohort, countUnitsFacilitator);
            let facilitators = formatFacilitators(currentCohort, countUnitsFacilitator, unitsGeneral);
            form.setFieldsValue({
                ...currentCohort,

                facilitators: facilitators,
                cofacilitators: cofacilitators,
                webinars: webinars_info,

                alp_coachs: currentCohort.alp_coachs?.map((v) => ({
                    user: v.id,
                })),
                qualities: currentCohort.qualities?.map((v) => ({
                    user: v.id,
                })),
                participants: currentCohort.participants?.map((v) => ({
                    user: v.id,
                })),
            });

            if (currentCohort?.divide_units !== undefined && currentCohort?.divide_units.includes("divide_units")) {
                setShowDividedUnits(true);
            }

        } catch (error) {
            console.log("failed load cohort : ", error);
        }

        // eslint-disable-next-line
    }, [currentCohort, form]);

    let formatWebinars = (currentCohort, countUnitsWebinar) => {
        let all_webinars_units = [];
        let main_webinars = currentCohort?.webinars?.map(v => {
            if (v.id !== 13450) {
                all_webinars_units = [...all_webinars_units, ...currentCohort.listsUnits["webinars"][v.id]]

                return {
                    user: v.id,
                    unit:
                        currentCohort.listsUnits["webinars"][v.id]?.length ===
                            countUnitsWebinar
                            ? ["all"]
                            : currentCohort.listsUnits["webinars"][v.id],
                }
            }
        }).filter(user_webinar => user_webinar !== undefined)

        setAllUnitsWebinars(all_webinars_units)

        if (main_webinars !== undefined) {
            main_webinars.push({
                user: 13450,
                unit: []
            })
        }
        return main_webinars;
    }

    let formatCofacilitators = (currentCohort, countUnitsCoFacilitator) => {

        let all_units_cofacilitators = [];
        let main_cofacilitators = currentCohort?.cofacilitators?.map(v => {
            if (v.id !== 13450) {
                all_units_cofacilitators = [...all_units_cofacilitators, ...currentCohort.listsUnits["cofacilitators"][v.id]]

                return {
                    user: v.id,
                    unit:
                        currentCohort.listsUnits["cofacilitators"][v.id]?.length === countUnitsCoFacilitator
                            ? ["all"]
                            : currentCohort.listsUnits["cofacilitators"][v.id],
                }
            }
        }).filter(user_cofac => user_cofac !== undefined)

        setAllUnitsCoFacilitators(all_units_cofacilitators);

        if (main_cofacilitators !== undefined) {
            main_cofacilitators.push({
                user: 13450,
                unit: [],
            })
        }
        return main_cofacilitators;
    }

    let formatFacilitators = (currentCohort, countUnitsFacilitator, unitsGeneral) => {

        let all_units_facilitators = [];
        let main_facilitators = currentCohort?.facilitators?.map(v => {
            if (v.id !== 13450) {

                all_units_facilitators = [...all_units_facilitators, ...currentCohort.listsUnits["facilitators"][v.id]]

                return {
                    user: v.id,
                    unit:
                        currentCohort?.listsUnits?.facilitators[v.id]?.length === countUnitsFacilitator
                            ? ["all"]
                            : currentCohort.listsUnits["facilitators"][v.id],
                }
            }
        }).filter(user_fac => user_fac !== undefined)

        setAllUnitsFacilitators(all_units_facilitators);


        if (main_facilitators !== undefined) {
            main_facilitators.push({
                user: 13450,
                unit: [],
            })
        }
        return main_facilitators;
    }

    const removeList = (list, field, setList) => {
        let newList = list;
        let deleteIndex = newList.findIndex((v) => v.name === field.name);
        newList = newList.filter((v) => v.name !== field.name);
        for (let i = deleteIndex; i < newList.length; i++) {
            newList[i].name = newList[i].name - 1;
        }
        setList(newList);
    };

    const removeWebinarUnassigened = (value) => {
        setAllUnitsWebinars(prevData => {
            return [...prevData, value];
        });
    }

    const removeCofacUnassigened = (value) => {
        setAllUnitsCoFacilitators(prevData => {
            return [...prevData, value];
        });
    }

    const removeFacilitatorsUnassiged = (value) => {
        setAllUnitsFacilitators(prevData => {
            return [...prevData, value];
        });
    }

    let searchUser = (value, setSelect, roles, fetching, list) => {
        setLastFetchId(lastFetchId + 1);
        setSelect({ ...list, ["fetching" + fetching]: true });
        let exclude_users = list?.data?.flatMap(user => user.value)
        API.post("api/user/getUsers/cohort-builder", {
            users: value,
            roles,
            exclude_users
        })
            .then(({ data: data_from_back }) => {
                if (data_from_back.users.length > 0) {
                    let result_users = data_from_back.users.filter((option) => option.status === "active").map((user) => ({
                        text: `${user.name} (${user.username})`,
                        value: user.key,
                        isEdpFacilitator: user.roles.some(item => item.slug === "facilitator_local") ? true : false,
                        isNislFacilitator: user.roles.some(item => item.slug === "facilitator_local_inc") ? true : false,
                        isNationalFacilitator: user.roles.some(item => item.slug === "facilitator_national") ? true : false
                    }));
                    setSelect(prevData => {
                        const updatedValues = { data: [...result_users, ...prevData["data"]], ['fetching' + fetching]: false }
                        return { ...prevData, ...updatedValues };
                    });
                } else {
                    setSelect(prevData => {
                        const updatedValues = { data: [...prevData["data"]], ['fetching' + fetching]: false }
                        return { ...prevData, ...updatedValues };
                    });
                }
            })
            .catch(() => { });
    };

    searchUser = debounce(searchUser, 1300);


    let changeFacilitatorUnit = (field, index, type) => {

        let values = form.getFieldsValue();

        //get the cohort_id
        let id_cohort = values.id;
        let unit_user = "";
        switch (type) {
            case "facilitators":
                unit_user = values.facilitators;
                break;
            case "cofacilitators":
                unit_user = values.cofacilitators;
                break;
            case "webinars":
                unit_user = values.webinars;
                break;
            default:
                break;
        }

        //get the facilitator id
        let id_fac = unit_user[index].user;

        let units = unit_user[index].unit;
        let arr_dates = [];
        let unit_dates = [];
        let unit_dates_ext = [{}];
        let unit_dates_all = [{}];
        let unit_dates_orientation = [{}];
        //get the unit or units id
        if (units !== undefined) {
            units.forEach((unit_loop) => {
                let unit = unit_loop?.split("-")[0];
                let info_unit = values.cohort_schedule.find(
                    (v) => v.unit === unit
                );

                let info_units_ext = values.cohort_schedule.find(
                    (v) => v.event_type === "ext"
                );

                let info_unit_orientation = values.orientation.find(
                    (v) => v.unit === "Orientation"
                );

                //units all
                if (unit === "all") {
                    let info_units_all = values.cohort_schedule;
                    info_units_all.forEach(unit_all => {
                        if (unit_all.event_type === "ext") {

                            unit_all.cohort_schedule_sub.forEach(unit_all_ext => {
                                let start_date_sub_unit = unit_all_ext.start_date_sub_unit;

                                if (start_date_sub_unit !== undefined && start_date_sub_unit !== null) {
                                    arr_dates.push(start_date_sub_unit.format("Y-M-D"));

                                    unit_dates_ext = [{}];
                                    unit_dates_ext.push({ unit: unit, date: start_date_sub_unit.format("Y-MM-DD") });
                                    unit_dates_all.push({ unit: unit, date: start_date_sub_unit.format("Y-MM-DD") });
                                }
                            })
                        } else {
                            let start_date = unit_all.start_date;
                            let end_date = unit_all.end_date;

                            if (start_date !== undefined && start_date !== null) {
                                arr_dates.push(start_date.format("Y-MM-DD"));
                                unit_dates_all.push({ unit: unit_all.unit, date: start_date.format("Y-MM-DD") });
                            }
                            if (end_date !== undefined && end_date !== null) {
                                arr_dates.push(end_date.format("Y-M-D"));
                                unit_dates_all.push({ unit: unit_all.unit, date: end_date.format("Y-MM-DD") });

                            }
                        }
                    })

                    //unit orientation
                    if (info_unit_orientation !== undefined) {
                        let start_date_orientation = info_unit_orientation.start_date;

                        if (start_date_orientation !== undefined && start_date_orientation !== null) {
                            arr_dates.push(start_date_orientation.format("Y-M-D"));
                            unit_dates_all.push({ unit: unit, date: start_date_orientation.format("Y-MM-DD") });
                        }
                    }
                } else {
                    if (unit !== "orientation") {
                        //units
                        if (info_unit !== undefined) {
                            //get the start date of the selected unit
                            let start_date = info_unit.start_date;
                            let end_date = info_unit.end_date;

                            if (start_date !== undefined && start_date !== null) {
                                arr_dates.push(start_date.format("Y-MM-DD"));

                                if (unit_dates[unit]) {
                                    unit_dates[unit].push(start_date.format("Y-MM-DD"));
                                } else {
                                    unit_dates[unit] = [];
                                    unit_dates[unit].push(start_date.format("Y-MM-DD"));
                                }
                            }
                            if (end_date !== undefined && end_date !== null) {
                                arr_dates.push(end_date.format("Y-M-D"));

                                if (unit_dates[unit]) {
                                    unit_dates[unit].push(end_date.format("Y-MM-DD"));
                                } else {
                                    unit_dates[unit] = [];
                                    unit_dates[unit].push(end_date.format("Y-MM-DD"));
                                }
                            }
                        }

                        //units ext
                        if (info_units_ext !== undefined) {
                            info_units_ext.cohort_schedule_sub.forEach(unit_ext => {

                                if (unit_ext.event_ext === unit) {

                                    let start_date_sub_unit = unit_ext.start_date_sub_unit;

                                    if (start_date_sub_unit !== undefined && start_date_sub_unit !== null) {
                                        arr_dates.push(start_date_sub_unit.format("Y-MM-D"));

                                        unit_dates_ext = [{}];
                                        unit_dates_ext.push({ unit: unit, date: start_date_sub_unit.format("Y-MM-DD") });
                                    }

                                }
                            });
                        }
                    } else {

                        //unit orientation
                        if (info_unit_orientation !== undefined) {
                            let start_date_orientation = info_unit_orientation.start_date;

                            if (start_date_orientation !== undefined && start_date_orientation !== null) {
                                arr_dates.push(start_date_orientation.format("Y-MM-D"));

                                unit_dates_orientation = [{}];
                                unit_dates_orientation.push({ unit: unit, date: start_date_orientation.format("Y-MM-DD") });
                            }
                        }
                    }
                }
            });

            //send the request to validate the schedule of the facilitator
            API.post("/api/event/validate-schedule-facilitator", {
                id_fac: id_fac,
                dates_events: arr_dates,
                id_cohort: id_cohort,
                unit_work: null,
                delivery: form.getFieldValue("delivery_model"),
                name: form.getFieldValue("name")
            }).then(({ data }) => {

                if (data.programed === true) {
                    if (data.onlearn_validate) {
                        confirm({
                            title: data.message,
                            icon: <ExclamationCircleOutlined />,
                            okButtonProps: { type: "primary" },
                            okText: "Yes",
                            cancelText: "No",
                            onCancel() {
                                removeSchedule(units, unit_dates_all, unit_dates, unit_user, unit_dates_orientation, unit_dates_ext, data, index, values);
                            },
                        });
                    } else {
                        Modal.warning({
                            title: data.message,
                            icon: <ExclamationCircleOutlined />,
                            okButtonProps: { type: "primary" },
                            okText: "OK",
                            onOk() {
                                removeSchedule(units, unit_dates_all, unit_dates, unit_user, unit_dates_orientation, unit_dates_ext, data, index, values);
                            },
                        });
                    }
                }
            })
                .catch(() => { });
        }
    };

    let removeSchedule = (units, unit_dates_all, unit_dates, unit_user, unit_dates_orientation, unit_dates_ext, data, index, values) => {
        if (units === "all" || units.includes("all")) {
            unit_dates_all.forEach((dates, indexUnit) => {
                if (dates.date !== undefined && dates.date.includes(data.date_coincidence)) {
                    //remove unit of the options
                    unit_user[index].unit = unit_user[index].unit.filter((unit) => String(unit) !== "all");
                }
                form.setFieldsValue(values);
            });

        } else {
            unit_dates.forEach((dates, indexUnit) => {
                if (dates.includes(data.date_coincidence)) {
                    //remove unit of the options
                    unit_user[index].unit = unit_user[index].unit.filter(
                        (unit) =>
                            String(unit) !==
                            String(indexUnit)
                    );
                }
                form.setFieldsValue(values);
            });

            unit_dates_ext.forEach((dates, indexUnit) => {
                if (dates.date !== undefined && dates.date.includes(data.date_coincidence)) {
                    //remove unit ext  of the options
                    unit_user[index].unit = unit_user[index].unit.filter(
                        (unit) =>
                            String(unit) !==
                            String(dates.unit)
                    );
                    form.setFieldsValue(values);
                }
            });


            unit_dates_orientation.forEach((dates, indexUnit) => {
                if (dates.date !== undefined && dates.date.includes(data.date_coincidence)) {
                    //remove unit orientation  of the options
                    unit_user[index].unit = unit_user[index].unit.filter(
                        (unit) =>
                            String(unit) !==
                            String(dates.unit)
                    );
                    form.setFieldsValue(values);
                }
            });
        }
    }

    const handleDivideUnits = (e) => {

        let valueSelected = e.target.checked;
        let newAllUnitsFacilitators = allUnitsFacilitators;
        setShowDividedUnits(valueSelected);

        let values = form.getFieldsValue();
        let facilitators = values.facilitators;

        const regex = /^[0-9]*$/;

        //get the unit or units id
        if (facilitators !== undefined) {
            facilitators.map((facilitator, index) => {
                facilitator.unit.map((unit_loop) => {

                    if (regex.test(unit_loop) === true) {
                        let counterDays = 2;

                        let info_unit = values.cohort_schedule.find(
                            (v) => v.unit === unit_loop
                        );

                        if (info_unit.event_type === "2_day") {
                            counterDays = diffOnDays(
                                info_unit.start_date,
                                info_unit.end_date
                            );

                            if (valueSelected === true) {
                                //Remove the entired unit
                                values.facilitators[index].unit = values.facilitators[index].unit.filter((item) => item !== unit_loop)
                                unitsFacilitators = unitsFacilitators.filter((item) => item.unit !== unit_loop)

                                if (facilitator.user != 13450) {
                                    newAllUnitsFacilitators = newAllUnitsFacilitators.filter((item) => item.unit !== unit_loop);
                                }

                                for (let i = 1; i <= counterDays + 1; i++) {
                                    unitsFacilitators.push({ "unit": `${unit_loop}`, "ext": `${unit_loop}-${i}`, "type": "2_day", name: `Unit ${unit_loop} - Day ${i}` })
                                    values.facilitators[index].unit.push(`${unit_loop}-${i}`)
                                    if (facilitator.user != 13450) {
                                        newAllUnitsFacilitators.push(`${unit_loop}-${i}`)
                                    }
                                }
                            }
                        }
                    }

                    if (unit_loop.includes("-") && valueSelected !== true) {

                        let entiredUnit = unit_loop.split("-")[0];
                        let counterDay = unit_loop.split("-")[1];

                        values.facilitators[index].unit = values.facilitators[index].unit.filter((item) => item !== `${unit_loop}`)

                        if (counterDay === '1') {
                            unitsFacilitators = unitsFacilitators.filter((item) => item.unit !== `${entiredUnit}`)
                            if (facilitator.user != 13450) {
                                newAllUnitsFacilitators = newAllUnitsFacilitators.filter((item) => item.unit !== `${entiredUnit}`)
                                newAllUnitsFacilitators.push(entiredUnit);
                            }
                            unitsFacilitators.push({ "unit": `${entiredUnit}`, "ext": `${entiredUnit}`, "type": "2_day", name: `Unit ${entiredUnit}` })
                            values.facilitators[index].unit.push(`${entiredUnit}`)
                        }
                    }
                })
            });
        }

        form.setFieldsValue(values);
        setUnitsFacilitators(unitsFacilitators);
        setAllUnitsFacilitators(newAllUnitsFacilitators);
    }

    useEffect(() => {
        const setLists = (listName) => {
            let list = [];
            let list_value = form.getFieldValue(listName);

            if (list_value) {
                for (let i = 0; i < list_value.length; i++) {

                    list.push({
                        name: i,
                        key: i,
                        isListField: true,
                        fieldKey: i,
                    });
                }
            }

            return list;
        };
        setListFacilitators(setLists("facilitators"));
        setListCoFacilitators(setLists("cofacilitators"));
        setListWebinars(setLists("webinars"));
        setListAlpCoachs(setLists("alp_coachs"));
        setListQualities(setLists("qualities"));
        setListParticipants(setLists("participants"));
        // eslint-disable-next-line
    }, [currentCohort, edit]);

    useEffect(() => {
        const getUsers = (list, setUserList, fetching, listState) => {
            setLastFetchId(lastFetchId + 1);
            setUserList({ ...listState, [fetching]: true });
            if (currentCohort) {
                if (currentCohort.participants && currentCohort.participants?.length > 0 && list === "participants") {

                    setUserList({
                        data: currentCohort.participants.map(v => ({
                            text: v.name + " (" + v.username + ")",
                            value: v.id
                        })),
                        [fetching]: false,
                    });
                } else {
                    let users_data = [];
                    let users_query = form.getFieldValue(list);
                    users_data = users_query.flatMap(user => user.user)

                    if (users_data.length > 0) {
                        API.post("/api/user/getUsers/cohort-builder", {
                            users: users_data,
                            list,
                            exclude_users: null
                        }).then(({ data }) => {
                            let result_users = data.users.map((user) => ({
                                text: `${user.name} (${user.username})`,
                                value: user.id,
                                isEdpFacilitator: user.roles.some(item => item.slug === "facilitator_local") ? true : false,
                                isNislFacilitator: user.roles.some(item => item.slug === "facilitator_local_inc") ? true : false,
                                isNationalFacilitator: user.roles.some(item => item.slug === "facilitator_national") ? true : false
                            }));
                            setUserList({ data: result_users, [fetching]: false });
                        })
                            .catch(() => { });
                    }
                }
            }
        };
        if (ready) {
            getUsers("participants", setParticipants, "fetchingParticipants", participants);
            getUsers("facilitators", setFacilitators, "fetchingFacilitators", facilitators);
            getUsers("cofacilitators", setCoFacilitators, "fetchingCoFacilitators", coFacilitators);
            getUsers("webinars", setWebinars, "fetchingWebinars", webinars);
            getUsers("alp_coachs", setAlpCoachs, "fetchingAlpCoachs", alpCoachs);
            getUsers("qualities", setQualities, "fetchingQualities", qualities);
        }
        // eslint-disable-next-line
    }, [currentCohort, ready]);

    return (
        <>
            <Col xs={24}>
                <Row>
                    <Col span={24}>
                        <strong>
                            Participants ({listParticipants.length})
                        </strong>
                    </Col>
                </Row>
                <Form.List name="participants">
                    {(fields, { add, remove }) => {
                        fields = listParticipants;
                        return (
                            <>
                                <Row gutter={[5, 0]}>
                                    {fields.map((field) => (
                                        <Col
                                            xs={24}
                                            md={8}
                                            key={field.name}
                                        >
                                            <Row gutter={[3, 0]}>
                                                <Col xs={22}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[
                                                            field.name,
                                                            "user",
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            "user",
                                                        ]}
                                                        style={{
                                                            marginBottom:
                                                                "12px",
                                                        }}
                                                    >
                                                        <Select
                                                            showSearch
                                                            value={
                                                                valueParticipants
                                                            }
                                                            placeholder="- Participant -"
                                                            notFoundContent={
                                                                fetchingParticipants ? (
                                                                    <Spin size="small" />
                                                                ) : null
                                                            }
                                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                                            onSearch={(v) =>
                                                                searchUser(
                                                                    v,
                                                                    setParticipants,
                                                                    null,
                                                                    "Participants",
                                                                    participants
                                                                )
                                                            }
                                                            style={{
                                                                width:
                                                                    "100%",
                                                            }}
                                                        >
                                                            {participants.data.map(
                                                                (d) => (
                                                                    <Select.Option key={d.value} value={d.value}
                                                                    >
                                                                        {
                                                                            d.text
                                                                        }
                                                                    </Select.Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xs={1}
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "5px",
                                                    }}
                                                >
                                                    <MinusCircleOutlined
                                                        onClick={() => {
                                                            remove(
                                                                field.name
                                                            );
                                                            removeList(
                                                                listParticipants,
                                                                field,
                                                                setListParticipants
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    ))}
                                </Row>

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        id="hola-mundo"
                                        onClick={(...p) => {
                                            add();
                                            addList(
                                                setListParticipants,
                                                listParticipants
                                            );
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add Participant
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            </Col>
            <Col md={12} xs={24}>
                <Row style={{ maxHeight: '23px' }}>
                    <Col span={3}>
                        <strong>Facilitators</strong>
                    </Col>
                    <Col span={21}>
                        <Form.Item name={"divide_units"} label={"Show each day?"} style={{ marginTop: '-5px' }}>
                            <Checkbox.Group>
                                <Checkbox value="divide_units" onChange={handleDivideUnits}></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List name="facilitators">
                    {(fields, { add, remove }) => {
                        fields = listFacilitators;

                        let facilitators_data = form.getFieldValue("facilitators");

                        return (
                            <>
                                {fields.map((field) => {
                                    let disabledfacUnassigned = false
                                    if (facilitators_data[field.key]?.user === 13450) {
                                        disabledfacUnassigned = true
                                        let units_unassigned = unitsFacilitators?.flatMap(unit => unit?.ext)?.
                                            filter(unit => !allUnitsFacilitators.includes(unit))?.
                                            filter(unit_fac => unit_fac !== undefined);

                                        facilitators_data[field.key].unit = units_unassigned;


                                    }
                                    return (
                                        <Row
                                            gutter={[5, 0]}
                                            key={field.name}
                                            style={{ marginRight: "6.5px" }}
                                        >
                                            <Col xs={12}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "user"]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        "user",
                                                    ]}
                                                    style={{
                                                        marginBottom: "12px",
                                                    }}
                                                >
                                                    <Select
                                                        showSearch
                                                        value={
                                                            valueFacilitators
                                                        }
                                                        placeholder="- Facilitator -"
                                                        notFoundContent={
                                                            fetchingFacilitators ? (
                                                                <Spin size="small" />
                                                            ) : null
                                                        }
                                                        onSearch={(v) => searchUser(v, setFacilitators, null, "Facilitators", facilitators)}
                                                        onSelect={(a, v) => {
                                                            if (nislCourses.includes(currentCohort.course_data.id) && (v.isNationalFacilitator === false && v.isNislFacilitator === false)) {
                                                                Modal.error({
                                                                    title: '',
                                                                    content: 'The facilitator is not certified to train a NISL cohort.',
                                                                });

                                                                let newFacilitators = facilitators.data.filter(fac => fac.value !== a);

                                                                setFacilitators({
                                                                    data: newFacilitators,
                                                                    fetchingFacilitators: false,
                                                                });

                                                                const values = form.getFieldsValue()
                                                                let currentRow = values.facilitators.findIndex(fac => fac.user === a)

                                                                Object.assign(values.facilitators[currentRow], { user: null })
                                                                form.setFieldsValue({ ...values, facilitators: values.facilitators })

                                                            } else {
                                                                changeFacilitatorUnit(
                                                                    field.fieldKey,
                                                                    field.name,
                                                                    "facilitators"
                                                                );
                                                            }
                                                        }}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {facilitators.data.map(
                                                            (d) => (
                                                                <Select.Option
                                                                    key={d.value}
                                                                    value={d.value}
                                                                    isEdpFacilitator={d.isEdpFacilitator}
                                                                    isNislFacilitator={d.isNislFacilitator}
                                                                    isNationalFacilitator={d.isNationalFacilitator}
                                                                >
                                                                    {d.text}
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={11}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "unit"]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        "unit",
                                                    ]}
                                                    style={{
                                                        marginBottom: "12px",
                                                    }}
                                                >
                                                    <Select
                                                        placeholder="- Unit -"
                                                        mode="multiple"
                                                        allowClear
                                                        disabled={disabledfacUnassigned === true ? true : false}
                                                        onSelect={(value) => {
                                                            changeFacilitatorUnit(
                                                                field.fieldKey,
                                                                field.name,
                                                                "facilitators"
                                                            );

                                                            removeFacilitatorsUnassiged(value)
                                                        }}
                                                    >
                                                        <Select.Option value="all"
                                                            onSelect={() => {
                                                                changeFacilitatorUnit(
                                                                    field,
                                                                    field.name,
                                                                    "facilitators"
                                                                )
                                                            }}>
                                                            All
                                                        </Select.Option>
                                                        {showOrientation && showDividedUnits === false ? (
                                                            <Select.Option
                                                                value={
                                                                    "orientation"
                                                                }
                                                                key={
                                                                    "orientation"
                                                                }
                                                                onSelect={() => {
                                                                    changeFacilitatorUnit(
                                                                        field,
                                                                        field.name,
                                                                        "facilitators"
                                                                    );
                                                                }}
                                                            >
                                                                Orientation
                                                            </Select.Option>
                                                        ) : null}

                                                        {unitsFacilitators.map(
                                                            (d) => {
                                                                let key = d.unit;
                                                                let value = d.unit;
                                                                let name = d.name;

                                                                if (d.ext) {
                                                                    key = d.ext;
                                                                    value = d.ext;
                                                                }
                                                                if ((d.type === "2_day" || d.type === "split") && showDividedUnits === false) {
                                                                    name = "Unit " + d.unit;
                                                                }
                                                                return (
                                                                    <>
                                                                        <Select.Option
                                                                            key={key}
                                                                            value={value}
                                                                        >
                                                                            {name}
                                                                        </Select.Option>
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={1}
                                                style={{
                                                    paddingTop: "5px",
                                                    paddingLeft: "0px",
                                                }}
                                            >
                                                <MinusCircleOutlined
                                                    onClick={() => {
                                                        removeList(
                                                            listFacilitators,
                                                            field,
                                                            setListFacilitators
                                                        );
                                                        remove(field.name);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add();
                                            addList(
                                                setListFacilitators,
                                                listFacilitators
                                            );
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add facilitator
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            </Col>
            <Col md={12} xs={24}>
                <Row>
                    <Col span={24}>
                        <strong>Co-Facilitators</strong>
                    </Col>
                </Row>
                <Form.List name="cofacilitators">
                    {(fields, { add, remove }) => {
                        fields = listCoFacilitators;

                        let cofac_data = form.getFieldValue("cofacilitators");
                        return (
                            <>
                                {fields.map((field) => {
                                    let disabledCofacUnassigned = false
                                    if (cofac_data[field.key]?.user === 13450) {
                                        disabledCofacUnassigned = true

                                        let units_unassigned = unitsFacilitators?.flatMap(unit => unit?.ext)?.
                                            filter(unit => !allUnitsCoFacilitators.includes(unit))?.
                                            filter(unit_Cofac => unit_Cofac !== undefined);

                                        cofac_data[field.key].unit = units_unassigned;

                                    }
                                    return (
                                        <Row gutter={[5, 0]} key={field.name}>
                                            <Col xs={12}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "user"]}
                                                    fieldKey={[field.fieldKey, "user"]}
                                                    style={{ marginBottom: "12px" }}
                                                >
                                                    <Select
                                                        showSearch
                                                        value={valueCoFacilitators}
                                                        placeholder="- CoFacilitator -"
                                                        notFoundContent={
                                                            fetchingCoFacilitators ? (
                                                                <Spin size="small" />
                                                            ) : null
                                                        }
                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        onSearch={(v) =>
                                                            searchUser(v, setCoFacilitators, null, "CoFacilitators", coFacilitators)
                                                        }
                                                        onSelect={(a, v) => {
                                                            if (nislCourses.includes(currentCohort.course_data.id) && (v.isNationalFacilitator === false && v.isNislFacilitator === false)) {
                                                                Modal.error({
                                                                    title: '',
                                                                    content: 'The CoFacilitator is not certified to train a NISL cohort.',
                                                                });

                                                                let newCofacilitators = coFacilitators.data.filter(fac => fac.value !== a);

                                                                setCoFacilitators({
                                                                    data: newCofacilitators,
                                                                    fetchingCofacilitators: false,
                                                                });

                                                                const values = form.getFieldsValue()
                                                                let currentRow = values.cofacilitators.findIndex(fac => fac.user === a)

                                                                Object.assign(values.cofacilitators[currentRow], { user: null })
                                                                form.setFieldsValue({ ...values, cofacilitators: values.cofacilitators })

                                                            } else {
                                                                changeFacilitatorUnit(field.fieldKey, field.name, "cofacilitators");
                                                            }
                                                        }}
                                                        style={{ width: "100%" }}
                                                    >
                                                        {coFacilitators.data.map(
                                                            (d) => (
                                                                <Select.Option
                                                                    key={d.value}
                                                                    value={d.value}
                                                                    isEdpFacilitator={d.isEdpFacilitator}
                                                                    isNislFacilitator={d.isNislFacilitator}
                                                                    isNationalFacilitator={d.isNationalFacilitator}
                                                                >
                                                                    {d.text}
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={11}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "unit"]}
                                                    fieldKey={[field.fieldKey, "unit"]}
                                                    style={{ marginBottom: "12px" }}
                                                >
                                                    <Select
                                                        placeholder="- Unit -"
                                                        mode={"multiple"}
                                                        allowClear
                                                        disabled={(disabledCofacUnassigned === true) ? true : false}
                                                        onSelect={(value) => {
                                                            changeFacilitatorUnit(field, field.name, "cofacilitators");
                                                            removeCofacUnassigened(value)
                                                        }}
                                                    >
                                                        <Select.Option value="all"
                                                            onSelect={() => { changeFacilitatorUnit(field, field.name, "cofacilitators"); }}>
                                                            All
                                                        </Select.Option>
                                                        {showOrientation ? (
                                                            <Select.Option
                                                                value={"orientation"}
                                                                key={"orientation"}
                                                                onSelect={() => {
                                                                    changeFacilitatorUnit(
                                                                        field,
                                                                        field.name,
                                                                        "cofacilitators"
                                                                    );
                                                                }}
                                                            >
                                                                Orientation
                                                            </Select.Option>
                                                        ) : null}
                                                        {unitsFacilitators.map(
                                                            (d) => {
                                                                let key = d.unit;
                                                                let value = d.unit;
                                                                let name = d.name;
                                                                if (d.ext) {
                                                                    key = d.ext;
                                                                    value = d.ext;
                                                                }
                                                                if (d.type === "2_day" || d.type === "split") {
                                                                    name = "Unit " + d.unit;
                                                                }
                                                                return (
                                                                    <Select.Option key={key} value={value}>
                                                                        {name}
                                                                    </Select.Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={1}
                                                style={{
                                                    paddingTop: "5px",
                                                    paddingLeft: "0px",
                                                }}
                                            >
                                                <MinusCircleOutlined
                                                    onClick={() => {
                                                        remove(field.name);
                                                        removeList(
                                                            listCoFacilitators,
                                                            field,
                                                            setListCoFacilitators
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add();
                                            addList(
                                                setListCoFacilitators,
                                                listCoFacilitators
                                            );
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add Co-Facilitator
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            </Col>
            {showWebinarSection ? (
                <Col md={12} xs={24}>
                    <Row>
                        <Col span={24}>
                            <strong>Webinar Producer</strong>
                        </Col>
                    </Row>
                    <Form.List name="webinars">
                        {(fields, { add, remove }) => {
                            fields = listWebinars;
                            let webinars_data = form.getFieldValue("webinars");
                            return (
                                <>
                                    {fields.map((field) => {
                                        let disabledWebinarUnassigned = false
                                        if (webinars_data[field.key]?.user === 13450) {
                                            disabledWebinarUnassigned = true

                                            let units_unassigned = unitsGeneral?.flatMap(unit => unit?.ext)?.
                                                filter(unit => !allUnitsWebinars.includes(unit))?.
                                                filter(unit_webinar => unit_webinar !== undefined);

                                            webinars_data[field.key].unit = units_unassigned;
                                        }

                                        return (
                                            <Row
                                                gutter={[5, 0]}
                                                key={field.name}
                                                style={{ marginRight: "6.5px" }}
                                            >
                                                <Col xs={12}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[
                                                            field.name,
                                                            "user",
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            "user",
                                                        ]}
                                                        style={{
                                                            marginBottom:
                                                                "12px",
                                                        }}
                                                    >
                                                        <Select
                                                            showSearch
                                                            value={valueWebinars}
                                                            placeholder="- NCEE Producer -"
                                                            notFoundContent={
                                                                fetchingWebinars ? (
                                                                    <Spin size="small" />
                                                                ) : null
                                                            }
                                                            onSelect={() => { changeFacilitatorUnit(field.fieldKey, field.name, "webinars"); }}
                                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                            onSearch={(v) => searchUser(v, setWebinars, null, "Webinars", webinars)}
                                                            style={{ width: "100%" }} >
                                                            {webinars.data.map(
                                                                (d) => (
                                                                    <Select.Option
                                                                        key={
                                                                            d.value
                                                                        }
                                                                        value={
                                                                            d.value
                                                                        }
                                                                    >
                                                                        {d.text}
                                                                    </Select.Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={11}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, "unit"]}
                                                        fieldKey={[field.fieldKey, "unit"]}
                                                        style={{ marginBottom: "12px" }}
                                                    >
                                                        <Select placeholder="- Unit -" mode={"multiple"} allowClear
                                                            disabled={(disabledWebinarUnassigned === true) ? true : false}
                                                            onSelect={(value) => {
                                                                changeFacilitatorUnit(field.fieldKey, field.name, "webinars");
                                                                removeWebinarUnassigened(value)
                                                            }}
                                                        >
                                                            <Select.Option value="all"
                                                                onSelect={() => { changeFacilitatorUnit(field.fieldKey, field.name, "webinars"); }}
                                                            >
                                                                All
                                                            </Select.Option>
                                                            {showOrientation ? (
                                                                <Select.Option value={"orientation"} key={"orientation"}
                                                                    onSelect={() => { changeFacilitatorUnit(field.fieldKey, field.name, "webinars"); }}   >
                                                                    Orientation
                                                                </Select.Option>
                                                            ) : null}
                                                            {unitsGeneral.map(
                                                                (d) => {
                                                                    let key = d.unit;
                                                                    let value = d.unit;
                                                                    if (d.ext) { key = d.ext; value = d.ext; }
                                                                    return (<Select.Option key={key} value={value}>  {d.name}</Select.Option>);
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={1} style={{ paddingTop: "5px", paddingLeft: "0px" }} >
                                                    <MinusCircleOutlined onClick={() => { remove(field.name); removeList(listWebinars, field, setListWebinars); }} />
                                                </Col>
                                            </Row>
                                        );
                                    })}

                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                add();
                                                addList(
                                                    setListWebinars,
                                                    listWebinars
                                                );
                                            }}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add Producer
                                        </Button>
                                    </Form.Item>
                                </>
                            );
                        }}
                    </Form.List>
                </Col>
            ) : null}
            <Col md={12} xs={24}>
                <Row>
                    <Col span={24}>
                        <strong>AL Advisers</strong>
                    </Col>
                </Row>
                <Form.List name="alp_coachs">
                    {(fields, { add, remove }) => {
                        fields = listAlpCoachs;
                        return (
                            <>
                                {fields.map((field) => (
                                    <Row gutter={[5, 0]} key={field.name}>
                                        <Col xs={23}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, "user"]}
                                                fieldKey={[
                                                    field.fieldKey,
                                                    "user",
                                                ]}
                                                style={{
                                                    marginBottom: "12px",
                                                }}
                                            >
                                                <Select
                                                    placeholder="- AL Adviser -"
                                                    showSearch
                                                    value={valueAlpCoachs}
                                                    notFoundContent={
                                                        fetchingAlpCoachs ? (
                                                            <Spin size="small" />
                                                        ) : null
                                                    }
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    onSearch={(v) =>
                                                        searchUser(
                                                            v,
                                                            setAlpCoachs,
                                                            [
                                                                "training_manager",
                                                                "nisl_manager",
                                                                "facilitator_local",
                                                                "facilitator_local_inc",
                                                                "facilitator_national",
                                                                "acilitator_candidate",
                                                                "state_coordinator",
                                                                "coaching_manager",
                                                                "distinguished_principal",
                                                                "coach",
                                                            ],
                                                            "AlpCoachs",
                                                            alpCoachs
                                                        )
                                                    }
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    {alpCoachs.data.map(
                                                        (d) => (
                                                            <Select.Option
                                                                key={
                                                                    d.value
                                                                }
                                                                value={
                                                                    d.value
                                                                }
                                                            >
                                                                {d.text}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={1}
                                            style={{
                                                paddingLeft: "0px",
                                                paddingTop: "5px",
                                            }}
                                        >
                                            <MinusCircleOutlined
                                                onClick={() => {
                                                    remove(field.name);
                                                    removeList(
                                                        listAlpCoachs,
                                                        field,
                                                        setListAlpCoachs
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                ))}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add();
                                            addList(
                                                setListAlpCoachs,
                                                listAlpCoachs
                                            );
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add AL Adviser
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            </Col>
            {showQualityAssurance ? (
                <Col md={12} xs={24}>
                    <Row>
                        <Col span={24}>
                            <strong>Quality Support</strong>
                        </Col>
                    </Row>
                    <Form.List name="qualities">
                        {(fields, { add, remove }) => {
                            fields = listQualities;
                            return (
                                <>
                                    {fields.map((field) => (
                                        <Row gutter={[5, 0]} key={field.name}>
                                            <Col xs={23}>
                                                <Form.Item
                                                    {...field}
                                                    name={[
                                                        field.name,
                                                        "user",
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        "user",
                                                    ]}
                                                    style={{
                                                        marginBottom:
                                                            "12px",
                                                    }}
                                                >
                                                    <Select
                                                        placeholder="- Quality Support -"
                                                        showSearch
                                                        value={
                                                            valueQualities
                                                        }
                                                        notFoundContent={
                                                            fetchingQualities ? (
                                                                <Spin size="small" />
                                                            ) : null
                                                        }
                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                                        onSearch={(v) =>
                                                            searchUser(
                                                                v,
                                                                setQualities,
                                                                null,
                                                                "Qualities",
                                                                qualities
                                                            )
                                                        }
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {qualities.data.map(
                                                            (d) => (
                                                                <Select.Option
                                                                    key={
                                                                        d.value
                                                                    }
                                                                    value={
                                                                        d.value
                                                                    }
                                                                >
                                                                    {d.text}
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={1}
                                                style={{
                                                    paddingLeft: "0px",
                                                    paddingTop: "5px",
                                                }}
                                            >
                                                <MinusCircleOutlined
                                                    onClick={() => {
                                                        remove(field.name);
                                                        removeList(
                                                            listQualities,
                                                            field,
                                                            setListQualities
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))}

                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                add();
                                                addList(
                                                    setListQualities,
                                                    listQualities
                                                );
                                            }}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add Quality Support
                                        </Button>
                                    </Form.Item>
                                </>
                            );
                        }}
                    </Form.List>
                </Col>
            ) : null}

        </>
    );
};
export default Assignments;
