import React, { useState, useEffect } from 'react';
import { Row, Col, Layout, Divider, Table, Spin, List} from 'antd';
import FormatedCell from '../../../../../components/UI/FormatedCell';
import UsersRecords from './UsersRecords';
import CohortInfo from "../../components/CohortInfo";
import API from "../../../../../api";
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    labelIcon: {
        width: '60px',
        height: '30px',
        margin: '3px',
        padding: '2px',
        minWidth: '3em',
    },
    containerIcon: {
        height: '20px',
    },
    xSmallCellPadding: {
        '& th': {
            padding: '0px 3px !important',
            backgroundColor: '#fafafa !important',
        },
        '& td': {
            padding: '1px 3px !important',
        },
        '& .ant-table-header':{
            overflow:'visible !important',
            boxShadow: '0px 3px 0px black',
        },
        'minWidth': '100%',
        '& .ant-table-column-sorters' : {
            padding: '16px 16px 16px 0px !important',
        }

    },
    grey: {
        '& td': {
            backgroundColor: '#d9d9d9 !important',
        },
    },
    borderRight:{
        '&:not(th)':{
            borderRight: '3px solid #f1f3f5 !important',
        }
    },
    borderLeft:{
        '&:not(th)':{
            borderLeft: '3px solid #f1f3f5 !important',
        }
    },
    description : {
        fontSize: '13px',
        lineHeight: '1.2em',
    },
    biggerSpin: {
        maxHeight:'70vh !important',
        height: '70vh !important',
    },
});

const { Content } = Layout;

const ConventionLabel = (props) => {
    const classes = useStyles();
    return(
        <Col span={props.config.width}>
            <Row align="middle">
                <Col span={3} className={classes.labelIcon} flex="0 1">
                    <FormatedCell color={props.config.color} border={props.config.border}>{props.config.letter}</FormatedCell>
                </Col>
                <Col className={classes.description} flex="1 0">
                    <Row align="middle">
                        {props.config.message}
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

const conventions = [
    {
        color: "#008900",
        letter: 'y',
        border: false,
        message: 'In attendance that day',
        width : '7',
    },
    {
        color: "#008900",
        letter: 'I/S',
        border: true,
        message: 'Independent Study was completed',
        width : '7',
    },
    {
        color: "#A0A0A0",
        letter: '',
        border: false,
        message: 'Manually added to this cohort as a make-up, but the attendance has not yet been recorded for that unit',
        width : '10',
    },
    {
        color: '#FF0000',
        letter: 'n',
        border: false,
        message: 'Not in attendance that day and no make-up as of yet',
        width : '7',
    },
    {
        color: "#FF0000",
        letter: 'I/S',
        border: true,
        message: 'Independent Study assigned but not completed',
        width : '7',
    },
    {
        color: "#A0A0A0",
        letter: 'y',
        border: false,
        message: 'Manually added to this cohort as a make-up, and they have been recorded as present for that day',
        width : '10',
    },
    {
        color: "#2196F3",
        letter: 'y(M)',
        border: false,
        message: 'Not in attendance in the home cohort but attended a make-up elsewhere',
        width : '7',
    },
    {
        color: "#ffa500",
        letter: 'y',
        border: false,
        message: 'Duplicate record, i.e., attended the same unit in 2 different cohorts',
        width : '7',
    },
    {
        color: "#A0A0A0",
        letter: 'n',
        border: false,
        message: 'Manually added to this cohort as a make-up, and they have been recorded as absent for that day',
        width : '10',
    },
]

const AttendanceReport = ({...props}) =>{
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [cols, setCols] = useState();
    const [data, setData] = useState();
    const [unitsInfo, setUnitsInfo] = useState();
    const [dataMakeups, setDataMakeUps] = useState();
    const [dataParticipants, setDataParticipants] = useState();
    const [dataDuplicates, setDataDuplicates] = useState();

    const usersColumns = [
        {
            title: '',
            dataIndex: 'unit',
            width: '7%'
        },
        {
            title: 'Facilitators',
            dataIndex: 'facilitators',
            width: '15%'
        },
        {
            title: 'Attendance Recording',
            dataIndex: 'attendanceInfo',
            width: '78%',
            render: value => <List
                size={"small"}
                dataSource={value}
                renderItem={item =><>{item} <br/></>}
            />,
        },
    ];

    const getAttendanceReportInfo = () => {
        setLoading(true);
        API.get(`api/get_attendance_report/${props.cohortId}`)
        .then(async (data) => {
            setCols(await data.data.headers.map((col,i) => {;
                const labelCOl = i===0 ? 'firstName': 'lastName'
                let unitName;
                let width;
                let alignText = "left";

                if (col.unitId === 'orientation'){
                    width = '3%';
                    unitName = `O` ;
                    alignText = 'center';
                }else if(col.index === 'firstName'){
                    width = '8%';
                    unitName = col.unitId;
                }else if(col.index === 'lastName'){
                    width = '10%';
                    unitName = col.unitId;
                }else{
                    width = '7%';
                    unitName = `Unit ${col.unitId}`;
                    alignText = 'center';
                }

                return({
                    title: unitName,
                    defaultSortOrder: 'descend',
                    sorter: i<2 ? (a, b) => {
                        return  a[labelCOl] > b[labelCOl] ? 1 :a[labelCOl] < b[labelCOl] ? -1 : 0
                    } : false,
                    align: alignText,
                    dataIndex: col.index,
                    width: width,
                    children: col.events.map((event,i) => {
                        const length = col.events.length;
                        return({
                            title: event.label,
                            dataIndex: event.eventId,
                            className: (length-1) === i ? classes.borderRight : i = 0 ? classes.borderLeft : '',
                            align: "center",
                            render: (record) => {
                                return <div key={`${record.id}`} className={classes.containerIcon} >
                                    <FormatedCell
                                        border={record?.border}
                                        color={record?.color}>{record?.letter}
                                    </FormatedCell>
                                </div>
                            }
                        })
                    }),
                })
            }));

             const dataTransform = data.data.participantAttendance.map((participant, i) => {
                const participantFormated = {
                    'key': i,
                    'firstName': participant.firstName,
                    'lastName': participant.lastName === null ? '' : participant.lastName,
                };
                participant.events && participant.events.map((event) => {

                    return participantFormated[event.eventId] = {
                        letter: event.letter,
                        color: event.color,
                        border: event.border,
                    }
                });
                return(participantFormated);
            });
            setData(dataTransform);
            setUnitsInfo(data.data.usersInfo.map((userInfo, i) => {
                return {
                    key: i,
                    unit: userInfo.unit,
                    facilitators: userInfo.facilitators.map((facilitator)=>{
                        facilitator.lastName = facilitator.lastName === null ? '' : facilitator.lastName;
                        return `${facilitator.firstName} ${facilitator.lastName}`;
                    }).join(', '),
                    cofacilitators: userInfo.cofacilitators.map((cofacilitator)=>{
                        cofacilitator.lastName = cofacilitator.lastName === null ? '' : cofacilitator.lastName;
                        return `${cofacilitator.firstName} ${cofacilitator.lastName}`;
                    }).join(', '),
                    attendanceInfo: userInfo.attendanceInfo,
                }
            }));
            setDataMakeUps(data.data.makeupsParticipants);
            setDataParticipants(data.data.addedParticipants.map((el, i)=>{el.key=i; return el;}));
            setDataDuplicates(data.data.duplicatedRecords);
            setLoading(false);
        })
        .catch((error) => {
            console.log( error);
        })
        .finally(() => {

        })
    }
    useEffect( () => {
        getAttendanceReportInfo();
    // eslint-disable-next-line
    }, []);

    return (
        <Spin className={classes.biggerSpin} spinning={loading || !data}>
            <Content>
                <Row>
                    <Col span={7}>
                        <Row>
                            <Col>
                                <CohortInfo
                                    cohortInfo={props.cohortInfo}
                                    loading={false}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={17}>
                        <Row>
                            {
                                conventions.map((convention,i) => <ConventionLabel key={i} config={convention}/>)
                            }
                        </Row>
                    </Col>
                </Row>
                {  data?.length > 0 &&
                    <>
                        <Row><Divider/></Row>
                        <Row>
                            <Table className={classes.xSmallCellPadding} columns={cols} dataSource={data} pagination={false} sticky/>
                        </Row>
                    </>
                }
                { dataParticipants?.length > 0 &&
                    <>
                        <Row><Divider/></Row>
                        <Row>
                            <UsersRecords title='+Guest Participants' dataSource={dataParticipants} columns={usersColumns} pagination={false} sticky/>
                        </Row>
                        </>
                }
                { dataMakeups?.length > 0 &&
                    <>
                        <Row><Divider/></Row>
                        <Row>
                            <UsersRecords title='Make-up records' dataSource={dataMakeups} columns={usersColumns} pagination={false} sticky/>
                        </Row>
                        </>
                }
                { dataDuplicates?.length > 0 &&
                    <>
                        <Row><Divider/></Row>
                        <Row>
                            <UsersRecords title='Duplicate records' dataSource={dataDuplicates} columns={usersColumns} pagination={false} sticky/>
                        </Row>
                    </>
                }
                { unitsInfo?.length > 0 &&
                    <>
                        <Row><Divider/></Row>
                        <Row>
                            <Table dataSource={unitsInfo} columns={usersColumns} className={classes.xSmallCellPadding} pagination={false} sticky/>
                        </Row>
                    </>
                }
            </Content>
        </Spin>
    )
}

export default AttendanceReport;
