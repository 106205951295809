import React from "react";
import { Table, Space, Button, Switch } from "antd";
import API from "../../../../api";

const ListRoles = ({ roles, deleteRole, editRole }) => {
    const changeState = (element) => {
        API.get(`/api/role/change/${element}`).then(({ data }) => {
            console.log(data);
        });
    };

    const columns = [
        {
            title: "Role",
            dataIndex: "name",
            defaultSortOrder: "descend",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Active",
            key: "active",
            dataIndex: "deleted_at",
            render: (text, record) => {
                if (record.deleted_at === null) {
                    return (
                        <>
                            <Switch
                                onClick={() => {
                                    changeState(record.key);
                                }}
                                checked={true}
                            />
                        </>
                    );
                } else {
                    return (
                        <>
                            <Switch
                                onClick={() => {
                                    changeState(record.key);
                                }}
                            />
                        </>
                    );
                }
            },
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => {
                return (
                    <>
                        <Space size="small">
                            <Button
                                type="primary"
                                onClick={() => {
                                    editRole({
                                        id: record.key,
                                        name: record.name,
                                    });
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={() => {
                                    deleteRole(record.key, text);
                                }}
                                type="primary"
                                danger
                            >
                                Delete
                            </Button>
                        </Space>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Table size="small" columns={columns} dataSource={roles} />
        </>
    );
};

export default ListRoles;
