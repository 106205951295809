import React, { useState, useEffect } from "react";
import { Upload, Modal, Button } from "antd";
import { CameraOutlined } from "../assets/icons";

import defaultImage from "./../assets/img/faces/face-0.jpg";
import { dispatchStorageEvent } from "../functions/dispatchStorageEvent";
import checkImage from "../functions/checkImageExist";

function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        Modal.error({ content: "Profile image files must be JPG/PNG." });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        Modal.error({ content: "Profile image files must be smaller than 2MB." });
    }
    return isJpgOrPng && isLt2M;
}

const Avatar = ({setExistsProfileImage, isMobile=false}) => {
    const { REACT_APP_BACKEND } = process.env;
    if (!localStorage.getItem("user-data")) {
        localStorage.clear();
        window.location.replace("/login");
    }
    const { avatar: avatarImg } = JSON.parse(localStorage.getItem("user-data"));


    const [imageUrl, setImageUrl] = useState(avatarImg || defaultImage);
    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            setImageUrl(info.file.response.url);
            const userData = JSON.parse(localStorage.getItem("user-data"));
            localStorage.setItem(
                "user-data",
                JSON.stringify({
                    ...userData,
                    avatar: info.file.response.url,
                })
            );
            setExistsProfileImage(true)
            dispatchStorageEvent();
        }
    };
    useEffect(() => {

        if(avatarImg){
            setExistsProfileImage(true)
        }

        checkImage(imageUrl, () => { }, () => {
            setImageUrl(defaultImage);
        });
        //eslint-disable-next-line
    }, [imageUrl,avatarImg]);



    return (
        <div>
            <div className="pages-profile-upload-avatar">
                <img
                    src={imageUrl}
                    className="pages-profile-upload-avatar-img"
                    alt="user-profile"
                />

            </div>
            <Upload
                name="avatar"
                showUploadList={false}
                action={`${REACT_APP_BACKEND}/api/user/update/avatar`}
                headers={{
                    Authorization:
                        "Bearer " + localStorage.getItem("jwt-token"),
                }}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                style={{
                    padding: "0px",
                }}
                accept=".jpg,.png"
            >
                <Button
                    shape="circle"
                    style={{ position: "absolute", top: isMobile ? "90px" : "75px", right: isMobile ? "36%" : "0px"}}
                >
                    <CameraOutlined
                        style={{
                            fontSize: "20px",
                            top: "3px",
                            position: "absolute",
                            left: "5px",
                        }}
                    />
                </Button>
            </Upload>

        </div>
    );
};

export default Avatar;
