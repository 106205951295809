import React, { useEffect, useState } from 'react';
import { Table,Modal, Button,Space } from "antd";
import API from "../../../api";
import moment  from "moment";
import  Materials  from "../../Dashboard/Cohorts/Materials/Materials";

function BomList({setShowForm,setCourse}) {
    const columns =[
        {

            key:"1",
            dataIndex:"course",
            title: "Course",
            width: "38%",
            render: (value) => {
                return (
                    <a style={{color:"#0051a0"}} href={() => false} onClick={()=>{
                        Modal.info({
                                    title: "",
                                    width : 1000,
                                    content: <Materials  course_material={value.material_status} />
                                  })
                    }}>
                        {value.name}
                    </a>
                );
            }
        },
        {
            key:"2",
            dataIndex:"file_name",
            title: "File",
            align: "center",
            width: "38%",
            render: (value,row) => {
                    return (
                    <a style={{color:"#0051a0"}} href={() => false} onClick={()=>{
                        downloadFile(row.file_url,value)
                    }}>
                        {value}
                    </a>
                );
            }
        },
        {
            key:"3",
            dataIndex:"who_created",
            title: "Created",
            align: "center",
            width: "10%",
            render: (value, row) => {
                if (value) {
                    return (
                    <span>
                        {value?.last_name}
                        <br/>
                        {moment(row.created_at).format("YY-MM-DD hh:mm")}
                    </span>)
                } else {
                    return null;
                }
            }
        },
        {
            key:"4",
            dataIndex:"who_updated",
            title: "Modified",
            align: "center",
            defaultSortOrder: "descend",
            render: (value, row) => {
                if (value) {
                    return (
                    <span>
                        {value?.last_name}
                        <br/>
                        {moment(row.updated_at).format("YY-MM-DD hh:mm")}
                    </span>)
                } else {
                    return null;
                }
            },
            sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix()
        },
        {
            key:"5",
            title: "Action",
            align: "center",
            fixed: "right",
            className: "column_without_padding table_action",
            render: (value, row) => (
                <>
                <Space size="middle">
                    <Button type={"primary"} onClick={()=>{
                         setCourse(value.course.id);
                         setShowForm(true)
                        console.log(value.course.id);
                    }}>Edit</Button>
                </Space>
                </>
              )
        }
    ];

    const [boms,setBoms]=useState([]);
    const [loadBoms,setLoadBoms]=useState(true);

    useEffect(()=>{
        getBoms()
    },[]);

    let downloadFile = (file,name) => {
        API.get(`api/download?file=${file}` , {
            responseType: "blob",
            headers: {"Content-Type": "application/vnd.ms-excel" }
            }).then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", name);
                document.body.appendChild(link);
                link.click();
            });
    }

    let getBoms= ()=>{
        API.get("api/boms").then(({data})=>{
            setBoms(data)
            setLoadBoms(false)
        }).catch(response=>{
            console.log(response);
        })
    }
    return (
        <>
            <Table loading={loadBoms} columns={columns} dataSource={boms}/>
        </>
    );
}

export default BomList;
