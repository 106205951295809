import React, { useState } from "react";
import { Spin } from "antd";
import { pinStyle } from "./Dashboard.style";

const QueryPage = () => {
    let [loadQueryPage, setLoadQueryPage] = useState(false);
    const { REACT_APP_MODERN_SIGNAL_URL, REACT_APP_URL } = process.env;
    let { token_modern_signal } = JSON.parse(localStorage.getItem("nisl-data"));

    return (
        <>
            {!loadQueryPage ? (
                <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
            ) : null}
            <iframe
                src={`${REACT_APP_MODERN_SIGNAL_URL}/MyAccount/LoginViaJWT?token=${token_modern_signal}&returnURL=/QueryParticipants/&from=web&returnTo=${REACT_APP_URL}/dashboard/cohort`}
                frameBorder={"0"}
                onLoad={() => {
                    setLoadQueryPage(true);
                }}
                title="dashboard-query-page"
                className="style-frame"
            />
        </>
    );
};

export default QueryPage;
