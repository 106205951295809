
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from '../../store/actions/actionTypes';
import "./Portal.less";
import { divStyle, tabStyle } from "./Portal.styles";
import { useLocation } from "react-router-dom";

//Icons
import { CalendarFull } from "../../assets/icons/CalendarFull";
import { Bullhorn } from "../../assets/icons/Bullhorn";
import { CalendarFullBlue } from "../../assets/icons/CalendarFullBlue";

// Router
import { BrowserRouter as Router, Switch, Route, useRouteMatch, Redirect, NavLink } from "react-router-dom";

// antd
import { Layout, Dropdown, Button, Menu, Tag, Row, Col, Typography, Select, Form } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, MenuOutlined } from "@ant-design/icons";

// components
import MenuComponent from "../../components/shared/MenuComponent";
import HeaderComponent from "../../components/shared/HeaderComponent";
import LogonPopupModal from "../../components/LogonPopupModal";
import RedirectComponent from "../../components/RedirectComponent";
import _ from "lodash";
import moment from "moment";

// pages
import Dashboard from "../../pages/Dashboard/Dashboard";
import Foundations from "../../pages/Foundations/Foundations";
import Communities from "../../pages/Communities/Communities";
import VideoAnnotations from "../../pages/Tools/VideoAnnotations/VideoAnnotations";
import Diagnostics from "../../pages/Tools/Diagnostics/Diagnostics";
import Course from "../../pages/Courses/Course";
import SharedAlps from "../../pages/Tools/SharedViews/SharedAlps";
import SharedPis from "../../pages/Tools/SharedViews/SharedPis";
import PractitionerInquiry from "../../pages/Tools/SharedViews/PractitionerInquiry";
import ActionLearning from "../../pages/Tools/SharedViews/ActionLearning";
import OnlineLearning from "../../pages/OnlineLearning/OnlineLearning";
import Admin from "../../pages/Admin/Admin";
import Profile from "../../pages/Profile/Profile";
import EventsUser from "../../pages/Courses/EventsUser";
import Banner from "./Banner";
import UserSearch from '../Portal/UserSearch';
import Support from "../../pages/Support/Support";

//axios instance
import API from "../../api";
import Modal from "antd/lib/modal/Modal";

//Functions
import { sharedWithMeInfo } from "../../functions/sharedProjects";
import { getModernSignalToken } from "../../functions/getModernSignalToken";
import { getAlpToken } from "../../functions/getAlpToken";

//Context
import { ParticipantDashboardContext } from "../../Hooks/Context/ParticipantDashboardContext";
import PrivateRoute from "../../Router/PrivateRoute";
import DataLink from "../../pages/Dashboard/Cohorts/DataLink";

//Hooks
import { UserSchedule } from "../../Hooks/UserSchedule";
import VerifyProfileData from "../../components/VerifyProfileData";
import ChangePassword from "../../components/ChangePassword";

const { Paragraph } = Typography;
const { REACT_APP_URL, REACT_APP_MODERN_SIGNAL_URL } = process.env;
const { Sider } = Layout;
const { Option } = Select;

const Portal = (props) => {

    const { roles_un, cohort, userID, token } = JSON.parse(localStorage.getItem("nisl-data"));
    const user_master = JSON.parse(localStorage.getItem("master-user"));
    const user_data = JSON.parse(localStorage.getItem("user-data"));
    let { path, url } = useRouteMatch();
    const [form] = Form.useForm();
    const query = useQuery();
    const location = useLocation();

    let { bodyEvents, dataUserSchedule, userScheduleModal, getScheduleUser } = UserSchedule();

    const [currentSize, setCurrentSize] = useState(window.innerWidth);
    const [logonModal, setLogonModal] = useState(false);
    const [logonModalContent, setLogonModalContent] = useState("");
    const [logonModalTitle, setLogonModalTitle] = useState("");
    const [logonModalId, setLogonModalId] = useState();
    const [participantNotificationHTMLContent, setParticipantNotificationHTMLContent] = useState("");
    const [participantNotificationContent, setParticipantNotificationContent] = useState("");
    const [participantNotificationTitle, setParticipantNotificationTitle] = useState("");
    const [participantNotificationActive, setParticipantNotificationActive] = useState(false);
    const [participantNotificationModal, setParticipantNotificationModal] = useState(false);
    const [showInfoNextEvent, setInfoNextEvent] = useState(false);
    const [messageInfoNextEvent, setMessageInfoNextEvent] = useState({});
    const [showInfoMeeting, setShowInfoMeeting] = useState(false);
    const [infoMeeting, setInfoMeeting] = useState({});
    const [userSchedule, setUserSchedule] = useState(false);
    const [showBannerHeader, setShowBannerHeader] = useState(false);
    const [isDataLinkVisible, setIsDataLinkVisible] = useState(false)
    const [showAdviser, setShowAdviser] = useState(false);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [dataCohort, setDataCohort] = useState([]);
    const [course, setCourse] = useState();
    const [LoadDashboard, setLoadDashboard] = useState(false);
    const [LoadRedirect, setLoadRedirect] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [userMaster, setUserMaster] = useState(false);
    const [messageBanner, setMessageBanner] = useState(false);
    const [showEvents, setShowEvents] = useState(false);
    const [modernSignalToken, setModernSignalToken] = useState();
    let [showContracts, setShowContracts] = useState(false);
    // eslint-disable-next-line
    let [dataEventsUser, setDataEventsUser] = useState(false);
    const [showQueryOption, setShowQueryOption] = useState(false);

    const [iconCalendar, setIconCalendar] = useState(<CalendarFull />);
    const [backgroundIconCalendar, setBackgroundIconCalendar] = useState('#003263');
    const [validatePassword, setValidatePassword] = useState(true);

    const [valuesUserSearch, setValuesUserSearch] = useState({
        username: "",
        honorific: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        job_title: "",
        school: "",
        district: "",
        address1: "",
        city: "",
        state_living: [],
        ZIPcode: ""
    });

    let [users, setUsers] = useState({
        data: [],
        value: [],
        fetching: false,
    });

    let [lastFetchId, setLastFetchId] = useState(0);

    const [widthSize] = useState(250);

    const { value } = users;

    const [verifyProfileModal, setVerifyProfileModal] = useState(false);
    const [withCalendarBtn, setWithCalendarBtn] = useState("10%");

    const isFirstTime = localStorage.getItem('first_login') ? JSON.parse(localStorage.getItem('first_login')) : false;

    const [showModalChangePass, setShowModalChangePass] = useState(false);

    useEffect(() => {
        if (course !== undefined) {
            setParticipantView(course)
        }
        // eslint-disable-next-line
    }, [course, userScheduleModal])

    useEffect(() => {
        if (Object.keys(dataUserSchedule).length > 0) {
            displayParticipantView()
        }
        // eslint-disable-next-line
    }, [dataUserSchedule])


    useEffect(() => {
        window.addEventListener("resize", updateSize);

        let { token_modern_signal } = JSON.parse(localStorage.getItem("nisl-data"));

        if (!token_modern_signal) {
            const { username } = JSON.parse(localStorage.getItem("nisl-data"));
            getModernSignalToken(username).then(() => {
                let { token_modern_signal } = JSON.parse(
                    localStorage.getItem("nisl-data")
                );
                setModernSignalToken(token_modern_signal);
            });
        } else {
            setModernSignalToken(token_modern_signal);
        }
    }, [userID, roles_un]);


    useEffect(() => {

        if (isFirstTime === true) {
            setVerifyProfileModal(true);
        } else {
            if (!sessionStorage.getItem("dontShowModalLogonPopup")) {
                API.get("api/get-active-logon-popup")
                    .then(({ data }) => {
                        if (data.id) {
                            let intercept = _.intersection(
                                data.roles.map((v) => v.slug),
                                roles_un
                            );
                            if (data.roles.length !== 0) {
                                if (intercept.length === 0) {
                                    return;
                                }
                            }
                            setLogonModal(true);
                            setLogonModalContent(data.text);
                            setLogonModalTitle(data.headline);
                            setLogonModalId(data.id);
                        }
                    })
                    .catch(console.log);
            }

            API.get("api/get-active-logon-popup?type=participant_notification")
                .then(({ data }) => {
                    if (data.text) {
                        setParticipantNotificationHTMLContent(data.text);
                        var div = document.createElement("div");
                        div.innerHTML = data.text;
                        var text = div.textContent || div.innerText || "";
                        setParticipantNotificationContent(text);
                        setParticipantNotificationTitle(data.headline);
                        if (
                            roles_un.includes("participant") ||
                            roles_un.includes("bbp_participant")
                        ) {
                            setParticipantNotificationActive(true);
                        }
                    }
                })
                .catch(console.log);
            let focus_user = query.get("user_search");
            if (focus_user !== null && focus_user !== undefined) {
                onSelect(focus_user, true);
            }
        }
        // eslint-disable-next-line
    }, [isFirstTime])

    let displayParticipantView = async () => {
        if (location.pathname.includes("/course/") && localStorage.getItem("show_participant_view")) {
            await setShowEvents(true)
            await localStorage.removeItem("show_participant_view")
            let event_scroll = await document.querySelector(".ant-modal-body .initial_scroll")
            if (event_scroll !== null) {
                await event_scroll.scrollIntoView()
            }
        }
    }

    useEffect(() => {
        let sharedWithMe = JSON.parse(localStorage.getItem("info-shared-me"));

        if (!sharedWithMe) {
            sharedWithMeInfo(token, cohort)
                .then(() => { })
                .catch(() => { });
        }
    }, [token, cohort])

    useEffect(() => {
        let nisl_data = JSON.parse(localStorage.getItem("nisl-data"));
        if (nisl_data) {
            if (!nisl_data.alp) {
                getAlpToken(userID, cohort)
                    .then(() => { })
                    .catch(() => { });
            }
        }
    }, [userID, cohort])

    useEffect(() => {
        if (showInfoMeeting) {
            setWithCalendarBtn("14%")
        }
    }, [showInfoMeeting])

    useEffect(() => {
        if ((roles_un.includes("administrator") || roles_un.includes("senior_admin")) && validatePassword === true) {
            let dateLastUpdatePass = localStorage.getItem("date-last-update-pass");
            let makeRequest = false;

            if (dateLastUpdatePass !== "" && dateLastUpdatePass !== null) {
                let dateCompare = moment(dateLastUpdatePass).add(45, 'd');

                if (dateCompare.format("Y-MM-DD") < moment().format("Y-MM-DD")) {
                    makeRequest = true;
                }
            } else {
                makeRequest = true;
            }

            if (makeRequest) {
                //It is to verify if the session was opened before the user changed his password in another plaace (device or browser)
                API.get("api/get-date-last-update-pass").then(({ data }) => {
                    localStorage.setItem("date-last-update-pass", data.lastDateChangedPassword);
                    let dateCompare = moment(data.lastDateChangedPassword).add(45, 'd');

                    if (data.lastDateChangedPassword === "" || dateCompare.format("Y-MM-DD") < moment().format("Y-MM-DD")) {
                        setShowModalChangePass(true)
                    }
                })
            }
        }

        setValidatePassword(false);

    }, [validatePassword])

    const toggleSider = () => {
        props.doMenuOpen(!props.menuOpen)
    };

    const updateSize = () => {
        setCurrentSize(window.innerWidth);
    };

    let searchUser = (value) => {
        setLastFetchId(lastFetchId + 1);
        setUsers({ data: [], fetching: true });
        if (value.length >= 3) {
            API.get(`/api/user/get-user-search/${value}`)
                .then(({ data }) => {
                    if (data.users.length > 0) {
                        let result_users = data.users.map((user) => ({
                            text: `${user.name} (${user.username})`,
                            value: user.key,
                        }));
                        setUsers({ data: result_users, fetching: false });
                    } else {
                        setUsers({ data: [], fetching: false });
                        Modal.warning({ content: "User not found" });
                    }
                })
                .catch(() => { });
        }
    };

    const onSelect = (data, redirect = false) => {
        let user_search = "";
        if (redirect === true) {
            user_search = data;
        } else {
            user_search = data.value;
        }
        API.get(`api/users/get-info-search-menu?user_id=${user_search}`).then(({ data }) => {
            setIsVisibleModal(true)
            setValuesUserSearch(data.info_user)
            setDataCohort(data.info_cohorts);
        })
    };

    let setParticipantView = (course) => {
        let user_id = JSON.parse(localStorage.getItem('user-data')).id
        getScheduleUser(course, user_id)
        setUserSchedule(userScheduleModal)
        setDataEventsUser(dataUserSchedule)
    }

    if (
        user_master !== null &&
        user_data !== null &&
        !showBanner &&
        !userMaster
    ) {
        setShowBanner(true);
        setUserMaster(user_master);
        API.post("api/role/get/names", { slugs: roles_un }).then(({ data }) => {
            let roles = data.roles.map(e => e.name);
            let rolesTxt = roles.join(", ");
            let textHeader = `${user_data.name} (${user_data.username}) - ${rolesTxt}`

            setMessageBanner(
                <Row style={{ width: "100%" }}>
                    <Col
                        style={{
                            textAlign: "center",
                            margin: "auto",
                            width: "50%",
                            color: "black",
                        }}
                        sm={22}
                        xs={19}
                    >
                        <Paragraph ellipsis style={{ marginBottom: "0px", fontSize: "13px" }}>{textHeader}</Paragraph>
                    </Col>
                    <Col sm={2} xm={4} >
                        <Button className="bg-close-login-as bg-warning" onClick={logoutLoginAs}>
                            CLOSE
                        </Button>
                    </Col>
                </Row>
            );
        }).catch((e) => {
            console.log(e.response);
        });
    }

    if (roles_un.includes("nisl_manager") && !LoadRedirect) {
        setLoadRedirect(true);
    }
    if (roles_un.includes("bbp_keymaster") && !LoadRedirect) {
        setLoadRedirect(true);
    }
    if (roles_un.includes("administrator") && !LoadRedirect) {
        setLoadRedirect(true);
    }
    if (roles_un.includes("training_manager") && !LoadRedirect) {
        setLoadRedirect(true);
    }
    if (roles_un.includes("facilitator_local") && !LoadRedirect) {
        setLoadRedirect(true);
    }

    if (roles_un.includes("facilitator_local_inc") && !LoadRedirect) {
        setLoadRedirect(true);
    }

    if (roles_un.includes("facilitator_national") && !LoadRedirect) {
        setLoadRedirect(true);
    }
    if (roles_un.includes("state_coordinator") && !LoadRedirect) {
        setLoadRedirect(true);
    }
    if (roles_un.includes("coach") && !LoadRedirect) {
        setLoadRedirect(true);
    }

    if (roles_un.includes("nisl_manager") && !LoadDashboard) {
        setLoadDashboard(true);
    }
    if (roles_un.includes("bbp_keymaster") && !LoadDashboard) {
        setLoadDashboard(true);
    }
    if (roles_un.includes("administrator") && !LoadDashboard) {
        setLoadDashboard(true);
    }
    if (roles_un.includes("training_manager") && !LoadDashboard) {
        setLoadDashboard(true);
    }
    if (roles_un.includes("facilitator_local") && !LoadDashboard) {
        setLoadDashboard(true);
    }
    if (roles_un.includes("facilitator_local_inc") && !LoadDashboard) {
        setLoadDashboard(true);
    }
    if (roles_un.includes("facilitator_national") && !LoadDashboard) {
        setLoadDashboard(true);
    }
    if (roles_un.includes("state_coordinator") && !LoadDashboard) {
        setLoadDashboard(true);
    }
    if (roles_un.includes("coach") && !LoadDashboard) {
        setLoadDashboard(true);
    }
    if (roles_un.includes("nisl_manager") && !showContracts) {
        setShowContracts(true);
    }

    if ((roles_un.includes("coaching_manager") || roles_un.includes("coach") || roles_un.includes("coaching_manager_associates") || roles_un.includes("distinguished_principal")) && !showAdviser) {
        setShowAdviser(true);
    }

    if ((roles_un.includes("administrator") || roles_un.includes("dw_power_user")) && !showQueryOption) {
        setShowQueryOption(true);
    }

    let logoutLoginAs = () => {
        let user_master = JSON.parse(localStorage.getItem("master-user"));
        API.get(`/api/user/loginas/${user_master.key}/${true}`).then(
            async ({ data }) => {
                localStorage.setItem("user-data", JSON.stringify(data.user));
                localStorage.setItem("nisl-data", JSON.stringify(data.data));
                localStorage.setItem("date-last-update-pass", data.lastDateChangedPassword);

                await getAlpToken(data?.user?.nisl_id)
                localStorage.setItem("jwt-token", data.token);
                localStorage.removeItem("info-shared-me");
                localStorage.removeItem("master-user");
                let lastLoginPage = localStorage.getItem("lastLoginPage");
                localStorage.removeItem("lastLoginPage");

                await window.location.replace(lastLoginPage || '/admin');
            }
        );
    };

    let dashboardTabs = null;
    let sizeNextEvent = showInfoMeeting ? { xs: 4, sm: 7, md: 8, lg: 8, xl: 8 } : { xs: 13, sm: 13, md: 13, lg: 13, xl: 14 };
    let sizeMeeting = showInfoMeeting ? { xs: 4, sm: 7, md: 8, lg: 8, xl: 8 } : null;
    let sizeNotification = showInfoMeeting ? { xs: 4, sm: 8, md: 8, lg: 8 } : { xs: 11, sm: 11, md: 11, lg: 11, xl: 10 };

    let participantNotificationText = showInfoMeeting ? 50 : 80;
    let participantNotifications = (
        <>
            <Row className="container" style={{ height: "100%" }} align="middle" gutter={[11, 0]}>
                <Col {...sizeNextEvent} style={{ display: "flex", alignItems: "center" }}>
                    <>
                        {userSchedule ?
                            (<><Button style={{ background: backgroundIconCalendar, borderRadius: "7px", height: 'auto', width: withCalendarBtn }}
                                type='primary'
                                icon={iconCalendar}
                                onClick={async () => {
                                    await setShowEvents(true);
                                    let event_scroll = document.querySelector(".ant-modal-body .initial_scroll")

                                    if (event_scroll !== null) {
                                        event_scroll.scrollIntoView()
                                    }

                                }}
                                onMouseEnter={() => {
                                    setBackgroundIconCalendar('#FFFFFF')
                                    setIconCalendar(<CalendarFullBlue />)
                                }}
                                onMouseLeave={() => {
                                    setBackgroundIconCalendar('#003263')
                                    setIconCalendar(<CalendarFull />)
                                }}
                            >
                            </Button>
                                <EventsUser showModal={showEvents}
                                    onCancel={() => {
                                        setShowEvents(false);
                                    }}
                                    bodyEvents={bodyEvents}
                                    infoUserSchedule={dataUserSchedule}
                                    setIsVisibleModal={setIsVisibleModal}
                                    setShowEvents={setShowEvents}
                                />
                            </>
                            )
                            : ""}
                        {showInfoNextEvent ? (
                            <div
                                className="primary-color"
                                style={{ marginLeft: "10px" }}
                                dangerouslySetInnerHTML={{
                                    __html: messageInfoNextEvent.text,
                                }}
                            ></div>
                        ) : ("")}
                    </>
                </Col>
                {showInfoMeeting ? (
                    <Col {...sizeMeeting} style={{ display: "flex", alignItems: "center" }}>
                        <>
                            <Button
                                type="primary"
                                id="metting_info"
                                style={{
                                    height: "auto",
                                    padding: "10px 15px 10px 15px",
                                    borderRadius: "12px",
                                    lineHeight: "0.9",
                                }}
                            >
                                <a
                                    href={`${infoMeeting.webinar_url}`}
                                    rel="noopener noreferrer nofollow"
                                    target="_blank"
                                    style={{ transition: "none" }}
                                >
                                    {infoMeeting.platform !== undefined
                                        ? infoMeeting.platform.toUpperCase()
                                        : infoMeeting.platform}
                                    <br></br>
                                    LINK
                                </a>
                            </Button>
                            <div
                                style={{ marginLeft: "10px" }}
                                className="primary-color"
                            >
                                <div align="left">
                                    <span> Meeting ID: </span>
                                    <span>{infoMeeting.meeting_id}</span>
                                </div>
                                <div align="left">
                                    <span>Password: </span>
                                    <span>
                                        {" "}
                                        {infoMeeting.password_meeting}{" "}
                                    </span>
                                </div>
                            </div>
                        </>
                    </Col>
                ) : ("")}
                <Col {...sizeNotification} style={{ display: "flex", alignItems: "center" }} >
                    {participantNotificationActive ? (
                        <>
                            <div style={{ background: "#003263", textAlign: "center", borderRadius: "32px", height: "46px", }} >
                                <Bullhorn style={{ fontSize: "50px", padding: "13px" }} />
                            </div>
                            <div className="primary-color" style={{ marginLeft: "5px", width: "100%" }}>
                                {participantNotificationContent.length > participantNotificationText
                                    ? participantNotificationContent.substr(
                                        0,
                                        participantNotificationText
                                    ) + "... "
                                    : participantNotificationContent}
                                <span
                                    style={{ fontStyle: "italic", textDecoration: "underline", cursor: "pointer" }}
                                    onClick={() => {
                                        setParticipantNotificationModal(true);
                                    }}
                                >
                                    read more
                                </span>
                            </div>
                        </>
                    ) : null}
                </Col>
            </Row>
        </>
    );
    if (currentSize > 576) {
        dashboardTabs = (
            <>
                <Row style={{ height: "100%" }} align="middle" >
                    {(roles_un.includes("nisl_manager") || roles_un.includes("facilitator_local") || roles_un.includes("facilitator_local_inc") || roles_un.includes("facilitator_national")) ?
                        (<Col md={3} lg={3} style={{ textAlign: "right" }}>
                            <span style={{ marginLeft: "16px", paddingRight: "5px" }}>
                                find participant
                            </span>
                        </Col>)
                        : null}
                    {(roles_un.includes("nisl_manager") || roles_un.includes("facilitator_local") || roles_un.includes("facilitator_local_inc") || roles_un.includes("facilitator_national")) ?
                        (<Col md={4} lg={4}>
                            <Form
                                form={form}
                            >
                                <Form.Item name="search-menu" className="form_item_search">
                                    <Select
                                        showSearch
                                        allowClear
                                        labelInValue
                                        value={value}
                                        placeholder=""
                                        filterOption={false}
                                        onSearch={searchUser}
                                        onSelect={onSelect}
                                        showArrow={false}
                                        notFoundContent={null}
                                        style={{ width: "180%" }}
                                    >
                                        {users.data.map((d) => (
                                            <Option key={d.value}>{d.text}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Form>
                            <UserSearch valuesUserSearch={valuesUserSearch} isVisibleModal={isVisibleModal} setIsVisibleModal={setIsVisibleModal} dataCohort={dataCohort} form={form} />
                        </Col>)
                        : null}
                    <Col md={14} lg={14} offset={3}>
                        <div style={tabStyle}>
                            <NavLink
                                className="navlink-header"
                                to={`${url}dashboard/cohorts`}
                            >
                                Cohort
                            </NavLink>
                            {showAdviser ? <NavLink
                                className="navlink-header"
                                to={`${url}dashboard/adviser`}
                            >
                                Adviser
                            </NavLink> : ""}
                            <NavLink
                                className="navlink-header"
                                to={`${url}dashboard/calendar`}
                            >
                                Calendar
                            </NavLink>
                            <NavLink
                                className="navlink-header"
                                to={`${url}dashboard/downloads`}
                            >
                                Downloads
                            </NavLink>
                            {showContracts ? (
                                <a
                                    style={{ margin: '0px' }}
                                    href={`${REACT_APP_MODERN_SIGNAL_URL}/MyAccount/LoginViaJWT?token=${modernSignalToken}&returnURL=/Contracts/&from=web&returnTo=${REACT_APP_URL}/dashboard/cohort`}
                                >
                                    Contracts
                                </a>
                            ) : null}

                            {showQueryOption ? (
                                <NavLink
                                    className="navlink-header"
                                    to={`${url}dashboard/query-page`}
                                >
                                    Query
                                </NavLink>) : null
                            }

                            {roles_un.includes("nisl_manager") ?
                                <button
                                    className="navlink-header link-button"
                                    onClick={() => setIsDataLinkVisible(true)}
                                >
                                    Data
                                </button> : null
                            }
                        </div>
                    </Col>
                </Row>
            </>
        );
    } else {
        let menu = (
            <Menu selectedKeys={[]}>
                <Menu.Item key={`${url}dashboard/cohorts`}>
                    <NavLink to={`${url}dashboard/cohorts`}>Cohort</NavLink>
                </Menu.Item>
                {showAdviser ? <Menu.Item key={`${url}dashboard/adviser`}>
                    <NavLink to={`${url}dashboard/adviser`}>Adviser</NavLink>
                </Menu.Item> : ""}
                <Menu.Item key={`${url}dashboard/calendar`}>
                    <NavLink to={`${url}dashboard/calendar`}>Calendar</NavLink>
                </Menu.Item>
                <Menu.Item key={`${url}dashboard/downloads`}>
                    <NavLink to={`${url}dashboard/downloads`}>
                        Downloads
                    </NavLink>
                </Menu.Item>
                {showQueryOption ? (
                    <Menu.Item key={`${url}dashboard/query-page`}>
                        <NavLink to={`${url}dashboard/query-page`}>
                            Query
                        </NavLink>
                    </Menu.Item>
                ) : null
                }

                {showContracts ? (
                    <Menu.Item key={`${url}dashboard/contracts`}>
                        <a
                            style={{ margin: '0px' }}
                            className="navlink-header"
                            href={`${REACT_APP_MODERN_SIGNAL_URL}/MyAccount/LoginViaJWT?token=${modernSignalToken}&returnURL=/Contracts/&from=web&returnTo=${REACT_APP_URL}/contracts/`}
                        >
                            Contracts
                        </a>
                    </Menu.Item>
                ) : null}
                {
                    roles_un.includes("nisl_manager") ?
                        <Menu.Item key={`${url}dashboard/data`}>
                            <button
                                className="link-button"
                                onClick={() => setIsDataLinkVisible(true)}
                            >
                                Data
                            </button>
                        </Menu.Item>
                        : null
                }

            </Menu>
        );
        dashboardTabs = (
            <Dropdown overlay={menu} trigger={["click"]}>
                <Button
                    type="text"
                    style={{ float: "right", margin: "12px 11px" }}
                >
                    dashboard
                    <MenuOutlined style={{ verticalAlign: "baseline" }} />{" "}
                </Button>
            </Dropdown>
        );
    }
    return (
        <Router>
            <Banner setShowBannerHeader={setShowBannerHeader} />
            <Layout style={{ minHeight: "100vh" }}>
                <Sider
                    style={{ marginTop: showBannerHeader ? "32px" : '0px' }}
                    trigger={
                        props.menuOpen ? (
                            <MenuUnfoldOutlined />
                        ) : (
                            <MenuFoldOutlined
                                style={{ marginRight: "188px" }}
                            />
                        )
                    }
                    collapsible
                    collapsed={props.menuOpen}
                    onCollapse={toggleSider}
                    width={widthSize}
                    className="layout-portal-sider layout-portal-sitebar"
                >
                    <div id="scroller">
                        <div style={divStyle}>
                            <img
                                src="/logo.png"
                                className="layout-portal-logo"
                                alt="logo"
                            />
                        </div>
                        <MenuComponent collapsed={props.menuOpen} />
                    </div>
                </Sider>
                <Layout
                    className="site-layout"
                    style={{ marginTop: showBannerHeader ? "32px" : '0px', marginLeft: props.menuOpen ? 80 : widthSize }}
                >
                    {showBanner ? (
                        <Tag className="tag_top_two">
                            {messageBanner}
                        </Tag>
                    ) : null}
                    <HeaderComponent>
                        {LoadDashboard
                            ? dashboardTabs
                            : participantNotifications}
                    </HeaderComponent>
                    <Switch>
                        <Route exact path={`${path}`}>
                            <h1>HOME</h1>
                            {LoadRedirect ? (
                                <Redirect to="/dashboard" />
                            ) : (
                                <Redirect to={`/course/${cohort}`} />
                            )}
                        </Route>
                        <Route path={`${path}dashboard`}>
                            <Dashboard />
                        </Route>
                        <Route path={`${path}course/:course`}>
                            <ParticipantDashboardContext.Provider
                                value={{
                                    setInfoMeeting,
                                    setShowInfoMeeting,
                                    setMessageInfoNextEvent,
                                    showInfoNextEvent,
                                    setInfoNextEvent,
                                    setCourse
                                }}
                            >
                                <Course />
                            </ParticipantDashboardContext.Provider>
                        </Route>
                        <Route path={`${path}foundations`}>
                            <Foundations />
                        </Route>
                        <Route path={`${path}communities`}>
                            <Communities />
                        </Route>
                        <Route path={`${path}diagnostics`}>
                            <Diagnostics />
                        </Route>
                        <Route path={`${path}video-annotations`}>
                            <VideoAnnotations />
                        </Route>
                        <Route path={`${path}shared-pis`}>
                            <SharedPis />
                        </Route>
                        <Route path={`${path}shared-alps`}>
                            <SharedAlps />
                        </Route>
                        <Route path={`${path}practitioner-inquiry`}>
                            <PractitionerInquiry />
                        </Route>
                        <Route path={`${path}action-learning`}>
                            <ActionLearning />
                        </Route>
                        <Route path={`${path}online-learning`}>
                            <OnlineLearning />
                        </Route>
                        <PrivateRoute path={`${path}admin`}>
                            <Admin />
                        </PrivateRoute>
                        <Route path={`${path}profile`}>
                            <Profile />
                        </Route>
                        <Route path={`${path}support`}>
                            <Support />
                        </Route>
                        <Route path="/redirect">
                            <RedirectComponent />
                            <Route />
                        </Route>
                    </Switch>
                    <LogonPopupModal
                        visible={logonModal}
                        setVisible={setLogonModal}
                        content={logonModalContent}
                        title={logonModalTitle}
                        logonPopupId={logonModalId}
                    />
                    <Modal
                        title={
                            <div style={{ textAlign: "center" }}>
                                {participantNotificationTitle}
                            </div>
                        }
                        visible={participantNotificationModal}
                        width="65%"
                        onCancel={() => setParticipantNotificationModal(false)}
                        footer={[
                            null,
                            <Button
                                onClick={() =>
                                    setParticipantNotificationModal(false)
                                }
                                type="primary"
                            >
                                Ok
                            </Button>,
                        ]}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: participantNotificationHTMLContent,
                            }}
                        />
                    </Modal>
                    {isDataLinkVisible && <DataLink isDataLinkVisible={isDataLinkVisible} setIsDataLinkVisible={setIsDataLinkVisible} />}
                    <VerifyProfileData
                        visible={verifyProfileModal}
                        fromVerifyFirstTime={isFirstTime}
                        requiredData={user_data.required_data}
                    />

                    {showModalChangePass &&
                        <ChangePassword
                            visible={showModalChangePass}
                            setVisible={setShowModalChangePass}
                            fromWhere={"portal"}
                        />}
                </Layout>
            </Layout>
        </Router>
    );
};
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const mapStateToProps = state => ({
    menuOpen: state.menuOpen,
    lastcohort: state.lastCohort
});

const mapDispatchToProps = dispatch => ({
    doMenuOpen: active => dispatch({ type: actionTypes.SET_MENU_OPEN, active: active }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Portal);
