import React, { useState } from "react";
import { Layout, Spin } from "antd";
import { pinStyle } from "../../Dashboard/Dashboard.style";
const { Content } = Layout;

const PractitionerInquiry = () => {
    const [loadPractionerInquery, setLoadPractionerInquery] = useState(false);
    const { token, cohort, alp } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_PORTAL_URL, REACT_APP_URL } = process.env;
    let jwt_alp = alp.token

    return (
        <Content className="site-layout-background layout-portal-content">
            <div className="layout-portal-main-container">
                {!loadPractionerInquery ? (
                    <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
                ) : null}
                <iframe
                    src={`${REACT_APP_PORTAL_URL}/?view=practitionerinquiry_ncee&course=${cohort}&redirect_app=${REACT_APP_URL}/practitioner-inquiry&token=${token}&alp=${jwt_alp}`}
                    frameBorder={"0"}
                    onLoad={() => setLoadPractionerInquery(true)}
                    title="practitioner-inquiry"
                    className="style-frame"
                />
            </div>
        </Content>
    );
};

export default PractitionerInquiry;
