import React, { useState, useEffect, createRef } from 'react';
import { createUseStyles } from "react-jss";
import {
    Row,
    Col,
    Layout,
    Button,
    Space,
    Divider,
    Table,
    Alert,
    Spin,
    Typography,
} from 'antd';
import ReactToPrint from 'react-to-print';
import CohortInfo from "../../components/CohortInfo";
import API from "../../../../../api";
import AbsenceListToPrint from "./AbsenceListToPrint";
import moment from "moment";

const { Text } = Typography;
const useStyles = createUseStyles({
    labelIcon: {
        width: '40px',
        height: '30px',
        margin: '3px',
        padding: '2px',
    },
    containerIcon: {
        height: '20px',
    },
    xSmallCellPaddingAudit: {
        '& th': {
            padding: '1px 3px !important',
            backgroundColor: '#fafafa !important',
        },
        '& td': {
            padding: '1px 3px !important',
        },
        '& .ant-table-header': {
            overflow: 'visible !important',
            boxShadow: '0px 3px 0px black',
        },
        'minWidth': '100%',
    },
    grey: {
        '& td': {
            backgroundColor: '#d9d9d9 !important',
        },
    },
    blueText: {
        color: "#0051a0",
    },
    buttonClass: {
        height: "3.5em",
        fontSize: "11px",
        background: "#fff",
        color: "#0051a0",
        width: "20%",
        minWidth: "12em",
        lineHeight: "1em",
    }
});
const { Content } = Layout;
const AttendanceAudit = (props) => {
    const {  totalParticipants } = props.cohortInfo;
    const [absenceData, setAbsenceData] = useState();
    const [previousSessionDate, setPreviousSessionDate] = useState();
    const [previousSessionUnit, setPreviousSessionUnit] = useState();
    const [lastSessionDate, setLastSessionDate] = useState();
    const [lastUnit, setLastUnit] = useState();
    const [participantsCanBeCertified, setParticipantsCanBeCertified] = useState([]);
    const [participantsCertified, setParticipantsCertified] = useState();
    const [totalCertificates, setTotalCertificates] = useState(0);
    const [totalCanBeCertified, setTotalCanBeCertified] = useState();
    const [facilitators, setFacilitators] = useState();
    const [canCertificate, setCanCertificate] = useState();
    const [cetificateLoading, setCetificateLoading] = useState(false);
    const { roles_un } = JSON.parse(localStorage.getItem("nisl-data"));
    const [allParticipants, setAllParticipants] = useState();
    const [pastEventsWithOutSave, setPastEventsWithOutSave] = useState([]);

    (roles_un.includes("certificates") && !canCertificate) && setCanCertificate(true);
    const ref = createRef();
    const classes = useStyles();
    const columns = [
        {
            title: "Last Name",
            dataIndex: "lastName",
            width: '10%',
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            width: '10%',
        },
        {
            title: "Absents",
            dataIndex: "units",
            width: '80%',
        },
    ]
    const [messageAbsent, setMessageAbsent] = useState();
    const [showTable, setShowTable] = useState(false);

    const getAbsenceReport = () => {
        API.get(`api/get_absence/${props.cohortId}`).then(async (response) => {
            setFacilitators(response.data.data.facilitators);
            setLastSessionDate(response.data.data.lastSession.length > 10 ? response.data.data.lastSession.replace(' ', ' @ ') : response.data.data.lastSession);
            setLastUnit(response.data.data.lastUnit);
            setTotalCertificates(response.data.data.totalCertificates);
            setTotalCanBeCertified(response.data.data.totalCanBeCertified);
            setParticipantsCanBeCertified(Object.values(response.data.data.participantsCanBeCertified));
            setParticipantsCertified(response.data.data.participantsCertified);
            setLastUnit(response.data.data.lastUnit);
            setPreviousSessionDate(response.data.data.previousSession.replace(' ', ' @ '));
            setPreviousSessionUnit(response.data.data.previousUnit);
            setAbsenceData(await response.data.data.participants.map((participant, i) => {
                return {
                    key: i,
                    lastName: `${participant.lastName}`,
                    firstName: `${participant.firstName}`,
                    units: participant.events.join(", "),
                }
            }));

            const messageAbsent = (response.data.data.totalAbsences <= 0 ? `none of the participants were absent for any sessions.` : `${totalParticipants - response.data.data.totalCanBeCertified} participants were absent for one or more sessions, as shown below.`);
            setMessageAbsent(messageAbsent)
            setShowTable(response.data.data.totalAbsences <= 0 ? false : true);
            setAllParticipants(response.data.data.allParticipantsIds);
            setPastEventsWithOutSave(response.data.data.pastEventsWithOutSaveData)
        })
    }
    const getCertificates = (type = '') => {
        setCetificateLoading(true);
        let toBeCertified = type === 'all' ? allParticipants : participantsCanBeCertified.concat(participantsCertified);

        API.get(`api/certifications/${props.cohortId}`,
            {
                responseType: "blob",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
                params: {
                    toBeCertified: toBeCertified,
                },
            }).then(response => {
                setCetificateLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                let name_document = `${type === 'all' ? 'All Certificates for' : `${totalCanBeCertified} Certificates for`}  ${props.cohortInfo.cohortName} ${moment().format('YYYY-MM-DD hh_mm a')}.pdf`;
                link.href = url;
                link.setAttribute("download", name_document);
                document.body.appendChild(link);
                link.click();
            })
    }
    useEffect(() => {
        getAbsenceReport();
    // eslint-disable-next-line
    }, []);
    return (
        <Content>
            <Spin spinning={cetificateLoading}>
                <Row gutter={16} >
                    <Col span={12}>
                        <CohortInfo cohortInfo={props.cohortInfo} />
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col xs={7} sm={9} md={7} lg={6} xl={5} xxl={4} >Last session: </Col>
                            <Col xs={17} sm={15} md={17} lg={18} xl={19} xxl={20} className={classes.blueText}>{lastUnit !== "" ? `Unit ${lastUnit} on ${lastSessionDate}` : ""}</Col>
                        </Row>
                        <Row>
                            <Col xs={7} sm={9} md={7} lg={6} xl={5} xxl={4}>Facilitators: </Col>
                            <Col xs={17} sm={15} md={17} lg={18} xl={19} xxl={20} className={classes.blueText}>{facilitators && facilitators.map(facilitator => {
                                let first = facilitator.firstName ? facilitator.firstName : '';
                                let last = facilitator.lastName ? facilitator.lastName : '';
                                return ` ${first} ${last}`;
                            }).toString()}</Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={12}>
                        <>
                            <Space direction="horizontal">
                                <ReactToPrint
                                    documentTitle={`Attendance Audit -  ${props.cohortInfo.cohortName} ${moment().format('YYYY-MM-DD hh_mm a')}`}
                                    trigger={() => {
                                        return (
                                            <Button type="primary" className={classes.buttonClass}>
                                                <Space direction="vertical" size={1}>
                                                    <div>Print</div>
                                                    <div>Attendance Audit</div>
                                                </Space>
                                            </Button>
                                        );
                                    }}
                                    content={() => ref.current}
                                />

                                {canCertificate ?
                                    <Button
                                        onClick={() => {
                                            getCertificates('all')
                                        }}
                                        type="primary"
                                        className={classes.buttonClass}>
                                        <Space direction="vertical" size={1}>
                                            <div>Create All {totalParticipants}</div>
                                            <div>Certificates</div>
                                        </Space>
                                    </Button> : ''
                                }
                                </Space>

                                <div style={{ display: 'none' }}>
                                    <div ref={ref}>
                                        {
                                            <AbsenceListToPrint
                                                cohortInfo={props.cohortInfo}
                                                dataSource={absenceData}
                                                columns={columns}
                                                className={classes.xSmallCellPaddingAudit}
                                                totalCertificates={totalCertificates}
                                                lastSessionDate={lastSessionDate}
                                                totalParticipants={totalParticipants}
                                                totalCanBeCertified={totalCanBeCertified}
                                            />
                                        }
                                    </div>
                                </div>
                            </>
                        </Col>
                        <Col span={12}>
                            <Row>
                                {canCertificate ?
                                    <Button
                                        onClick={() => {
                                            getCertificates()
                                        }}
                                        type="primary"
                                        disabled={totalCanBeCertified === 0}
                                        className={classes.buttonClass}>
                                        <Space direction="vertical" size={1}>
                                            <div>Create {totalCanBeCertified}</div>
                                            <div>Certificates</div>
                                        </Space>
                                    </Button> : participantsCanBeCertified?.length > 0 && <Text underline>Participants without absences:</Text>
                                }
                            </Row>
                            <Row>
                                {
                                    props.dataTable?.filter(el => {
                                        return (participantsCanBeCertified?.indexOf(el.key) !== -1) ?
                                            true : false
                                    }).map(el => `${el.firstName} ${el.lastName}`).join(", ")
                                }
                            </Row>
                        </Col>
                </Row>
                <Row><Divider /></Row>
                <Row>
                    <Col span={11}>
                        <Space direction="vertical">
                            <Alert message={`${totalCertificates} of these certificates were previously created. See the Attendance Export for details.`} type="error" />
                            {previousSessionUnit !== "" && <Alert message={`As of ${previousSessionDate}, all participants must complete units ${previousSessionUnit}.`} type="info" />}
                            <Alert message={`Of the ${totalParticipants} total participants, ${messageAbsent}`} type="info" />
                        </Space>
                    </Col>
                    <Col span={12} offset={1}>
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                            {pastEventsWithOutSave.filter(el => el.event_id !== 'orientation').length > 0 ?
                                'Attendance has not been saved yet for these past units or sessions:'
                                : null
                            }
                        </span>
                        <br />
                        {pastEventsWithOutSave.length > 0 ?
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: pastEventsWithOutSave.sort(function(a,b){
                                        return new Date(a.start_date) - new Date(b.start_date);
                                      }).map(el=> el.event_id !== 'orientation' ? `${el.event_id} on ${moment(el.start_date).format("MM-DD-YY")} - facilitator ${el.facilitators.flatMap(fac => `<a href='mailto:${fac.email}?subject=missing attendance for unit ${el.unit_id}'}>${fac.name}</a>`).join(',')} ${el.meeting?.name !== undefined ? `(${el.meeting?.name})` : ''}` : '').join('<br>')
                                }}
                            >
                            </span>
                            : null
                        }
                    </Col>
                </Row>
                <Row><Divider /></Row>
                <Row>
                    {showTable && <Table
                        dataSource={absenceData}
                        columns={columns}
                        pagination={false}
                        className={classes.xSmallCellPaddingAudit}
                        sticky
                    />
                    }
                </Row>
            </Spin>
        </Content>
    )
}

AttendanceAudit.defaultProps = {
    totalToCertificate: 0,
}

export default AttendanceAudit;

