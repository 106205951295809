import React, { useState } from "react";
import { Layout, Spin } from "antd";
import { pinStyle } from "../../Dashboard/Dashboard.style";
const { Content } = Layout;

const SharedAlps = () => {
    const [loadSharedAlps, setLoadSharedAlps] = useState(false);
    const { token,alp } = JSON.parse(localStorage.getItem("nisl-data"));
    let  jwt_alp  = alp.token
    const { REACT_APP_CMS_URL, REACT_APP_URL } = process.env;

    return (
        <Content className="site-layout-background layout-portal-content">
            <div className="layout-portal-main-container">
                {!loadSharedAlps ? (
                    <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
                ) : null}
                <iframe
                    src={`${REACT_APP_CMS_URL}/shared-alps/?token=${token}&alp=${jwt_alp}&redirect_app=${REACT_APP_URL}/shared-alps`}
                    frameBorder={"0"}
                    title="dashboard-cohort"
                    onLoad={() => setLoadSharedAlps(true)}
                    className="style-frame"
                />
            </div>
        </Content>
    );
};

export default SharedAlps;
