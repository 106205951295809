import React, { useEffect } from "react";
import { Form, Input, Modal, Row, Col } from "antd";

const CreateRoles = ({ visible, onCancel, onCreate, onEdit, edit, values }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(values);
    }, [form, values]);
    return (
        <Modal
            visible={visible}
            title="Create  new role"
            okText="Create"
            cancelText="Cancel"
            onCancel={onCancel}
            cancelButtonProps={{danger:true}}
            okButtonProps={{type:"primary"}}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        if (!edit) {
                            onCreate(values, form);
                        } else {
                            onEdit(values, form);
                        }
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
        >
            <Form form={form} name="role" layout="vertical">
                <Row gutter={[0, 10]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item name="id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input a role name",
                                },
                            ]}
                        >
                            <Input placeholder="Role name" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CreateRoles;
