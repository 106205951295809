import React from "react";
import "../../layouts/Login/login.less";
import { useHistory } from "react-router-dom";
import { Layout, Button, Row, Col, Form, Input, Modal } from "antd";
import imageLogo from "../../assets/img/logo.png";
import API from "../../api";
import {
    headerStyle,
    alertStyle,
    formNewPasswordStyle,
} from "./../../layouts/NewPassword/NewPassword.styles";

const { Header, Content } = Layout;
const { Item } = Form;

const onFinish = (values, history) => {
    console.log(values);
    API.post("api/forgot-password", values).then(({ data }) => {
        if (data.response === true) {
            history.replace("/login", {
                message: {
                    type: "success",
                    message: "Check your email for the confirmation link.",
                },
            });
        } else {
            Modal.error({
                title: "",
                content: data.error,
            })
        }
    });
};

const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
};

const rules = {
    username: [{ required: true, message: "Please input your username." }],
};

const NewPassword = () => {
    const history = useHistory();
    const handleDone = () => {
        history.replace("/login");
    };
    return (
        <Layout className="login-layout" style={{ height: "100%" }}>
            <Header
                style={{ ...headerStyle, textAlign: "center", background: "white" }}
            >
                <Button
                    onClick={handleDone}
                    style={{
                        left: "5px",
                        position: "absolute",
                        margin: "4px 0",
                    }}
                    type="primary"
                >
                    Cancel
                </Button>
                <span style={{ color: "#8a6d3b", fontWeight: "bold" }}>
                    FORGOT PASSWORD
                </span>
            </Header>
            <Row justify="center" align="middle" style={{ height: "100%" }}>
                <Col className="gutter-row" lg={8} md={12} xs={24} sm={16}>
                    <Content>
                        <Row justify="center">
                            <Col span={13}>
                                <img
                                    src={imageLogo}
                                    className="login-layout-logo"
                                    alt="login"
                                />
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={24}>
                                <div
                                    style={{
                                        ...alertStyle,
                                        height: "auto",
                                        padding: "10px 10px 5px 10px",
                                    }}
                                >
                                    <p>
                                        <strong>
                                            Please enter your username (which is
                                            typically your email address). Then
                                            press the Get New Password button.
                                            You will immediately receive a link
                                            via email. Click that link to create
                                            a new password.
                                        </strong>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Form
                                    style={formNewPasswordStyle}
                                    name="basic"
                                    onFinish={(values) =>
                                        onFinish(values, history)
                                    }
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Item
                                        name="username"
                                        rules={rules.username}
                                    >
                                        <Input placeholder="Username" />
                                    </Item>

                                    <Item>
                                        <Button htmlType="submit" ghost>
                                            Get New Password
                                        </Button>
                                    </Item>
                                </Form>
                            </Col>
                        </Row>
                    </Content>
                </Col>
            </Row>
        </Layout>
    );
};

export default NewPassword;
