/* eslint-disable */
import React from 'react';
import { Row, Col, Spin, Typography, Modal } from 'antd';
import { createUseStyles } from 'react-jss';
import { EXECUTIVE_COACHING } from '../../../../config/courses';
import { NOT_REQUIERD, UNASSIGNED } from '../../../../config/default_users';
const useStyles = createUseStyles({
    blueText: {
        color: "#0051a0",
    },
    blackText:{
        color: "black",
        paddingLeft: "4px",
        width: '7.1em',
    },
    center:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '6em',
    },
	link: {
		marginLeft: '8px'
	}
});

const ColaboratorsInfo = (props) => {
    let cofacilitatorsText = '';
    let cofacilitatorsCompletedText = '';
    let showCofacilitatorsModal = false;
    if (props.colaborators?.cofacilitators?.length >= 3) {
        showCofacilitatorsModal = true;
        cofacilitatorsText = props.colaborators?.cofacilitators.flatMap((cofac) => `${cofac.firstName} ${cofac.lastName}`).slice(0,3).join(", ").concat(' ...')
        cofacilitatorsCompletedText = props.colaborators?.cofacilitators.flatMap((cofac) => `${cofac.firstName} ${cofac.lastName}`).join(", ")
    }else{
        cofacilitatorsCompletedText = props.colaborators?.cofacilitators.flatMap((cofac) => `${cofac.firstName} ${cofac.lastName}`).join(", ")
    }

    let producers = props.colaborators?.producers.filter(v => v.email !== NOT_REQUIERD && v.email !== UNASSIGNED);
    let AlAdvisers = props.colaborators?.ALAdvise.filter(v => v.email !== NOT_REQUIERD && v.email !== UNASSIGNED);

    const classes = useStyles(props)
    const { Text } = Typography;

    return (!props.loading ? <>

        {parseInt(props.courseId) !== EXECUTIVE_COACHING ?
            <>
            <Row>
                <Col span={24} className={classes.blackText}>
                    Facilitator:&nbsp;
                    <span className={classes.blueText}>
                        {
                            props.colaborators?.facilitators?.map((el) => `${el.firstName} ${el.lastName}`).join(', ') || 'UNASSIGNED'
                        }
                    </span>
                    {props?.showUnitEvaluationLink && <>
                        <Text underline className={classes.link}>
                            <a target="_blank" rel="noopener noreferrer" onClick={props.showResponsesModal}>Unit Evaluation Report</a>
                        </Text>
                    </>}
                </Col>
            </Row>
            <Row>
                <Col span={24} className={classes.blackText}>
                    Co-Facilitator:&nbsp;
                    <span className={classes.blueText}>
                        {
                            showCofacilitatorsModal ?
                                <span style={{ cursor: 'pointer'}}
                                    onClick={() =>
                                        Modal.info({
                                            title: "",
                                            content: cofacilitatorsCompletedText
                                        })}>
                                    {cofacilitatorsText}
                                </span>
                                : <span>{cofacilitatorsCompletedText}</span>
                        }
                    </span>
                </Col>
            </Row>
                <Row>
                    { producers?.length === 0  ?
                        null :
                        <Col span={24} className={classes.blackText}>
                            Producer:&nbsp;
                            <span className={classes.blueText}>
                                {
                                    props.colaborators?.producers?.map((el) => `${el.firstName} ${el.lastName}`).join(', ')
                                }
                            </span>
                        </Col>
                    }
                </Row>
                <Row>
                    { AlAdvisers?.length === 0 ?
                        null :
                        <Col span={24} className={classes.blackText}>

                            AL Adviser:&nbsp;
                            <span className={classes.blueText}>
                                {
                                    props.colaborators?.ALAdvise?.map((el) => `${el.firstName} ${el.lastName}`).join(', ')
                                }
                            </span>
                        </Col>
                    }
                </Row>
            </> :
            <Row>
                <Col span={24} className={classes.blackText}>
                    {props.colaborators?.ALAdvise.length === 1 ? `Coach:` : `Coaches: `}
                    <span className={classes.blueText}>
                        {
                            props.colaborators?.ALAdvise?.map((el) => `${el.firstName} ${el.lastName}`).join(', ') || 'UNASSIGNED'
                        }
                    </span>
                </Col>
            </Row>}
    </> : <Spin spinning={true} className={classes.center} />
    )
}

export default ColaboratorsInfo;
