import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Row, Select, message, Form, Modal, Radio } from 'antd'
import API from "../../../../api";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createUseStyles } from "react-jss";

import moment from 'moment';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

const useStyles = createUseStyles({
    label: {
        paddingTop: '1em',
    }
});

const { useForm } = Form;

const ChangeDistrict = () => {
    const classes = useStyles();

    const BYCOHORT = 'byCohort';
    const BYDOMAIN = 'byDomain';

    const initialFormValues = { type_upload: BYCOHORT };

    const [form] = useForm();
    const [typeUpload, setTypeUpload] = useState(BYCOHORT);
    const [cohorts, setCohorts] = useState([]);
    const [disableDistrict, setDisableDistrict] = useState(true);
    const [districts, setDistricts] = useState();
    const [states, setStates] = useState();
    const [cohortSelected, setCohortSelected] = useState();
    const [districtSelected, setDistrictSelected] = useState();
    const [stateSelected, setStateSelected] = useState();
    const [feedbackParticipantData, setFeedbackParticipantData] = useState({});
    const [participantData, setParticipantData] = useState({});
    const [lastBatch, setLastBatch] = useState({});
    const [totalForBatch, setTotalForBatch] = useState(0);

    const getCohorts = () => {
        API.get("api/cohort").then(({ data }) => setCohorts(data));
    };

    const getStates = () => {
        API.get("api/getStates").then(({ data }) => setStates(data.states));
    };

    const saveChanges = (values) => {

        API.post(`api/saveChangeDistrict`, {
            values: values
        }).then(({ data }) => {

            message.destroy("save_changes");

            let contentMessage = "The accounts were updated. To see details of which accounts were modified, please download the log.";
            if (!data) {
                contentMessage = "The accounts were not updated. Error ocurred.";
            }
            getBatchData()
            Modal.confirm({
                title: contentMessage,
                onOk() {
                    reloadForm();
                }
            });

        }).catch();

    }

    const setType = ([field]) => {
        if (field) {
            if (field.name[0] === "type_upload") {
                removeDistrictSelected()
                setDisableDistrict(true)
                setDistricts([]);
                removeStateCohortDomain(field.value);

                if (field.value === BYDOMAIN) {
                    getStates();
                }

                setTypeUpload(field.value);
            }
        }
    }

    const handleVerifyDistrict = (type, value) => {

        removeDistrictSelected();

        setDisableDistrict(true)
        setDistricts();

        API.get("api/verifyDistrictsToChange", {
            params: {
                "type": type,
                "value": value
            }
        }).then(({ data }) => {
            setDisableDistrict(false)
            setDistricts(data);
        })
    }

    const removeDistrictSelected = () => {
        const values = form.getFieldsValue()
        form.setFieldsValue({ ...values, district: null })
    }

    const removeStateCohortDomain = (type) => {
        const values = form.getFieldsValue()
        if (type === BYCOHORT) {
            form.setFieldsValue({ ...values, cohort: null })
        } else {
            form.setFieldsValue({ ...values, state: null, domain: '' })
        }
    }

    const checkUsersAffected = () => {

        form.validateFields()
            .then((values) => {
                API.post("api/verifyUsersToChangeDistrict", {
                    "type": typeUpload,
                    "values": values
                }).then(({ data : {usersNothMatch, counter} }) => {
                    let contentMessage = ``;
                    let domainSelected = values.domain;

                    if (counter >= 1) {
                        if (typeUpload === BYCOHORT) {
                            contentMessage = (<>Are you sure you want to add District {districtSelected} to {counter} accounts in cohort {cohortSelected} that are currently missing a district?</>)
                        } else {
                            contentMessage = (<>Are you sure you want to add District {districtSelected} to {counter} accounts in {stateSelected} that have emails of {domainSelected} and that are currently missing the district?</>)
                        }

                        Modal.confirm({
                            width: '50%',
                            title: contentMessage,
                            content: (
                                <>
                                    {usersNothMatch >= 1 ? (
                                        <>
                                            <div style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.85)' }}>
                                                Note that {usersNothMatch} of these accounts
                                                will have their state changed
                                                because their state does not
                                                match {stateSelected}. You can see
                                                those account/state details on
                                                the ADD DISTRICT LOG.
                                            </div>
                                        </>
                                    ) : null}
                                </>
                            ),
                            icon: ( <ExclamationCircleOutlined style={{ color: "#ecd62a" }}/>),
                            onOk() {
                                message.loading({
                                    content: "Saving changes...",
                                    key: "save_changes",
                                    duration: 0,
                                });

                                saveChanges(values);
                            },
                        });

                    } else {
                        if (typeUpload === BYCOHORT) {
                            contentMessage = `There are no accounts with blank Districts in cohort ${cohortSelected}.`
                        } else {
                            contentMessage = `There are no ${stateSelected} accounts with blank Districts and with an email domain of ${domainSelected}.`
                        }

                        Modal.info({
                            title: "",
                            content: contentMessage,
                            onOk() {
                                reloadForm();
                            }
                        })
                    }
                })
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    }

    const downloadLog = () => {

        API.get(`api/export_district_changes_report`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const formatDate = moment(new Date()).format('YYYY-MM-DD hh_mm a');
            link.setAttribute("download", `Batch Add District ${formatDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    }

    const reloadForm = () => {
        if(typeUpload === BYDOMAIN){
            return form.resetFields(['district', 'domain']);
        }
        form.resetFields();
    }

    let getBatchData = () => {
        API.get("api/batchDistrictData").then(({ data : {feedback_data, participants_data, last_batch, total_for_batch} }) => {
            setFeedbackParticipantData({users_with_district: feedback_data.users_with_district, total_users: feedback_data.total_users, avg: feedback_data.avg})
            setParticipantData({users_with_district: participants_data.users_with_district, total_users: participants_data.total_users, avg: participants_data.avg})
            setLastBatch({name: last_batch.admin.name, updated_at: last_batch.updated_at})
            setTotalForBatch(total_for_batch)
        });
    }
    useEffect(() => {
        if (typeUpload === BYCOHORT && cohorts.length <= 0) {
            getCohorts();
        }
        getBatchData()
        // eslint-disable-next-line
    }, [typeUpload]);


    return (
        <>
            <Form initialValues={initialFormValues}
                form={form}
                layout="vertical"
                onFieldsChange={setType}
            >

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="type_upload"
                            label="Please select which type of batch process you want to use. You may add Districts to accounts that have blank District data. If the account has a District associated with it, that will not be modified when using this batch process."
                        >
                            <Radio.Group>
                                <Radio style={radioStyle} value={BYCOHORT}>
                                    By Cohort
                                </Radio>
                                <Radio style={radioStyle} value={BYDOMAIN}>
                                    By Email Domain
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {
                        typeUpload === BYCOHORT ?
                            <Col xs={24} md={6} lg={9}>
                                <Form.Item name="cohort" label="Cohort">

                                    <Select
                                        style={{
                                            width: "400px"
                                        }}
                                        showSearch
                                        options={cohorts}
                                        placeholder="Select a Cohort"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.label
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onChange={(value, item) => {
                                            setCohortSelected(item.label)
                                            handleVerifyDistrict(BYCOHORT, value);
                                        }}
                                    />

                                </Form.Item>
                            </Col> :
                            <>
                                <Col xs={24} md={6} lg={6}>
                                    <Form.Item name="state" label="State">
                                        <Select
                                            style={{
                                                width: "80%"
                                            }}
                                            showSearch
                                            options={states}
                                            placeholder="Select a State"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.label
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) === 0
                                            }
                                            onChange={(value, item) => {
                                                setStateSelected(item.label);
                                                handleVerifyDistrict(BYDOMAIN, value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={6}>
                                    <Form.Item name="domain" label="Domain"
                                        rules={[{ required: true, message: 'Please enter a domain.' }]}>
                                        <Input placeholder="@Domain" style={{ width: "90%" }} />
                                    </Form.Item>
                                </Col>
                            </>


                    }
                    <Col xs={24} md={6} lg={9}>
                        <Form.Item name="district" label="District"
                            rules={[{ required: true, message: 'You must select a District.' }]}>

                            <Select
                                style={{
                                    width: "400px"
                                }}
                                showSearch
                                options={districts}
                                placeholder="Select a District  "
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >=
                                    0
                                }
                                onChange={(value, item) => {
                                    setDistrictSelected(item.label);
                                }}

                                disabled={disableDistrict}
                            />

                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={2} lg={3}>
                        <Button type="primary" htmlType="button" onClick={checkUsersAffected}>
                            Add District
                        </Button>
                    </Col>
                    <Col xs={12} md={2} lg={3}>
                        <Button type="primary" htmlType="button" onClick={downloadLog}>
                            Download Log
                        </Button>
                    </Col>
                </Row>

                <Row className={classes.label}>
                    <Col xs={24}>
                        The Log that is downloaded will show a list of all batches run using ADD DISTRICT and all the accounts that were updated for each batch.
                    </Col>
                    <Col xs={24}>
                        Last batch run: {moment(lastBatch.updated_at).format("M/DD/YY hh:mm a")} ({lastBatch.name})
                    </Col>
                    <Col xs={24} style={{ marginTop: '20px' }}>
                        Districts added by batch so far: {totalForBatch?.toLocaleString()}
                        <br/>
                        Districts on Participant + Participant (SA) accounts: {participantData.users_with_district?.toLocaleString()} of {participantData.total_users?.toLocaleString()} total ({participantData.avg}%)
                        <br/>
                        Districts on Feedback Diagnostic accounts: {feedbackParticipantData.users_with_district?.toLocaleString()} of {feedbackParticipantData.total_users?.toLocaleString()} total ({feedbackParticipantData.avg}%)
                        <br/>
                        <p style={{ textIndent: '1em' }}>*does not include internal accounts, inactive accounts, or merged accounts</p>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ChangeDistrict
