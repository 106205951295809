import React, { useState, useEffect } from "react";
import {
    Form,
    Row,
    Col,
    Select,
    Radio,
    Button,
    Upload,
    Tooltip,
    Table,
    message,
    Modal
} from "antd";
import {
    UploadOutlined,
    QuestionCircleOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
} from "@ant-design/icons";
import API from "../../../../../api";
import { handleUpload } from "../../../../../functions/users/importUserCsv";
import { ModalCSVHelp } from "./ModalCSVHelp";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { useForm } = Form;

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

const columns = [
    {
        title: "",
        dataIndex: "success",
        key: "success",
        render: (text, record, index) => {
            if (text) {
                return (
                    <CheckCircleTwoTone
                        style={{ fontSize: "20px" }}
                        twoToneColor="#52c41a"
                    />
                );
            } else {
                return (
                    <CloseCircleTwoTone
                        style={{ fontSize: "20px" }}
                        twoToneColor="white"
                    />
                );
            }
        },
    },
    {
        title: "Username",
        dataIndex: "username",
        key: "username",
    },
    {
        title: "First name",
        dataIndex: "first_name",
        key: "first_name",
    },
    {
        title: "Last name",
        dataIndex: "last_name",
        key: "last_name",
    },
    {
        title: "Job title",
        dataIndex: "job_title",
        key: "job_title",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
    },
    {
        title: "District",
        dataIndex: "district",
        key: "district",
    },
    {
        title: "School",
        dataIndex: "school",
        key: "school",
    },
    {
        title: "Account Is",
        dataIndex: "message",
        key: "message",
    },
];

const getCourses = (setCourses) => {
    API.get("api/courses").then(({ data }) => {

        data = data.filter((option) => option.active).map(e => ({
            label: `(${e.number.replace("cohort", "")}) ${e.label}`,
            value: e.value
        }))

        setCourses(data)
    });
};

const getRoles = (setRoles) => {
    API.get("api/role").then(({ data }) => setRoles(data.roles_no_trash));
};

const getCohorts = (setCohorts) => {
    API.get("api/cohort").then(({ data }) => setCohorts(data));
};

const onFinish = (values, setData, setTotalRows, setTotalSuccess) => {
    setData([]);
    message.loading({
        content: "Uploading users...",
        key: "loading_csv",
        duration: 0,
    });
    handleUpload(values)
        .then(({ data }) => {
            message.destroy("loading_csv");
            if (data.response) {
                setData(Object.keys(data.csv).map((v) => {
                    return {
                        row: v,
                        success: data.csv[v].success,
                        ...data.csv[v].csv_data,
                        message: data.csv[v].message,
                    }
                }));

                //Show the pending users on a modal
                if (data.pendingUsers.length > 0) {

                    Modal.error({
                        icon: <ExclamationCircleOutlined />,
                        width: '50%',
                        content: (
                            <div>
                                {data.pendingUsers.length} of these records may be a duplicate account. This account/s is being flagged as Pending: <br />
                                {data.pendingUsers.join("\n")}
                                <br/><br/>
                                After investigating, you may manually change the account/s from Pending to Active. In the meanwhile, the Pending account/s cannot be added to a roster and cannot be logged into.
                            </div>),
                        onOk() {
                        }
                    });
                }

                setTotalRows(data.totalRows)
                setTotalSuccess(data.totalSave)
                const event = new Event("loadUsers");
                window.document.dispatchEvent(event);
            } else {
                const first_error = Object.keys(data.errors);
                Modal.warning({ content: data.errors[first_error][0] });
            }
        })
        .catch((err) => {
            console.log(err.response);
        });
};

const ImportCsv = () => {
    const [fileList, setFileList] = useState();
    const [courses, setCourses] = useState();
    const [roles, setRoles] = useState();
    const [cohorts, setCohorts] = useState();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [totalSuccess, setTotalSuccess] = useState(0);
    const [visibleHelp, setVisibleHelp] = useState(false);
    const [typeUpload, setTypeUpload] = useState("courses");
    const [initialFormValues, setInitialFormValues] = useState({
        csv_file: undefined,
        roles: [],
        privileges: [],
        send_credentials: false,
        send_updated_data: false,
        type_upload: "courses",
    })

    const [form] = useForm();



    const getDefaultCourse = () => {
        API.get("api/courses/get/default-course")
            .then(({ data }) => {
                let formValues = { ...initialFormValues, privileges: [Number(data.default_course)] }
                form.setFieldsValue(formValues)
                setInitialFormValues(formValues)
                API.get("api/role/get/default-role")
                    .then(({ data }) => {
                        form.setFieldsValue({ ...formValues, roles: [Number(data.default_role)] })
                        setInitialFormValues({ ...formValues, roles: [data.default_role] })
                    })
                    .catch(console.log);
            })
            .catch(console.log);
    }

    useEffect(() => {
        getCourses(setCourses);
        getRoles(setRoles);
        getCohorts(setCohorts);
        getDefaultCourse();
        // eslint-disable-next-line
    }, [setCourses]);

    const props = {
        beforeUpload: (file) => {
            setData([]);
            setFileList(file);
            return false;
        },
        accept: ".csv",
    };

    const setType = ([field]) => {
        if (field) {
            if (field.name[0] === "type_upload") {
                setTypeUpload(field.value);
            }
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form
                        layout="vertical" form={form}
                        onFinish={(values) => onFinish(values, setData, setTotalRows, setTotalSuccess)}
                        initialValues={initialFormValues} style={{ margin: "0 0 10px 0" }}
                        onFieldsChange={setType}
                    >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    name="type_upload"
                                    label="Please select which type of batch process you want to use. Please DO NOT include the cohort name in the CSV file. For new accounts that have precisely the same First+Last+State as an existing account, those are flagged Pending. After investigating, you may manually change the account from Pending to Active. In the meanwhile, the Pending account cannot be added to a roster and cannot be logged into."
                                >
                                    <Radio.Group>
                                        <Radio style={radioStyle} value={"courses"}>
                                            Add to an Existing User's Roles and Course Privileges
                                        </Radio>
                                        <Radio style={radioStyle} value={"cohorts"}>
                                            Enroll Users in a Pre-Existing Cohort
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col xs={24} md={8}>
                                <Form.Item name="roles" label="Role">
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        options={roles}
                                        placeholder="Select a Role"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.label
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            {
                                typeUpload === "cohorts" ?
                                    <Col xs={24} md={16}>
                                        <Form.Item name="cohorts" label="Cohort *the required course for this cohort will be automatically added and defaulted in the user's account">

                                            <Select
                                                style={{
                                                    width: "400px"
                                                }}
                                                showSearch
                                                options={cohorts}
                                                placeholder="Select a Cohort"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.label
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >=
                                                    0
                                                }
                                            />

                                        </Form.Item>
                                    </Col> :
                                    <Col xs={24} md={8}>
                                        <Form.Item name="privileges" label="Course Privileges">
                                            <Select
                                                mode="multiple" options={courses} showSearch
                                                placeholder="Select a Privilege" optionFilterProp="children"
                                                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            />
                                        </Form.Item>
                                    </Col>


                            }
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={6}>
                                <Form.Item
                                    name="csv_file"
                                    label={
                                        <span>
                                            CSV File&nbsp;
                                            <Tooltip title="Help?">
                                                <QuestionCircleOutlined
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setVisibleHelp(true);
                                                    }}
                                                    style={{
                                                        position: "absolute",
                                                        top: "5px",
                                                    }}
                                                />
                                            </Tooltip>
                                        </span>
                                    }
                                    valuePropName="file"
                                >
                                    <Upload {...props} showUploadList={false}>
                                        <Button>
                                            <UploadOutlined />{" "}
                                            {fileList
                                                ? fileList.name
                                                : "Click to choose file"}
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={24}>
                                <Button htmlType="submit" type="primary">
                                    Start importing
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            {
                data.length > 0 ?
                    (
                        <>
                            <Row justify="center">
                                <Col>
                                    <h5>
                                        {totalSuccess} out of {totalRows} accounts added Inserting and updating data
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Table
                                        columns={columns} rowKey="username" pagination={false} dataSource={data}
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : null
            }
            <ModalCSVHelp visible={visibleHelp} setVisible={setVisibleHelp} />
        </>
    );
};

export default ImportCsv;
