import React from 'react';
import CohortInfo  from '../../components/CohortInfo';
import { Table, Divider, Row, Col, Alert, Space } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    pageToPrint: {
        padding: '25px',
        alignText: 'center'
    },
    logo:{
        width: '100px',
        heigth: '100px',
    },
    blueBack:{
        backgroundColor:'#003263',
        padding: '9px',
        with: '100%',
    }
})

const AbsenceListToPrint = (props) => {
    const classes = useStyles();
    return <div className={classes.pageToPrint}>
        <Row>
            <Col align="center">
                <Space gutter={6}>
                    <Col justify="center" align="middle" className={classes.blueBack}>
                        <img src="/logo.png" alt="logo" className={classes.logo}/>
                    </Col>
                    <Col>
                    <Row><Alert message={`${props.totalCertificates} of these certificates were previously created. See the Attendance Export for details.`} type="error"/></Row>
                    <Row>{ props.previousSession && <Alert message={`As of ${props.previousSession} @  Et`} type="info"/> }</Row>
                    <Row><Alert message={`Of the ${props.totalParticipants} total participants, ${props.totalParticipants-props.totalCanBeCertified} participants were absent for one or more sessions, as show below.`} type="info"/></Row>
                    </Col>
                </Space>
            </Col>
        </Row>
        <Row><Divider/></Row>
        <Row><CohortInfo cohortInfo={props.cohortInfo}/></Row>
        <Row><Divider/></Row>
        <Row>
            <Col span={24}>
            {props.dataSource && <Table
                className={props.className}
                dataSource={props.dataSource}
                columns={props.columns}
                pagination={false}
            />}</Col>
        </Row>
    </div>
}

export default AbsenceListToPrint;
