import { loginFormStyle } from "./../Login/login.styles";
export const formNewPasswordStyle = {
    ...loginFormStyle,
    paddingTop: "20px",
};
export const headerStyle = {
    padding: "0 5px",
    height: "40px",
    lineHeight: "40px",
};
export const alertStyle = {
    margin: "14px 51px",
    height: "auto",
    backgroundColor: "#fcf8e3",
    borderRadius: "5px",
    //textAlign: "center",
    color: "#8a6d3b",
    borderColor: "#faebcc",
};
