import React from "react";

// Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

// Layouts
import Portal from "../layouts/Portal/Portal";
import Login from "../layouts/Login/Login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import NewPassword from "../layouts/NewPassword/NewPassword";
import API from "../api";

const RouterRoot = () => {
    API.get("api/keepalive").then( ({data, headers }) => {
        if (headers.maintenance) {
            window.location.href = "https://maintenance.ncee.org/";
        }
    })
    return (
        <>
            <Router>
                <Switch>
                    <Route path="/login/:msg?">
                        <Login />
                    </Route>
                    <Route path="/forgot-password">
                        <ForgotPassword />
                    </Route>
                    <Route path="/new-password">
                        <NewPassword />
                    </Route>
                    <Route path="/recover-password/:token">
                        <NewPassword recover={true}/>
                    </Route>
                    <PrivateRoute path="/">
                        <Portal />
                    </PrivateRoute>
                </Switch>
            </Router>
        </>
    );
};

export default RouterRoot;
