import React, { useState } from 'react';
import { Button, Col, Input, Row, Table } from 'antd';
import moment from 'moment-timezone/moment-timezone';
import _ from "lodash";
import { validate_page } from "../../../functions/ControlPages";
import FilterCohorBuilder from "./CohortBuilderFilters";
import { createUseStyles } from 'react-jss';
import { UNIQUE } from '../../../config/typeEvents';

const useStyles = createUseStyles({
    capitalize: {
        textTransform: "capitalize",
    },
});

const CohortList = ({ handleSearchInput, cohortData, setDisplayForm, setCurrentCohort, handleSearch, getDataPaginate, LoadingTable, setEdit, configsCohortBuilder, ready, setReady, setCohortId, visibleFilterCohortBuilder, setVisibleFilterCohortBuilder, filterSearchValues, textFilter, setTextFilter, textTotalCohorts, extraClassBtn, isAdmin, isCoach }) => {
    const classes = useStyles();
    moment.tz.add('America/New_York|EST EDT|50 40|0101|1Lz50 1zb0 Op0');

    let [eventTypes] = useState({
        "hours": { "name": "ext" },
        "two_day": { "two_day": "2_day" },
        "split": { "split": "n/a" }
    });

    const columns = [
        {
            title: "Title",
            dataIndex: "name",
            sorter: true,
            render(value, record) {
                const event = record.next_event ? record.next_event : record.previous_event ? record.previous_event : record.first_event;
                return <a target='_blank'  rel="noopener noreferrer" href={`dashboard/cohort/${record.id}/event/${event?.event_id}/unit/${event?.unit_id}/cohort/${record.postId}`}
                >{value}</a>
            }
        },
        {
            title: "Course",
            dataIndex: "course",
            sorter: true,
            width: 200,
            render: (value) => {
                if (value) {
                    if (value.name) {
                        return value.name;
                    } else {
                        return "n/a"
                    }
                } else {
                    return "n/a"
                }
            }
        },
        {
            title: "Delivery",
            dataIndex: "delivery_model",
            width: 130,
            render: (value) => {
                if (value) {
                    if (value.name) {
                        return value.name;
                    } else {
                        return "n/a"
                    }
                } else {
                    return "n/a"
                }
            }
        },
        {
            title: "Start",
            dataIndex: "first_event",
            sorter: true,
            align: "center",
            width: 130,
            render: (value) => {
                if (value == null) {
                    return (
                        <span> TBD </span>
                    )
                } else {
                    let time_convert = "";
                    time_convert = value?.start_time ? moment("2000-01-01 " + value.start_time).format("h:mm") : "";
                    return (<span>
                        {value.length === 0 ? "n/a" :
                            (value.start_date ? moment.tz(value.start_date, "America/New_York").format("YY-MM-DD") : "n/a")
                        }
                        <br />
                        {time_convert}
                    </span>
                    )
                }
            }
        },
        {
            title: "Next",
            dataIndex: "next_event",
            sorter: true,
            align: "center",
            width: 150,
            render: (value, row) => {
                if (!row.force_end) {
                    if (value == null) {
                        if (row.max_event?.start_date && moment(row.max_event.start_date).format('Y-MM-DD') < moment().format("Y-MM-DD")) {
                            return (
                                <span> Ended <br /> {moment(row.max_event.start_date).format('YY-MM-DD')}</span>
                            )
                        } else {
                            return (
                                <span> TBD </span>
                            )
                        }
                    } else {
                        let start_time_convert = value?.start_time ? moment("2000-01-01 " + value.start_time).format("h:mma") : "";
                        let end_time_convert = value?.end_time ? moment("2000-01-01 " + value.end_time).format("h:mma") : "";
                        return (
                            <span>
                                {value.event_id !== 'orientation' && 'Unit'} <span className={classes.capitalize}>{value.event_id}</span> {!isNaN(value.event_id) ? <>- day {value.day} </> : null}
                                {value.start_date ? moment.tz(value.start_date, "America/New_York").format("MM/DD") : "n/a"}
                                <br />
                                {start_time_convert ? <>{start_time_convert}-{end_time_convert}</> : null}
                            </span>
                        );
                    }
                } else {
                    if (row.max_event?.start_date && moment(row.max_event.start_date).format('Y-MM-DD') < moment().format("Y-MM-DD")) {
                        return (
                            <span> Force Ended <br /> {moment(row.max_event.start_date).format('YY-MM-DD')}</span>
                        )
                    } else {
                        return (
                            <span> Force Ended </span>
                        )
                    }
                }
            }
        },
        {
            title: "Participants",
            dataIndex: "participants",
            align: "center",
            width: "5%",
            render: (value) => {
                return value.length;
            }
        },
        {
            title: "Created",
            width: 130,
            align: "center",
            dataIndex: "created_at",
            sorter: true,
            defaultSortOrder: "descend",
            render: (value, record) => {
                if (value) {
                    return (<span>
                        {record.who_is_author?.last_name}
                        <br />
                        {moment(value).format("YY-MM-DD h:mm")}
                    </span>)
                } else {
                    return null;
                }
            }
        },
        {
            title: "Modified",
            sorter: true,
            width: 130,
            align: "center",
            defaultSortOrder: "descend",
            dataIndex: "updated_at",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {record.revisions[0]?.author?.last_name}
                            <br />
                            {moment(value).format("YY-MM-DD h:mm")}
                        </span>
                    );
                } else {
                    return null;
                }
            }
        },
        {
            title: "Action",
            align: "center",
            className: "column_without_padding table_action",
            render: (value, row) => (
                <Button type="primary" onClick={() => {
                    validate_page(`cohortbuilder/edit/${row.postId}`).then((next) => {
                        if (next) {
                            setReady(false)
                            setCurrentCohort({});
                            setEdit(true);
                            setCohortId(row.postId);
                            setDisplayForm(true);
                            let data_row = getDataRow(row);
                            setCurrentCohort(data_row);
                            setReady(true)
                        }
                    })

                }}>
                    Edit
                </Button>
            )
        },
    ];
    const getDataRow = (data) => {
        let orientation = [];
        let listsUnits = [];
        let userList = {};
        var cohort_schedule = [];
        var unitsCourse = data.course?.section_units?.flatMap(sub_course => { return sub_course?.units });
        var units_check = [];
        let start_date_cohort_value
        let end_date_cohort_value
        units_check = data.sub_courses?.split(",").map(v => v.substr(6)).map((v, i) => v - 1)

        if (data?.events) {
            // for is the id for orientation
            orientation = data.events.filter(v => v.unit_id === "orientation");
            if (orientation.length > 0) {

                orientation[0] = {
                    url: orientation[0].url_unit_evaluation,
                    end_date: orientation[0].end_date ? moment.tz(orientation[0].end_date, "America/New_York") : null,
                    event_type: "2_day",
                    start_date: orientation[0].start_date ? moment.tz(orientation[0].start_date, "America/New_York") : null,
                    start_time: orientation[0].start_time ? moment(moment().format("Y-M-D") + " " + orientation[0].start_time) : null,
                    end_time: orientation[0].end_time ? moment(moment().format("Y-M-D") + " " + orientation[0].end_time) : null,
                    meeting_type: orientation[0].meeting_type ? orientation[0].meeting_type : null
                };
            }
            let twoDays = data.events.filter(v => v.type_event.slug === "two_day");
            let split = data.events.filter(v => v.type_event.slug === "split");
            let ext = data.events.filter(v => v.type_event.slug === "hours");
            let unique = data.events.find(v => v.type_event.slug === UNIQUE);

            start_date_cohort_value = unique?.start_date ? moment.tz(unique?.start_date, "America/New_York") : null;
            end_date_cohort_value = unique?.end_date ? moment.tz(unique?.end_date, "America/New_York") : null;


            if (twoDays.length > 0) {
                twoDays = _.groupBy(twoDays, "unit_id");
                let units = Object.keys(twoDays)
                for (let i = 0; i < units.length; i++) {
                    const unit = units[i];
                    cohort_schedule[unit - 1] = {
                        unit,
                        ids_for_delete: [],
                        dates: twoDays[unit].flatMap(event => {
                            return (event.start_date !== null && event.start_date !== undefined) ? { date: moment(event.start_date).format("Y-MM-DD"), id: event.id } : ""
                        }),
                        url_unit_evaluation: twoDays[unit][0].url_unit_evaluation,
                        end_date: twoDays[unit][twoDays[unit].length - 1].end_date ? moment.tz(twoDays[unit][twoDays[unit].length - 1].end_date, "America/New_York") : null,
                        start_date: twoDays[unit][0].start_date ? moment.tz(twoDays[unit][0].start_date, "America/New_York") : null,
                        start_time: twoDays[unit][0].start_time ? moment(moment().format("Y-M-D") + " " + twoDays[unit][0].start_time) : null,
                        end_time: twoDays[unit][0].end_time ? moment(moment().format("Y-M-D") + " " + twoDays[unit][0].end_time) : null,
                        event_type: "2_day",
                        cohort_schedule_sub: [],
                        meeting_type: (twoDays[unit][0].meeting_type !== null) ? twoDays[unit][0].meeting_type : null
                    };
                }
            }
            if (split.length > 0) {
                split = _.groupBy(split, "unit_id");
                let units = Object.keys(split)
                for (let i = 0; i < units.length; i++) {
                    const unit = units[i];
                    cohort_schedule[unit - 1] = {
                        unit,
                        dates: split[unit].flatMap(event => moment(event.start_date).format("Y-MM-DD")),
                        url_unit_evaluation: split[unit][0].url_unit_evaluation,
                        end_date: split[unit][split[unit].length - 1].end_date ? moment.tz(split[unit][split[unit].length - 1].end_date, "America/New_York") : null,
                        start_date: split[unit][0].start_date ? moment.tz(split[unit][0].start_date, "America/New_York") : null,
                        start_time: split[unit][0].start_time ? moment(moment().format("Y-M-D") + " " + split[unit][0].start_time) : null,
                        end_time: split[unit][0].end_time ? moment(moment().format("Y-M-D") + " " + split[unit][0].end_time) : null,
                        event_type: "split",
                        cohort_schedule_sub: []
                    };
                }
            }

            if (ext.length > 0) {
                ext = _.groupBy(ext, "unit_id")
                let extUnits = Object.keys(ext);
                for (let i = 0; i < extUnits.length; i++) {
                    const unit = extUnits[i];
                    ext[unit].sort((a, b) => a.event_id.localeCompare(b.event_id))
                    cohort_schedule[unit - 1] = {
                        unit,
                        url_unit_evaluation: ext[unit][0].url_unit_evaluation,
                        event_type: "ext",
                        ids_for_delete: [],
                        dates: ext[unit].flatMap(event => {
                            return (event.start_date !== null && event.start_date !== undefined) ? { date: moment(event.start_date).format("Y-MM-DD"), id: event.id } : ""
                        }),
                        cohort_schedule_sub: ext[unit].map(v => (
                            {
                                id: v.id,
                                event_ext: v.event_id,
                                event_type_sub_unit: eventTypes[v.type_event.slug].name,
                                start_date_sub_unit: v.start_date ? moment.tz(v.start_date, "America/New_York") : null,
                                start_time_sub_unit: v.start_time ? moment(moment().format("Y-M-D") + " " + v.start_time) : null,
                                end_time_sub_unit: v.end_time ? moment(moment().format("Y-M-D") + " " + v.end_time) : null,
                                url_unit_evaluation_sub_unit: v.url_unit_evaluation ? v.url_unit_evaluation : null,
                                meeting_type_sub_unit: v.meeting_type ? v.meeting_type : null
                            }
                        ))
                    }
                }
            }

            let eventsFiltered = data.events.filter(v => v.user_units.length > 0);
            let units = [];
            eventsFiltered.forEach(v => {
                v.user_units.map(a => {
                    a.event = {
                        event_id: v.event_id,
                        unit_id: v.unit_id,
                        day: v.day,
                        type_event: v.type_event,
                        start_date: v.start_date,
                        end_date: v.end_date,
                    }

                    a.user = {
                        text: a?.user?.name + " (" + a?.user?.username + ")",
                        value: a?.user?.id
                    }
                    return a;
                });
                units.push(v.user_units);
            });
            let unitsFlat = units.flat()
            let unitsGroup = _.groupBy(unitsFlat, "type_user");
            listsUnits = {
                facilitators: _.groupBy(unitsGroup[configsCohortBuilder.types_user ? configsCohortBuilder.types_user.find(v => v.slug === "facilitator").id : 1], "user_id"),
                cofacilitators: _.groupBy(unitsGroup[configsCohortBuilder.types_user ? configsCohortBuilder.types_user.find(v => v.slug === "cofacilitator").id : 2], "user_id"),
                webinars: _.groupBy(unitsGroup[configsCohortBuilder.types_user ? configsCohortBuilder.types_user.find(v => v.slug === "webinar").id : 3], "user_id"),
                alp_coachs: _.groupBy(unitsGroup[configsCohortBuilder.types_user ? configsCohortBuilder.types_user.find(v => v.slug === "alp_coach").id : 4], "user_id"),
                qualities: _.groupBy(unitsGroup[configsCohortBuilder.types_user ? configsCohortBuilder.types_user.find(v => v.slug === "quality").id : 5], "user_id"),
            }

            Object.keys(listsUnits).forEach(v => {
                Object.keys(listsUnits[v]).forEach(b => {
                    if (!userList[v]) {
                        userList[v] = [];
                    }
                    userList[v] = [...userList[v], listsUnits[v][b][0].user];
                    if (v === "webinars") {
                        //cohort not is pss then return producers
                        if (!data.delivery_model?.is_edp_pss) {
                            listsUnits[v][b] = listsUnits[v][b].map(c => {
                                if (c.event.type_event.slug === "hours") {
                                    c = c.event.event_id
                                } else if (c.event.type_event.slug === "two_day" && c.event.start_date !== null) {
                                    c = c.event.unit_id + "-" + c.event.day
                                } else {
                                    c = c.event.unit_id;
                                }
                                return c;
                            });
                        } else {
                            listsUnits[v][b] = [];
                        }
                    } else if (v === "facilitators") {

                        if (data.divide_units) {
                            listsUnits[v][b] = listsUnits[v][b].map(c => {

                                if (c.event.type_event.slug === "hours") {
                                    c = c.event.event_id;
                                } else {
                                    c = `${c.event.unit_id}-${c.event.day}`;
                                }
                                return c;
                            });
                        } else {
                            listsUnits[v][b] = _.uniq(listsUnits[v][b].map(c => {
                                if (c.event.type_event.slug === "hours") {
                                    c = c.event.event_id;
                                } else {
                                    c = c.event.unit_id;
                                }
                                return c;
                            }));
                        }
                    } else if (v === "cofacilitators") {
                        listsUnits[v][b] = _.uniq(listsUnits[v][b].map(c => {
                            if (c.event.type_event.slug === "hours") {
                                c = c.event.event_id;
                            } else {
                                c = c.event.unit_id;
                            }
                            return c;
                        }));
                    }
                    else {
                        listsUnits[v][b] = listsUnits[v][b].map(c => {
                            c = c.event.unit_id;
                            return c;
                        });
                    }

                })
            })

        }

        for (let i = 0; i < unitsCourse?.length; i++) {
            if (!cohort_schedule[i]) {
                cohort_schedule[i] = { unit: i + 1, event_type: "2_day", meeting_type: null, ids_for_delete: [], dates: [], cohort_schedule_sub: [] };
            }
        }

        if (!data.delivery_model?.is_edp_pss) {
            data.qualities = [];
        }

        let orientation_value = [];
        if (orientation?.length > 0) {
            orientation_value = [{ ...orientation[0], event_type: "2_day", unit: "Orientation" }];
        }

        return {
            id: data.id,
            name: data?.name,
            course: data.course?.key,
            course_data: data.course,
            state: data.state?.code,
            delivery_model: data.delivery_model?.slug,
            city: data.city,
            district: data.district,
            contract: data.contract,
            has_suite: data.has_suite,
            collaboration: [data.communities ? "communities" : null, data.drive ? "nisl_drive" : null],
            link_folder: data.link_folder,
            material_status: data.material_status,
            welcome_email: data.welcome_email,
            force_end: [data.force_end ? "yes" : null],
            reason: (data.log_force_end[0]) ? data.log_force_end[0].reason_force_end : '',
            facilitators: Object.keys(listsUnits.facilitators).map(v => ({ id: Number(v), key: Number(v) })),
            cofacilitators: Object.keys(listsUnits.cofacilitators).map(v => ({ id: Number(v), key: Number(v) })),
            webinars: Object.keys(listsUnits.webinars).map(v => ({ id: Number(v), key: Number(v) })),
            alp_coachs: data.alp_coachs,
            qualities: data.qualities,
            participants: data.participants,
            webinar_url: data.meeting?.webinar_url,
            platform: data.meeting?.platform,
            meeting_id: data.meeting?.meeting_id,
            password_meeting: data.meeting?.password_meeting,
            cohort_schedule: cohort_schedule,
            orientation: orientation_value,
            listsUnits,
            userList,
            sub_courses: data.sub_courses?.split(",").map(v => v.substr(6)),
            default_subunits: data?.course?.section_units,
            arr_units_check: units_check,
            district_id: data.district_id,
            postId: data.postId,
            is_internal: [data.is_internal ? "is_internal" : null],
            force_end_log: data.log_force_end ? data.log_force_end[0] : null,
            start_date_cohort: data?.start_date_cohort ? moment.tz(data?.start_date_cohort, "America/New_York") : null, //start_date_cohort_value,
            end_date_cohort: data?.end_date_cohort ? moment.tz(data?.end_date_cohort, "America/New_York") : null, //end_date_cohort_value,
            divide_units: [data.divide_units ? "divide_units" : null],
        }

    }
    return (
        <>
            <Row gutter={[0, 10]} style={{display: isAdmin ? 'block' : 'none'}} >
                <Col xs={24} sm={12} md={12} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Button type="primary" onClick={async () => {
                        setDisplayForm(true);
                        setEdit(false);
                        let units = _.flatMap(configsCohortBuilder.default_subunits, 'units');
                        await setCurrentCohort({
                            name: "",
                            course: configsCohortBuilder.default_course,
                            state: "n/a",
                            delivery_model: null,
                            city: "",
                            district: "",
                            contract: "",
                            has_suite: "no",
                            collaboration: ["communities"],
                            link_folder: "",
                            material_status: "",
                            welcome_email: "",
                            force_end: [],
                            facilitators: [],
                            cofacilitators: [],
                            webinars: [],
                            alp_coachs: [],
                            qualities: [],
                            participants: [],
                            webinar_url: "",
                            platform: null,
                            meeting_id: "",
                            password_meeting: "",
                            cohort_schedule: units.map(v => ({ unit: v, event_type: "2_day", cohort_schedule_sub: [] })),
                            orientation: [{ event_type: "2_day", unit: "Orientation" }],
                            default_subunits: configsCohortBuilder?.default_subunits,
                            sub_courses: configsCohortBuilder?.default_courses?.value?.split(",").map(v => v.substr(6)),
                            is_internal: [],
                            force_end_log: null,
                            divide_units: []
                        });
                        await setReady(true);
                    }}>Add New</Button>
                </Col>
            </Row>
            <Row gutter={[0, 10]} justify="end">
                <Col xs={12} sm={6} md={6} lg={4} xl={4}  style={{display: isAdmin ? 'block' : 'none'}} >
                    <span style={{ marginTop: '10px', whiteSpace: 'nowrap', display: 'flex', margin: '10px 0px 0 auto', float: 'right' }}>
                        {textFilter}
                    </span>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={2} className='button-filter'  style={{display: isAdmin ? 'block' : 'none'}} >
                    <Button
                        type="primary"
                        className={`filter_btn ${extraClassBtn}`}
                        onClick={() => {
                            setVisibleFilterCohortBuilder(true)
                        }}
                    >
                        Filter
                    </Button>
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                    <Input.Search
                        allowClear
                        placeholder="Search"
                        onSearch={handleSearchInput}
                        style={{ width: "100%" }}
                    />
                </Col>

                <FilterCohorBuilder
                    visible={visibleFilterCohortBuilder}
                    handleSearch={handleSearch}
                    setVisibleFilterCohortBuilder={setVisibleFilterCohortBuilder}
                    filterSearchValues={filterSearchValues}
                    setTextFilter={setTextFilter}
                />

            </Row>
            <Row gutter={[0, 10]}
                style={{
                    textAlign: "left"
                }}>
                {textTotalCohorts}
            </Row>
            <Table
                //size="small"
                columns={columns}
                loading={LoadingTable}
                dataSource={cohortData.data}
                onChange={getDataPaginate}
                pagination={{
                    defaultPageSize: 50,
                    total: cohortData.total,
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}
            />
        </>
    );
}

export default CohortList
