import React from "react";
import { Modal, Form, Row, Col, Select, Radio, Button, Input } from "antd";
import RichTextEditor from "../../../components/RichTextEditor";

const { Option } = Select;

const FormPortalBanner = ({roles,edit,visible,handleOk,handleCancel,form,cohorts,textArea,setTextArea,valuesEdit}) => {

    return (
        <Modal
            title={edit ? "Update Banner" : "Add Banner"}
            centered
            width="70%"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
                <div>
                    {/* {edit ? (
                        <Button
                            type="primary"
                            danger
                            style={{ float: "left" }}

                        >
                            Delete
                        </Button>
                    ) : null} */}
                    <Button onClick={() => { handleCancel(); }}>
                        Cancel
                    </Button>
                    <Button onClick={() => { handleOk(); }} type="primary">
                        OK
                    </Button>
                </div>
            }
        >
            <Form layout="vertical" form={form}>
                <Form.Item name="id" noStyle>
                    <Input type="hidden"></Input>
                </Form.Item>
                <Row className="ant-form-item">
                    <Col span={24} className="ant-form-item-label">
                        <label>Text:</label>
                    </Col>
                    <Col span={24} className="ant-form-item-control banner_edit">
                        <RichTextEditor showPreview={false} value={textArea} setValue={setTextArea}/>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item name="display_to_roles" label="Select roles for display:">
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="All users"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {roles.map((v, i) => (
                                    <Option key={i} value={v.key}>
                                        {v.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item name="active" label="This Banner is:">
                            <Radio.Group>
                                <Radio value={true}>
                                    Activated
                                    </Radio>
                                <Radio value={false}>
                                    Not Activated
                                    </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default FormPortalBanner;
