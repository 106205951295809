export const TEACHER = { id: 1, label: 'Teacher' };
export const TEACHER_LEADER = { id: 2, label: 'Teacher-Leader' };
export const DEPARTMENT_HEAD = { id: 3, label: 'Department Head/ Department Chair' };
export const ASSISTANT_PRINCIPAL = { id: 4, label: 'Assistant/ Vice Principal' };
export const PRINCIPAL = { id: 5, label: 'Principal' };
export const PRINCIPAL_LEAD = { id: 6, label: 'Principal Lead/ Cluster Principal' };
export const ASSISTANT_SUPERINTENDENT = { id: 7, label: 'Assistant Superintendent' };
export const SUPERINTENDENT = { id: 8, label: 'Superintendent' };
export const DISTRICT_ADMINISTRATOR = { id: 9, label: 'District Administrator' };
export const OTHER = { id: 10, label: 'Other' };
