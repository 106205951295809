import React from 'react';
import { Table, Typography }  from 'antd';
import { createUseStyles } from "react-jss";
const { Text } = Typography;
const useStyles = createUseStyles({
    table: {
        '& td, th': {
            padding: '3px 3px !important',
            backgroundColor: 'white !important',
        },
        '& th': {
            fontWeight: "bold",
        },
        '& .ant-table-header':{
            overflow:'visible !important',
            boxShadow: '0px 3px 0px black',
        }
    },
});

const UsersRecords = ({...props}) => {
    const classes = useStyles();
    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
    ]
    return (
        <Table
            pagination={false}
            showHeader={false}
            className={classes.table}
            title={() => <Text strong>{props.title}</Text>}
            dataSource={props.dataSource}
            columns={columns}
        />
    )
}


export default UsersRecords;
