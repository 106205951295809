import React, { useEffect, useState } from "react";

import { Layout, Spin } from "antd";
import { pinStyle } from "../../Dashboard/Dashboard.style";
const { Content } = Layout;

const { REACT_APP_URL, REACT_APP_MODERN_SIGNAL_URL } = process.env;

const Diagnostics = () => {

    const [modernSignalToken, setModernSignalToken] = useState();
    const [loadModernSignal, setLoadModernSignal] = useState(false);
    const [urlIframe, setUrlIframe] = useState("");

    const { token_modern_signal, token } = JSON.parse(localStorage.getItem("nisl-data"));

    if(!modernSignalToken){
        setModernSignalToken(token_modern_signal);
    }

    useEffect(() => {
        setUrlIframe(`${REACT_APP_MODERN_SIGNAL_URL}/MyAccount/LoginViaJWT?token=${modernSignalToken}&returnURL=/Participant/Index&from=web&returnTo=${REACT_APP_URL}/diagnostics&token_ncee=${token}`);
    }, [modernSignalToken, token]);

    return (
        <Content className="site-layout-background layout-portal-content">
            {!loadModernSignal ? (
                <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
            ) : null}
            <div className="layout-portal-main-container">
                <iframe
                    src={urlIframe}
                    frameBorder={"0"}
                    onLoad={() => setLoadModernSignal(true)}
                    title="video-annotations"
                    className="style-frame"
                />
            </div>
        </Content>
    );
};

export default Diagnostics;
