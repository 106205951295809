import React, { useEffect, useState } from "react";
import { Select, AutoComplete, Modal, Button } from "antd";
import API from "../../../api";
import debounce from "lodash/debounce";
import _ from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";

let cancelTokenSource = axios.CancelToken.source();
const { Option } = Select;

const SearchCohort = ({handleChange, currentValue, setSearchValue, handleSelect, placeholder}) => {
    let [cohortSearch, setCohortSearch] = useState({
        data: [],
        value: [],
        fetching: false,
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    let [lastFetchId, setLastFetchId] = useState(0);

    const getCohorts = () => {
        API.get("/api/searchCohort")
            .then(({ data }) => {
                appendSearch(data, currentValue)
            })
            .catch(() => {});
    };

    let searchCohort = async (value) => {
        setLastFetchId(lastFetchId + 1);
        let searchCohort = API.get(`/api/searchCohort/${value}`, {
            cancelToken: cancelTokenSource.token
        });
        await searchCohort.then(({ data }) => {
            if (data.cohorts.length > 0) {
                appendSearch(data, currentValue)
                setIsModalVisible(false)
            } else {
                setCohortSearch({ data: [], fetching: false });
                setTimeout(() => {
                    setIsModalVisible(true)
                }, 500);
            }
        })
            .catch(() => { });
    };

    let appendSearch = (data, currentValue) =>{
        let result_cohorts = data.cohorts.filter(cohort => cohort.name !== currentValue).map((cohort) => ({
            text:   cohort.name,
            value:  cohort.name,
        }));

        let result_sorter = _.sortBy(result_cohorts, "value")
        setCohortSearch({ data: result_sorter, fetching: false });
    }

    searchCohort = debounce(searchCohort, 200);

    useEffect(() => {
        getCohorts();
    }, []);


    useEffect(() => {
        return () => {
            cancelTokenSource.cancel('Operation canceled  other search');
            cancelTokenSource = axios.CancelToken.source();
        }
      }, [currentValue])

    const handleCancel = () => {
        setIsModalVisible(false);
      };

    const { fetching } = cohortSearch;
    return (
        <>
            <Modal type="warning" onCancel={handleCancel} visible={isModalVisible} onOk={handleCancel}
                footer={[
                    <div>
                        <Button  type="primary" onClick={handleCancel}>OK</Button>
                    </div>
                ]}
            ><ExclamationCircleOutlined style={{ fontSize:'23px', marginRight:'20px' }}></ExclamationCircleOutlined><span>Cohort not found</span></Modal>
            <AutoComplete
                value={currentValue || null}
                showSearch
                allowClear
                placeholder={placeholder ? placeholder : 'Search'}
                notFoundContent={null}
                onSearch={searchCohort}
                onClear={handleChange}
                onSelect={handleSelect ? handleSelect : handleChange}
                onChange={(value)=>{setSearchValue(value)}}
                style={{ width: "100%" }}
            >
                {cohortSearch.data.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                    ))}
            </AutoComplete>
        </>
    );
};

export default SearchCohort;
