import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Row, Col, Select, Radio, DatePicker, Divider, Button, InputNumber, Checkbox} from "antd";
import API from "../../../api";
import moment from 'moment';

const FilterCohorBuilder = ({ visible, values, handleSearch, setVisibleFilterCohortBuilder, setTextFilter }) => {

    const [formFilter] = Form.useForm();
    const styLabels = {
        margin: '2px 0px',
        padding: '0px'
    };
    const styBodyModal = {
        paddingTop: '0px'
    };
    const styleRow = {
        paddingTop: "5px",
        paddingBottom: "5px"
    }

    const ENTER_KEY = 'Enter';
    const [courses, setCoursesForm] = useState();
    const [states, setStatesForm] = useState();
    const [deliveryModels, setDeliveryModelsForm] = useState();
    const [createdbyForm, setCreatedbyForm] = useState();
    const [modifyByForm, setModifyByForm] = useState();
    const [participantsForm, setParticipants] = useState();
    const [meetingTypes, setMeetingTypes] = useState();

    const getSelectOptions = () => {

        API.get("/api/get_cohort_builder_filters")
            .then(({ data }) => {
                setCoursesForm(data.courses);
                setStatesForm(data.states);
                setDeliveryModelsForm(data.deliveryModels);
                setMeetingTypes(data.meetingTypes)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getUserListFilter = (search, typeFilter) => {
        API.get(`api/getUserListFilter?search=${search}&typeFilter=${typeFilter}`).then(({ data }) => {

            if (typeFilter === "participant") {
                setParticipants(data);
            } else if (typeFilter === "whoCreate") {
                setCreatedbyForm(data);
            } else {
                setModifyByForm(data);
            }
        });
    }

    useEffect(() => {
        if (visible) {
            getSelectOptions();
            if (localStorage.getItem('filterCohortBuilder') == null) {
                getUserListFilter("", "participant");
                getUserListFilter("", "whoCreate");
                getUserListFilter("", "whoModify");
                setTextFilter("");
            } else {
                let filterValues = JSON.parse(localStorage.getItem('filterCohortBuilder'));

                filterValues.create_start = filterValues.create_start ? moment(filterValues.create_start) : "";
                filterValues.create_end = filterValues.create_end ? moment(filterValues.create_end) : "";
                filterValues.modify_at = filterValues.modify_at ? moment(filterValues.modify_at) : "";
                filterValues.modify_end = filterValues.modify_end ? moment(filterValues.modify_end) : "";
                filterValues.next_at = filterValues.next_at ? moment(filterValues.next_at) : "";
                filterValues.next_end = filterValues.next_end ? moment(filterValues.next_end) : "";
                filterValues.start_at = filterValues.start_at ? moment(filterValues.start_at) : "";
                filterValues.start_end = filterValues.start_end ? moment(filterValues.start_end) : "";

                getUserListFilter(filterValues.participant, "participant");
                getUserListFilter(filterValues.created_by, "whoCreate");
                getUserListFilter(filterValues.modify_by, "whoModify");

                formFilter.setFieldsValue(filterValues);
            }
        }
        // eslint-disable-next-line
    }, [visible]);

    const handlerFilter = () => {
        formFilter.validateFields()
            .then((values) => {
                setVisibleFilterCohortBuilder(false);
                handleSearch("nothing", values, formFilter);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    return (
        <Modal
            visible={visible}
            title={"Filter Cohort Builder List"}
            centered
            cancelText="Cancel"
            cancelButtonProps={{ danger: true }}
            onCancel={() => {
                setVisibleFilterCohortBuilder(false);
            }}
            width="92%"
            okButtonProps={{ type: "primary" }}
            bodyStyle={styBodyModal}
            footer={
                <div>
                    <Button
                        onClick={() => {
                            setVisibleFilterCohortBuilder(false);
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type="secundary"
                        onClick={() => {
                            formFilter.resetFields();
                            if (JSON.parse(localStorage.getItem('filterCohortBuilder')) != null) {
                                localStorage.removeItem('filterCohortBuilder');
                            }
                            setTextFilter("");
                            handlerFilter()
                        }}>
                        Clear
                    </Button>
                    <Button
                        type="primary"
                        onClick={handlerFilter}>
                        Done
                    </Button>
                </div>
            }
        >
            <Form
                onKeyUp={(e) => {
                    if (e.key === ENTER_KEY) {
                        handlerFilter()
                    }
                }}
                form={formFilter}
                style={{ display: "unset" }}
                name="formFilter" layout="inline">

                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={3} md={3}>
                        <Form.Item name="ID">
                            <Input placeholder="ID"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item
                            name="name"
                        >
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Form.Item name="city">
                            <Input placeholder="City" />
                        </Form.Item>
                    </Col>

                    <Col xs={21} sm={9} md={9} lg={5} xl={5}>
                        <Form.Item
                            name="states">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="State"
                                options={states}
                                optionFilterProp="children"
                                autoComplete="new-password"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row style={styleRow}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="course">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Course"
                                options={courses}
                                optionFilterProp="children"
                                autoComplete="new-password"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="delivery_model">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Delivery Model"
                                options={deliveryModels}
                                optionFilterProp="children"
                                autoComplete="new-password"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item name="contract">
                            <Input placeholder="Contract" />
                        </Form.Item>
                    </Col>

                </Row>

                <Row style={styleRow}>
                    <Col xs={24} md={8}>
                        <Form.Item name="has_suite" label={"Client has GSuite?"}>
                            <Radio.Group>
                                <Radio value="yes">Yes</Radio>
                                <Radio value="no">No</Radio>
                                <Radio value="some">Some Participants</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={16}>
                        <Form.Item name={"is_internal"} label={"Internal?"}>
                            <Checkbox.Group>
                                <Checkbox value="is_internal"></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={styleRow}>
                    <Col xs={24} md={12}>
                        <Form.Item name="materials">
                            <Input placeholder="Materials" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            name="meeting_types">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Meeting types"
                                options={meetingTypes}
                                optionFilterProp="children"
                                autoComplete="new-password"
                                filterOption={(input, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) === 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left" plain>
                    Participants info
                </Divider>

                <Row gutter={[0, 10]} align="middle">
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="participant">
                            <Select
                                showSearch
                                placeholder="Participant"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                onInputKeyDown={(e) => getUserListFilter(e.target.value, 'participant')}
                                options={participantsForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={2} md={2}>
                        Total articipants:
                    </Col>
                    <Col xs={2} sm={2} md={2}>
                        <Form.Item name="count_start">
                            <InputNumber min={0} max={100} placeholder="from" />
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3}>
                        <Form.Item name="count_end">
                            <InputNumber min={0} max={100} placeholder="to" />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left" style={styLabels} plain>
                    Events info
                </Divider>

                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="start_at">
                            <DatePicker
                                placeholder="Events start from [date]"
                                format={"yyyy-MM-DD"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="start_end">
                            <DatePicker
                                placeholder="Events start to [date]"
                                format={"yyyy-MM-DD"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="next_at">
                            <DatePicker
                                placeholder="Next event start from [date]"
                                format={"yyyy-MM-DD"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="next_end">
                            <DatePicker
                                placeholder="Next event start to [date]"
                                format={"yyyy-MM-DD"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left" style={styLabels} plain>
                    Create info
                </Divider>

                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="created_by">
                            <Select
                                showSearch
                                placeholder="Cohort created By"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                onInputKeyDown={(e) => getUserListFilter(e.target.value, 'whoCreate')}
                                options={createdbyForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="create_start">
                            <DatePicker
                                placeholder="Cohort created from [date]"
                                format={"yyyy-MM-DD"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="create_end">
                            <DatePicker
                                placeholder="Cohort created to [date]"
                                format={"yyyy-MM-DD"}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left" style={styLabels} plain>
                    Modify info
                </Divider>

                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="modify_by">
                            <Select
                                showSearch
                                placeholder="Cohort modified By"
                                optionFilterProp="children"
                                autoComplete="new-password"
                                onInputKeyDown={(e) => getUserListFilter(e.target.value, 'whoModify')}
                                options={modifyByForm}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="modify_at">
                            <DatePicker
                                placeholder="Cohort modified from [date]"
                                format={"yyyy-MM-DD"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                        <Form.Item name="modify_end">
                            <DatePicker
                                placeholder="Cohort modified to [date]"
                                format={"yyyy-MM-DD"}
                                style={{ width: "100%" }} /
                            >
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default FilterCohorBuilder;
