import React from "react";
import { Table, Space, Button, Switch } from "antd";
import moment from "moment";

const ListParticipantNotifications = ({ participantNotifications, deleteParticipantNotification, editParticipantNotification, setEdit }) => {
    const columns = [
        {
            title: "Title",
            dataIndex: "headline",
            sorter: (a, b) => {
                if (a.headline > b.headline) {
                    return 1;
                }
                if (a.headline < b.headline) {
                    return -1;
                }
                return 0;
            },
        },
        {
            title: "Created",
            key: "created_at",
            dataIndex: "created_at",
            width: 120,
            align: "center",
            className : "column_without_padding",
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {record.who_created.last_name} <br /> {moment(value).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Status",
            key: "status",
            width: 120,
            dataIndex: "active",
            render: (text, record) =>
                <>
                    <Switch
                        onClick={() => {
                            deleteParticipantNotification(record.id);
                        }}
                        defaultChecked={record.active}
                        checked={record.active}
                    />
                </>

        },
        {
            title: "Activated",
            key: "activated",
            width: 120,
            dataIndex: "who_activated",
            align: "center",
            className : "column_without_padding",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {value.last_name}
                            <br />
                            {moment(record.activated_at).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "De-Activated",
            key: "who_deactivated",
            width: 120,
            dataIndex: "who_deactivated",
            align: "center",
            className : "column_without_padding",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {value.last_name}
                            <br />
                            {moment(record.de_activated_at).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Modified",
            key: "who_updated",
            dataIndex: "who_updated",
            width: 120,
            defaultSortOrder: "descend",
            align: "center",
            className : "column_without_padding",
            sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
            render: (value, record) => {
                if(value){
                    return (
                        <span>
                            {value.last_name}
                            <br />
                            {moment(record.updated_at).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                }else{
                    return null;
                }
            }
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            className :"column_without_padding table_action",
            render: (text, record) => (
                <Space size="small">
                    <Button
                        type="primary"
                        onClick={() => {
                            let copy_record = JSON.parse(JSON.stringify(record));
                            setEdit(true)
                            editParticipantNotification(copy_record);
                        }}>
                        Edit
                    </Button>
                </Space>
            )
        },
    ];

    return (
        <>
            <Table size="small"  columns={columns} dataSource={participantNotifications} rowKey="id" />
        </>
    );
};

export default ListParticipantNotifications;
