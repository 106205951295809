import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Typography } from "antd";
const { Paragraph} = Typography;

const HeaderTitle = ({ user }) => {
    
    return (
        <div>
            <Row gutter={[400, 0]}>
                <Col className='username-info' xs={10} sm={10} md={10} lg={10} xl={10} style={{ fontWeight: "bold", color: "#313777", fontSize: "20px" }}>
                    {user?.honorific} {user?.first_name} {user?.last_name}
                </Col>
                <Col className='district-info' span={9} style={{textAlign: "right", fontWeight: "bold", paddingRight: "0px !important", paddingLeft: "0px !important", color: "#313777"}}>
                    <Paragraph ellipsis={true} style={{ fontWeight: "bold", color: "#313777" }}>{user?.district_data?.name ? ` ${user?.district_data?.name},` : ''} &nbsp;{user?.state_living?.code} </Paragraph>
                </Col>

            </Row>
        </div>
    )
}

HeaderTitle.propTypes = {
    user: PropTypes.object
}

export default HeaderTitle