import {useRef} from 'react';

export const useChunk = () => {

    const next = useRef(0);
    let validateRequest = useRef([]);
    let from = useRef("today");
    let last_load = useRef(0);

    let increment_next = ()=>{
        next.current += 1;
    }

    let reset_next = ()=>{
        next.current = 0;
    }

    let add_validate = (value)=>{
        validateRequest.current.push(value);
    }

    let reset_validate = ()=>{
        validateRequest.current=[];
    }

    let get_next = ()=>{
        return next.current;
    }

    let validate_request = (value)=>{
        return validateRequest.current.includes(value);
    }

    let calls = ()=>{
        return validateRequest.current;
    }

    let change_from = (value) =>{
        from.current = value;
    }

    let return_from = () =>{
        return from.current;
    }

    let set_last_load = (value) =>{
        last_load.current = value
    }

    let get_last_load = () =>{
        return last_load.current;
    }

    let reset_last_load = () =>{
        last_load.current=0;
    }

    return {
        increment_next,
        reset_next,
        add_validate,
        reset_validate,
        get_next,
        validate_request,
        change_from,
        return_from,
        calls,
        set_last_load,
        get_last_load,
        reset_last_load
    }
};
