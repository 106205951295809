import React from 'react';

import {Table,Row,Col} from 'antd';

const Materials = ({course_material,cohort_materials}) => {

    const columns_material = [
        {
          title: 'ISBN',
          width : "20%",
          render: (row)=> {
            if(row.length === 1 || row.length === 0) {
                if(row.length === 0) {
                    return {
                        children: <span>
                            &nbsp;
                        </span>,
                        props: {
                            colSpan: 2,
                        },
                    }
                }else {
                    return {
                        children: <span>
                            {row[0]}
                        </span>,
                        props: {
                            colSpan: 2,
                        },
                    }
                }
            }else{
                return (
                    <span>
                        {row[0]}
                    </span>
                )
            }
        }
        },
        {
          title: 'Kit item description',
          width : "95%",
          render: (row)=> {
            if(row.length === 1 || row.length === 0) {
                return {
                   children: null,
                    props: {
                        colSpan: 0,
                    },
                }
            }else{
                return (
                    <span>
                        {row[1]}
                    </span>
                )
            }
            }
        }
    ];

    return (
        <Row>

            { cohort_materials && cohort_materials !== null ?
            (
                <span>{cohort_materials}</span>
            )
              : ""
            }

            { course_material && course_material !== null ?
            (
                <>
                    <Col span={24} className="title_course_materials">
                      <span>The participant kits include the following</span>
                    </Col>
                    <Col span={24}>
                        <Table style={{float:"left", width : "100%" }} className={"table_materials"} bordered={true} pagination={false} columns={columns_material} dataSource={course_material} />
                    </Col>
                </>
              )
              : ""
            }
        </Row>
    );
};

export default Materials;
