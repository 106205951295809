import React, { useState } from "react";
import { Spin } from "antd";
import { pinStyle } from "../Dashboard/Dashboard.style";

const WebBasedResources = () => {
    let [loadWebBasedResources, setLoadWebBasedResources] = useState(false);
    const { REACT_APP_CMS_URL } = process.env;
    const { token } = JSON.parse(localStorage.getItem("nisl-data"));
    return (
        <>
            {!loadWebBasedResources ? (
                <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
            ) : null}
            <iframe
                src={`${REACT_APP_CMS_URL}/nisl_pages/foundations-web-based-resources/?token=${token}&from_app=no&needs_a_handle=no`}
                frameBorder={"0"}
                title="dashboard-cohort"
                className="style-frame"
                onLoad={() => {
                    setLoadWebBasedResources(true);
                }}
            />
        </>
    );
};

export default WebBasedResources;
