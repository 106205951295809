export const DEFAULT_OPTION_FEEDBACK = 3;

export const DIAGNOSTICS_OPTIONS_FILTER = [
    { "value": 1, "label": "Omit Feedback Diagnostics accounts" },
    { "value": 2, "label": "Feedback Diagnostics accounts only" },
    { "value": 3, "label": "All accounts incl. Feedback Diagnostics" }
];

export const FILTER_STATUS = [
    { value: "active", label: "Active", default: true },
    { value: "inactive", label: "Inactive", default: true },
    { value: "merged", label: "Merged", default: true },
    { value: "pending", label: "Pending", default: true },
];
