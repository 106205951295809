import React, { useEffect, useState, useRef } from "react";
import { Table, Row, Space, Input, Col, Button, Radio, Form, Modal, message } from "antd";
import moment from "moment";
import API from "../../../../api";
import { loginWith } from "../../../../functions/loginWith";
import { createUseStyles } from "react-jss";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const useStyles = createUseStyles({
    filterSelector: {
        width: '90%',
    },
    label: {
        paddingTop: '0.1em',
    }
});

const UserMergeLogs = () => {
    const [activeReverse, setActiveReverse] = useState(false);

    const columns = [
        {
            title: "Secondary ID",
            dataIndex: "old_cms_id",
            rowKey: "old_cms_id",
        },
        {
            title: "Secondary (deleted) Username",
            dataIndex: "old_username",
            rowKey: "old_username",
        },
        {
            title: "Secondary State",
            dataIndex: "old_state",
            rowKey: "old_state",
        },
        {
            title: "",
            dataIndex: "reverse",
            rowKey: "reverse",
            width: '5em',
            align: "center",
            hidden: { activeReverse },
            render: (value, record) => {
                return (
                    <Form.Item
                        style={{ marginBottom: "0px" }}
                        name="same"
                        onClick={() => {
                            handleReverse(record);
                        }}
                    >
                        <Radio value={record.id}></Radio>
                    </Form.Item>
                )
            }
        },
        {
            title: "Primary ID",
            dataIndex: "new_id",
            rowKey: "new_id",
            render: (value, record) => {
                if (record?.primary_account?.nisl_id) {
                    return (
                        <span>
                            {record?.primary_account?.nisl_id}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Primary (remaining) Username",
            dataIndex: "new_username",
            rowKey: "new_username",
            render: (value, record) => {
                if (record?.primary_account?.username) {
                    if (record.primary_account.roles.some(item => item.slug === "senior_admin" || item.slug === "administrator")) {
                        return record.primary_account.username
                    } else {
                        return (
                            <button className={"link-button"}
                                style={{ color: "#0051a0" }}
                                onClick={() => { loginWith(record.primary_account.id) }}>
                                {record?.primary_account?.username}
                            </button>
                        )
                    }
                } else {
                    return null;
                }
            }
        },
        {
            title: "Primary State",
            dataIndex: "new_state",
            rowKey: "new_state",
            render: (value, record) => {
                if (record?.primary_account?.state_living) {
                    return (
                        <span>
                            {record?.primary_account?.state_living?.code}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Name",
            dataIndex: "name",
            rowKey: "name",
            render: (value, record) => {
                if (record?.primary_account?.first_name) {
                    return (
                        <span>
                            {record?.primary_account?.first_name}  {record?.primary_account?.last_name}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: " Merged",
            dataIndex: "created_at",
            rowKey: "created_at",
            align: "center",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {record?.who_merged?.last_name} <br /> {moment(value).format("YY-MM-DD H:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        }
    ];

    const classes = useStyles();
    const [valueSearch, setValueSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const [textTotalLogs, setTextTotalLogs] = useState();

    const [loadingTable, setLoadingTable] = useState(false);

    const [dataMergedLogs, setDataMergedLogs] = useState([]);

    const [columnsTable, setColumnsTable] = useState();
    const [visible, setVisible] = useState(false);
    const [secondaryUsername, setSecondaryUsername] = useState();
    const [primaryUsername, setPrimaryUsername] = useState();
    const [idReverse, setIdReverse] = useState();
    const [disableButton, setDisableButton] = useState(false);
    const [primaryEmail, setPrimaryEmail] = useState();
    const [secondaryEmail, setSecondaryEmail] = useState();

    const getMergedLogs = (search = null, pageCurrent = null, pageSize = null) => {
        let searchText = search != null ? search : valueSearch;

        if (pageCurrent != null) {
            setCurrentPage(pageCurrent);
        }

        API.get(`/api/getUserMergeLogs?search=${searchText}&page=${pageCurrent}&per_page=${pageSize}`).then(({ data }) => {
            setColumnsTable(columns.filter(item => !item.hidden))
            setDataMergedLogs(data.logs)
            setLoadingTable(false);
            setTextTotalLogs(data.logs.total.toLocaleString() + ' of ' + data.totalLogs.toLocaleString() + ' merged accounts')
        });
    };

    useEffect(() => {
        setLoadingTable(true)
        getMergedLogs();
    }, []);

    let handleSearchInput = (valueSearch) => {
        setLoadingTable(true);
        setValueSearch(valueSearch);
        getMergedLogs(valueSearch);
    };

    let getDataPaginate = (page, filters = {}, sorter = {}, extra = {}) => {
        getMergedLogs(null, page.current, page.pageSize);
    };

    let activeReverseOption = () => {
        setColumnsTable(columns);
        setActiveReverse(true);
    }

    let handleReverse = (values) => {
        setVisible(true)
        setSecondaryUsername(values.old_account?.username);
        setPrimaryUsername(values.primary_account.username);

        setSecondaryEmail(values.old_account?.email);
        setPrimaryEmail(values.primary_account.email);
        setIdReverse(values.id);
    }

    let handleCancel = () => {
        setVisible(false);
        setPrimaryUsername("");
        setSecondaryUsername("");
        setPrimaryEmail("");
        setSecondaryEmail("");
        setIdReverse("");
        setActiveReverse(false);
        setColumnsTable(columns.filter(item => !item.hidden))
    }

    let handleReverMerged = (idRegister) => {
        setDisableButton(true);
        message.loading({
            content: "Saving changes...",
            key: "changing_usernames",
            duration: 0,
        });

        API.post(`api/reverse_merged_username`, {
            idRegister: idRegister
        }).then(({ data }) => {
            message.destroy("changing_usernames");
            setDisableButton(false);

            let contentMessage = "The usernames and emails were changed.";
            if (data.error) {
                contentMessage = "The usernames and emails were not changed. Error ocurred.";
            }

            Modal.confirm({
                title: contentMessage,
                onOk() {
                    getMergedLogs();
                    setActiveReverse(false)
                }
            });

            setVisible(false);
        }).catch();
    }

    return (
        <>
            <Row>
                <Col xs={24} sm={6}>
                </Col>
                <Col xs={24} sm={12} style={{ textAlign: "center" }}>
                    <Space direction="horizontal">

                        {!activeReverse ?
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        activeReverseOption();
                                    }}
                                >
                                    Reverse
                                </Button>
                                <div>
                                    Click here to select a merged account for which we need to<br />reverse the Secondary and Primary Username and Email.
                                </div>
                            </>
                            : <div>
                                Select wich merged account to reverse the Secondary and Primary Username and Email.
                                <br />The Secondary Username and Email will become the Primary Username and Email. And vice versa.
                            </div>
                        }
                    </Space>

                </Col>

                <Col xs={24} sm={6}>
                    <Row justify="end">
                        <Input.Search
                            allowClear
                            placeholder="Search"
                            className={classes.filterSelector}
                            onSearch={handleSearchInput}
                        />
                    </Row>
                </Col>

            </Row>
            <Row className={classes.label}>
                {textTotalLogs}
            </Row>

            <Table columns={columnsTable}
                onChange={getDataPaginate}
                rowClassName={() => "responsive-row"}
                pagination={{
                    current: currentPage,
                    defaultPageSize: 50,
                    total: dataMergedLogs.total,
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}
                loading={loadingTable}
                dataSource={dataMergedLogs.data} />

            <Modal
                visible={visible}
                closable={false}
                keyboard={false}
                maskClosable={false}
                cancelText="Cancel"
                onCancel={handleCancel}
                cancelButtonProps={{ danger: true, disabled: disableButton }}
                okButtonProps={{ type: "primary", disabled: disableButton }}
                onOk={() => {
                    handleReverMerged(idReverse);
                }}
            >
                The Secondary username will switch to {primaryUsername} and the Primary username will switch to {secondaryUsername}.<br /><br />
                The Secondary email will switch to {primaryEmail} and the Primary email will switch to {secondaryEmail}.
            </Modal>
        </>
    );
}

export default UserMergeLogs;
