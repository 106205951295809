import React, { useEffect, useState } from "react";
import ListRoles from "./ListRoles";
import CreateRoles from "./Create";
import API from "../../../../api";
import { Button, Space, Modal } from "antd";

const RolePage = () => {
    const [visibleFormRoles, setVisibleFormRoles] = useState(false);
    const [formRolesEdit, setFormRolesEdit] = useState(false);
    const [formRolesValues, setFormRolesValues] = useState({});
    const [roles, setRoles] = useState([]);

    const handleCreate = (values, form) => {
        API.post("/api/role", values)
            .then(({ data }) => {
                setVisibleFormRoles(false);
                form.resetFields();
                setRoles([
                    ...roles,
                    { key: data.role.id, name: data.role.name },
                ]);
                Modal.success({ content: "role saved succefully" });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleEditRole = (values, form) => {
        API.put("/api/role/" + values.id, values)
            .then(({ data }) => {
                form.resetFields();
                setVisibleFormRoles(false);
                setRoles(
                    roles.map((v) => {
                        if (v.key === data.role.id) {
                            v.name = data.role.name;
                        }
                        return v;
                    })
                );
                Modal.success({ content: "role saved succefully" });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handledDeleteRole = (id, text) => {
        API.delete(`api/role/${id}`).then(({ data }) => {
            let new_roles = getRoles();
            setRoles(new_roles);
            Modal.error({ content: "role deleted" });
            console.log(data);
        });
    };

    const handleEdit = (values) => {
        setVisibleFormRoles(true);
        setFormRolesEdit(true);
        setFormRolesValues(values);
    };

    const getRoles = () => {
        API.get("/api/role").then(({ data }) => setRoles(data.roles));
    };
    useEffect(() => {
        getRoles();
    }, []);
    return (
        <>
            <Space direction="vertical">
                <Button
                    type="primary"
                    onClick={() => {
                        setFormRolesEdit(false);
                        setVisibleFormRoles(true);
                    }}
                >
                    Add Role
                </Button>
                <CreateRoles
                    onCancel={() => {
                        setVisibleFormRoles(false);
                    }}
                    onCreate={handleCreate}
                    onEdit={handleEditRole}
                    visible={visibleFormRoles}
                    edit={formRolesEdit}
                    values={formRolesValues}
                />
            </Space>
            <ListRoles
                roles={roles}
                deleteRole={handledDeleteRole}
                editRole={handleEdit}
            />
        </>
    );
};

export default RolePage;
