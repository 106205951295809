import React, { useEffect, useState } from "react";
import { Layout, Spin } from "antd";
import { pinStyle } from "../../Dashboard/Dashboard.style";
import { getAccessVeo } from "../../../functions/getAccessVeo";
const { Content } = Layout;

const VideoAnnotations = () => {
    const [veoUrl, setVeoUrl] = useState();
    const [loadVideoAnnotations, setLoadVideoAnnotations] = useState(false);
    useEffect(() => {
        getAccessVeo().then((res) => setVeoUrl(res));
    }, []);
    return (
        <Content className="site-layout-background layout-portal-content">
            <div className="layout-portal-main-container">
                {!loadVideoAnnotations ? (
                    <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
                ) : null}
                <iframe
                    src={veoUrl}
                    frameBorder={"0"}
                    onLoad={() => setLoadVideoAnnotations(true)}
                    title="video-annotations"
                    className="style-frame"
                />
            </div>
        </Content>
    );
};

export default VideoAnnotations;
