import React, { useState, useEffect } from "react";
import { Row, Button, Form } from "antd";
import FormPortalBanner from "./FormPortalBanner";
import ListsBanner from "./ListsBanner";
import API from "../../../api";

const PortalBanner = () => {
    const [form] = Form.useForm();
    const [edit, setEdit] = useState(false);
    const [roles, setRoles] = useState([]);
    const [visible, setVisible] = useState(false);
    const [cohorts, setCohorts] = useState([]);
    const [textArea, setTextArea ] = useState("")
    const [banners, setBanners ] = useState("")
    const [valuesEdit, setValuesEdit ] = useState([])

    const getCohorts = () => {
        API.get("api/cohort").then(({ data }) => setCohorts(data));
    };

    useEffect(() => {
        getCohorts();
        API.get("/api/role").then(({ data }) => setRoles(data.roles));
    }, []);

    const handleOk = () =>{
        setVisible(false);
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                values.text = textArea;
                if (edit) {
                    handleUpdate(values);
                } else {
                    handleCreate(values);
                }
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }


    const handleCreate = (values) => {
        API.post("api/logon-popup?type=portal_banner", values)
        .then(({ data }) => getBanners())
        .catch(({ response: { data } }) => { console.log(data) })
    }

    const handleUpdate = (values) => {
        API.put(`api/logon-popup/${values.id}?type=portal_banner`, values)
            .then(({ data }) => getBanners())
            .catch(({ response: { data } }) => { console.log(data) })
    }

    const handleEdit = (values)=>{
        setVisible(true);
        setEdit(true)
        setTextArea(values.text)
        let edit_values = {
            ...values,
            display_to_roles : values.roles.map(v => {
                return v.id;
            }),
                display_to_cohorts : values.cohorts.map(v => {
                return v.id;
            })
        }
        form.setFieldsValue(edit_values)
        setValuesEdit(edit_values)
    }

    const handleCancel = () =>{
        setVisible(false);
    }

    const handleDelete = (id) => {
        API.delete(`api/logon-popup/${id}?type=portal_banner`).then(({ data }) => getBanners()).catch(console.log);
    }

    const getBanners = () => API.get("/api/logon-popup?type=portal_banner")
    .then(({ data }) => setBanners(data))
    .catch(console.log);

    useEffect(()=>{
        getBanners();
    },[])
    return (
        <>
            <Row>
                <Button
                    type="primary"
                    onClick={() => {
                        form.setFieldsValue({
                            headline: "",
                            display_to_roles: [],
                            display_to_cohorts: [],
                            active: false
                        });
                        setTextArea("");
                        setEdit(false);
                        setVisible(true);
                    }}
                >
                    Add New Portal Banner
                </Button>
            </Row>
            <br />
            <ListsBanner ObjectBanners={banners} handleEdit={handleEdit} handleDelete={handleDelete}  />

            <FormPortalBanner
                roles={roles}
                edit={edit}
                visible={visible}
                handleOk={handleOk}
                handleCancel={handleCancel}
                form={form}
                cohorts={cohorts}
                textArea={textArea}
                setTextArea={setTextArea}
                valuesEdit={valuesEdit}
            />
        </>
    );
};
export default PortalBanner;
