/* eslint-disable no-mixed-operators */
import React from "react";
import { Table, Space, Button } from "antd";
import "./users.less";
import { loginWith } from "../../../../functions/loginWith";
import { validate_page } from "../../../../functions/ControlPages";

const ListUsers = ({ users, editUser, getDataPaginate, LoadingTable }) => {
    const columns = [
        {
            title: "Username",
            dataIndex: "username",
            width: 120,
            rowKey: "username",
            render(value, record) {
                if (record.status === "active") {
                    if (record.roles.some(item => item.slug === "senior_admin" || item.slug === "administrator")) {
                        return value
                    }else{
                        return <button className={"link-button"} style={{ color: "#0051a0" }} onClick={() => { loginWith(record.id) }}>{value}</button>
                    }
                } else {
                    return value
                }
            }
        },
        {
            title: "Name",
            dataIndex: "name",
            width: "10%",
            rowKey: "name",
        },
        {
            title: "Privs",
            dataIndex: "roles",
            rowKey: "roles",
            width: "15%",
            render: (text, record) => {
                if (record.roles.length <= 0) {
                    return <div style={{color: "red"}}> [blank] </div>;
                }else{
                    return record.roles.map((role) => role.name).join(", ");
                }
            }
        },
        {
            title: "Cohorts",
            dataIndex: "cohorts",
            rowKey: "cohorts",
            width: "20%",
            render: (text, record) => {
                let test_size = '';
                if (record.cohorts.length > 5) {
                    test_size = '...';
                }
                let cohorts = record.cohorts.slice(0, 5)
                return <>{cohorts.map((cohort, i) => {
                    const event = cohort.next_event ? cohort.next_event : cohort.previous_event ? cohort.previous_event : cohort.first_event;
                    return <>
                        <a target='blank' el="noopener noreferrer" href={`dashboard/cohort/${cohort.id}/event/${event?.event_id}/unit/${event?.unit_id}/cohort/${cohort.postId}`}
                        >
                            {cohort.name}
                        </a>
                        {(i + 1) !== cohorts.length && ", "}
                    </>
                })}{test_size}</>
            }
        },
        {
            title: "Course Bookmark",
            dataIndex: "default_course",
            rowKey: "default_course",
            width: "15%",
            render: (text, record) => {
                if (record.default_course_data !== null && record.default_course_data !== undefined) {
                    return record.default_course_data.name
                }
            }
        },
        {
            title: "Created",
            dataIndex: "created_at",
            width: "10%",
            rowKey: "created_at",
            padding: 10,
            sorter: true,
            defaultSortOrder: "descend",
            align: "center",
            className: "column_without_padding",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {record?.who_created?.last_name} <br /> {value}
                        </span>
                    )
                } else {
                    return null;
                }
            }

        },
        {
            title: "Modified",
            dataIndex: "updated_at",
            width: "10%",
            rowKey: "updated_at",
            padding: 10,
            sorter: true,
            defaultSortOrder: "descend",
            align: "center",
            className: "column_without_padding",
            render: (value, record) => {
                return (
                    <span>
                        {record?.who_updated?.last_name} <br /> {value}
                    </span>
                )
            }

        },
        {
            title: "Action",
            rowKey: "action",
            fixed: "right",
            align: "center",
            className: "column_without_padding table_action",
            render(text, record) {
                if (record.status !== "merged") {
                    return <Space size="middle">
                        <Button
                            type="primary"
                            onClick={() => {
                                validate_page(`user/edit/${record.id}`).then((next) => {
                                    if (next) {
                                        editUser(record);
                                    }
                                })

                            }}
                        >
                            Edit
                        </Button>
                    </Space>
                } else {
                    return <>Merged</>
                }
            },
        },
    ];

    return (
        <div>
            {/* <TableResponsive columnsDefinition={columns}>
                {(renderExpandRow, validateHiddenColumns) => ( */}
            <Table
                className="table_users"
                loading={LoadingTable}
                columns={columns}
                dataSource={users.data}
                rowClassName={() => "responsive-row"}
                onChange={getDataPaginate}
                // expandedRowRender={renderExpandRow}
                // expandable={{
                //     expandedRowRender: renderExpandRow,
                //     rowExpandable: (record) => {
                //         return validateHiddenColumns;
                //     },
                // }}
                pagination={{
                    defaultPageSize: 50,
                    total: users.total,
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}
            />
            {/* )}
            </TableResponsive> */}
        </div>
    );
};

export default ListUsers;
