import React, { useState, useEffect } from "react";
import { Table, Modal } from "antd";
import API from "../../../api";

const CalendarLegend = ({ modalVisible, setModalVisible }) => {

    const columns = [
        {
            title: "Color",
            dataIndex: "color",
            width: '20em',
            rowKey: "id",
            render: (value, record) => {
                return <div style={{ backgroundColor: record.background_color, color: record.text_color, textAlign: "center" }}>Text Color</div>
            }
        },
        {
            title: "Group Name",
            dataIndex: "name",
            width: '120em',
            rowKey: "name",
            render: (value, record) => {
                return value;
            }
        },
    ];

    const [courseGroups, setCourseGroups] = useState();

    const showModalLegends = () => {
        if (!courseGroups) {
            API.get(`api/get-course-groups/`).then(({ data }) => {
                setCourseGroups(data.courseGroups)
            });
        }
    }

    useEffect(() => {
        if (modalVisible === true) {
            showModalLegends()
        }
    }, [modalVisible])

    return (
        <Modal visible={modalVisible}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setModalVisible(false)}>
            <Table
                className="table_course_groups"
                columns={columns}
                dataSource={courseGroups}
                pagination={false}
                rowClassName={() => "responsive-row"}
            />
        </Modal>
    );
}

export default CalendarLegend;
