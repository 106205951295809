import React, { useState } from 'react';
import { Form, Button, Input, Alert, Select } from "antd";
const { Option } = Select;

const SupportTabForm = (props) => {
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);
    const { form, name, onFinish, hasAccess } = props;
    const access = ['All users', 'All users except Participant and Superintendents','Admins only'];
    return (
        <>
        <Form
            form={form}
            name={name}
            onFinish={onFinish}
            labelCol={{ span: 4 }}
            colon={false}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            scrollToFirstError
        >

            <Form.Item
                name="tab"
                label="Tab"
                initialValue={props.tabName}
                rules={[
                    {
                        required: true,
                        message: 'tab'
                    }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="has_access"
                label="Access"
                initialValue={hasAccess}
                rules={[
                    {
                        required: true,
                        message: 'tab'
                    }
                ]}
            >
                <Select
                    showSearch
                    placeholder="Choose access"
                    optionFilterProp="children"
                >
                {
                    access.map((option) => {
                        return  <Option key={option} value={option}>{ option }</Option>
                    })
                }
                </Select>
            </Form.Item>
        </Form>
        {
            errorMessage && <Alert
                message={errorMessage}
                type="error"
                showIcon
                closable
                action={
                    <Button size="small" danger onClick={() => {setErrorMessage(props.errorException)}}>
                        Detail
                    </Button>
                }
                onClose={() => setErrorMessage(false)}
                />

        }
    </>);
}
export default SupportTabForm;
