import React, { useEffect, useState, useContext } from "react";
import { Layout, Spin } from "antd";
import { useParams } from "react-router-dom";
import { pinStyle } from "../Dashboard/Dashboard.style";
import { sharedWithMeInfo } from "./../../functions/sharedProjects"
import { getInfoEvent } from "./../../functions/getInfoEvent"
import API from "../../api";
import { ParticipantDashboardContext } from "../../Hooks/Context/ParticipantDashboardContext";
import { getAlpToken } from "../../functions/getAlpToken";
import { getInfoCoursesType } from "./../../functions/getInfoCoursesType"

const { Content } = Layout;

const Course = () => {

    let { course } = useParams();
    const [cohort, setCohort] = useState(course);
    const [loadCourse, setLoadCourse] = useState(false);
    const [applyTransaction, setApplyTransaction] = useState(false);
    const { setInfoMeeting, setShowInfoMeeting, setMessageInfoNextEvent, setInfoNextEvent, setCourse } = useContext(ParticipantDashboardContext)
    const { disabilities, token, token_modern_signal, roles_un } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_URL, REACT_APP_PORTAL_URL } = process.env;

    const [disabilitesToSend, setDisabilitiesToSend] = useState();

    useEffect(() => {
        //Add the disabilite in case the facilitator can't see the FG notes because he/she is only EPD facilitator
        API.get(`api/getCourseInfoByKey`, {
            params: {
                courseKey: course
            }
        }).then(({ data }) => {
            let courseId = data.courseInfo.id

            getInfoCoursesType()
                .then(({ data }) => {

                    let admins = roles_un.includes('administrator') || roles_un.includes('senior_admin');
                    let isFacilitator = roles_un.includes('facilitator_national') || roles_un.includes('facilitator_local_inc');
                    console.log(data.nislCourses)
                    console.log(courseId)
                    if (data.nislCourses.includes(courseId) && (!isFacilitator && !admins)) {
                        disabilities.push("lesson-guidelines");
                        console.log("enter")
                        setDisabilitiesToSend(disabilities);
                    }else{
                        let remove_disability = disabilities.includes("lesson-guidelines") || disabilities.includes("supplemental-notes");
                        if(remove_disability) {
                            setDisabilitiesToSend(disabilities.filter(disability => disability !== 'lesson-guidelines' && disability !== 'supplemental-notes'));
                        }else{
                            setDisabilitiesToSend(disabilities)
                        }
                    }
                });
        });

        setCohort(course);
        setLoadCourse(false);
        let nislData = JSON.parse(localStorage.getItem("nisl-data"));
        let userID = nislData.userID;
        nislData = JSON.stringify({ ...nislData, cohort: course });
        localStorage.setItem("nisl-data", nislData);

        //All the courses >= 39 will apply transition style
        if (parseInt(course.replace("cohort", "")) >= 39) {
            setApplyTransaction(true);
        } else {
            setApplyTransaction(false);
        }

        getInfoEvent(course)
            .then(({ data }) => {
                if (data.text !== null && data.text !== "") {
                    setInfoNextEvent(true);
                    setMessageInfoNextEvent(data);
                }

                if (data.meeting_info.webinar_url !== "" && data.meeting_info.webinar_url !== undefined) {
                    setShowInfoMeeting(true);
                    setInfoMeeting(data.meeting_info);
                }
            })
            .catch((error) => {
                console.log(error)
            });

        getAlpToken(userID, course)
            .then(() => { })
            .catch(() => { });

        nislData = JSON.parse(localStorage.getItem("nisl-data"));
        sharedWithMeInfo(nislData.token, course);
        setCourse(course)
        // eslint-disable-next-line
    }, [course]);

    return (
        <>
            <Content className="site-layout-background layout-portal-content">
                <div className="layout-portal-main-container">
                    {!loadCourse ? (
                        <Spin
                            tip="Loading..."
                            size="large"
                            style={pinStyle}
                        ></Spin>
                    ) : null}

                    {/* {disabilitesToSend ? */}
                        <iframe
                            src={`${REACT_APP_PORTAL_URL}/?view=courses_ncee&course=${cohort}&roles=${roles_un}&disabilities=${disabilitesToSend}&redirect_course=${REACT_APP_URL}/course/${course}&token=${token}&diagnostics=${token_modern_signal}&apply_transiction=${applyTransaction}`}
                            frameBorder={"0"}
                            onLoad={() => setLoadCourse(true)}
                            title="dashboard-cohort"
                            className="style-frame"
                        />
                   {/*      : <></>
                    } */}
                </div>
            </Content>
        </>
    );
};

export default Course;
