import React from 'react';
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    container: props => ({
        width: '100%',
        height: '100%',
        flexWrap: 'wrap',
        backgroundColor: !props.border ? props.color : 'white',
        border: !props.border ? '' : `3px solid ${props.color}`,
        borderRadius: '2px',
        color: props.border ? props.color : 'black',
        fontSize: '11px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })
});

const FormatedCell = ({children, ...props}) => {
    const classes = useStyles(props);
    return (
        <div className={classes.container}>
            {children}
        </div>
    )
}

FormatedCell.defaultProps = {
    color: "white",
  }

export default FormatedCell;

/* OPTIONS AND COLORS
<FormatedCell color="#237804">Y</FormatedCell>
<FormatedCell color="#f5222d">N</FormatedCell>
<FormatedCell color="#237804" border={true}>I/S</FormatedCell>
<FormatedCell color="#f5222d" border={true}>I/S</FormatedCell>
<FormatedCell color="#d48806" border={true}>Y(M)</FormatedCell>
<FormatedCell color="#00474f" border={true}>Y</FormatedCell>
<FormatedCell color="#595959" border={false}></FormatedCell>
<FormatedCell color="#595959" border={false}>Y</FormatedCell>
<FormatedCell color="#595959" border={false}>N</FormatedCell>
*/
