import React, { useState, useEffect } from "react";
import Avatar from "../../components/Avatar";
import { Layout, Row, Col, Form, Input, Button, Select, Modal, Radio } from "antd";
import { getStates } from "../../functions/getStates";
import API from "../../api";
import { dispatchStorageEvent } from "../../functions/dispatchStorageEvent";
import { useHistory } from "react-router-dom";
import "./profile.less"
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Districts from "../../components/Districts";
import Schools from "../../components/Schools";
import { createPhoneMask } from "../../functions/createPhoneMask";
import MaskedInput from 'antd-mask-input';
import { TEACHER, ASSISTANT_PRINCIPAL, PRINCIPAL, ASSISTANT_SUPERINTENDENT, SUPERINTENDENT, TEACHER_LEADER } from "../../config/JobFunctions";

const { confirm } = Modal;

const { Content } = Layout;
const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 20,
    },
};

const Profile = ({ fromVerifyFirstTime, cancelEditFirstTime, requiredData }) => {

    const [form] = Form.useForm();
    const history = useHistory();
    const [states, setStates] = useState([]);
    const [ui, setUis] = useState();
    const [showUi, setShowUi] = useState(false);
    const [jobFunctions, setJobFunctions] = useState([]);
    const [emailAssign, setEmailAssign] = useState(JSON.parse(localStorage.getItem("user-data")).email_assign);
    const [job_function] = useState(JSON.parse(localStorage.getItem("user-data")).job_function);
    const [existsProfileImage, setExistsProfileImage] = useState(false);
    const [changeForm, setChangeForm] = useState(false);
    const [originalEmail] = useState(JSON.parse(localStorage.getItem("user-data")).email);
    const [changeEmail, setChangeEmail] = useState(false);
    const [stateId, setStateId] = useState(JSON.parse(localStorage.getItem("user-data")).state_id);
    const [district, setDistrict] = useState(JSON.parse(localStorage.getItem("user-data")).district_id ? JSON.parse(localStorage.getItem("user-data")).district_id : null);
    const [school, setSchool] = useState(JSON.parse(localStorage.getItem("user-data")).school ? JSON.parse(localStorage.getItem("user-data")).school : null);

    const onFinish = (values) => {
        updateProfile(values, history);
    };

    const [styleColInfo, setStyleColInfo] = useState({ paddingTop: "1px" });
    const styleTextInfo = { fontSize: "13px", color: "#2f51a0" };
    let formItemStyle = { marginBottom: "2px" }

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
            setIsMobile(true);
            setStyleColInfo({ paddingTop: "8px" })
        } else {
            setIsMobile(false);
            setStyleColInfo({ paddingTop: "1px" })
        }
        // eslint-disable-next-line
    }, [navigator?.userAgent]);

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("user-data"));
        data.state_id = "";
        data.job_function = "";
        data.district = "";
        data.school = "";
        form.setFieldsValue(data);

        getStates()
            .then(({ data: { states } }) => {
                setStates(states);
                let id_pa = "";

                states.forEach(element => {
                    if (element.code === "PA") {
                        id_pa = element.id;
                    }
                });

                if (id_pa === stateId) {
                    setShowUi(true)
                }

                let values = form.getFieldsValue()
                values.state_id = stateId;
                form.setFieldsValue(values)
            })
            .catch((e) => {
                console.log(e);
            });
        getJobFunctions(job_function)

        let ui_values = Array(29).fill(1).flatMap((v, k) => ({ value: k + 1, label: k + 1 }))
        ui_values.unshift({ value: "not_affiliated", label: "not affiliated with an IU" });
        setUis(ui_values)
        //eslint-disable-next-line
    }, [form]);

    let getJobFunctions = (selected_value) => {
        API.get("api/job_functions").then(({ data }) => {
            setJobFunctions(data)
            let values = form.getFieldsValue()

            if (selected_value) {
                values.job_function = selected_value;
                form.setFieldsValue(values)
            } else {
                formatJobFunction(values.job_title);
            }
        })
    }

    let redirectForm = () => {
        let url_redirect = localStorage.getItem("lastPageVisited");
        history.push(url_redirect || "/");
        history.go(0)
    }


    const updateProfile = (user, history) => {
        if (changeForm || requiredData) {

            user.fromVerifyFirstTime = fromVerifyFirstTime;
            user.required_data = !requiredData;
            API.put("api/user/" + user.id, user)
                .then(({ data }) => {
                    localStorage.setItem("user-data", JSON.stringify(data.user));
                    form.setFieldsValue(data.user)
                    dispatchStorageEvent();

                    if (data.all?.fromVerifyFirstTime === true) {
                        localStorage.removeItem("first_login");
                    }

                    Modal.success({
                        content: "Profile updated succesfully",
                        onOk: () => {
                            redirectForm()
                        },
                        okButtonProps: { type: "primary" }
                    });
                })
                .catch((err) => {
                    console.log(err);
                });

        } else {
            redirectForm()
        }
    };

    const rules = {
        password: [{ required: true, message: "Please input your password." }],
        repeat_password: [
            { required: true, message: "Please confirm your password." },
            ({ getFieldValue }) => ({
                validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        "The two passwords that you entered do not match."
                    );
                },
            }),
        ],
    };

    const handleUpdateJobTitle = e => {
        const { value } = e.target;
        formatJobFunction(value);
    }

    const formatJobFunction = (value) => {

        if (value.length >= 5) {

            let valuesform = form.getFieldsValue();

            let regexAsSuper = /^(ass|v).*(sup).*/i;
            let regexAsPrin = /^(ass|v).*(prin).*/i;
            let regexSuperintendent = /^sup.*t$/i;
            let regexPrincipal = /^pr.*l$/i;
            let regexTeacher = /.*teach.*/i;

            if (regexAsSuper.test(value)) {
                valuesform.job_function = ASSISTANT_SUPERINTENDENT.id;
            }
            if (regexAsPrin.test(value)) {
                valuesform.job_function = ASSISTANT_PRINCIPAL.id;
            }
            if (regexSuperintendent.test(value)) {
                valuesform.job_function = SUPERINTENDENT.id;
            }
            if (regexPrincipal.test(value)) {
                valuesform.job_function = PRINCIPAL.id;
            }
            if (regexTeacher.test(value)) {
                valuesform.job_function = TEACHER.id;
            }

            form.setFieldsValue(valuesform);
        }
    }

    return (
        <Content className="site-layout-background layout-portal-content">

            <Form
                initialValues={{
                    "phone": createPhoneMask(JSON.parse(localStorage.getItem("user-data"))?.phone)
                }}
                {...layout}
                autoComplete="off"
                name="profile"
                form={form}
                colon={false}
                onFieldsChange={() => {
                    setChangeForm(true)
                }}
            >

                <Row
                    justify="center"
                    style={{ height: "100%", padding: "15px 0" }}
                >
                    <Col xs={24} md={23} >
                        {!fromVerifyFirstTime ?
                            <Row justify="center" style={{ paddingTop: "15px", paddingBottom: "20px" }}>
                                <Col>
                                    {requiredData ? <span style={{ color: 'red', fontWeight: "bold" }}>Please review your profile information. Correct any information that is incorrect or add any information that is missing. All the fields in the profile are mandatory.</span> :
                                        <span style={{ color: "rgb(179, 147, 54)", fontWeight: "bold", alignself: "flex-end", padding: "5px" }}>Profile</span>
                                    }
                                </Col>
                            </Row>

                            : <></>
                        }

                        {fromVerifyFirstTime ? (
                            <>
                                <Row
                                    justify="center"
                                    style={{ padding: "2px 0" }}>
                                    <Col xs={24} md={24}>
                                        <div style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>Please set a new password, add a profile photo (optional), and review your profile information.
                                            Correct any information that is incorrect. All the fields in the profile are mandatory.</div>
                                    </Col>
                                </Row>
                                <Row justify="center" style={{ padding: "5px 0px 5px 0px" }} >
                                    <Col>
                                        <span style={{ margin: "15px 0", fontSize: "14px" }}>
                                            Username: {form.getFieldValue("username")}
                                        </span>
                                    </Col>
                                </Row>
                            </>) : <></>
                        }

                        <Row gutter={[0, 0]} align="middle">
                            {fromVerifyFirstTime ?
                                <Col lg={12} xs={24}  >
                                    <Form.Item name={"password"} label="New Password" style={formItemStyle}
                                        rules={rules.password}
                                    >
                                        <Input autoComplete="new-password" placeholder="New Password" />
                                    </Form.Item>

                                    <Form.Item name={"repeat_password"} label="Confirm Password" style={{ formItemStyle }}
                                        dependencies={["password"]}
                                        rules={rules.repeat_password}
                                    >
                                        <Input placeholder="Confirm Password"
                                        />
                                    </Form.Item>
                                    <br />
                                </Col>

                                : <></>}

                            {fromVerifyFirstTime && !isMobile ?
                                <Col lg={12} xs={24}  >
                                    <Row justify="center">
                                        <Col offset={8} >
                                            <Avatar setExistsProfileImage={setExistsProfileImage} />
                                        </Col>

                                        <div style={{ width: "45%", top: "50%", marginTop: "auto", marginBottom: "auto", marginLeft: "30px" }}>
                                            {(!existsProfileImage) ? "Please consider adding a profile photo so your facilitator and peers in your course can better identify you during meetings etc." : ""}
                                        </div>

                                    </Row>
                                    <br />
                                </Col>
                                : <></>}
                        </Row>


                        {!fromVerifyFirstTime && !isMobile ? (
                            <>
                                <Row justify="center">
                                    <Col offset={8} >
                                        <Avatar setExistsProfileImage={setExistsProfileImage} />
                                    </Col>

                                    <div style={{ width: "30%", top: "50%", marginTop: "auto", marginBottom: "auto", marginLeft: "30px" }}>
                                        {(!existsProfileImage) ? "Please consider adding a profile photo so your facilitator and peers in your course can better identify you during meetings etc." : ""}
                                    </div>
                                </Row>
                                <Row justify="center" style={{ padding: "25px 0px 30px 0px" }} >
                                    <Col>
                                        <span style={{ margin: "15px 0", fontSize: "14px" }}>
                                            Username: {form.getFieldValue("username")}
                                        </span>
                                    </Col>
                                </Row>
                            </>
                        ) : <></>}

                        <Row>
                            <Col span={24}>

                                <Row gutter={[0, 0]}>
                                    <Form.Item noStyle name="id">
                                        <Input type="hidden" />
                                    </Form.Item>

                                    <Col lg={12} xs={24}  >
                                        <Form.Item name={"first_name"} label="First" style={formItemStyle}
                                            rules={[{ required: true, message: 'Please enter your first Name.' }]}
                                        >
                                            <Input autoComplete="new-password" placeholder="First Name" />
                                        </Form.Item>

                                        <Form.Item name={"last_name"} label="Last" style={{ ...formItemStyle }}
                                            autoComplete="new-password"
                                            rules={[{ required: true, message: 'Please enter your last name.' }]}
                                        >
                                            <Input placeholder="Last Name"
                                            />
                                        </Form.Item>
                                        <Form.Item name={"job_title"} label="Job Title" style={{ ...formItemStyle, marginBottom: "-5px" }}
                                            rules={[
                                                { required: true, message: 'Please enter your title.' },
                                            ]}
                                        >
                                            <Input autoComplete="new-password" placeholder="Title"
                                                onKeyUp={handleUpdateJobTitle}
                                            />
                                        </Form.Item>

                                        <Col offset={isMobile ? 0 : 5} style={{ marginTop: "10px" }}>
                                            <span style={{ fontSize: "13px", color: "#2f51a0" }}>
                                                Select the job function that best describes the work you do.
                                            </span>
                                        </Col>

                                        <Form.Item name={"job_function"} label="Job Function" style={{ ...formItemStyle, marginBottom: "-5px" }}
                                            rules={[{ required: true, message: 'Please enter your job function.' }]}
                                        >
                                            <Select
                                                listHeight={320}
                                                showSearch
                                                autoComplete="new-password"
                                                placeholder="Select a job function"
                                                optionFilterProp="children"
                                                options={jobFunctions}
                                                filterOption={(input, option) =>
                                                    option.label
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                            </Select>
                                        </Form.Item>


                                        <Col offset={isMobile ? 0 : 5} style={{ marginTop: "10px" }}>
                                            <span style={{ fontSize: "13px", color: "#2f51a0" }}>
                                                Enter your preferred contact number, either mobile or work.
                                            </span>
                                        </Col>

                                        <Col offset={isMobile ? 0 : 2}>
                                            <Row justify="right">
                                                <Form.Item label={"Phone/ext."} name={"phone"} style={{ width: isMobile ? '100%' : '65%', marginBottom: "0px" }}
                                                    rules={[
                                                        { required: true, message: 'Please enter your phone.' },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || value.match(/\d+/g).join("")?.length === 10) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Please enter a 10-digit phone number.'));
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <MaskedInput autoComplete="new-password" mask={"000-000-0000"} name="phone" />
                                                </Form.Item>

                                                <Form.Item wrapperCol={{ span: 24 }} name={"phone_extension"} style={{ width: isMobile ? "50%" : '35%', marginBottom: "0px" }} >
                                                    <Input autoComplete="off" placeholder="Ext" />
                                                </Form.Item>
                                            </Row>
                                        </Col>

                                        <Form.Item name={"email"} label="Email" style={formItemStyle}
                                            rules={[{ required: true, message: 'Please enter your email.' }, { type: 'email', message: 'The input is not valid E-mail.' }]}
                                        >
                                            <Input
                                                autoComplete="new-password"
                                                onBlur={(e) => {
                                                    if (e.target.value !== originalEmail && !changeEmail) {
                                                        Modal.warning({
                                                            content: "Changing your email address here does not change your username. Continue to login using your original username. If you wish to change your username, please contact the NCEE Helpdesk at helpdesk@ncee.org.",
                                                        });
                                                        setChangeEmail(true)
                                                    }
                                                }} placeholder="Email Address" />
                                        </Form.Item>

                                        <Col offset={isMobile ? 0 : 5} style={styleColInfo}>
                                            <span style={styleTextInfo}>
                                                Is this your work or personal email address?

                                                <Form.Item
                                                    name={"email_assign"}
                                                    label=""
                                                    style={{ ...formItemStyle, fontSize: "13px", color: "black" }}
                                                    rules={[{ required: true, message: 'Please select either work or personal.' }]}
                                                >
                                                    <div>
                                                        <Radio.Group name="email_assign" value={emailAssign} onChange={(e) => { setEmailAssign(e.target.value); }}>
                                                            <Radio value={"work"}>work</Radio>
                                                            <Radio value={"personal"}>personal</Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </Form.Item>
                                            </span>
                                        </Col>
                                    </Col>

                                    <Col lg={12} xs={24} >
                                        <Col offset={isMobile ? 0 : 5} style={styleColInfo} >
                                            <span style={styleTextInfo}>
                                                Select the state <span style={{ fontWeight: 'bold' }}>in which you work.</span><br />Then select your district, and (if applicable) your school.
                                            </span>
                                        </Col>
                                        <Form.Item name={"state_id"} label="State" style={formItemStyle}
                                            rules={[{ required: true, message: 'Please enter your state.' }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select a State"
                                                optionFilterProp="children"
                                                options={states}
                                                onFocus={() => {
                                                    document.querySelectorAll("#profile_state_id")[0].removeAttribute("aria-autocomplete")
                                                }}
                                                autoComplete="new-password"
                                                filterOption={(input, option) =>
                                                    option.label
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(value, item) => {
                                                    setStateId(value)
                                                    setDistrict("")
                                                    let values = form.getFieldsValue();
                                                    values.district = null;
                                                    values.school = null
                                                    form.setFieldsValue(values)
                                                    if (item.code === "PA") {
                                                        setShowUi(true)
                                                    } else {
                                                        setShowUi(false)
                                                    }
                                                }}
                                            ></Select>
                                        </Form.Item>
                                        {showUi && <Form.Item name={"ppid"} label="PPID" style={formItemStyle}
                                            rules={[{ required: true, message: 'Please enter your first PPID.' }]}
                                        >
                                            <Input placeholder="00000" />
                                        </Form.Item>
                                        }
                                        {showUi ?
                                            <Form.Item
                                                name={"iu"}
                                                label="IU"
                                                style={{ ...formItemStyle, marginBottom: "-5px" }}
                                                autoComplete="new-password"
                                                rules={[{ required: true, message: 'Please select a IU.' }]}>
                                                <Select

                                                    placeholder="iu"
                                                    options={ui}
                                                >
                                                </Select>
                                            </Form.Item> : ""}

                                        <Col offset={isMobile ? 0 : 5} style={styleColInfo}>
                                            <span style={styleTextInfo}>
                                                If you are not affiliated with a district or school, select "not affiliated with a district or school".
                                            </span>
                                        </Col>

                                        <Districts form={form} stateId={stateId} district={district} setDistrict={setDistrict} setSchool={setSchool} rules={[{ required: true, message: 'Please enter your district.' }]} label={"District"} />

                                        <Schools form={form} district_id={district} school={school} setSchool={setSchool} label={"School"} />
                                    </Col>
                                </Row>


                                {isMobile ? (
                                    <>
                                        <Row justify="center">
                                            <Col xs={24} style={{ textAlign: "center" }}>
                                                <br /> <Avatar setExistsProfileImage={setExistsProfileImage} isMobile={isMobile} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={24}>
                                                <div >
                                                    {(!existsProfileImage) ? "Please consider adding a profile photo so your facilitator and peers in your course can better identify you during meetings etc." : ""}
                                                </div>
                                            </Col>
                                        </Row>
                                    </>) : <></>
                                }

                                {fromVerifyFirstTime || requiredData ? (
                                    <Row
                                        justify="center"
                                        style={{ padding: "2px 0" }}>
                                        <Col xs={24}>
                                            <br /> For assistance with the NCEE Portal, contact the helpdesk between the hours for 8am to 6pm M-F (ET)
                                            at helpdesk@ncee.org or 855-443-6359.
                                        </Col>
                                    </Row>) : <></>
                                }

                                <Row style={{ float: "right", marginTop: "20px" }}>
                                    <div>
                                        <Button style={{ marginRight: "10px" }} onClick={() => {
                                            if (changeForm) {
                                                confirm({
                                                    title: "Do you want to cancel without saving?",
                                                    icon: <ExclamationCircleOutlined />,
                                                    okButtonProps: { type: "primary" },
                                                    okText: "Yes",
                                                    cancelText: "No",
                                                    cancelButtonProps: { danger: true },
                                                    onOk() {
                                                        if (fromVerifyFirstTime === true || requiredData === true) {
                                                            cancelEditFirstTime()
                                                        } else {
                                                            redirectForm()
                                                        }
                                                    },
                                                    onCancel() {
                                                        console.log("Cancel");
                                                    },
                                                });

                                            } else {
                                                if (fromVerifyFirstTime === true || requiredData === true) {
                                                    cancelEditFirstTime()
                                                } else {
                                                    redirectForm()
                                                }
                                            }
                                        }}>
                                            Cancel
                                        </Button>

                                        <Button type="primary" onClick={() => {
                                            form.validateFields().then(values => {
                                                onFinish(values);
                                            })
                                        }}>
                                            OK
                                        </Button>
                                    </div>
                                </Row>

                            </Col>
                        </Row>

                    </Col>
                </Row >
            </Form >
        </Content >
    );
};

export default Profile;
