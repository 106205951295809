import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select,  Button, Upload} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import API from "../../../api";
import example_BOMs from "../../../assets/files/example_BOMs.xlsx";


const { useForm } = Form;


const BomForm = ({onFinish,course,setShowForm}) => {
    const [fileList, setFileList] = useState();
    const [courses, setCourses] = useState();
    const [initialFormValues, setInitialFormValues] = useState({
         materials: undefined,
         course: 0
     })

    const [form] = useForm();

    const setCourse = () => {
        let formValues = { ...initialFormValues, course: course }
        form.setFieldsValue(formValues)
        setInitialFormValues(formValues)
    }

    const getCourses = (setCourses) => {
        API.get("api/courses").then(({ data }) => {
            data = data.map(e => ({
                label: `(${e.number.replace("cohort","")}) ${e.label}`,
                value: e.value
            }));
            setCourses(data)
            setCourse();
        });
    };


    useEffect(() => {
        getCourses(setCourses);
        // eslint-disable-next-line
    }, [setCourses]);

    const props = {
        beforeUpload: (file) => {
            setFileList(file);
            return false;
        },
        accept: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };


    return (
        <div>
            <Row gutter={[5,5]} >
                <Col  span={14}>
                </Col>
                <Col  span={10} style={{textAlign: "right" }}>
                    <Button  href={example_BOMs} >XLSX Sample file</Button>
                    <Button style={{marginLeft: "8px" }} type="primary" onClick={() => {setShowForm(false) }} >OK</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form
                        layout="vertical" form={form}
                        onFinish={(values) => onFinish(values,form)}
                        initialValues={initialFormValues} style={{ margin: "0 0 10px 0" }}

                    >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={9}>
                                <Form.Item name="course" label="Course">
                                    <Select options={courses} showSearch
                                        placeholder="Select a Course" optionFilterProp="children"
                                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    className="input_material"
                                    name="materials"
                                    label={
                                        <span>
                                            Materials File
                                        </span>
                                    }
                                    valuePropName="file"
                                    rules={[{required:true, message:'Please attach the file (xlsx) with the material status.'}]}
                                >
                                    <Upload {...props}  showUploadList={false}>
                                        <Button>
                                            <UploadOutlined />{" "}
                                            {fileList
                                                ? fileList.name
                                                : "Click to choose file"}
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button htmlType="submit" type="primary">
                                    Start importing
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default BomForm;
