import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Modal } from 'antd';
import API from '../../../api';
import ListParticipantNotifications from './ListParticipantNotification';
import FormParticipantNotification from './FormParticipantNotification';
import { ExclamationCircleOutlined } from '@ant-design/icons';




const ParticipantNotification = () => {
    const [form] = Form.useForm();
    const [visibleFormParticipantNotification, setVisibleFormParticipantNotification] = useState(false);
    const [textArea, setTextArea] = useState("");
    const [participantNotifications, setParticipantNotifications] = useState([]);
    const [edit, setEdit] = useState(false)
    const handleOk = () => {
        setVisibleFormParticipantNotification(false);
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                values.text = textArea;
                if (edit) {
                    handleUpdate(values);
                } else {
                    handleCreate(values);
                }
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }
    const handleCancel = () => {
        setVisibleFormParticipantNotification(false)
    }

    const handleDelete = (id) => {
        API.delete(`api/logon-popup/${id}?type=participant_notification`).then(({ data }) => getParticipantNotifications()).catch(console.log);
    }

    const handleEdit = (values) => {
        setVisibleFormParticipantNotification(true);
        setTextArea(values.text);
        console.log(values);
        values.display_to = values.roles.map(v => {
            return v.id;
        });
        form.setFieldsValue(values)
    }

    const handleCreate = (values) => {
        API.post("api/logon-popup?type=participant_notification", values)
            .then(({ data }) => getParticipantNotifications())
            .catch(({ response: { data } }) => { console.log(data) })
    }

    const handleUpdate = (values) => {
        console.log(values)
        API.put(`api/logon-popup/${values.id}?type=participant_notification`, values)
            .then(({ data }) => getParticipantNotifications())
            .catch(({ response: { data } }) => { console.log(data) })
    }

    const showDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this permanently? You can deactivate it instead, if necessary.',
            icon: <ExclamationCircleOutlined style={{color: "#ecd62a"}} />,
            onOk() {
                API.delete(`api/logon-popup/delete/${id}`).then(() => {
                    setVisibleFormParticipantNotification(false);
                    getParticipantNotifications();
                }).catch();
            }
        });
    }

    const getParticipantNotifications = () => API.get("/api/logon-popup?type=participant_notification")
        .then(({ data }) => setParticipantNotifications(data))
        .catch(console.log);

    useEffect(() => {
        getParticipantNotifications();
    }, [])

    return (
        <>
            <Row>

                <Button
                    type="primary"
                    onClick={() => {
                        form.setFieldsValue({
                            active: false,
                            headline: ""
                        });
                        setTextArea("");
                        setEdit(false);
                        setVisibleFormParticipantNotification(true);
                    }}
                >
                    Add New Participant Notification
                </Button>
            </Row>
            <br />
            <ListParticipantNotifications
                participantNotifications={participantNotifications} setEdit={setEdit}
                editParticipantNotification={handleEdit} deleteParticipantNotification={handleDelete}
                showDelete={showDelete} />
            <FormParticipantNotification
                form={form}
                visible={visibleFormParticipantNotification}
                handleCancel={handleCancel}
                handleOk={handleOk}
                setTextArea={setTextArea}
                textArea={textArea}
                showDelete={showDelete}
                edit={edit} />
        </>
    )
}

export default ParticipantNotification
