

import API from "../api";
import { Modal } from "antd";

const { REACT_APP_BACKEND } = process.env;

let disabled_page = (page) => {
    navigator.sendBeacon(`${REACT_APP_BACKEND}/api/disabled_page?token=${localStorage.getItem("jwt-token")}&page=${page}`)
}

let active_page = (page) => {
    API.post('api/active_page', {
        page: page,
        user_id: JSON.parse(localStorage.getItem("user-data")).id
    })

}

let validate_page = async (page) => {
    try {
        let allowed_page = await API.get(`api/validate_page`, {
            params: {
                page: page
            }
        });

        return allowed_page?.data?.next;

    } catch ({ response }) {
        let user = response?.data?.page?.user;
        if (response !== undefined) {
            Modal.warning({
                title: '',
                content: `${user.name} (${user.email}) has this file open now.  You cannot access this file until ${user.first_name} closes the file.`,
            });
        }
    }
}

const update_page = (page) => {

    API.post('api/update_page', {
        page: page,
        user_id: JSON.parse(localStorage.getItem("user-data")).id
    })
}

export { disabled_page, active_page, validate_page, update_page }
