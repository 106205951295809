import React from "react";
import { Modal, Button } from "antd";

const PolicyModal = ({ visible, setVisible }) => {

    const { REACT_APP_CMS_URL } = process.env;

    return (
        <Modal
            title="MAKE-UP, ABSENCE, AND TARDINESS POLICY"
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            style={{ textAlign: "center" }}
            centered
            width="90%"
            bodyStyle={{ height: "75vh" }}
            footer={[
                <Button
                    key="link"
                    href={`${REACT_APP_CMS_URL}/nisl_pages/attendance-policy/?print=true`}
                    type="primary"
                    target="_blank"
                >
                    Print
                </Button>,
            ]}
        >
            <div>
                <iframe
                    title="policy"
                    src={`${REACT_APP_CMS_URL}/nisl_pages/attendance-policy/`}
                    frameBorder={"0"}
                    width="100%"
                    style={{ height: "68vh" }}
                />
            </div>
        </Modal>
    );
};

export default PolicyModal;
