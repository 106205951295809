import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from "react-redux";
import * as actionTypes from '../../../store/actions/actionTypes';
import { Button, Col, Empty, Layout, Row, Spin, Table, Space, Checkbox } from "antd";
import { pinStyle } from "./../Dashboard.style";
import { getAlpToken } from "../../../functions/getAlpToken";
import moment from "moment";
import DashboardFilter from "../DashboardFilter";
import getColumns from './getColumns';
import API from "../../../api";
import { v4 as uuidv4 } from 'uuid';
import { useChunk } from "../../../Hooks/useChunk";
import SearchCohort from './SearchCohort';
import axios from "axios";
import _ from "lodash"
import { useHistory } from "react-router-dom";

let cancelTokenSource = axios.CancelToken.source();
const { Content } = Layout;

const scrollConfig = {
    y: "calc(100vh - 220px)",
    x: true,
};
const VISUAL_FILTERS = 'visualFilters';
const COHORT_FILTER = 'cohort';
const COACHING_FILTER = 'dashboard_include_coaching';

const Cohorts = ({ doOpenMenu }) => {
    //custom hook for managment chunk the all request
    const { increment_next, get_next, add_validate, validate_request, reset_next, reset_validate, change_from, return_from, set_last_load, get_last_load, reset_last_load } = useChunk();

    const today = moment();

    //
    const dataDashboard = useRef([]);
    //states
    const [emptyText, setEmptyText] = useState("No cohort sessions match your search criteria.")
    let [loadCohort, setLoadCohort] = useState(false);
    let [alpToken, setAlpToken] = useState(false);
    const [cohorts, setCohorts] = useState({ total: 0, data: [] });
    const { userID } = JSON.parse(localStorage.getItem("nisl-data"));
    const [viewCities, setViewCities] = useState(true);
    const [columnView, setColumnView] = useState('assignments_attendance');
    const [searchCohortChange, setSearchCohortChange] = useState(false);
    const [columnsList, setColumnsList] = useState([])
    const [loading, setLoading] = useState(false);
    const [fromDashboard, setFromDashboard] = useState("today");
    const currentFilter = useRef({});
    const [searchValue, setSearchValue] = useState('');
    const [alpData, setAlpData] = useState({ alp_data: [] })
    const [missingAttendance, setMissingAttendance] = useState({})
    const { REACT_APP_URL, REACT_APP_PORTAL_URL } = process.env;
    const { roles_un } = JSON.parse(localStorage.getItem("nisl-data"));
    const [checkCoaching, setCheckCoaching] = useState(roles_un.includes('state_coordinator'))
    const history = useHistory();

    var load = 0;

    let buttonOptions = [
        {
            'slug': 'assigments',
            'name': 'Participant Activity',
        },
        {
            'slug': 'participant_activity',
            'name': 'Assignments+Attendance',
        },
        {
            'slug': 'assignments_attendance',
            'name': 'View Assignments',
        }
    ];

    let alp_token = async () => {
        await getAlpToken(userID)
        const { alp } = JSON.parse(localStorage.getItem("nisl-data"))
        setAlpToken(alp?.token)
    }

    let getAlpData = (token, nislIds, cohort_user_ids, cohort_participants) => {

        API.post("api/get_alp_data", {
            nisl_ids: nislIds,
            cohort_user_ids: cohort_user_ids,
            cohort_participants: cohort_participants
        }).then(({ data }) => {
            setAlpData({ alp_data: Object.assign(alpData.alp_data, data) })
        }).catch((response) => {
            console.log(response);
        })
    }


    let getDashboardData = useCallback((from_scroll = false, first_filter = null) => {
        if (first_filter === true) {
            reset_next();
            reset_last_load();
            reset_validate();
        }

        if (!loading) {
            if (!validate_request(get_next())) {

                add_validate(get_next())

                var nislIds = [];
                var cohort_user_ids = [];
                var cohort_participants = [];
                API.get(`api/dashboard_data?page=${get_next()}${!_.isEmpty(currentFilter.current) ? "&filters=" + JSON.stringify(currentFilter.current) : ""}&from=${return_from()}&last_load=${get_last_load()}`, {
                    cancelToken: cancelTokenSource.token
                }).then(({ data }) => {

                    //Set the columns based on the user filters saved
                    if (data.visualFilters?.filter.viewCities !== undefined && data.visualFilters?.filter.viewCities !== null) {
                        setViewCities(data.visualFilters.filter.viewCities);
                        setColumnView(typeof data.visualFilters.filter.columnView !== "boolean" ? data.visualFilters.filter.columnView : 'assignments_attendance');
                    }

                    change_from(data?.from)
                    let from = return_from();
                    set_last_load(data.last_load)

                    if (get_next() === 0) {
                        setLoadCohort(true);
                    }
                    increment_next(1)


                    let events = [];
                    if (first_filter === true && (from === "past" || from === "today")) {
                        dataDashboard.current = data.events;
                    } else if (searchCohortChange !== true && first_filter !== true && (from === "past" || from === "today")) {
                        dataDashboard.current = dataDashboard.current.concat(data.events);
                    }


                    events = dataDashboard.current;

                    let result = [];
                    let map = new Map();
                    let check_year = "";
                    let mark_next = false;
                    let today_exists = false;
                    for (const item of events) {
                        if (data.skip === 0 && data.from === "today") {
                            let diff_days = 0;
                            diff_days = moment(item.start).diff(moment(), 'days') + 2;
                            if (diff_days <= 5) {
                                let validate_last_unit = [];
                                validate_last_unit = data.last_units.filter(v => v.id === item.id)
                                if (validate_last_unit.length > 0 && item.url_unit_evaluation === null && item.cohort.course.is_edp) {
                                    item.warning_url = true
                                }
                            }
                        }

                        if (item.change_year === true) {
                            continue;
                        } else {

                            if (!map.has(item.id)) {
                                map.set(item.id, true);   // set any value to Map
                                if (from === "past") {
                                    if (moment(item.start_date_event).format("YYYY") !== check_year && check_year !== "TBD") {
                                        if (item?.is_tbd === true) {
                                            check_year = "TBD";
                                        } else {
                                            check_year = moment(item.start_date_event).format("YYYY");
                                        }
                                        result.push({ id: uuidv4(), change_year: true, year_group_by: (!item?.is_tbd) ? moment(item.start_date_event).format("YYYY") : "Not Yet Scheduled", span: (!item?.is_tbd) ? 22 : 23 });
                                    }
                                }
                                if (from === "past") {
                                    if (moment(item.start_date).isSameOrAfter(moment(), "day")) {

                                        let is_same = moment(item.start_date).isSame(moment(), "day");

                                        if (!is_same && mark_next === false && today_exists === false) {
                                            mark_next = true;
                                        } else {
                                            today_exists = true;
                                            mark_next = false;
                                        }
                                    }
                                }
                                result.push({ ...item, mark_next: mark_next, change_year: false, year_group_by: moment(item.start_date).format("YYYY") });
                            }
                        }
                    }

                    setLoading(false);
                    setCohorts({ data: result, total: data.total });


                    nislIds = data.nisl_ids;
                    cohort_user_ids = data.cohort_user_ids;
                    cohort_participants = data.cohort_participants;
                    const { alp } = JSON.parse(localStorage.getItem("nisl-data"))
                    getAlpData(alp?.token, nislIds, cohort_user_ids, cohort_participants);

                    if (data.total / 50 >= 3 && load < 2 && from_scroll === false) {
                        getDashboardData(false, null)
                        // eslint-disable-next-line
                        load = load + 1;
                    }
                    setMissingAttendance(prevData => Object.assign(prevData, data.missingAttendance))
                });
                getAlpToken(userID)

            }
        }
    }, [cohorts])

    let goToDrive = () => {
        window.open("https://drive.google.com/drive/folders/0AGCjXi3-6PH0Uk9PVA")
    }

    useEffect(() => {
        doOpenMenu(true);
        getFilterCoaching()
        alp_token();
        getDashboardData(false, true);
        if (roles_un.includes("facilitator_local") || roles_un.includes("facilitator_local_inc")) {
            setEmptyText(<Empty imageStyle={{ display: "none" }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                <span style={{ fontSize: "28px", color: "#0051a0", lineHeight: "50vh", height: "50vh" }}>
                    You are currently not assigned as a facilitator to an active cohort.
                </span>
            }
            />)
        } else {
            setEmptyText(<Empty imageStyle={{ display: "none" }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                <span style={{ fontSize: "28px", color: "#0051a0", lineHeight: "50vh", height: "50vh" }}>
                    No cohort sessions match your search criteria.
                </span>
            }
            />)
        }
        // eslint-disable-next-line
    }, [])

    let getFilterCoaching = () => {
        API.get(`/api/filter-cohort?type_filter=${COACHING_FILTER}`)
            .then(({ data }) => {
                if (data.filters.length > 0) {
                    setCheckCoaching(data.filters[0].filter)
                }
            })
    }

    useEffect(() => {
        return () => {
            cancelTokenSource.cancel('Operation canceled by the other request');
            cancelTokenSource = axios.CancelToken.source();
        }
    }, [searchCohortChange])

    useEffect(() => {

        setColumnsList(getColumns({
            columnView,
            viewCities,
            countCohorts: cohorts?.total,
            alpData: alpData.alp_data,
            today: today,
            missingAttendance
        }));
        // eslint-disable-next-line
    }, [columnView, viewCities, cohorts?.total, REACT_APP_PORTAL_URL, userID, alpToken, REACT_APP_URL, alpData]);


    useEffect(() => {
        let table_dashboard = document.querySelector('.ant-table-body')
        if (table_dashboard !== null) {
            table_dashboard.addEventListener('scroll', (event) => {
                let maxScroll = event.target.scrollHeight - event.target.clientHeight
                let currentScroll = event.target.scrollTop
                // eslint-disable-next-line
                if (currentScroll.toFixed() == maxScroll || currentScroll.toFixed() - 1 == maxScroll) {
                    setLoading(true);
                    if (fromDashboard !== "" && fromDashboard !== null) {
                        getDashboardData(true, false);
                    }
                }
            })
        }
        // eslint-disable-next-line
    }, [cohorts.data])

    let changeDefaultColumns = (type) => {

        let filters = { viewCities: viewCities, columnView: columnView };

        if (type === "name") {
            setViewCities(!viewCities);
            filters.viewCities = !viewCities;
        } else {
            let newType = setButtonAssigments()
            filters.columnView = newType;
        }

        let saveFilter = handleSaveFilters(VISUAL_FILTERS, filters, true)
        saveFilter.then(({ data }) => {
            console.log(data);
        })
            .catch((response) => { console.log(response) })
    }

    let setButtonAssigments = () => {
        let currentOption = buttonOptions.findIndex(option => option.slug === columnView)
        let nextOption = buttonOptions[currentOption + 1] !== undefined ? buttonOptions[currentOption + 1] : buttonOptions[0];
        setColumnView(nextOption.slug);
        return nextOption.slug;
    }

    let handleChangeSearch = (searchParam) => {
        let value = searchParam && searchParam !== undefined ? searchParam : ''
        setLoading(true);
        currentFilter.current = {
            'retain_filter': false,
            'years': 2015,
            'check_past': value ? true : false,
            'cohort_name': value,
        }
        let save_filter = true;

        if (!value) {
            save_filter = false;
            setSearchValue('')
            currentFilter.current = {};
            setSearchCohortChange(false)
        } else {
            setSearchCohortChange(true)
            setSearchValue(value)
        }


        let saveFilter = handleSaveFilters(COHORT_FILTER, currentFilter.current, save_filter)
        saveFilter.then(({ data }) => {
            getDashboardData(false, true)
        })
            .catch((response) => { console.log(response) })
    }

    let handleSaveFilters = (type, filters, save_filters) => {
        return API.post("api/user/saveFilters", {
            type_filters: type,
            filters: filters,
            save_filters
        })
    }

    return (
        <Content className="ant-layout-content site-layout-background" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            {!loadCohort ? (
                <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
            ) : null}
            <br />
            <Row>
                <Col span={10} gutter={[10, 5]} style={{ textAlign: "left" }}>
                    <Button type={"primary"} onClick={() => { changeDefaultColumns("name") }}>{viewCities ? "View Cities" : "Cohort Names"}</Button> &nbsp;
                    <Button type={"primary"} onClick={() => { changeDefaultColumns(buttonOptions.find(option => option.slug === columnView).slug) }}>{buttonOptions.find(option => option.slug === columnView).name}</Button>
                    <Checkbox style={{ marginLeft: '20px' }} checked={checkCoaching} onChange={(e) => {
                        setSearchCohortChange(e.target.checked)
                        setLoading(true);
                        setCheckCoaching(e.target.checked);
                        let saveFilter = handleSaveFilters(COACHING_FILTER, e.target.checked, true)
                        saveFilter.then(({ data }) => {
                            getDashboardData(false, true)
                        }).catch((error) => {
                            console.log(error);
                        })
                    }}>include coaching?</Checkbox>
                    {/* <Button type={"primary"} onClick={() => { changeDefaultColumns("column") }}>{columnView ? "Participant Activity" : "View Assignments"}</Button> */}
                </Col>
                <Col span={4} style={{
                    textAlign: "center",
                    color: "rgb(179, 147, 54)",
                    fontWeight: "bold",
                    alignSelf: "flex-end",
                    padding: "5px"
                }}> Cohort Dashboard </Col>
                <Col span={10} >
                    <Row justify="end">
                        {roles_un.includes("coach") && !roles_un.includes("administrator")
                            ? <Button className='goAdmin' onClick={() => history.replace("/admin")} type={"primary"} style={{ "height": "auto", fontSize: "13px" }}>
                                <Space direction="vertical" size={1}>
                                    <div>Schedule Coaching/Consulting</div>
                                </Space>
                            </Button>
                            : ''
                        }&nbsp;
                        <Col span={12}>
                            <SearchCohort handleChange={handleChangeSearch} setSearchValue={setSearchValue} currentValue={searchValue} />
                        </Col>
                        &nbsp;
                        {roles_un.includes("state_coordinator")
                            ? <Button className='drive_state_coordinator' onMouseDown={e => e.preventDefault()} type={"primary"} style={{ "height": "auto" }} onClick={() => { goToDrive() }}>
                                <Space direction="vertical" size={1}>
                                    <div>Add Cohort</div>
                                    <div>or Update</div>
                                </Space>
                            </Button>
                            : ''
                        }&nbsp;
                        <DashboardFilter
                            setData={setCohorts}
                            setLoading={setLoading}
                            getData={getDashboardData}
                            setCurrentFilter={currentFilter}
                            currentFilter={currentFilter}
                            filterType="cohort"
                            calendarRef={null}
                            setFromDashboard={setFromDashboard}
                            setSearchValue={setSearchValue}
                        />
                    </Row>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Table
                        defaultExpandAllRows={true}
                        columns={columnsList}
                        dataSource={cohorts?.data}
                        indentSize={0}
                        infinity={true}
                        loading={loading}
                        size={"small"}
                        pagination={false}
                        scroll={{ y: scrollConfig.y }}
                        rowKey={(record) => record.id}
                        locale={{ emptyText: emptyText }}

                    />
                </Col>
            </Row>
        </Content>
    );
};


const mapDispatchToProps = dispatch => ({
    doOpenMenu: active => dispatch({ type: actionTypes.SET_MENU_OPEN, active: active }),
});

export default connect(null, mapDispatchToProps)(Cohorts);

