import React from "react";
import Icon from "@ant-design/icons";

const CameraOutlinedSvg = () => (
    <svg version="1.0" width="1em" height="1em" viewBox="0 0 1213 1213">
        <g
            transform="translate(0,1213) scale(0.100000,-0.100000)"
            fill="currentColor"
        >
            <path d="M4370 10879 c-353 -26 -778 -193 -1085 -428 -33 -25 -177 -160 -320 -301 -147 -145 -288 -275 -325 -299 -137 -91 -345 -178 -530 -223 -79 -19 -128 -21 -710 -27 -607 -7 -627 -8 -699 -30 -333 -100 -577 -345 -673 -677 l-23 -79 0 -3375 0 -3375 23 -79 c97 -334 344 -581 678 -678 l79 -23 5295 0 5295 0 79 23 c262 76 470 242 596 475 22 40 49 100 60 132 20 58 20 82 20 3525 0 3443 0 3467 -20 3525 -11 32 -38 92 -60 132 -124 231 -330 396 -591 474 -72 22 -92 23 -699 30 -692 7 -678 6 -884 79 -279 99 -369 161 -681 469 -137 136 -272 265 -300 288 -160 130 -427 272 -653 346 -170 57 -308 85 -471 97 -151 11 -3250 10 -3401 -1z m3489 -663 c182 -43 392 -131 532 -221 51 -32 153 -126 324 -294 138 -136 285 -275 327 -308 236 -187 551 -331 885 -405 93 -21 130 -22 713 -28 436 -5 626 -10 652 -19 92 -30 182 -122 210 -214 19 -62 19 -6512 0 -6574 -28 -94 -121 -187 -215 -215 -62 -19 -10352 -19 -10414 0 -97 29 -186 118 -215 215 -19 62 -19 6512 0 6574 28 94 116 184 210 214 26 9 216 14 652 19 580 6 620 8 711 28 343 77 650 217 887 405 42 33 190 172 327 308 255 251 312 297 462 373 157 79 344 138 484 155 41 5 821 8 1734 7 1555 -2 1665 -3 1734 -20z" />
            <path d="M5733 8944 c-838 -101 -1597 -572 -2063 -1280 -248 -377 -402 -801 -455 -1250 -20 -168 -20 -500 0 -668 53 -453 210 -881 461 -1258 475 -712 1226 -1174 2069 -1273 169 -20 501 -20 669 0 1326 155 2376 1204 2531 2530 20 169 20 501 0 669 -134 1141 -936 2098 -2039 2431 -148 44 -324 80 -492 100 -160 19 -524 18 -681 -1z m637 -650 c805 -101 1517 -667 1803 -1434 155 -415 181 -898 72 -1326 -200 -782 -804 -1396 -1583 -1610 -369 -101 -794 -101 -1163 0 -771 211 -1373 817 -1579 1588 -95 357 -95 779 0 1136 105 392 316 752 606 1032 497 481 1157 700 1844 614z" />
        </g>
    </svg>
);

export const CameraOutlined = (props) => (
    <Icon component={CameraOutlinedSvg} {...props} />
);
