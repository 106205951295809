import React, { useState } from "react";
import { Spin } from "antd";
import { pinStyle } from "./Dashboard.style";

const Downloads = () => {
    let [loadDownloads, setLoadDownloads] = useState(false);
    const { cohort: cohortId } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_PORTAL_URL } = process.env;
    return (
        <>
            {!loadDownloads ? (
                <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
            ) : null}
            <iframe
                src={`${REACT_APP_PORTAL_URL}/?view=download_ncee&cohort=${cohortId}`}
                frameBorder={"0"}
                onLoad={() => {
                    setLoadDownloads(true);
                }}
                title="dashboard-downloads"
                className="style-frame"
            />
        </>
    );
};

export default Downloads;
