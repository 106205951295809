import React, { useState } from 'react'
import { Modal, Button, Row, Col, Spin, Layout, Space } from 'antd'
import FacilitatorUtilization from './Reports/FacilitatorUtilization'
import AttendanceAuditReport from "./AttendanceAuditReport";
import API from "../../../api";
import { createUseStyles } from "react-jss";
import moment from "moment";
import AttendanceData from './AttendanceData';
import UserAccountsReport from './UserAccountsReport';

const { Content } = Layout;
const useStyles = createUseStyles({
    largeButton: {
        height: "2em",
        fontSize: "11px",
        background: "#fff",
        color: "#0051a0",
        width: "80%",
        lineHeight: "1em",
    },
    container: {
        '& Button': {
            width: '80%',
            height: '40px',
            marginTop: '0.1em',
        },
    },
});
const DataLink = (props) => {
    const classes = useStyles();
    const [isVisibleFacilitatorUtilization, setIsVisibleFacilitatorUtilization] = useState(false)
    // eslint-disable-next-line
    const [isStateSpin, setStateSpin] = useState(false);
    const [showAttendanceAuditReport, setShowAttendanceAuditReport] = useState(false);
    const [showUsersAttendanceReport, setShowUsersAttendanceReport] = useState(false);
    const [showAttendanceData, setShowAttendanceData] = useState(false);
    const { token } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_CMS_URL } = process.env;
    const exportDataMaster = (values, form) => {
        let request = null;
        let filename = "Master Schedule.xlsx";
        request = API.get(`api/date-export-reports?&filename=Master Schedule`);
        request
            .then(({ data }) => {
                filename = "Master Schedule " + data + ".xlsx";
            });

        API.get(`api/download-master-schedule`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        });
    }


    const exportCohortData = () => {
        let request = null;
        let filename = "Cohort Data.xlsx";
        request = API.get(`api/date-export-reports?&filename=CohortData`);
        request
            .then(({ data }) => {
                filename = "Cohort Data " + data + ".xlsx";
            });
        API.get(`api/download-cohort-data`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        });
    }

    const exportFacilitatorSchedule = (values, form) => {
        let request = null;
        let filename = "Facilitator Event Details.xlsx";
        request = API.get(`api/date-export-reports?&filename=FacilitatorSchedule`);
        request
            .then(({ data }) => {
                filename = "Facilitator Event Details " + data + ".xlsx";
            });
        API.get(`api/download-facilitator-schedule`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        });
    }

    const exportDiagnosticsReport = (values, form) => {
        let url = `${REACT_APP_CMS_URL}/download-report/?report=diagnostics_report&token=${token}`;
        window.location.href = url;
    }

    const exportCertificatesReport = (values, form) => {
        API.get(`api/export_certificate`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const formatDate = moment(new Date()).format('YYYY-MM-DD hh_mm a');
            link.setAttribute("download", `Certificates Data ${formatDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    }

    const exportJobfunctionChangesReport = (values, form) => {
        API.get(`api/export_job_function_changes_report`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const formatDate = moment(new Date()).format('YYYY-MM-DD hh_mm a');
            link.setAttribute("download", `Batch Job Function Changes ${formatDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    }

    return (
        <>
            <UserAccountsReport showUsersAttendanceReport={showUsersAttendanceReport} setShowUsersAttendanceReport={setShowUsersAttendanceReport}/>
            <AttendanceAuditReport showAttendanceAuditReport={showAttendanceAuditReport} setShowAttendanceAuditReport={setShowAttendanceAuditReport}></AttendanceAuditReport>
            <AttendanceData showAttendanceData={showAttendanceData} setShowAttendanceData={setShowAttendanceData}></AttendanceData>

            <Modal
                visible={props.isDataLinkVisible}
                title="Data Sets"
                centered
                width="70%"
                onCancel={() => props.setIsDataLinkVisible(false)}
                footer={null}
            >
                <Content className={classes.container}>
                    <Spin spinning={isStateSpin} delay={500}>
                        <Space direction="vertical" size={10}>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={() => {
                                            setShowUsersAttendanceReport(true)
                                        }}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>User</div>
                                            <div>Accounts</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Download reports with account details for anyone who has an account on the NISL portal, including EDP and SUA participants. This report is generated once each day, at midnight.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            exportCohortData()
                                        }}
                                        className={classes.largeButton}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Cohort</div>
                                            <div>Data</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Download an XLS with data for all cohorts/sessions, beginning when the NISL portal launched in May 2016. This report is generated once each day, at midnight.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={(value, form) => {
                                            exportCertificatesReport(value, form)
                                        }}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Certificates</div>
                                            <div>Created</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    List of all the certificates that have been created on the portal and when/who created them. This is NOT the same as graduation. Some certificates are not awarded or are awarded at a later time.
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button type="primary"
                                        className={classes.largeButton}
                                        onClick={(value, form) => {
                                            exportDataMaster(value, form)
                                        }}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Master</div>
                                            <div>Schedule</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Download an XLS report that includes all training events for all cohorts. Also includes a list of NISL/EDP units ending for use with Alliance and Collaborative follow-up work. This report is updated three times a day, at 3am, 11am, and 2pm ET.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={(value, form) => {
                                            exportFacilitatorSchedule(value, form)
                                        }}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Facilitators</div>
                                            <div>Schedule</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Download an XLS with the training schedule data for all facilitators.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={() => {
                                            setShowAttendanceAuditReport(true)
                                        }}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Attendance Audit</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Download an XLS or a list of participants, which if any session they have marked absent and which make-ups have been scheduled.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={() => {
                                            setShowAttendanceData(true)
                                        }}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Attendance Raw Data</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    Download an XLS with raw attendance data that's been recorded in the portal.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        className={classes.largeButton}
                                        type="primary"
                                        onClick={() => {
                                            setIsVisibleFacilitatorUtilization(true);
                                        }} >
                                        <Space direction="vertical" size={1}>
                                            <div>Facilitator</div>
                                            <div>Utilization</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    This utilization report shows this fiscal year's facilitator assignments for each national facilitator.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={(value, form) => {
                                            exportDiagnosticsReport(value, form)
                                        }}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Feedback</div>
                                            <div>Diagnostics Data</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    This data includes the list of respondents, the link for each respondent, and the status of each response.
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                    <Button
                                        type="primary"
                                        className={classes.largeButton}
                                        onClick={(value, form) => {
                                            exportJobfunctionChangesReport(value, form)
                                        }}
                                    >
                                        <Space direction="vertical" size={1}>
                                            <div>Job Function</div>
                                            <div>Changes</div>
                                        </Space>
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                    This data include the list of all batches run using System Tool to set Job Function automatically and all the accounts that were updated for each batch.
                                </Col>
                            </Row>


                        </Space>
                    </Spin>
                </Content>
            </Modal>
            <FacilitatorUtilization isVisibleFacilitatorUtilization={isVisibleFacilitatorUtilization} setIsVisibleFacilitatorUtilization={setIsVisibleFacilitatorUtilization} />
        </>
    )
}

export default DataLink
