
import React, { useEffect } from 'react';

import { Button, Col, DatePicker, Form, Input, Row, Select, TimePicker, Modal } from 'antd';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import API from '../../../api';
import moment from "moment"

import refreshCohortSchedule from "../../../functions/cohorts/refreshCohortSchedule";
import { EXECUTIVE_COACHING } from "../../../config/courses";

const { confirm } = Modal;


export const CohortSchedule = ({ form, ready, setShowOrientation, handleFormChange, currentCohort, meetingTypes, isExecutiveCourse }) => {
    const FIRST_UNIT = '1';
    const EVENT_TYPE_EXT = 'ext';
    const EVENT_TYPE_TWO_DAY = '2_day';
    useEffect(() => {
        if (ready) {
            refreshCohortSchedule(form, null, currentCohort)
        }
    }, [ready])


    useEffect(() => {
        if (ready) {
            let values = form.getFieldsValue();

            if (isExecutiveCourse && values.course === `cohort${EXECUTIVE_COACHING}`) {

                if (values.id === undefined) {
                    values.cohort_schedule = [{ unit: 1, event_type: EVENT_TYPE_EXT, cohort_schedule_sub: [] }]
                } else {
                    let unit_of_work = values.cohort_schedule[0];
                    if (unit_of_work.event_type !== EVENT_TYPE_EXT) {
                        values.cohort_schedule[0].event_type = EVENT_TYPE_EXT;
                    }
                }

                form.setFieldsValue(values)
                changeEventType(0, 1);
            } else {

                let unit_of_work = values.cohort_schedule[0];

                let html_element = document.querySelector(
                    `#principal_unit${unit_of_work.unit} div.ant-row`
                );

                if (html_element != null) {
                    html_element.style.display = "";
                }

                let html_element_sub_unit = document.querySelector(
                    `#principal_unit${unit_of_work.unit} div.sub_unit${unit_of_work.unit}`
                );

                if (html_element_sub_unit != null) {
                    html_element_sub_unit.style.display = "none";
                }
            }
        }

    }, [isExecutiveCourse])

    let alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    /**
     *
     * @param {*} unit
     * @param {*} sub_units
     * @param {*} sub_unit
     *
     * add one sub unit on some position, the logic is the next
     * 1) created the next sub unit for add
     * 2) add sub unit on last position
     * 3) add sub unit on position assigned for the user without remove the next sub unit
     * 4) remove last sub unit
     * 5) update index of sub units
     */

    let addSubUnit = (unit, sub_units, sub_unit) => {
        let values = form.getFieldsValue();
        let unit_of_work = values.cohort_schedule[unit.name]
        let sub_unit_data = sub_unit;

        if (unit_of_work.event_type === "ext") {

            let sub_unit_parent = unit_of_work.cohort_schedule_sub[0];

            let sub_unit = {};

            sub_unit.event_ext = "";
            sub_unit.start_time_sub_unit = (sub_unit_parent?.start_time_sub_unit !== undefined) ? sub_unit_parent?.start_time_sub_unit : '';
            sub_unit.end_time_sub_unit = (sub_unit_parent?.end_time_sub_unit !== undefined) ? sub_unit_parent?.end_time_sub_unit : '';
            sub_unit.event_type_sub_unit = 'ext';
            sub_unit.url_unit_evaluation_sub_unit = (sub_unit_parent?.url_unit_evaluation_sub_unit !== undefined) ? sub_unit_parent.url_unit_evaluation_sub_unit : '';
            sub_unit.start_date_sub_unit = '';
            sub_unit.dates = [];
            sub_unit.end_date_sub_unit = '';

            unit_of_work.cohort_schedule_sub[unit_of_work.cohort_schedule_sub.length - 1] = sub_unit;

            unit_of_work.cohort_schedule_sub.splice(sub_unit_data.name + parseInt(1), 0, unit_of_work.cohort_schedule_sub[unit_of_work.cohort_schedule_sub.length - 1])
            unit_of_work.cohort_schedule_sub.pop()

            unit_of_work.cohort_schedule_sub.map((value, index) => {
                value.event_ext = unit_of_work.unit + alphabet[index]
                return value
            })

            form.setFieldsValue(values)

        }
    }



    let unitOfWork = (values, unit_param) => {

        let unit_of_work = null;

        values.cohort_schedule.forEach(unit => {
            if (unit.unit === unit_param) {
                unit_of_work = unit;
            }
        });

        return unit_of_work;
    }

    /**
     *
     * @param {*} unit
     * @param {*} unit_index
     * this function its executed when the user an unit of type contignuos for ext
     *  then deleted the parent unit and created the first row as sub unit of type ext (with letter)
     */
    let changeEventType = (unit, unit_index) => {
        try {
            let values = form.getFieldsValue();
            let unit_of_work = unitOfWork(values, unit_index)
            if (unit_of_work.event_type === "ext") {
                if (unit_of_work?.dates?.length > 0) {
                    let ids_delete = []
                    ids_delete = unit_of_work.dates.flatMap(event => event.id)
                    if (ids_delete.length > 0) {
                        unit_of_work.ids_for_delete = ids_delete
                        unit_of_work.dates = []
                    }
                }

                let html_element = document.querySelector(
                    `#principal_unit${unit_of_work.unit} div.ant-row`
                );
                if (html_element != null) {
                    html_element.style.display = "none";
                }


                let html_element_sub_unit = document.querySelector(
                    `#principal_unit${unit_of_work.unit} div.sub_unit${unit_of_work.unit}`
                );

                if (html_element_sub_unit != null) {
                    html_element_sub_unit.style.display = "";
                }


                let letter = alphabet[0];

                let index_event = `${unit_of_work.unit}${letter}`;

                let sub_unit = {};

                sub_unit.event_ext = index_event;
                sub_unit.start_date_sub_unit = unit_of_work.start_date;
                sub_unit.end_date_sub_unit = '';
                sub_unit.event_type_sub_unit = 'ext';
                sub_unit.dates = [];
                sub_unit.meeting_type_sub_unit = 2;
                sub_unit.start_time_sub_unit = unit_of_work.start_time;
                sub_unit.end_time_sub_unit = unit_of_work.end_time;
                sub_unit.url_unit_evaluation_sub_unit = unit_of_work.url_unit_evaluation;

                //set data for first sub unit
                unit_of_work.cohort_schedule_sub[0] = sub_unit;
                unit_of_work.dates = [];

            }
        } catch (error) {
            console.log("error change event type on cohort schedule", error);
        }
    }

    /**
     *
     * @param {*} unit
     * @param {*} unit_index
     * this function is executed when the user change an unit of type ext for contignuos
     *  then deleted all sub units and created an only unit parent of type contignuos
     */
    let changeEventTypeSubUnit = (unit, unit_index) => {
        try {
            let values = form.getFieldsValue();
            let unit_of_work = unitOfWork(values, unit_index)
            unit_of_work.event_type = "2_day";
            let ids_delete = []
            if (unit_of_work?.dates?.length > 0) {
                ids_delete = unit_of_work.dates.flatMap(event => event.id)
                if (ids_delete.length > 0) {
                    unit_of_work.ids_for_delete = ids_delete
                    unit_of_work.dates = []
                }
            }

            let html_element = document.querySelector(
                `#principal_unit${unit_of_work.unit} div.ant-row`
            );

            if (html_element != null) {
                html_element.style.display = "";
            }

            let html_element_sub_unit = document.querySelector(
                `#principal_unit${unit_of_work.unit} div.sub_unit${unit_of_work.unit}`
            );

            if (html_element_sub_unit != null) {
                html_element_sub_unit.style.display = "none";
            }


            unit_of_work.start_date = unit_of_work.cohort_schedule_sub[0]?.start_date_sub_unit;
            unit_of_work.start_time = unit_of_work.cohort_schedule_sub[0]?.start_time_sub_unit;
            unit_of_work.end_time = unit_of_work.cohort_schedule_sub[0]?.end_time_sub_unit;
            unit_of_work.meeting_type = 1;
            unit_of_work.cohort_schedule_sub = [];

            let form_values = {
                ...values, ...values.cohort_schedule[unit.name] = unit_of_work
            };

            form.setFieldsValue(form_values);
            handleFormChange({ cohort_schedule: {} }, form_values);

        } catch (error) {
            console.log("error change event type on cohort schedule SUB UNIT", error);
        }
    }

    let validateFacilitatorUnit = (values, unit_row, key, unit_of_work_param) => {
        let arr_users = ["facilitators", "cofacilitators", "webinars"];
        let unit_user = [];
        arr_users.forEach((user) => {
            switch (user) {
                case "facilitators":
                    unit_user = values.facilitators;
                    break;
                case "cofacilitators":
                    unit_user = values.cofacilitators;
                    break;
                case "webinars":
                    unit_user = values.webinars;
                    break;
                default:
                    break;
            }

            let info_unit_orientation = values.orientation.find(
                (v) => v.unit === "Orientation"
            );

            if (unit_user !== undefined && unit_user?.length > 0) {

                unit_user.forEach((item) => {

                    let id_fac = item.user;
                    let arr_dates = [];
                    let id_cohort = values.id;
                    let unit_of_work = "";

                    if (item.unit.includes("all") || item.unit === "all") {

                        let info_units_all = values.cohort_schedule;
                        info_units_all.forEach(unit_all => {
                            if (unit_all.event_type === "ext") {

                                unit_all.cohort_schedule_sub.forEach(unit_all_ext => {
                                    let start_date_sub_unit = unit_all_ext.start_date_sub_unit;

                                    if (start_date_sub_unit !== undefined && start_date_sub_unit !== null) {
                                        arr_dates.push(start_date_sub_unit.format("Y-M-D"));
                                    }
                                })
                            } else {
                                let start_date = unit_all.start_date;
                                let end_date = unit_all.end_date;

                                if (start_date !== undefined && start_date !== null && start_date !== "") {
                                    arr_dates.push(start_date.format("Y-MM-DD"));
                                }
                                if (end_date !== undefined && end_date !== null && end_date !== "") {
                                    arr_dates.push(end_date.format("Y-M-D"));

                                }
                            }
                        })

                        //unit orientation
                        if (info_unit_orientation !== undefined) {
                            let start_date_orientation = info_unit_orientation.start_date;
                            let end_date_orientation = info_unit_orientation.end_date;

                            if (start_date_orientation !== undefined && start_date_orientation !== null) {
                                arr_dates.push(start_date_orientation.format("Y-M-D"));
                            }

                            if (end_date_orientation !== undefined && end_date_orientation !== null) {
                                arr_dates.push(end_date_orientation.format("Y-M-D"));
                            }
                        }
                    } else {
                        //validate if the user have the unit assignment on form, if this is true then validate the date units on database
                        let schedule_valid = item?.unit?.filter((unit_validate) => {
                            if (typeof unit_validate === 'string') {
                                return unit_validate?.split("-")[0] === unit_of_work_param
                            }
                        });

                        if (schedule_valid?.length > 0) {


                            if (unit_row !== null) {
                                //unit
                                if (values.cohort_schedule[unit_row - 1].start_date) {
                                    arr_dates.push(values.cohort_schedule[unit_row - 1].start_date.format("Y-MM-DD"));
                                }

                                if (values.cohort_schedule[unit_row - 1].end_date) {
                                    arr_dates.push(values.cohort_schedule[unit_row - 1].end_date.format("Y-MM-DD"));
                                }

                                //unit ext
                                if (values.cohort_schedule[unit_row - 1].event_type === "ext") {
                                    unit_of_work = values.cohort_schedule[unit_row - 1]
                                    values.cohort_schedule[unit_row - 1].cohort_schedule_sub.forEach(unit_ext => {
                                        if (unit_ext.event_ext === unit_of_work.unit + alphabet[key]) {
                                            if (unit_ext.start_date_sub_unit) {
                                                arr_dates.push(unit_ext.start_date_sub_unit.format("Y-MM-DD"));
                                            }
                                        }
                                    });
                                }
                            } else {
                                //unit orientation
                                if (unit_row === null) {
                                    unit_of_work = values.orientation[0]

                                    if (unit_of_work.start_date) {
                                        arr_dates.push(unit_of_work.start_date.format("Y-MM-DD"));
                                    }
                                }
                            }
                        }
                    }
                    API.post("/api/event/validate-schedule-facilitator", {
                        id_fac: id_fac,
                        dates_events: arr_dates,
                        id_cohort: id_cohort,
                        delivery: form.getFieldValue("delivery_model"),
                        name: form.getFieldValue("name")
                    }).then(({ data }) => {
                        if (data.programed === true) {
                            if (data.onlearn_validate) {
                                confirm({
                                    title: data.message,
                                    icon: <ExclamationCircleOutlined />,
                                    okButtonProps: { type: "primary" },
                                    okText: "Yes",
                                    cancelText: "No",
                                    onCancel() {
                                        removeDateSchedule(unit_row, values, key);
                                    },
                                });
                            } else {
                                Modal.warning({
                                    title: data.message,
                                    icon: <ExclamationCircleOutlined />,
                                    okButtonProps: { type: "primary" },
                                    okText: "OK",
                                    onOk() {
                                        removeDateSchedule(unit_row, values, key);
                                    },
                                });
                            }
                        }
                    })
                        .catch(() => {

                        });

                });
            }

        });
    }

    let removeDateSchedule = (unit_row, values, key) => {
        if (unit_row === null) {
            values.orientation[0].start_date = "";
            form.setFieldsValue(values);
        } else {
            if (values.cohort_schedule[unit_row - 1].event_type === "ext") {
                values.cohort_schedule[unit_row - 1].cohort_schedule_sub[key].start_date_sub_unit = "";
                form.setFieldsValue(values);
            } else {
                values.cohort_schedule[unit_row - 1].start_date = "";
                values.cohort_schedule[unit_row - 1].end_date = "";
                form.setFieldsValue(values);
            }
        }
    }



    let handleChangeDate = (date_working = null, unit_row = null, type = null, key = null) => {

        let values = form.getFieldsValue();
        let unit_of_work = {};
        if (unit_row !== null) {
            unit_of_work = unitOfWork(values, unit_row)
        } else {
            unit_of_work = values.orientation[0]
        }

        let end_date = null;
        let start_date = null;
        start_date = unit_of_work?.start_date;
        end_date = unit_of_work?.end_date;
        let validate_schedule = "";
        if (unit_of_work?.event_type === "2_day") {
            validate_schedule = unit_row;
        } else {
            if (unit_of_work?.dates?.length > 0) {
                validate_schedule = values.cohort_schedule.find(event => event.unit === unit_row)?.cohort_schedule_sub[key]?.event_ext;
            }
        }

        if (start_date !== undefined && start_date !== null && end_date !== undefined && end_date !== null && start_date !== "" && end_date !== "") {
            let start_date_after = start_date.isAfter(end_date)
            if (start_date_after) {
                Modal.warning({
                    content: "The unit Start Date should not be higher than his End Date, please review."
                });
            }
        }
        validateFacilitatorUnit(values, unit_row, key, validate_schedule);
    }

    let handleChangeTime = (unit_row = null, value, field_name = null) => {
        let values = form.getFieldsValue();
        let unit_of_work = {};
        if (unit_row !== null) {
            unit_of_work = unitOfWork(values, unit_row)
        } else {
            unit_of_work = values.orientation[0]
        }

        let start_time = unit_of_work.start_time;
        let end_time = unit_of_work.end_time;
        if (start_time !== undefined && start_time !== null && end_time !== undefined && end_time !== null) {
            let start_time_after = start_time.isAfter(end_time)
            if (start_time_after) {
                Modal.warning({
                    content: "The unit Start Time should not be higher than his End Time, please review."
                });
            }
        }

        if (value !== undefined) {
            validateHoursWork(value)
        }

        cloneTimes(unit_of_work, field_name, values)
    }

    let cloneTimes = (unit_of_work, field_name, values) => {
        if (unit_of_work.unit === FIRST_UNIT) {
            let applied_times_validation = false;
            values.cohort_schedule.forEach(function (unit, index) {
                if (unit.unit === FIRST_UNIT) {
                    return;
                }
                if (unit.event_type === EVENT_TYPE_EXT && unit.cohort_schedule_sub.length > 0) {
                    unit.cohort_schedule_sub.forEach(function (sub_unit) {
                        if (sub_unit[`${field_name}_sub_unit`] !== null && sub_unit[`${field_name}_sub_unit`] !== undefined) {
                            applied_times_validation = true
                        }
                    })
                } else if (unit[field_name] !== null && unit[field_name] !== undefined) {
                    applied_times_validation = true;
                }
            })

            if (applied_times_validation) {
                Modal.confirm({
                    content: 'Apply this time to all other units?',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk() {
                        setTimesClone(values, unit_of_work, field_name)
                    }
                });
            } else {
                setTimesClone(values, unit_of_work, field_name)
            }
        }
    }

    let setTimesClone = (values, unit_of_work, field_name) => {
        let start_time = null;
        let end_time = null;
        if (unit_of_work.event_type === EVENT_TYPE_TWO_DAY) {
            start_time = unit_of_work.start_time
            end_time = unit_of_work.end_time
        } else if (unit_of_work.event_type === EVENT_TYPE_EXT) {
            start_time = unit_of_work.cohort_schedule_sub[0]?.start_time_sub_unit;
            end_time = unit_of_work.cohort_schedule_sub[0]?.end_time_sub_unit
        }
        values.cohort_schedule.forEach(function (unit) {
            if (unit.event_type === EVENT_TYPE_EXT && unit.cohort_schedule_sub.length > 0) {
                unit.cohort_schedule_sub.forEach(sub_unit => { sub_unit[`${field_name}_sub_unit`] = field_name === 'start_time' ? start_time : end_time; })
            } else {
                unit[field_name] = field_name === 'start_time' ? start_time : end_time;
            }
        })

        form.setFieldsValue(values)
    }

    let handleChangeTimeSubUnit = (unit_row, value, field_name) => {
        if (value !== undefined) {
            validateHoursWork(value)
        }

        let values = form.getFieldsValue();
        let unit_of_work = {};
        if (unit_row !== null) {
            unit_of_work = unitOfWork(values, unit_row)
        }

        cloneTimes(unit_of_work, field_name, values)
    }

    let validateHoursWork = (time) => {
        try {
            let start_time_limit = moment(moment().format("Y-M-D") + " " + "07:00");
            let end_time_limit = moment(moment().format("Y-M-D") + " " + "20:01");
            let start_time_before = time?.isBefore(start_time_limit)
            let end_time_before = time?.isAfter(end_time_limit)

            if (start_time_before || end_time_before) {
                Modal.warning({
                    content: "Please review the entered Start time and End time.  They seems earlier or later than normal work hours.  Change the times entered if necessary."
                });
            }
        } catch (error) {
            console.log("validate times", error);
        }
    }

    let removeSubUnit = (sub_unit, unit, unit_row) => {
        let values = form.getFieldsValue();
        let unit_of_work = values.cohort_schedule[unit.name]

        unit_of_work.dates.splice(sub_unit, 1)

        if (unit_of_work.event_type === "ext") {
            unit_of_work.cohort_schedule_sub.map((value, index) => {
                value.event_ext = unit_of_work.unit + alphabet[index]
                return value
            })


            form.setFieldsValue(values)

        }
    }

    return (
        <>
            <Row className='unique_event_2' style={{ width: '100%', display: isExecutiveCourse ? 'block' : 'none' }}>
                <Col xs={24}>
                    <Row>
                        <Col span={24}><strong>Cohort Schedule</strong></Col>
                    </Row>
                    <Row>
                        <Col span={6}><label>Start Date</label></Col>
                        <Col span={6}><label>End Date</label></Col>
                    </Row>
                    <Row style={{ marginLeft: "0px", alignItems: "baseline" }} gutter={[5, 0]} >
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: "11px " }}
                                name={'start_date_cohort'}
                                fieldKey={'start_date_cohort'}
                            >
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: "11px " }}
                                name={'end_date_cohort'}
                                fieldKey={'end_date'}
                            >
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='complete_cohort_schedule_2'>
                <Col xs={24}>
                    <Row>
                        <Col span={24}><strong>Cohort Schedule</strong></Col>
                    </Row>
                    <Row>
                        <Col xs={2}><label>Unit</label></Col>
                        <Col className="dates_schedule" xs={2}><label>Start Date</label></Col>
                        <Col className="dates_schedule" xs={2}><label>End Date</label></Col>
                        <Col className="notshowexecutive" xs={2}><label>Meeting</label></Col>
                        <Col xs={2}><label>Event Type</label></Col>
                        <Col className="notshowexecutive" xs={8}><label>URL for the Unit Evaluation</label></Col>
                        <Col xs={4}><label>Start & End time (enter ET)</label></Col>
                    </Row>
                    <Row style={{ display: isExecutiveCourse ? 'none' : 'block' }}>
                        <Form.List name="orientation">
                            {
                                (fields, { add, remove }) => {
                                    return (
                                        <>
                                            {fields.map(field => (
                                                <Row style={{ marginLeft: "0px", alignItems: "baseline" }} gutter={[5, 0]} key={field.name}>
                                                    <Col xs={2}>
                                                        <Form.Item
                                                            style={{ marginBottom: "11px " }}
                                                            {...field}
                                                            name={[field.name, 'unit']}
                                                            fieldKey={[field.fieldKey, 'unit']}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={2} className="dates_schedule">
                                                        <Form.Item
                                                            style={{ marginBottom: "11px " }}
                                                            {...field}
                                                            name={[field.name, 'start_date']}
                                                            fieldKey={[field.fieldKey, 'start_date']}
                                                        >
                                                            <DatePicker onChange={(value) => { handleChangeDate(value, null, 'start_date') }} style={{ width: "100%" }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="dates_schedule" xs={2}>
                                                        <Form.Item
                                                            style={{ marginBottom: "11px " }}
                                                            {...field}
                                                            name={[field.name, 'end_date']}
                                                            fieldKey={[field.fieldKey, 'end_date']}
                                                        >
                                                            <DatePicker onChange={(value) => { handleChangeDate(value, null, 'end_date') }} style={{ width: "100%" }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={2} style={{ display: isExecutiveCourse ? 'none' : 'block' }}>
                                                        <Row style={{ textAlign: "center" }}>
                                                            <Col xs={24}>
                                                                <Form.Item
                                                                    style={{ marginBottom: "11px " }}
                                                                    {...field}
                                                                    name={[field.name, 'meeting_type']}
                                                                    fieldKey={[field.fieldKey, 'meeting_type']}
                                                                >
                                                                    <Select options={meetingTypes} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <Row style={{ textAlign: "center" }}>
                                                            <Col xs={24}>
                                                                <Form.Item
                                                                    style={{ marginBottom: "11px " }}
                                                                    {...field}
                                                                    name={[field.name, 'event_type']}
                                                                    fieldKey={[field.fieldKey, 'event_type']}

                                                                >
                                                                    <Select value={"n/a"} disabled={isExecutiveCourse ? true : false}  >
                                                                        <Select.Option value={"2_day"}>Contiguous</Select.Option>
                                                                        <Select.Option value={"ext"}>Ext</Select.Option>
                                                                        <Select.Option value={"n/a"}>n/a</Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: "11px " }}
                                                            {...field}
                                                            name={[field.name, 'url']}
                                                            fieldKey={[field.fieldKey, 'url']}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <Form.Item
                                                            style={{ marginBottom: "11px " }}
                                                            {...field}
                                                            name={[field.name, 'start_time']}
                                                            fieldKey={[field.fieldKey, 'start_time']}
                                                        >
                                                            <TimePicker use12Hours onChange={(value) => { handleChangeTime(null, value) }} format="h:mm a" style={{ width: "100%" }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <Form.Item
                                                            style={{ marginBottom: "11px " }}
                                                            {...field}
                                                            name={[field.name, 'end_time']}
                                                            fieldKey={[field.fieldKey, 'end_time']}
                                                        >
                                                            <TimePicker use12Hours onChange={(value) => { handleChangeTime(null, value) }} format="h:mm a" style={{ width: "100%" }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={1} style={{ paddingLeft: "0px", paddingTop: "5px" }}>
                                                        <MinusCircleOutlined onClick={() => { remove(field.name); setShowOrientation(false) }} />
                                                    </Col>
                                                </Row>
                                            ))}
                                        </>
                                    )
                                }}
                        </Form.List>
                    </Row>
                </Col>
                <Col xs={24}>
                    <Form.List name="cohort_schedule">
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    {
                                        fields.map(field => {
                                            let values;
                                            let unit_row = null;
                                            values = form.getFieldsValue();
                                            unit_row = values.cohort_schedule ? values.cohort_schedule[field.name] : undefined;
                                            if (unit_row !== undefined) {
                                                unit_row = unit_row.unit
                                            }
                                            return (
                                                <div id={`principal_unit${unit_row}`}>
                                                    <Row style={{ marginLeft: "0px", alignItems: "baseline" }} gutter={[5, 0]} key={field.name}>
                                                        <Col xs={2}>
                                                            <Form.Item
                                                                style={{ marginBottom: "11px " }}
                                                                {...field}
                                                                name={[field.name, 'unit']}
                                                                fieldKey={[field.fieldKey, 'unit']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={2} className="dates_schedule" id={`start_date${unit_row}`}>
                                                            <Row style={{ textAlign: "center" }}>
                                                                <Col xs={24}>
                                                                    <Form.Item
                                                                        className="start_date"
                                                                        style={{ marginBottom: "11px " }}
                                                                        {...field}
                                                                        name={[field.name, 'start_date']}
                                                                        fieldKey={[field.fieldKey, 'start_date']}
                                                                    >
                                                                        <DatePicker onChange={(value) => { handleChangeDate(value, unit_row, 'start_date') }} style={{ width: "100%" }} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={2} className="dates_schedule" id={`end_date${unit_row}`}>
                                                            <Row style={{ textAlign: "center" }}>
                                                                <Col xs={24}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: "11px " }}
                                                                        {...field}
                                                                        name={[field.name, 'end_date']}
                                                                        fieldKey={[field.fieldKey, 'end_date']}
                                                                    >
                                                                        <DatePicker onChange={(value) => { handleChangeDate(value, unit_row, 'end_date') }} style={{ width: "100%" }} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={2} style={{ display: isExecutiveCourse ? 'none' : 'block' }}>
                                                            <Row style={{ textAlign: "center" }}>
                                                                <Col xs={24}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: "11px " }}
                                                                        {...field}
                                                                        name={[field.name, 'meeting_type']}
                                                                        fieldKey={[field.fieldKey, 'meeting_type']}
                                                                    >
                                                                        <Select options={meetingTypes} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <Row style={{ textAlign: "center" }}>
                                                                <Col xs={24}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: "11px " }}
                                                                        //{...field}
                                                                        name={[field.name, 'event_type']}
                                                                        fieldKey={[field.fieldKey, 'event_type']}
                                                                        defaultValue="ext"
                                                                    >
                                                                        <Select onChange={() => { changeEventType(field, unit_row) }}  >
                                                                            <Select.Option value={"2_day"}>Contiguous</Select.Option>
                                                                            <Select.Option value={"ext"}>Ext</Select.Option>
                                                                            <Select.Option value={"n/a"}>n/a</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={8} style={{ display: isExecutiveCourse ? 'none' : 'block' }}>
                                                            <Row style={{ textAlign: "center" }}>
                                                                <Col xs={24}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: "11px " }}
                                                                        {...field}
                                                                        name={[field.name, 'url_unit_evaluation']}
                                                                        fieldKey={[field.fieldKey, 'url_unit_evaluation']}
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={2} id={`start_time${unit_row}`}>
                                                            <Row style={{ textAlign: "center" }}>
                                                                <Col xs={24}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: "11px " }}
                                                                        {...field}
                                                                        name={[field.name, 'start_time']}
                                                                        fieldKey={[field.fieldKey, 'start_time']}
                                                                    >
                                                                        <TimePicker
                                                                            onChange={(value) => handleChangeTime(unit_row, value, 'start_time')} use12Hours format="h:mm a" style={{ width: "100%" }} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={2} id={`end_time${unit_row}`}>
                                                            <Row style={{ textAlign: "center" }}>
                                                                <Col xs={24}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: "11px " }}
                                                                        {...field}
                                                                        name={[field.name, 'end_time']}
                                                                        fieldKey={[field.fieldKey, 'end_time']}
                                                                    >
                                                                        <TimePicker onChange={(value) => handleChangeTime(unit_row, value, 'end_time')} use12Hours format="h:mm a" style={{ width: "100%" }} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className={`sub_unit${unit_row}`}>
                                                        <Col xs={24}>
                                                            <Form.List name={[field.name, 'cohort_schedule_sub']}>
                                                                {(sub_units, { add, remove }) => {

                                                                    return (
                                                                        <>
                                                                            <Row >
                                                                                <Col xs={24}>
                                                                                    {sub_units.map(sub_unit => (

                                                                                        <>
                                                                                            <Row className={`sub_unit_${unit_row}_${sub_unit.key}`} style={{ marginLeft: "0px", alignItems: "baseline" }} gutter={[5, 0]}>
                                                                                                <Col xs={2}>
                                                                                                    <Form.Item
                                                                                                        style={{ marginBottom: "11px " }}
                                                                                                        {...sub_unit}
                                                                                                        name={[sub_unit.name, 'event_ext']}
                                                                                                        fieldKey={[sub_unit.fieldKey, 'event_ext']}

                                                                                                    >
                                                                                                        <Input readOnly />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col xs={2} className="dates_schedule">
                                                                                                    <Form.Item
                                                                                                        style={{ marginBottom: "11px " }}
                                                                                                        className="start_date"
                                                                                                        {...sub_unit}
                                                                                                        name={[sub_unit.name, 'start_date_sub_unit']}
                                                                                                        fieldKey={[sub_unit.fieldKey, 'start_date_sub_unit']}
                                                                                                    >
                                                                                                        <DatePicker onChange={(value) => { handleChangeDate(value, unit_row, 'start_date_sub_unit', sub_unit.key) }} style={{ width: "100%" }} />
                                                                                                    </Form.Item>
                                                                                                </Col>

                                                                                                <Col xs={2} className="dates_schedule">
                                                                                                    <Form.Item
                                                                                                        style={{ marginBottom: "11px " }}
                                                                                                        {...sub_unit}
                                                                                                        name={[sub_unit.name, 'end_date_sub_unit']}
                                                                                                        fieldKey={[sub_unit.fieldKey, 'end_date_sub_unit']}
                                                                                                    >
                                                                                                        <DatePicker onChange={(value) => { handleChangeDate(value, unit_row, sub_unit.name + 'end_date_sub_unit', sub_unit.key) }} disabled style={{ width: "100%" }} />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col xs={2} style={{ display: isExecutiveCourse ? 'none' : 'block' }}>
                                                                                                    <Row style={{ textAlign: "center" }}>
                                                                                                        <Col xs={24}>
                                                                                                            <Form.Item
                                                                                                                style={{ marginBottom: "11px " }}
                                                                                                                {...sub_unit}
                                                                                                                name={[sub_unit.name, 'meeting_type_sub_unit']}
                                                                                                                fieldKey={[sub_unit.fieldKey, 'meeting_type_sub_unit']}
                                                                                                            >
                                                                                                                <Select options={meetingTypes} />
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                                <Col xs={2}>
                                                                                                    <Row style={{ textAlign: "center" }}>
                                                                                                        <Col xs={24}>
                                                                                                            <Form.Item
                                                                                                                style={{ marginBottom: "11px " }}
                                                                                                                {...sub_unit}
                                                                                                                name={[sub_unit.name, 'event_type_sub_unit']}
                                                                                                                fieldKey={[sub_unit.fieldKey, 'event_type_sub_unit']}
                                                                                                            >
                                                                                                                <Select onChange={() => { changeEventTypeSubUnit(field, unit_row) }} disabled={isExecutiveCourse ? true : false} >
                                                                                                                    <Select.Option value={"2_day"}>Contiguous</Select.Option>
                                                                                                                    <Select.Option value={"ext"}>Ext</Select.Option>
                                                                                                                    <Select.Option value={"n/a"}>n/a</Select.Option>
                                                                                                                </Select>
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                                <Col xs={8} style={{ display: isExecutiveCourse ? 'none' : 'block' }}>
                                                                                                    <Form.Item
                                                                                                        style={{ marginBottom: "11px " }}
                                                                                                        {...sub_unit}
                                                                                                        name={[sub_unit.name, 'url_unit_evaluation_sub_unit']}
                                                                                                        fieldKey={[sub_unit.fieldKey, 'url_unit_evaluation_sub_unit']}
                                                                                                    >
                                                                                                        <Input />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col xs={2}>
                                                                                                    <Form.Item
                                                                                                        style={{ marginBottom: "11px " }}
                                                                                                        {...sub_unit}
                                                                                                        name={[sub_unit.name, `start_time_sub_unit`]}
                                                                                                        fieldKey={[sub_unit.fieldKey, 'start_time_sub_unit']}
                                                                                                    >
                                                                                                        <TimePicker onChange={(value) => handleChangeTimeSubUnit(unit_row, value, 'start_time')} use12Hours format="h:mm a" style={{ width: "100%" }} />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col xs={2}>
                                                                                                    <Form.Item
                                                                                                        style={{ marginBottom: "11px " }}
                                                                                                        {...sub_unit}
                                                                                                        name={[sub_unit.name, 'end_time_sub_unit']}
                                                                                                        fieldKey={[sub_unit.fieldKey, 'end_time_sub_unit']}
                                                                                                    >
                                                                                                        <TimePicker onChange={(value) => handleChangeTimeSubUnit(unit_row, value, 'end_time')} use12Hours format="h:mm a" style={{ width: "100%" }} />
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col xs={1} >
                                                                                                    <MinusCircleOutlined onClick={async () => { await remove(sub_unit.name); await removeSubUnit(sub_unit.name, field, unit_row) }} >
                                                                                                    </MinusCircleOutlined>
                                                                                                    <PlusCircleOutlined style={{ marginLeft: "3px" }} className="btn_add_sub_unit" parent-unit={`${unit_row}`} id={`btn_add_event${unit_row}`} onClick={() => {
                                                                                                        add();
                                                                                                        addSubUnit(field, sub_units, sub_unit)
                                                                                                    }}>
                                                                                                        <Col xs={4}>
                                                                                                            <Form.Item
                                                                                                                style={{ marginBottom: "11px " }} >
                                                                                                                <Button type="dashed" onClick={() => {
                                                                                                                    add();
                                                                                                                    addSubUnit(field, sub_units, sub_unit)
                                                                                                                }} block icon={<PlusOutlined />}>
                                                                                                                </Button>
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </PlusCircleOutlined>
                                                                                                </Col>
                                                                                            </Row>

                                                                                        </>
                                                                                    ))}
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )
                                                                }}
                                                            </Form.List>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                </>


                            )
                        }}
                    </Form.List>
                </Col>
            </Row>
        </>
    );
};
