import React from "react";
import { Table } from "antd";
import ImageCertificateCourse from "../../../components/ImageCertificateCourse";
import moment from "moment";

const CoursesList = ({ courses, getDataPaginate, LoadingTable, handleDownload, getCourses, currentPage }) => {

    const columns = [
        {
            title: "Course ID",
            dataIndex: "name",
            width: '15em',
            rowKey: "id",
            render: (value, record) => {
                return <span> {record.key.replace("cohort", "")} </span>
            }
        },
        {
            title: "Course",
            dataIndex: "name",
            width: '120em',
            rowKey: "name",
            render: (value, record) => {
                if (record.certificate_image_url) {
                    return (
                        <span style={{ color: "#0051a0", cursor:'pointer' }} onClick={() => {
                            handleDownload(record.certificate_image_url, record.certificate_image_name)
                        }}>{value}</span>
                    )
                } else {
                    return value;
                }
            }
        },
        {
            title: "Abreviation",
            dataIndex: "abreviation",
            width: '120em',
            rowKey: "abreviation",
        },
        {
            title: "Certificate Template",
            dataIndex: "certificate",
            width: '120em',
            rowKey: "certificate",
            render: (value, record) => {
                return (
                    <ImageCertificateCourse imageUrl={record.certificate_image_url} imageName={record.certificate_image_name} courseId={record.id} getCourses={getCourses} />
                )
            }
        },
        {
            title: "Uploaded",
            dataIndex: "certificate_image_date",
            width: "10%",
            rowKey: "certificate_image_date",
            padding: 10,
            align: "center",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {record?.image_saved_by?.last_name} <br />
                            {moment(value).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }

        },
    ];

    return (
        <div>
            <Table
                className="table_courses"
                columns={columns}
                dataSource={courses.data}
                loading={LoadingTable}
                onChange={getDataPaginate}
                rowClassName={() => "responsive-row"}
                pagination={{
                    current: currentPage,
                    defaultPageSize: 10,
                    total: courses.total,
                    showSizeChanger: false,
                    showQuickJumper: false,
                }}
            />
        </div>
    );
}

export default CoursesList;
