import React, { useState } from "react";
import { Layout, Spin } from "antd";
import { pinStyle } from "../Dashboard/Dashboard.style";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

const OnlineLearning = () => {
    const [loadOnlineCourse, setLoadOnlineCourse] = useState(false);
    const { token } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_PORTAL_URL, REACT_APP_URL } = process.env;

    let query = useQuery();

    let url_iframe = `${REACT_APP_PORTAL_URL}?view=onlinecourses_ncee&token=${token}&redirect_app=${REACT_APP_URL}/online-learning&from_online_learn=true`;
    let redirect_iframe = query.get("current_url");
    if (redirect_iframe !== null) {
        url_iframe= `${url_iframe}&url_redirect=${redirect_iframe}`;
    }

    return (
        <Content className="site-layout-background layout-portal-content">
            <div className="layout-portal-main-container">
                {!loadOnlineCourse ? (
                    <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
                ) : null}
                <iframe
                    src={url_iframe}
                    frameBorder={"0"}
                    title="action-learning"
                    onLoad={() => setLoadOnlineCourse(true)}
                    className="style-frame"
                />
            </div>
        </Content>
    );

};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}



export default OnlineLearning;
