import React from "react";
import { Table, Space, Button, Switch } from "antd";
import moment from "moment";

const ListLogonPopups = ({ logonpopups, deleteLogonPopup, editLogonPopup, setEdit }) => {
    const columns = [
        {
            title: "Title",
            dataIndex: "headline",
            sorter: (a, b) => {
                if (a.headline > b.headline) {
                    return 1;
                }
                if (a.headline < b.headline) {
                    return -1;
                }
                return 0;
            },
        },
        {
            title: "Created",
            key: "created_at",
            width: 120,
            align: "center",
            dataIndex: "created_at",
            className : "column_without_padding",
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {record.who_created?.last_name} <br /> {moment(value).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Status",
            key: "status",
            width: "5%",
            dataIndex: "active",
            render: (text, record) =>
                <>
                    <Switch
                        onClick={() => {
                            deleteLogonPopup(record.id);
                        }}
                        defaultChecked={record.active}
                        checked={record.active}
                    />
                </>

        },
        {
            title: "Displayed to",
            key: "created_at",
            dataIndex: "roles",
            width: 120,
            render: (value) => {
                let roles = value.map(v => v.name);
                return <span>{roles.length > 0 ? roles.join(", ") : "All Users"}</span>
            }
        },
        {
            title: "Activated",
            key: "activated",
            width:120,
            dataIndex: "who_activated",
            align: "center",
            className : "column_without_padding",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {value.last_name} <br /> {moment(record.activated_at).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "De-Activated",
            key: "who_deactivated",
            width:120,
            dataIndex: "who_deactivated",
            align: "center",
            className : "column_without_padding",
            render: (value, record) => {
                if (value) {
                    return (
                        <span>
                            {value.last_name}
                                <br />
                            {moment(record.de_activated_at).format("YY-MM-DD hh:mm")}
                        </span>
                    )
                } else {
                    return null;
                }
            }
        },
        {
            title: "Modified",
            key: "who_updated",
            dataIndex: "who_updated",
            width: 120,
            align: "center",
            className : "column_without_padding",
            defaultSortOrder: "descend",
            sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
            render: (value, record) => {
                if(value){
                    return (
                        <span>
                            {value.last_name}
                            <br/>
                            {moment(record.updated_at).format("YY-MM-DD")} {moment(record.updated_at).format("hh:mm")}
                        </span>
                    )
                }else{
                    return null;
                }

            }


        },
        {
            title: "Action",
            key: "action",
            align: "center",
            className :"column_without_padding table_action",
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        onClick={() => {
                            let copy_record = JSON.parse(JSON.stringify(record));
                            setEdit(true)
                            editLogonPopup(copy_record);

                        }}>
                        Edit
                    </Button>
                </Space>
            )

        },

    ];

    return (
        <>
            <Table size="small"  columns={columns} dataSource={logonpopups} rowKey="id" />
        </>
    );
};

export default ListLogonPopups;
