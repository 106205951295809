import React, { useState } from "react";
import { Modal, Row, Col, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import Header from "../../components/user/Header";
import HeaderTitle from "../../components/user/HeaderTitle";
import { useHistory } from "react-router-dom";
import PolicyModal from "../../components/PolicyModal";

const EventsUser = ({ showModal, onCancel, bodyEvents, infoUserSchedule, setIsVisibleModal, setShowEvents }) => {
    const [showAttendancePolicies, setShowAttendancePolicies] = useState(false)
    const history = useHistory();

    let handleChangeToProfile = () => {
        setIsVisibleModal(false)
        setShowEvents(false)
        localStorage.setItem('show_participant_view', true);
        history.push("/profile")
    }
    return (
        <>
            <Modal
                width="77%"
                title={<HeaderText setShowAttendancePolicies={setShowAttendancePolicies} data={infoUserSchedule} handleChangeToProfile={handleChangeToProfile} />}
                visible={showModal}
                centered
                onCancel={() => setShowEvents(false)}
                bodyStyle={{
                    maxHeight: window.innerHeight - 425 + "px",
                    overflowY: "scroll",
                    paddingTop: "0px"
                }}
                className="events_user"
                footer={[
                    <div>
                        <Button onClick={() => { PrinToPdf(true) }} style={{ width: '5%', padding: '0px' }} icon={<PrinterOutlined style={{ fontSize: '23px', height:'90%' }} />}></Button>
                        <Button key="buttonEventUser" type="primary" onClick={() => setShowEvents(false)}>OK</Button>
                    </div>
                ]}
            >
                <iframe id="ifmcontentstoprint" title="ifmcontentstoprint" style={{ height: "0px", width: "0px", position: "absolute", }} ></iframe>
                <div className="content-events" style={{ backgroundColor: "#f1f1f1" }}>
                    {bodyEvents !== undefined && Object.keys(bodyEvents).length > 0 ? bodyEvents : ''}
                </div>
            </Modal>
            <PolicyModal visible={showAttendancePolicies} setVisible={setShowAttendancePolicies}></PolicyModal>
        </>
    );
};
const HeaderText = ({ data, setShowAttendancePolicies, handleChangeToProfile }) => {
    return (
        <>
            <HeaderTitle user={data?.user}></HeaderTitle>
            <Header user={data?.user} handleChangeToProfile={handleChangeToProfile} fromUser={true} />
            <Row>
                <Col span={14} >
                    {!data.enrrolled_cohort.delivery_model.is_virtual ?
                        <span style={{ color: "#313777", float: "left" }}>
                            {data?.enrrolled_cohort?.city}, {data?.enrrolled_cohort?.state?.code}  &#8226; {data?.enrrolled_cohort?.name}
                        </span>
                        : <span style={{ color: "#313777", float: "left" }}>
                            {data?.enrrolled_cohort?.name}
                        </span>}
                </Col>
                <Col span={10} style={{ textAlign: 'end' }}>
                    <span className="course_header">
                        Course: <span style={{ color: "#b39336" }}>{data?.enrrolled_cohort?.course?.name} </span>
                    </span>
                </Col>
            </Row>
            <hr style={{ marginTop: "0px", marginBottom: "5px" }} />
            <Row style={{ marginBottom: '-15px' }}>
                <p style={{ textAlign: "initial", fontSize: '14px' }} >
                    If the attendance data below is incorrect or incomplete, please contact your facilitator.
                    For PA participants, please contact your Regional Program Lead.
                    <Button className='attendance_policies' style={{ paddingTop: "0px", paddingLeft: "0px" }} type={"link"} onClick={() => setShowAttendancePolicies(true)}>
                        <span style={{ textDecoration: 'underline' }}> Click here for the NISL Attendance Policy </span>
                    </Button>
                </p>
            </Row>
            <Row >
                <Col span={18} offset={3} style={{ textAlign: 'center' }} className='absent_percent'>
                    <div style={{ fontWeight: "bold", color: "#f31515" }}>Your facilitator has marked you in attendance for {data?.avg_attendance?.total_attendance} of {data?.completed_units?.length} completed units.
                        <span className="avg_attendance" style={{ fontWeight: "bold", color: "#f31515" }}  >
                            &nbsp;({data?.avg_attendance?.percentaje_attendance?.toFixed(0)}% attendance)
                        </span>
                    </div>
                </Col>
            </Row>
        </>
    );
};
const PrinToPdf = (showFromCourse) => {
    document.querySelector(".avg_attendance").style.color = "red";
    let data = document.querySelector("div.events_user .ant-modal-body");
    let data_header = document.querySelector("div.events_user .ant-modal-title");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(
        `<style type="text/css" media="print">
    .ant-row {
        display: flex;
        flex-flow: row wrap;
    }
    @page
    {
        margin:0.87cm 0.87cm 0.87cm 0.87cm;
        size: landscape; 
    }
    body
    {
        padding-top: 8px;
        font-family: sans-serif;
        font-size: 15px;
        background-color:#FFFFFF;
   }
    @media (min-width: 1200px)
    .ant-col-xl-9 {
        display: block;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
        background-color : orange;
    }
    .ant-col {
        min-width: 13%;
    }
    
    .ant-col div.day-info{
        width: 150px;
    }

    .ant-col div.column_pdf{
        width: 350px;
        height: 20px !important;
        padding-top: 17px !important;
    }

    .ant-col div.column_attendance{
        width: 580px !important;
        height: 20px !important;
        margin-left : 30px !important;
        padding-top: 10px !important;
    }

    .ant-col.year_blank{
        min-width: 500px;
    }

    div.attendance_mark {
        width: 100% !important;;
        margin-top : 8px !important;
    }

    .column_makeups{
        height: 50px !important;
    }

    .ant-col div.column_makeups{
        width: 265px;
        padding-top: 5px;
    }

    .user-header{
        height: 125px !important;
    }

    .pages-profile-upload-avatar {
        margin-top: 10px;
        margin-left: 80px !important;
    }

    .edit-profile-button {
        display: none;
    }

    .pages-profile-upload-avatar-img {
        border-radius: 100px !important;
        width: 100px;
        height: 100px;
    }

    .column-profile{
        width: 550px;
        font-size: 15px !important;
        margin-top: 20px !important;
        margin-left: 35px !important;
    }

    .divider-user-schedule{
        background-color : #c1c1c1 !important;
        height: 2px !important;
    }

    .print_icon {
        display: none
    }
    
    .percent_attendance {
        display: none
    }

    .course_header{
        margin-left: 675px;
    }

    .absent_percent{
        margin-left: 250px;
        margin-right: 250px;
        margin-top: 10px;
    }

    .district-info{
        margin-left: 500px;
    }

    .attendance_policies{
        display:none !important;
    }

    </style>
    <style type="text/css" media="print">
    * {
        -webkit-print-color-adjust: exact !important; /*Chrome, Safari */ color-adjust: exact !important;  /*Firefox*/
       }
    </style>`);
    pri.document.write(data_header.innerHTML + data.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();

};
export default EventsUser;
