import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Modal } from 'antd';
import API from '../../../api';
import ListLogonPopups from './ListLogonPopup';
import FormLogonPopup from './FormLogonPopup';
import { ExclamationCircleOutlined } from '@ant-design/icons';



const LogonPopup = () => {
    const [form] = Form.useForm();
    const [visibleFormLogonPopup, setVisibleFormLogonPopup] = useState(false);
    const [textArea, setTextArea] = useState("");
    const [roles, setRoles] = useState([]);
    const [logonPopups, setLogonPopups] = useState([]);
    const [edit, setEdit] = useState(false)


    const handleOk = () => {
        setVisibleFormLogonPopup(false);
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                values.text = textArea;
                if (edit) {
                    handleUpdate(values);
                } else {
                    handleCreate(values);
                }
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }
    const handleCancel = () => {
        setVisibleFormLogonPopup(false)
    }

    const handleDelete = (id) => {
        API.delete(`api/logon-popup/${id}`).then(({ data }) => getLogonPopups()).catch(console.log);
    }

    const handleEdit = (values) => {
        setVisibleFormLogonPopup(true);
        setTextArea(values.text);
        console.log(values);
        values.display_to = values.roles.map(v => {
            return v.id;
        });
        form.setFieldsValue(values)
    }

    const handleCreate = (values) => {
        API.post("api/logon-popup", values)
            .then(({ data }) => getLogonPopups())
            .catch(({ response: { data } }) => { console.log(data) })
    }

    const handleUpdate = (values) => {
        console.log(values)
        API.put(`api/logon-popup/${values.id}`, values)
            .then(({ data }) => getLogonPopups())
            .catch(({ response: { data } }) => { console.log(data) })
    }
    const getLogonPopups = () => API.get("/api/logon-popup")
        .then(({ data }) => setLogonPopups(data))
        .catch(console.log);

    const showDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this permanently? You can deactivate it instead, if necessary.',
            icon: <ExclamationCircleOutlined style={{color: "#ecd62a"}} />,
            onOk() {
                API.delete(`api/logon-popup/delete/${id}`).then(() => {
                    setVisibleFormLogonPopup(false);
                    getLogonPopups();
                }).catch();
            }
        });
    }


    useEffect(() => {
        getLogonPopups();
        API.get("/api/role").then(({ data }) => setRoles(data.roles));
    }, [])

    return (
        <>
            <Row>

                <Button
                    type="primary"
                    onClick={() => {
                        form.setFieldsValue({
                            active: false,
                            headline: "",
                            display_to: []
                        });
                        setTextArea("");
                        setEdit(false);
                        setVisibleFormLogonPopup(true);
                    }}
                >
                    Add New Logon Pop
                </Button>
            </Row>
            <br />
            <ListLogonPopups
                logonpopups={logonPopups} setEdit={setEdit} editLogonPopup={handleEdit}
                deleteLogonPopup={handleDelete}  />
            <FormLogonPopup
                form={form}
                visible={visibleFormLogonPopup}
                handleCancel={handleCancel}
                handleOk={handleOk}
                roles={roles}
                setTextArea={setTextArea}
                textArea={textArea}
                showDelete={showDelete}
                edit={edit} />

        </>
    )
}

export default LogonPopup
