import React, { useEffect, useState } from 'react';
import  API  from "../../api";


const Banner = ({setShowBannerHeader}) => {

    const [messageBanner,setMessageBanner] = useState("")
    const [showBannerNotification,setShowBannerNotification] = useState(false)

    const getBannersForShow = ()=>{
        API.get(`api/portal_banners`).then(({data})=>{
            if (data.show_banner) {
                setShowBannerNotification(true)
                setShowBannerHeader(true)
                setMessageBanner(data.banner_for_show.text)
            }
        }).catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        getBannersForShow();
        // eslint-disable-next-line
    },[])

    return (
        showBannerNotification ?
            <div className="ql-result banner_style" dangerouslySetInnerHTML={{ __html : messageBanner }}></div>
        : ''
    );
};

export default Banner;
