export const divStyle = {
    width: "100%",
    textAlign: "center",
};


export const tabStyle = {
    float: "right",
    //margin: "24px 81px",
};

export const searchStyle = {
    float: "left",
    width: "30%",
    margin: "14px 10px",
};
