import React from "react";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const MenuItem = ({ text, url, collapsed, icon, sub }) => {
    let renderTitle = text;

    if (!sub) {
        if (collapsed) {
            renderTitle = icon ? icon : text.slice(0, 1);
        } else {
            renderTitle = (
                <>
                    {icon ? icon + " " : null}{renderTitle}
                </>
            );
        }
    }
    return (
        <>
            <span onMouseEnter={console.log}>{renderTitle}</span>
            <Link to={url} />
        </>
    );
};

MenuItem.defaultProps = {
    collapsed: false,
    sub: false,
};

MenuItem.propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    collapsed: PropTypes.bool,
    icon: PropTypes.object,
    sub: PropTypes.bool,
};

export default MenuItem;
