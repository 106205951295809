import React, { useEffect, useState, useRef, useCallback } from "react";
import { Layout, Row, Col, Button, Modal } from "antd";
// import { pinStyle } from "../Dashboard.style";
import { useLocation } from "react-router-dom";
import DashboardFilter from "../DashboardFilter";
import API from "../../../api";
import moment from "moment";
import CalendarComponent from "./CalendarComponent";
import CalendarLegend from "./CalendarLegend";

const { Content } = Layout;

const Calendar = () => {

    const query = useQuery();
    const [currentFilter, setCurrentFilter] = useState();
    const [unitSchedule, setUnitSchedule] = useState(null);
    const [unitNumber, setUnitNumber] = useState(null);
    const [showButtons, setShowButtons] = useState(false)
    const [currentCalendar, setCurrentCalendar] = useState();
    const [showCities, setShowCities] = useState(false);
    const [certification, setCertification] = useState([]);
    const defaultYear = useRef("Select a year");

    const calendar = useRef();
    const { roles_un } = JSON.parse(localStorage.getItem("nisl-data"));

    const [modalVisible, setModalVisible] = useState(false);

    function getDaysArray(startDate, stopDate) {
        var dateArray = [];
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate));
            currentDate = currentDate.addDays(1);
        }
        return dateArray;
    }

    useEffect(() => {
        // show buttons
        if (roles_un.includes('facilitator_national') && ((roles_un.includes("state_coordinator") || roles_un.includes("nisl_manager")))) {
            setShowButtons(true);
        }

        // which calendar
        if (roles_un.includes("state_coordinator")) {
            setCurrentCalendar("complete");
        } else if (roles_un.includes('facilitator_national') || roles_un.includes('facilitator_master')) {
            setCurrentCalendar("my_schedule");
        } else if (roles_un.includes("nisl_manager") || roles_un.includes("training_manager")) {
            setCurrentCalendar("complete");
        } else {
            setCurrentCalendar("complete");
        }

        let focus_date = query.get("date");
        if (focus_date !== null) {
            calendar.current.getApi().gotoDate(focus_date);
            let currentday = document.querySelector(`td[data-date='${focus_date}']`)
            if (currentday) {
                currentday.scrollIntoView();
            }
        } else {
            calendar.current.getApi().gotoDate(moment().format("YYYY-MM-DD"));
            let currentday = document.querySelector(`td[data-date='${moment().format("YYYY-MM-DD")}']`)
            if (currentday) {
                currentday.scrollIntoView();
            }
        }

        API.get(`api/calendar_date/`).then(({ data }) => {
            if (data?.start && data?.start !== false) {
                navigateToDate(data?.start)
            }
        });
        // eslint-disable-next-line
    }, [])

    let handleDateClick = (day) => {
        if (day.dayEl.children[0]?.children[2]?.children[0]?.children[0]) {
            let available = 1;
            if (day.dayEl.children[0]?.children[2]?.children[0]?.children[0].style.backgroundColor === "rgb(149, 214, 23)") {
                day.dayEl.children[0].children[2].children[0].children[0].style.backgroundColor = "rgb(232, 3, 77)"; /** red*/
                available = 0;
            } else if (day.dayEl.children[0].children[2].children[0].children[0].style.backgroundColor === "rgb(232, 3, 77)") {
                day.dayEl.children[0].children[2].children[0].children[0].style.backgroundColor = "rgb(255, 255, 0)"; /** yellow*/
                available = 2;
            } else {
                day.dayEl.children[0].children[2].children[0].children[0].style.backgroundColor = "rgb(149, 214, 23)"; /** green*/
                available = 1;
            }
            API.post("api/save-busy-days", {
                available,
                day: day.dateStr
            }).then(({ data }) => null).catch(console.log);

        }
    }

    const handleEventClick = ({ event }) => {
        if (!event.extendedProps.is_busy) {
            window.open(`cohort/${event.extendedProps.cohort_id}/event/${event.extendedProps.event_id}/unit/${event.extendedProps.unit_id}/cohort/${event.extendedProps.post_id}`, "_blank");
        }
    }

    const memoizedFecthEvents = useCallback(
        (info, successCallback, failureCallback) => {
            if (currentCalendar) {
                let dates = getDaysArray(new Date(info.startStr), new Date(info.endStr));
                let strParams = "";
                if (unitSchedule) {
                    strParams = `?calendar=unit&certification=${unitSchedule}`;
                }
                if (strParams === "") {
                    /**
                     * my_schedule
                     * complete
                     * */
                    strParams = `?calendar=${currentCalendar}`;
                }
                if (currentFilter) {
                    strParams += `&filters=${currentFilter} `;
                }
                let start_date = new Date(info.startStr).getTime()
                let end_date = new Date(info.endStr).getTime()
                let middle_date = new Date((start_date + end_date) / 2);

                if (defaultYear.current !== moment(middle_date).format("YYYY")) {
                    defaultYear.current = "Select a year"
                }

                API.get(`api/calendar_data/${info.startStr}/${info.endStr}${strParams}`).then((response) => {
                    if (response.status !== 200) {
                        failureCallback(response);
                    } else {
                        let print_events = [];
                        if (currentCalendar === "my_schedule") {
                            let busy_days = []
                            let events = response.data.events?.map(e => [e.start, e.end]);
                            let busy = response.data.busy_days?.map(e => e.available_date);
                            dates.forEach(v => {
                                let event_date = moment(v).format("YYYY-MM-DD");
                                // events.forEach()
                                if (!busy.includes(event_date)) {
                                    busy_days.push({
                                        start: event_date,
                                        display: 'background',
                                        backgroundColor: v.getDay() === 6 ? 'rgb(232, 3, 77)' /** red*/ : 'rgb(149, 214, 23)'/** green*/,
                                        is_busy: true
                                    });
                                } else {
                                    let busyDay = response.data.busy_days.find(v => v.available_date === event_date);
                                    let color = 1;
                                    switch (busyDay.available) {
                                        case 0:
                                            color = 'rgb(232, 3, 77)';/** red*/
                                            break;
                                        case 1:
                                            color = 'rgb(149, 214, 23)';/** green*/
                                            break;
                                        case 2:
                                            color = 'rgb(255, 255, 0)';/** yellow*/
                                            break;
                                        default:
                                            color = 'rgb(149, 214, 23)';/** green*/
                                            break;
                                    }
                                    busy_days.push({
                                        start: event_date,
                                        display: 'background',
                                        backgroundColor: color,
                                        is_busy: true
                                    });
                                }
                            })
                            events.forEach(e => {
                                busy_days = busy_days.filter(b => !moment(b.start).isBetween(e[0], e[1], undefined, "[]"));
                            })
                            print_events = response.data.events.concat(busy_days);
                        } else {
                            setCertification(response.data.certifications)
                            print_events = response.data.events;
                        }
                        successCallback(
                            print_events.map(function (event) {
                                if (event?.is_busy) {
                                    return event;
                                } else {

                                    let title_unit = `${(event?.event_id === 'orientation') ? 'Orientation' : 'Unit ' + event?.event_id}`;
                                    let title = (!showCities ?
                                        `${title_unit}: ${event?.cohort?.name}` :
                                        `${title_unit}: ${event?.cohort?.city}, ${event?.cohort?.state?.code}`
                                    ) + (
                                            event?.facilitators?.length > 0 ?
                                                ` - ${event?.facilitators?.map((v, i) => ` ${v.name}`)}` : ""
                                        )
                                    return {

                                        title: title,
                                        state: event?.cohort?.state?.code,
                                        city: event?.cohort?.city,
                                        event_id: event?.event_id,
                                        unit_id: event?.unit_id,
                                        post_id: event?.cohort?.postId,
                                        cohort_id: event?.cohort?.id,
                                        name: event?.cohort?.name,
                                        start: event?.start,
                                        facilitators: event?.facilitators,
                                        end: moment(event?.end).add(1, "d").format("YYYY-MM-DD"),
                                        color: event.cohort?.course?.group?.background_color ? event.cohort?.course?.group?.background_color : '#808080',
                                        textColor: event.cohort?.course?.group?.text_color ? event.cohort?.course?.group?.text_color : '#FFFFFF',
                                    }
                                }
                            })
                        )

                    }
                }).catch(error => { console.log(error) })
            }
            // eslint-disable-next-line
        }, [currentCalendar, currentFilter, unitSchedule],
    );

    useEffect(() => {
        if (showCities !== undefined) {
            calendar.current.getApi().getEvents().forEach(event => {
                if (event?.extendedProps?.event_id) {
                    let facilitators = (event?.extendedProps?.facilitators?.length > 0 ?
                        ` - ${event?.extendedProps?.facilitators?.map((v, i) => ` ${v.name}`)}` : ""
                    )
                    let title_unit = `${(event?.extendedProps?.event_id === 'orientation') ? 'Orientation' : 'Unit ' + event?.extendedProps?.event_id}`;

                    if (showCities) {
                        event.setProp("title", `${title_unit}: ${event?.extendedProps?.city}, ${event?.extendedProps?.state}${facilitators}`);
                    } else {
                        event.setProp("title", `${title_unit}: ${event?.extendedProps?.name}${facilitators}`);
                    }
                }
            });
        }
    }, [showCities]);

    const changeCalendar = (newCalendar, unit = null, newUnitNumber = null) => {
        if (currentCalendar !== newCalendar) {
            setCurrentCalendar(newCalendar);
        }
        if (unitSchedule !== unit) {
            setUnitSchedule(unit);
        }

        if (unitNumber !== newUnitNumber) {
            setUnitNumber(newUnitNumber)
        }
    };

    useEffect(() => {
        if (currentCalendar && unitSchedule && unitNumber) {
            calendar.current.getApi().refetchEvents();
        }
    }, [currentCalendar, unitSchedule, unitNumber])

    const openModalFacilitatorInfo = () => {
        Modal.info({
            title: `${certification.length} facilitators are certified to conduct ${unitNumber} trainings:`,
            width: "40%",
            content: <>
                <ul>
                    {certification.map(c => <li>{c.name}</li>)}
                </ul>
            </>
        });
    }

    const changeYear = (year) => {
        defaultYear.current = year
        navigateToDate(year)
    }

    let navigateToDate = (date) => {
        calendar.current.getApi().gotoDate(date);
    }

    return (
        <>
            <Content className="ant-layout-content site-layout-background" style={{
                paddingLeft: "15px",
                paddingRight: "15px",
            }}>
                <br />
                <Row >
                    <Col span={10} style={{ textAlign: "left" }}>
                        <Button type={"primary"} style={{ height: "32px", lineHeight: "1.2", fontSize: "10px" }} onClick={() => { setShowCities(s => !s); }}>
                            {!showCities ? <>View<br />Cities</> : <>Cohort<br />Names</>}
                        </Button>
                        {(showButtons) ?
                            <>
                                &nbsp;
                                <Button type={"primary"} style={{ height: "32px", lineHeight: "1.2", fontSize: "10px" }} onClick={() => changeCalendar("my_schedule")}>
                                    My<br />Schedule
                                </Button>
                                &nbsp;
                                <Button type={"primary"} style={{ height: "32px", lineHeight: "1.2", fontSize: "10px" }} onClick={() => changeCalendar("complete")}>
                                    Complete<br />Calendar
                                </Button>
                            </>
                            : null}
                    </Col>
                    <Col span={3} style={{
                        color: "rgb(179, 147, 54)",
                        fontWeight: "bold",
                        alignSelf: "flex-end",
                        padding: "5px"
                    }}>Training Calendar</Col>
                    <Col span={11} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", flexWrap: "wrap" }}>
                        {
                            currentCalendar === "complete" ?
                                <>
                                    <Button style={{ height: "32px", lineHeight: "1.2", fontSize: "10px", top: "2px", marginBottom: "5px" }} onClick={() => { changeCalendar("complete", "EDP_Unit_4", 4); }} type={"primary"} >
                                        EDP Unit 4<br />Schedule
                                    </Button>
                                    &nbsp;
                                    <Button style={{ height: "32px", lineHeight: "1.2", fontSize: "10px", top: "2px", marginBottom: "5px" }} onClick={() => { changeCalendar("complete", "EDP_Unit_5", 5); }} type={"primary"} >
                                        EDP Unit 5<br />Schedule
                                    </Button>
                                    &nbsp;
                                    <Button style={{ height: "32px", lineHeight: "1.2", fontSize: "10px", top: "2px", marginBottom: "5px" }} onClick={() => { changeCalendar("complete", "EDP_Unit_6", 6); }} type={"primary"} >
                                        EDP Unit 6<br />Schedule
                                    </Button>
                                    &nbsp;
                                </>
                                : null
                        }
                        <Button style={{ height: "32px", lineHeight: "1.2", fontSize: "10px", top: "2px", marginBottom: "5px" }} onClick={() => { setModalVisible(true) }} type={"primary"} >
                            Legend<br />(colors)
                        </Button>
                        &nbsp;

                        {unitSchedule ?
                            <Button style={{ height: "32px", lineHeight: "1.2", fontSize: "10px", top: "2px", marginBottom: "5px" }} onClick={() => { changeCalendar("complete"); }} type={"primary"} >
                                Clear<br />filter
                            </Button> :
                            <DashboardFilter
                                setData={() => null}
                                setCurrentFilter={setCurrentFilter}
                                currentFilter={currentFilter}
                                filterType="calendar"
                                calendarRef={calendar}
                            />
                        }


                    </Col>
                </Row>
                <br />
                {unitSchedule ? <Row>
                    <Col span={24}>
                        <div style={{
                            color: "#d51c20",
                            fontSize: "12px",
                            lineHeight: "14px",
                            textAlign: "justify",
                            padding: "0 10px 0 10px",
                            width: "67%",
                            float: "left",
                            alignItems: "center",
                            fontWeight: "bold",
                            marginTop: "3px",
                        }}>
                            NOTE: <button style={{
                                background: "none",
                                border: "none",
                                padding: "0!important",
                                color: "#069",
                                textDecoration: "underline",
                                cursor: "pointer",
                            }} onClick={openModalFacilitatorInfo} >{certification.length} {certification.length === 1 ? "facilitator" : "facilitators"}</button>
                            {certification.length === 1 ? " is" : " are"} certified to conduct EDP Unit {unitNumber} trainings. The events below show where any of the {certification.length} certified
                            {certification.length === 1 ? " facilitator" : " facilitators"} are already scheduled.
                            Days with no {certification.length === 1 ? " event" : " events"} scheduled or fewer than {certification.length} {certification.length === 1 ? "event " : "events "}
                            scheduled with certified facilitators are available for scheduling EDP Unit {unitNumber}
                        </div>
                    </Col>
                </Row> : null}
                <Row>
                    <Col
                        span={24}
                        style={{
                            width: "97.6%", position: "absolute"
                        }}
                    >
                        <CalendarComponent defaultYear={defaultYear} handleEventClick={handleEventClick} handleDateClick={handleDateClick} fecthEvents={memoizedFecthEvents} unitSchedule={unitSchedule} calendar={calendar} changeYear={changeYear} />
                    </Col>
                </Row>

                <CalendarLegend modalVisible={modalVisible} setModalVisible={setModalVisible} />
            </Content>
        </>
    );
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default Calendar;
