import React, { useEffect, useState } from 'react';
import {
    Form,
    Row,
    Col,
    DatePicker,
    Spin,
    Button
} from "antd";
import moment from "moment";
import { createUseStyles } from "react-jss";
import { pinStyle } from "../../Dashboard/Dashboard.style";

const { REACT_APP_URL, REACT_APP_PORTAL_URL } = process.env;

const useStyles = createUseStyles({
    filterSelector: {
        width: '100%',
        paddingBottom: '2em',
    },
    labelCenter: {
        textAlign: 'center',
    }
});

const Pspdfkit = () => {

    const classes = useStyles();
    const [data, setData] = useState();
    const [loadContent, setLoadContent] = useState(false);

    const [form] = Form.useForm();

    const generateReport = (type) => {
        
        form.validateFields()
            .then((values) => {
                setLoadContent(true)

                const start_date = moment(values.start_date).format("Y-MM-DD");
                const end_date = moment(values.end_date).format("Y-MM-DD");

                if (type == "summary") {
                    setData();
                    
                    fetch(`${REACT_APP_PORTAL_URL}/report_pspdfkit.php?start=${start_date}&end=${end_date}&type=${type}`)
                        .then(res => {
                            return res.json();
                        })
                        .then(res => {
                            console.log(res);
                            setData(res);
                            setLoadContent(false);
                        });

                } else {
                    let filename = `PDF Error Log ${start_date} - ${end_date}.xlsx`;

                    fetch(`${REACT_APP_PORTAL_URL}/report_pspdfkit.php?start=${start_date}&end=${end_date}&type=${type}`)
                        .then(resp => resp.blob())
                        .then(blob => {
                            setLoadContent(false);
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement("a");
                            a.style.display = "none";
                            a.href = url;
                            // the filename you want
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                        })
                        .catch(() => alert("oh no!"));
                }
            })
            .catch((info) => {
                console.log("info", info);
            });
    }

    return (
        <>
            <Row>
                <Col span={24} className={classes.filterSelector}>
                    <Form layout="vertical" form={form} >

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className={classes.filterSelector} >
                            <Col xs={4}>
                                <Form.Item name="start_date" rules={[{ required: true }]} className="hide_rule_message">
                                    <DatePicker
                                        placeholder="Start Date"
                                        format={"yyyy-MM-DD"}
                                        style={{ width: "100%" }} /
                                    >
                                </Form.Item>
                            </Col>

                            <Col xs={4}>
                                <Form.Item name="end_date" rules={[{ required: true }]} className="hide_rule_message">
                                    <DatePicker
                                        placeholder="End Date"
                                        format={"yyyy-MM-DD"}
                                        style={{ width: "100%" }} /
                                    >
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className={classes.filterSelector}>

                            <Col span={3}>
                                <Button htmlType="submit" type="primary" onClick={() => { generateReport("summary") }}>
                                    Show Summary
                                </Button>
                            </Col>
                            <Col span={3}>
                                <Button htmlType="submit" type="primary" onClick={() => { generateReport("download") }}>
                                    Download xlsx
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Spin spinning={loadContent}>
                {
                    data ?
                        (
                            <>
                                <Row>
                                    <Col span={2}>
                                        <b>Type</b>
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        <b>Total</b>
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        <b>Percents</b>
                                    </Col>
                                </Row>
                                <Row className={classes.filterSelector}>
                                    <Col span={2}>
                                        Total
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.total.toLocaleString("en-US")}
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        100%
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2}>
                                        Success
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.success ? data.success.counter.toLocaleString("en-US") : "n/a"}
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.success ? `${(((data.success.counter * 100 / data.total).toFixed(2)).toLocaleString("en-US"))}%` : "n/a" }
                                    </Col>
                                </Row>
                                <Row className={classes.filterSelector}>
                                    <Col span={2}>
                                        Failures
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.success ? (data.total - data.success.counter).toLocaleString("en-US") : "n/a"}
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.success ? `${(((data.total - data.success.counter) * 100 / data.total).toFixed(2)).toLocaleString("en-US")}%` : 'n/a'}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2}>
                                        Not Found
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.not_found ? data.not_found.counter.toLocaleString("en-US") : "n/a"}
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.not_found ? `${(((data.not_found.counter * 100 / data.total).toFixed(2)).toLocaleString("en-US"))}%` : "n/a"}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2}>
                                        Empty Token
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.empty_token ? (data.empty_token.counter.toLocaleString("en-US")) : "n/a"}
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.empty_token ? `${((data.empty_token.counter * 100 / data.total).toFixed(2)).toLocaleString("en-US")}%` : "n/a"}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2}>
                                        Missing File
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.missing_file ? data.missing_file.counter.toLocaleString("en-US") : "n/a"}
                                    </Col>
                                    <Col span={2} className={classes.labelCenter}>
                                        {data.missing_file ? `${((data.missing_file.counter * 100 / data.total).toFixed(2)).toLocaleString("en-US")}%`: "n/a"}
                                    </Col>
                                </Row>
                            </>
                        ) : (<><Row className={classes.filterSelector}></Row></>)
                }
            </Spin>
        </>
    );
}

export default Pspdfkit;
