import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Modal } from 'antd';
import { CohortSchedule } from "./CohortSchedule";
import Assignments from "./Assignments";
import Settings from "./Settings";
import { SettingOutlined } from "@ant-design/icons";
import { disabled_page, active_page, update_page } from "../../../functions/ControlPages";
import { EXECUTIVE_COACHING } from "../../../config/courses";

const CohortBuilderForm = ({ onFinish, form, cohortBuilderFields, setDisplayForm, configsCohortBuilder, currentCohort, onCancel, edit, ready, setReady, disabledDate, finishing, selectCourses, onDelete, selectDistrict, valuesChanged, setValuesChanged, meetingTypes, cohortId, setEdit, isAdmin, isCoach }) => {
    const [unitsGeneral, setUnitsGeneral] = useState([]);
    const [unitsFacilitators, setUnitsFacilitators] = useState([]);
    const [idCohort, setIdCohort] = useState()
    const [psqlId, setPsqlId] = useState(0)
    const [nameCohort, setNameCohort] = useState("")
    const [currentView, setCurrentView] = useState(0)
    const [goToViewText, setGoToViewText] = useState("Assignments")
    const [lastViewSettings, setLastViewSettings] = useState()


    const [topCohortTitle, setTopCohortTitle] = useState(0);
    const [subCourses, setSubCourses] = useState(currentCohort.sub_courses?.map((v, i) => "course" + (v)).join(","));
    const [isExecutiveCourse, setIsExecutiveCourse] = useState(false);

    // Validation States
    window.onscroll = function () {

        try {

            if (window.pageYOffset > topCohortTitle) {
                document.getElementsByClassName("form_buttons")[0].style.right = "17px"
                document.getElementsByClassName("header_cohort")[0].style.position = "fixed"
                document.getElementsByClassName("form_buttons")[0].style.textAlign = "right"
                document.getElementsByClassName("form_buttons")[0].style.position = "fixed"
                document.getElementsByClassName("form_buttons")[0].style.marginTop = "5px"
            } else {
                document.getElementsByClassName("header_cohort")[0].style.position = "initial"
                document.getElementsByClassName("form_buttons")[0].style.textAlign = "right"
                document.getElementsByClassName("form_buttons")[0].style.position = ""
                document.getElementsByClassName("form_buttons")[0].style.right = ""
            }
        } catch (error) {
            console.log(document.getElementsByClassName("header_cohort")[0]);
            console.log(document.getElementsByClassName("form_buttons")[0]);
        }
    };

    const [showQualityAssurance, setShowQualityAssurance] = useState(false);
    const [showWebinarSection, setShowWebinarSection] = useState(true);
    const [showOrientation, setShowOrientation] = useState(false);

    const [onEdit, setOnEdit] = useState(false);

    const diff_on_days = (start_date, end_date) => {
        var date_1 = new Date(start_date);
        var date_2 = new Date(end_date);
        var day_as_milliseconds = 86400000;
        var diff_in_millisenconds = date_2 - date_1;
        return Math.ceil(diff_in_millisenconds / day_as_milliseconds);
    }

    const handleFormChange = (changedValues, allValues) => {

        try {
            if (Object.keys(changedValues).includes("cohort_schedule")) {

                if (!Object.keys(allValues).includes("cohort_schedule")) {
                    allValues = currentCohort
                }
                if (allValues.cohort_schedule) {

                    let units = [...allValues.cohort_schedule, ...allValues.orientation];
                    if (units.length > 0) {
                        let listUnitsGeneral = [];
                        for (let i = 0; i < units.length; i++) {
                            const unit = units[i];
                            let valueUnit = null;;
                            if (unit.event_type === "split") {
                                valueUnit = { unit: unit.unit, type: unit.event_type, name: `Unit ${unit.unit} - All days` }
                                listUnitsGeneral = [...listUnitsGeneral, valueUnit];
                                continue;
                            }
                            if (unit.event_type === "ext") {
                                let subUnits = unit.cohort_schedule_sub
                                let listSubUnits = []
                                if (subUnits.length !== 0) {
                                    for (let j = 0; j < subUnits.length; j++) {
                                        const subUnit = subUnits[j];
                                        if (subUnit !== undefined) {
                                            listSubUnits.push({ unit: unit.unit, ext: subUnit.event_ext, type: unit.event_type, name: `Unit ${subUnit.event_ext}` })
                                        } else {
                                            let alphabet = [
                                                'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
                                                'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
                                                'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
                                            listSubUnits.push({ unit: unit.unit, ext: `${unit.unit}${alphabet[j]}`, type: unit.event_type, name: `Unit ${unit.unit}${alphabet[j]}` })
                                        }
                                    }
                                } else {
                                    listSubUnits.push({ unit: unit.unit, type: unit.event_type, name: `Unit ${unit.unit} - All days` });
                                }
                                listUnitsGeneral = [...listUnitsGeneral, ...listSubUnits];
                                continue;
                            }
                            if (unit.event_type === "2_day") {
                                if (unit.start_date !== null && unit.end_date !== null) {
                                    if ((unit.start_date !== undefined && unit.end_date !== undefined)) {
                                        let cantDays = diff_on_days(unit.start_date, unit.end_date);

                                        //If the units should be shown divide add a second day to the events list on assigments
                                        if (allValues.divide_units?.includes("divide_units")) {
                                            cantDays = cantDays > 0 ? cantDays += 1 : 0;
                                        }

                                        let listSubUnits = []
                                        for (let j = 1; j <= cantDays; j++) {
                                            listSubUnits.push({ unit: unit.unit, ext: `${unit.unit}-${j}`, type: unit.event_type, name: `Unit ${unit.unit} - day ${j}` })
                                        }

                                        if (unit.unit === "Orientation") {
                                            listSubUnits.push({ unit: "Orientation", ext: `${unit.unit}`, type: unit?.event_type, name: `Orientation` })
                                        }

                                        listUnitsGeneral = [...listUnitsGeneral, ...listSubUnits];

                                    } else {
                                        valueUnit = { unit: unit.unit, type: unit?.event_type, name: `Unit ${unit.unit} - All days` };
                                        if (unit.unit === "Orientation") {
                                            valueUnit = { unit: "Orientation", ext: `orientation`, type: unit?.event_type, name: `Orientation` }
                                        }

                                        listUnitsGeneral = [...listUnitsGeneral, valueUnit];
                                    }
                                } else {
                                    valueUnit = { unit: unit.unit, ext: unit.unit, type: unit?.event_type, name: `Unit ${unit.unit} - All days` };
                                    if (unit.unit === "Orientation") {
                                        valueUnit = { unit: "Orientation", ext: `orientation`, type: unit?.event_type, name: `Orientation` }
                                    }
                                    listUnitsGeneral = [...listUnitsGeneral, valueUnit];
                                }
                                continue;
                            }
                        }

                        setUnitsGeneral(listUnitsGeneral);
                        let listUnitsFacilitators = [];
                        listUnitsFacilitators = [];
                        for (let i = 0; i < listUnitsGeneral.length; i++) {
                            const h = listUnitsGeneral[i];
                            if (h.type === "2_day" && !allValues.divide_units?.includes("divide_units")) {
                                if (!listUnitsFacilitators.find(v => v.unit === h.unit)) {
                                    if (h.unit === "Orientation") {
                                        listUnitsFacilitators.push({ unit: "Orientation", ext: `orientation`, type: h?.event_type, name: `Orientation` })
                                    } else {
                                        listUnitsFacilitators.push({ unit: h.unit, ext: h.unit, type: h.type, name: h.name });
                                    }
                                }
                            } else if (h.type === "2_day" && allValues.divide_units?.includes("divide_units") === true) {

                                if (h.unit === "Orientation") {
                                    listUnitsFacilitators.push({ unit: "Orientation", ext: h.ext, type: h?.event_type, name: h.name })
                                } else {
                                    listUnitsFacilitators.push({ unit: h.unit, ext: h.ext, type: h.type, name: h.name });
                                }

                            } else {
                                listUnitsFacilitators.push(h);
                            }

                        }
                        // listUnitsFacilitators.push({ unit: h.unit, ext : h.unit ,type: h.type, name: h.name });
                        currentCohort.unitsFacilitators = listUnitsFacilitators;

                        setUnitsFacilitators(listUnitsFacilitators);
                    }
                }

            }
        } catch (error) {
            console.log("error change form", error);
        }

    }

    const onOkBuiderFrom = (form) => {
        form
            .validateFields()
            .then(values => {
                let sub_courses_data = subCourses !== undefined ? subCourses : currentCohort.sub_courses?.map((v, i) => "course" + (v)).join(",");
                onFinish(values, sub_courses_data);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                Modal.error({
                    title: 'Failed to save cohort',
                    content: 'The following error has occurred: ' + info,
                });
            });
    }


    useEffect(() => {
        setTopCohortTitle(document.getElementsByClassName("header_cohort")[0].offsetTop);

        let values = form.getFieldsValue();
        handleFormChange({ cohort_schedule: {} }, values);
        // eslint-disable-next-line
    }, [ready, form]);



    useEffect(() => {
        const cleanup = () => {
            if (cohortId) {
                disabled_page(`cohortbuilder/edit/${cohortId}`);
                setOnEdit(false);
            }
        }


        window.onbeforeunload = (event) => {
            event.preventDefault()
            cleanup()
        }


        return () => edit ? cleanup() : ""
        // eslint-disable-next-line
    }, []);



    useEffect(() => {
        setShowOrientation(currentCohort.orientation?.length > 0 ? true : false);
        if (edit) {
            if (currentCohort?.postId) {
                active_page(`cohortbuilder/edit/${currentCohort.postId}`)
            }
            setIdCohort(currentCohort.postId);
            setPsqlId(currentCohort.id);
            setNameCohort(currentCohort.name);

            if (currentCohort?.course === `cohort${EXECUTIVE_COACHING}`) {
                setIsExecutiveCourse(true);
            } else {
                setIsExecutiveCourse(false);
            }
        }
        setShowQualityAssurance(["edp_pss", "edp_pss_virtual2", "edp_pss_virtualm", 'edp_materials_virtual2_nisl', 'edp_materials_virtualm_nisl', 'edp_materials_nisl'].includes(currentCohort.delivery_model))
        setShowWebinarSection(!["edp_pss", "edp_pss_virtual2", "edp_pss_virtualm", 'edp_materials_virtual2_nisl', 'edp_materials_virtualm_nisl', 'edp_materials_nisl'].includes(currentCohort.delivery_model))
        // eslint-disable-next-line
    }, [currentCohort, form]);


    let changeView = () => {
        if (currentView === 2 && lastViewSettings === 0 && lastViewSettings !== undefined) {
            setCurrentView(1)
            setGoToViewText("Assignments")
        } else if (currentView === 2 && lastViewSettings === 1 && lastViewSettings !== undefined) {
            setCurrentView(0)
            setGoToViewText("Cohort Schedule")
        } else if (currentView === 1) {
            setCurrentView(0)
            setGoToViewText("Cohort Schedule")
        } else if (currentView === 0) {
            setCurrentView(1)
            setGoToViewText("Assignments")
        } else if (lastViewSettings === undefined) {
            setCurrentView(0)
            setGoToViewText("Cohort Schedule")
        }
    }


    useEffect(() => {
        if (edit) {
            //go to the cohort schedule view
            setCurrentView(1)

            if (idCohort !== "" && idCohort !== undefined) {
                setOnEdit(true);
            }
        } else {
            //go to the settings view
            setCurrentView(2)
        }
        // eslint-disable-next-line
    }, [idCohort])

    useEffect(() => {
        if (onEdit) {
            const interval = setInterval(() => {
                update_page(`cohortbuilder/edit/${idCohort}`);
            }, 300000);
            return () => clearInterval(interval);
        }

    }, [onEdit]);

    const steps = [
        {
            title: 'Assignments',
            content: (<Assignments form={form} currentCohort={currentCohort} edit={edit}
                ready={ready} handleFormChange={handleFormChange}
                unitsFacilitators={unitsFacilitators} unitsGeneral={unitsGeneral}
                showQualityAssurance={showQualityAssurance}
                showWebinarSection={showWebinarSection}
                showOrientation={showOrientation}
                diffOnDays={diff_on_days}
                setUnitsFacilitators={setUnitsFacilitators} />),
        },
        {
            title: 'CohortSchedule',
            content: (<CohortSchedule form={form} ready={ready}
                setShowOrientation={setShowOrientation} handleFormChange={handleFormChange}
                currentCohort={currentCohort}
                meetingTypes={meetingTypes}
                isExecutiveCourse={isExecutiveCourse} />),
        },
        {
            title: 'Settings',
            content: (<Settings form={form} cohortBuilderFields={cohortBuilderFields}
                configsCohortBuilder={configsCohortBuilder} currentCohort={currentCohort}
                selectCourses={selectCourses} edit={edit}
                setShowQualityAssurance={setShowQualityAssurance}
                setShowWebinarSection={setShowWebinarSection}
                setShowOrientation={setShowOrientation}
                setSubCourses={setSubCourses}
                setNameCohort={setNameCohort}
                selectDistrict={selectDistrict}
                setIsExecutiveCourse={setIsExecutiveCourse}
                EXECUTIVE_COACHING={EXECUTIVE_COACHING}
            />),
        },
    ];


    return (
        <>
            <Row gutter={[5, 5]} className="header_cohort" style={{ zIndex: "10", top: "0px", width: "100%", height: "40px", background: "rgb(255, 255, 255)", transition: "all 0.3s ease" }}>
                <Col span={14}>
                    {edit ? <h4>{nameCohort} (ID {idCohort})</h4> : <h4>{nameCohort}</h4>}
                </Col>
                <Col span={10} style={{ textAlign: "right" }} className="form_buttons">

                    {isAdmin ? <>
                        <Button type="primary" onClick={() => {
                            changeView()
                        }} >{goToViewText}</Button>

                        <SettingOutlined style={{ marginLeft: "8px" }} onClick={() => {
                            if (currentView !== 2) {
                                setLastViewSettings(currentView)
                            }
                            setCurrentView(2)

                        }} />
                    </> : <></>
                    }

                    <Button style={{ marginLeft: "8px" }} onClick={() => {
                        onCancel(form, edit);
                        setReady(false);
                        setIdCohort("");
                    }}>Cancel</Button>
                    <Button style={{ marginLeft: "8px" }} type="primary" onClick={() => {
                        onOkBuiderFrom(form)
                        setOnEdit(false);
                    }}>OK</Button>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form form={form} layout={"horizontal"} onValuesChange={handleFormChange} onFieldsChange={() => { !valuesChanged && setValuesChanged(true) }}>
                        <Form.Item noStyle name={"id"}>
                            <Input hidden />
                        </Form.Item>
                        <Row gutter={[5, 0]} key="1" style={{ display: (currentView !== 0) ? "none" : "" }}>
                            {(steps[0]) ? steps[0].content : ""}
                        </Row>
                        <Row gutter={[5, 0]} key="2" style={{ display: (currentView !== 1) ? "none" : "" }} >
                            {(steps[1]) ? steps[1].content : ""}
                        </Row>
                        <Row gutter={[5, 0]} key="3" style={{ display: (currentView !== 2) ? "none" : "" }}>
                            {(steps[2]) ? steps[2].content : ""}
                        </Row>
                        <Row gutter={[5, 5]} style={{ margin: "0px" }}>
                            <Col span={12}>
                                {(edit && currentView === 2) ? <Button type={"primary"} danger
                                    onClick={() => onDelete(psqlId)}
                                >DELETE</Button> : null}
                            </Col>
                            <Col span={12} style={{ display: "flex", textAlign: "right", justifyContent: "flex-end" }}>

                                {isAdmin ? <>
                                    <Button type="primary" onClick={() => {
                                        changeView()
                                    }} >{goToViewText}</Button>

                                    <SettingOutlined style={{ marginLeft: "8px", display: "flex", alignItems: "center" }} onClick={() => {
                                        if (currentView !== 2) {
                                            setLastViewSettings(currentView)
                                        }
                                        setCurrentView(2)

                                    }} />
                                </> : <></>
                                }
                                <Button style={{ marginLeft: "8px" }} onClick={() => {
                                    onCancel(form, edit);
                                    setReady(false);
                                }}>Cancel</Button>

                                <Button style={{ marginLeft: "8px" }} type="primary" onClick={() => onOkBuiderFrom(form)}>OK</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
export default CohortBuilderForm



