import React from "react";
import Icon from "@ant-design/icons";

const CourseOutlinedSvg = () => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 1024.000000 1024.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g
            transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
            fill="currentColor"
            stroke="none"
        >
            <path
                d="M435 10146 c-67 -31 -137 -109 -159 -178 -15 -49 -16 -398 -14 -4878
l3 -4825 27 -51 c34 -65 105 -128 167 -149 42 -14 506 -15 4671 -13 l4625 3
42 23 c54 28 107 81 135 135 l23 42 3 4844 2 4844 -23 51 c-34 74 -70 111
-139 145 l-61 31 -4626 0 -4626 0 -50 -24z m8865 -1331 l0 -705 -4190 0 -4190
0 0 705 0 705 4190 0 4190 0 0 -705z m0 -4780 l0 -3325 -4190 0 -4190 0 0
3325 0 3325 4190 0 4190 0 0 -3325z"
            />
            <path d="M1510 8795 l0 -285 285 0 285 0 0 285 0 285 -285 0 -285 0 0 -285z" />
            <path d="M2580 8795 l0 -285 285 0 285 0 0 285 0 285 -285 0 -285 0 0 -285z" />
            <path d="M3690 8795 l0 -285 285 0 285 0 0 285 0 285 -285 0 -285 0 0 -285z" />
            <path
                d="M3365 6644 c-105 -7 -340 -36 -345 -43 -3 -4 -52 -15 -110 -24 -57
-10 -107 -22 -110 -26 -3 -4 -38 -13 -79 -21 -40 -7 -78 -19 -85 -25 -6 -6
-39 -17 -74 -25 -35 -7 -69 -18 -75 -24 -12 -11 -30 -18 -124 -46 -24 -7 -43
-16 -43 -21 0 -4 -12 -10 -27 -14 -49 -12 -283 -125 -283 -136 0 -4 -13 -10
-30 -14 -56 -12 -142 -113 -165 -192 -3 -13 -11 -23 -16 -23 -11 0 -13 -4377
-1 -4382 4 -1 17 -24 30 -50 21 -42 41 -67 99 -120 11 -10 35 -23 54 -30 19
-6 37 -16 41 -21 9 -14 257 -15 266 -1 4 6 27 18 52 28 25 10 47 21 50 24 9
12 71 42 86 42 8 0 14 4 14 8 0 5 26 17 58 27 31 11 64 24 72 30 8 6 35 18 60
25 25 7 52 20 61 27 8 7 26 13 39 13 25 0 92 21 100 31 6 7 86 28 140 36 19 3
40 9 45 13 12 10 133 30 225 37 36 3 71 10 79 17 20 15 529 16 539 0 4 -6 39
-14 77 -17 103 -9 219 -28 225 -37 3 -4 21 -10 40 -13 72 -11 145 -29 150 -36
3 -4 35 -15 73 -25 37 -10 67 -22 67 -27 0 -5 7 -9 16 -9 18 0 117 -31 124
-39 10 -12 47 -29 78 -36 18 -3 32 -10 32 -15 0 -4 15 -13 33 -20 17 -8 39
-19 47 -25 8 -7 38 -21 65 -31 28 -10 53 -23 57 -28 10 -15 266 -15 276 0 4 6
28 19 55 29 26 10 47 21 47 24 0 4 23 15 50 25 28 10 50 21 50 25 0 4 26 16
58 26 31 11 64 24 72 31 8 6 36 17 63 25 26 7 47 17 47 20 0 7 57 23 125 36
17 3 32 10 36 14 5 9 68 25 139 36 19 3 37 9 40 13 6 10 129 30 225 37 39 3
73 10 77 17 10 15 519 16 539 0 8 -6 43 -14 79 -17 118 -11 203 -25 209 -35 3
-5 26 -12 51 -15 67 -8 143 -26 149 -36 4 -4 35 -16 71 -25 36 -9 66 -20 68
-26 2 -5 14 -10 27 -10 13 0 27 -5 30 -10 3 -6 17 -10 29 -10 13 0 29 -6 35
-13 6 -8 38 -22 71 -32 33 -10 60 -21 60 -25 0 -4 21 -15 48 -25 26 -10 52
-24 57 -31 6 -6 28 -17 50 -24 22 -7 43 -18 47 -24 9 -14 256 -15 266 -1 4 6
26 19 50 29 23 11 42 22 42 26 0 4 9 10 20 13 11 3 24 17 30 31 5 14 14 26 20
26 6 0 15 10 20 23 5 12 18 36 29 52 l21 30 0 2187 c0 1752 -3 2189 -13 2200
-7 7 -21 32 -31 56 -11 23 -23 42 -28 42 -5 0 -13 12 -18 26 -6 14 -19 28 -30
31 -11 3 -20 9 -20 13 0 5 -12 15 -27 23 -16 8 -48 26 -73 39 -25 14 -61 34
-80 46 -19 12 -41 22 -49 22 -8 0 -22 6 -30 13 -20 16 -148 70 -221 93 -30 10
-64 23 -75 30 -11 6 -45 18 -75 25 -30 7 -57 17 -60 21 -3 4 -42 15 -85 24
-44 10 -82 21 -85 25 -3 4 -57 15 -120 25 -63 10 -117 21 -120 25 -3 4 -73 15
-155 25 -216 24 -705 24 -915 0 -85 -10 -157 -21 -160 -25 -4 -7 -64 -18 -187
-36 -24 -4 -43 -10 -43 -14 0 -4 -38 -15 -85 -26 -47 -10 -85 -22 -85 -26 0
-4 -17 -10 -37 -13 -43 -8 -106 -28 -113 -37 -3 -3 -39 -16 -80 -28 -41 -13
-77 -27 -80 -31 -3 -3 -25 -14 -50 -24 -25 -10 -48 -23 -52 -29 -11 -18 -103
-13 -123 6 -9 9 -21 17 -26 17 -5 0 -31 9 -57 21 -71 32 -84 37 -127 50 -22 6
-42 15 -45 18 -7 9 -112 41 -133 41 -10 0 -17 4 -17 8 0 5 -37 17 -82 27 -46
10 -85 22 -88 26 -6 8 -174 39 -212 39 -14 0 -28 4 -30 10 -7 21 -255 41 -538
44 -157 2 -312 2 -345 0z m335 -804 c0 -6 37 -10 91 -10 109 0 343 -27 347
-41 2 -5 13 -9 24 -9 32 0 182 -32 188 -39 3 -4 40 -15 83 -25 42 -10 77 -22
77 -26 0 -4 29 -15 65 -25 36 -9 65 -21 65 -25 0 -4 16 -11 36 -15 20 -3 47
-18 60 -32 l24 -26 0 -1577 c0 -867 -3 -1580 -7 -1583 -3 -4 -11 -3 -17 2 -14
12 -158 51 -185 51 -11 0 -21 5 -23 10 -3 10 -46 20 -153 36 -22 4 -45 11 -51
15 -14 11 -127 27 -244 36 -47 3 -88 11 -92 17 -10 16 -686 15 -696 0 -4 -7
-43 -14 -87 -17 -124 -8 -250 -27 -253 -37 -2 -6 -13 -10 -24 -10 -32 0 -182
-32 -188 -40 -3 -3 -34 -13 -70 -20 -36 -7 -72 -16 -80 -20 -8 -4 -27 -10 -42
-14 l-28 -6 0 1580 c0 1071 3 1588 10 1601 6 10 17 19 25 19 8 0 38 11 68 24
29 14 74 30 100 37 26 6 47 15 47 19 0 4 33 16 73 25 39 10 77 21 82 25 11 9
97 29 160 37 22 3 45 9 51 14 7 6 52 14 100 19 49 6 95 12 101 15 7 2 87 5
178 6 91 1 165 5 165 10 0 5 5 9 10 9 6 0 10 -4 10 -10z m2910 1 c0 -5 78 -11
173 -14 185 -6 327 -21 336 -35 3 -5 24 -12 46 -14 59 -8 159 -30 165 -38 3
-3 32 -12 65 -20 33 -7 69 -18 80 -25 11 -6 47 -20 80 -30 33 -10 62 -21 65
-25 3 -3 25 -12 49 -20 24 -8 50 -20 57 -28 12 -11 14 -271 14 -1598 l0 -1584
-27 6 c-16 4 -36 11 -45 15 -9 5 -30 9 -47 9 -16 0 -33 5 -36 10 -3 6 -21 10
-40 10 -18 0 -35 5 -37 12 -4 10 -33 17 -158 34 -25 4 -48 11 -51 15 -6 11
-118 27 -239 36 -47 3 -88 11 -92 17 -10 16 -686 15 -696 -1 -4 -6 -41 -13
-82 -16 -110 -7 -233 -25 -239 -36 -3 -5 -31 -11 -61 -14 -67 -6 -163 -26
-170 -36 -3 -3 -43 -16 -90 -28 -47 -12 -95 -25 -107 -29 l-23 -6 0 1575 c0
889 4 1586 9 1599 6 16 22 27 55 36 25 6 46 16 46 22 0 5 6 10 14 10 19 0 120
32 126 40 3 4 37 15 75 25 39 10 75 22 80 26 11 8 97 28 160 36 22 3 42 9 45
14 8 12 155 29 313 36 75 3 137 9 137 14 0 5 11 9 25 9 14 0 25 -4 25 -9z"
            />
            <path d="M3030 4805 l0 -415 615 0 615 0 0 415 0 415 -615 0 -615 0 0 -415z" />
            <path d="M3030 3435 l0 -415 615 0 615 0 0 415 0 415 -615 0 -615 0 0 -415z" />
            <path d="M6020 4805 l0 -415 615 0 615 0 0 415 0 415 -615 0 -615 0 0 -415z" />
            <path d="M6020 3435 l0 -415 615 0 615 0 0 415 0 415 -615 0 -615 0 0 -415z" />
        </g>
    </svg>
);

export const CourseOutlined = (props) => (
    <Icon component={CourseOutlinedSvg} {...props} />
);
