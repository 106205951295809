import React, { useState, useEffect, useRef } from "react";
import { Space, Button, Row, Input, Col, Modal, Select, message } from "antd";
import API from "../../../../api";
import CreateUser from "../Users/Create";
import ListUsers from "../Users/ListUsers";
import FilterUser from "../Users/FilterUsers";
import { createPhoneMask } from "../../../../functions/createPhoneMask";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { disabled_page } from "../../../../functions/ControlPages";
import { createUseStyles } from "react-jss";
import { DEFAULT_OPTION_FEEDBACK, DIAGNOSTICS_OPTIONS_FILTER, FILTER_STATUS } from "../../../../config/Admin/users";

const useStyles = createUseStyles({
    filterSelector: {
        width: '22em',
    },
    label: {
        paddingTop: '1em',
    },
    filterStatus:{
        width: '25em',
    }
});

const { confirm } = Modal;

const UserPage = () => {
    const classes = useStyles();
    const [disabledExport, setDisabledExport] = useState(true);
    const [visibleFormUser, setVisibleFormUser] = useState(false);
    const [visibleFilterUser, setVisibleFilterUser] = useState(false);
    const [visibleFormEditUser, setVisibleFormEditUser] = useState(false);
    const [valuesFormEditUser, setValuesFormEditUser] = useState({});
    const [users, setUsers] = useState([]);
    const [showStaffContractor, setShowStaffContractor] = useState()
    const [filterSearch, setFilterSearch] = useState(false);
    const [valueSearch, setValueSearch] = useState("");
    const [textTotalAccounts, setTextTotalAccounts] = useState("");
    const [extraClassBtn, setExtraClassBtn] = useState("no_force_selected");
    const [sorterColumn, setSorterColumn] = useState("updated_at");
    const [sorterOrder, setSorterOrder] = useState("desc");
    const [LoadingTable, setLoadingTable] = useState(true);
    const [filterSearchValues, setFilterSearchValues] = useState((localStorage.getItem('filter_users') ? JSON.parse(localStorage.getItem('filter_users')) : false));
    const [changeForm, setChangeForm] = useState(false);
    const inputValueSearch = useRef()
    const [filterDiagnosticsUsers, setFilterDiagnosticsUsers] = useState(null);

    const [textFilter, setTextFilter] = useState(() => {
        if (JSON.parse(localStorage.getItem('filter_users')) != null && JSON.parse(localStorage.getItem("filter_save")) !== false) {
            setDisabledExport(false);
            return '(Filters have been set)';

        } else {
            return '';
        }
    });

    const [valuesCreate] = useState({
        username: "",
        first_name: "",
        last_name: "",
        password: "",
        roles: [10],
        job_title: "",
        email: "",
        phone: "",
        school: "",
        district: "",
        courses: [10],
        active: true,
        staff_contractor: "staff",
        state_coordinator: false,
        default_course: 10,
        states_coordinator: [],
    });

    const defaultStatusFilter = FILTER_STATUS.filter(status => status.default).flatMap(status =>  status.value)
    const [valueStatusFilter, setValueStatusFilter] = useState(defaultStatusFilter);

    let setBtnState = (a, b) => {
        if (a !== b) {
            setExtraClassBtn("force_selected")
        } else {
            setExtraClassBtn("no_force_selected")
        }
    }

    let getUsersPaginate = (current, pageSize, column, order, filter = null, empty_form = null) => {
        setLoadingTable(true)
        API.get(`/api/user?page=${current}&per_page=${pageSize}&column=${column}&order_by=${order}&filter=${JSON.stringify(filter)}&filterDiagnosticsUsers=${filterDiagnosticsUsers}&filterStatus=${JSON.stringify(valueStatusFilter)}`).then(({ data }) => {
            setUsers(data.users)
            setLoadingTable(false)
            setTextTotalAccounts(data.users.total.toLocaleString() + ' of ' + data.totalUsers.toLocaleString() + ' accounts')
            if (empty_form) {
                setBtnState(data.users.total, data.totalUsers)
            } else {
                setExtraClassBtn("no_force_selected")
            }
        });
    }


    let getUsersSearchPaginate = (valueSearch, current, pageSize, column, order, filter = null, empty_form = null) => {
        setLoadingTable(true)
        API.get(`api/user/search/${valueSearch}?page=${current}&per_page=${pageSize}&column=${column}&order_by=${order}&filter=${JSON.stringify(filter)}&filterDiagnosticsUsers=${filterDiagnosticsUsers}&filterStatus=${JSON.stringify(valueStatusFilter)}`).then(({ data }) => {
            setFilterSearch(true)
            setUsers(data.users)
            setLoadingTable(false)
            setTextTotalAccounts(data.users.total.toLocaleString() + ' of ' + data.totalUsers.toLocaleString() + ' accounts')
            if (empty_form) {
                setBtnState(data.users.total, data.totalUsers)
            } else {
                setExtraClassBtn("no_force_selected")
            }

        });
    }

    let setValuesFilter = () => {
        setValuesFormEditUser(JSON.parse(localStorage.getItem('filter_users')));
    }

    const exportData = () => {
        let newDate = new Date();
        let file_name = "Accounts - Filtered " + moment(newDate, 'ddd DD-MMM-YYYY, hh:mm:ss a').format('YYYY-MM-DD hh_mm a') + ".xlsx";;
        setLoadingTable(true)
        handleFilterCancel()
        let searchValue = "";
        if (valueSearch && valueSearch !== null && valueSearch !== undefined && valueSearch !== "") {
            searchValue = valueSearch;
        } else {
            searchValue = JSON.stringify(filterSearchValues);
        }
        API.get(`api/export-csv?filter=${searchValue}`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file_name);
            document.body.appendChild(link);
            link.click();
            getUsers();
            if (valueSearch && valueSearch !== null && valueSearch !== undefined && valueSearch !== "") {
                inputValueSearch.current.handleReset(() => null);
                setDisabledExport(true)
            }
        }).catch(()=>{
            Modal.warning({content : "Problem with the file"})
            setLoadingTable(false)
        })
    }

    const filterSearchData = (values, form) => {
        localStorage.setItem("filter_users", JSON.stringify(values));
        localStorage.setItem('filter_save', true)
        let empty_form = false;

        form.getFieldsValue("filter", input => {
            if (input.touched === true && form.getFieldValue(input.name[0]) !== '' && form.getFieldValue(input.name[0]) !== null) {
                empty_form = true;
            }
        });
        //validations setTextFilter
        if (empty_form) {
            setTextFilter("(Filters have been set)");
            setDisabledExport(false);
        } else {
            form.resetFields();
            setVisibleFormEditUser(false);
            disabled_page(`user/edit/${values.id}`)
            setValuesFormEditUser({});
            localStorage.removeItem('filter_users');
            localStorage.setItem('filter_save', false)
            setTextFilter("");
            setDisabledExport(true);
        }

        if (JSON.parse(localStorage.getItem("filter_users")) == null) {
            setTextFilter("");
        }

        setLoadingTable(true)
        handleFilterCancel()
        setFilterSearchValues(values)

        if (valueSearch !== "") {
            getUsersSearchPaginate(valueSearch, users.current_page, users.per_page, sorterColumn, sorterOrder, values, empty_form)
        } else {
            getUsersPaginate(users.current_page, users.per_page, sorterColumn, sorterOrder, values, empty_form)
            setFilterSearch(false)
        }

    }

    let getDataPaginate = (page, filters = {}, sorter = {}, extra = {}) => {
        let change_order = false;
        if (extra.action === "sort" && !sorter.column !== undefined && sorter.order !== undefined) {
            change_order = true;
            setSorterColumn(sorter.field)
            setSorterOrder(sorter.order)
        }

        if (!filterSearch) {
            if (change_order) {
                getUsersPaginate(page.current, page.pageSize, sorter.field, sorter.order, filterSearchValues)
            } else {
                getUsersPaginate(page.current, page.pageSize, sorterColumn, sorterOrder, filterSearchValues)
            }
        } else {
            if (valueSearch && valueSearch !== null && valueSearch !== undefined && valueSearch !== "") {
                if (change_order) {
                    getUsersSearchPaginate(valueSearch, page.current, page.pageSize, sorter.field, sorter.order, filterSearchValues)
                } else {
                    getUsersSearchPaginate(valueSearch, page.current, page.pageSize, sorterColumn, sorterOrder, filterSearchValues)
                }

            } else {
                getUsers();
            }
        }
    };

    const handleCreate = (values, form) => {
        setChangeForm(false)
        message.loading({
            content: "Saving User...",
            key: "saving_user",
            duration: 0,
        });
        API.post("/api/user", values)
            .then(({ data }) => {
                message.destroy("saving_user");

                if (data?.message === "Pending") {
                    Modal.warning({
                        content: "This may be a duplicate account. It is being flagged as Pending. After investigating, you may manually change the account from Pending to Active. In the meanwhile, the Pending account cannot be added to a roster and cannot be logged into.",
                        onOk() {
                            getDataPaginate({
                                current: users.current_page,
                                pageSize: users.per_page,
                            });
                            form.resetFields();
                            form.setFieldsValue(valuesCreate);
                            setVisibleFormUser(false);
                            setVisibleFormEditUser(false);
                            disabled_page(`user/edit/${values.id}`)
                            setValuesFormEditUser({});
                        }
                    });
                } else {
                    getDataPaginate({
                        current: users.current_page,
                        pageSize: users.per_page,
                    });
                    form.resetFields();
                    form.setFieldsValue(valuesCreate);
                    setVisibleFormUser(false);
                    setVisibleFormEditUser(false);
                    disabled_page(`user/edit/${values.id}`)
                    setValuesFormEditUser({});
                }
            })
            .catch((response) => {
                message.destroy("saving_user");
                Modal.warning({ content: "The username or email has already been taken." });
            });
    };

    const handleEditUser = (values, form) => {
        setChangeForm(false)
        message.loading({
            content: "Saving User...",
            key: "saving_user",
            duration: 0,
        });
        API.put("/api/user/update/" + values.id, values)
            .then(({ data }) => {
                message.destroy("saving_user");
                getDataPaginate({
                    current: users.current_page,
                    pageSize: users.per_page,
                });
                form.resetFields();
                setVisibleFormUser(false);
                setVisibleFormEditUser(false);
                disabled_page(`user/edit/${values.id}`)
                setValuesFormEditUser({});
            })
            .catch((error) => {
                message.destroy("saving_user");
                console.error(error);
            });
    };

    const handleEdit = (values) => {
        let form_values = {
            ...values,
            honorific: values.honorific !== "NULL" ? values.honorific : "",
            first_name: values.first_name !== "NULL" ? values.first_name : "",
            last_name: values.last_name !== "NULL" ? values.last_name : "",
            phone: values.phone !== "NULL" ? createPhoneMask(values?.phone) : "",
            job_title: values.job_title !== "NULL" ? values.job_title : "",
            address1: values.address1 !== "NULL" ? values.address1 : "",
            address2: values.address2 !== "NULL" ? values.address2 : "",
            city: values.city !== "NULL" ? values.city : "",
            ZIPcode: values.ZIPcode !== "NULL" ? values.ZIPcode : "",
            district: values.district_id !== "NULL" && values?.district_id !== undefined ? values.district_id : null,
            school: values.school !== "NULL" && values?.school !== undefined ? values.school : null,
            notes: values.notes !== "NULL" ? values.notes : "",
            certifications: values.certifications.map(v => v.id),
            roles: values.roles.map((element) => element.id),
            courses: values.courses.map((element) => element.course_id),
            states_coordinator: values.states_coordinator.map(
                (element) => element.state_id
            ),
            updated_by: values.who_updated ? values.who_updated.username : "TBD",
            created_by: values.who_created ? values.who_created.username : "TBD",
            updated_at: values.updated_at ? values.updated_at : "TBD",
            profile_updated: values.profile_updated ? values.profile_updated : "TBD",
            created_at: values.created_at ? values.created_at : "TBD",
            password: "",
            status: values.status ? values.status : null
        };
        setValuesFormEditUser(form_values);
        setVisibleFormUser(true);
        setVisibleFormEditUser(true);
    };


    const handleSearch = (value) => {
        setLoadingTable(true)
        setValueSearch(value);
        if (value && value !== null && value !== undefined && value !== "") {
            setDisabledExport(false);
            API.get(`api/user/search/${value}?column=${sorterColumn}&order_by=${sorterOrder}&filter=${JSON.stringify(filterSearchValues)}&filterDiagnosticsUsers=${filterDiagnosticsUsers}`).then(({ data }) => {
                setFilterSearch(true);
                setUsers(data.users);
                setLoadingTable(false)
                setTextTotalAccounts(data.users.total.toLocaleString() + ' of ' + data.totalUsers.toLocaleString() + ' accounts')
            });
        } else {
            setDisabledExport(true);
            getUsers();
        }
    };

    const handleFilterCancel = () => {
        setVisibleFilterUser(false);
    };

    const handleCancel = (values, form, edit) => {
        //there is always a modified field that is default course
        if (changeForm) {
            confirm({
                title: "Do you want to cancel without saving?",
                icon: <ExclamationCircleOutlined />,
                okButtonProps: { type: "primary" },
                okText: "Yes",
                cancelText: "No",
                cancelButtonProps: { danger: true },
                onOk() {
                    form.resetFields();
                    setChangeForm(false)
                    setVisibleFormUser(false);
                    setVisibleFormEditUser(false);
                    disabled_page(`user/edit/${values.id}`)
                    setValuesFormEditUser({});
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        } else {
            form.resetFields();
            setVisibleFormUser(false);
            setVisibleFormEditUser(false);
            disabled_page(`user/edit/${values.id}`)
            setValuesFormEditUser({});
        }
    };

    const deleteUser = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to permanently delete this account?  There is no way to restore a deleted account.',
            icon: <ExclamationCircleOutlined style={{ color: "#ecd62a" }} />,
            onOk() {
                API.delete(`api/user/${id}`).then(({ data }) => {
                    getDataPaginate({
                        current: users.current_page,
                        pageSize: users.per_page,
                    });
                    setVisibleFormUser(false);
                    Modal.warning({ content: 'User account deleted. ' });

                }).catch();
            }
        });
    }

    const getUsers = async () => {
        if(filterDiagnosticsUsers === null) {
            return;
        }
        if(valueStatusFilter === null) {
            return;
        }

        await API.get(`api/user?filter=${JSON.stringify(filterSearchValues)}&filterDiagnosticsUsers=${filterDiagnosticsUsers}&filterStatus=${JSON.stringify(valueStatusFilter)}`).then(({ data }) => {
            setUsers(data.users)
            setLoadingTable(false)
            setTextTotalAccounts(data.users.total.toLocaleString() + ' of ' + data.totalUsers.toLocaleString() + ' accounts')
            setBtnState(data.users.total, data.totalUsers)
        });
    };
    window.document.addEventListener("loadUsers", () => {
        getUsers();
    });

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, [filterDiagnosticsUsers]);

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, [valueStatusFilter]);


    useEffect(() => {
        getHeaderUserFilter()
        // eslint-disable-next-line
    }, []);

    const getHeaderUserFilter = () => {
        return API.get('api/getHeaderUserFilter').then(({data: {filter}}) => setFilterDiagnosticsUsers(filter?.filter || DEFAULT_OPTION_FEEDBACK))
    }

    const handleChangeDiagnostics = (value) => {
        setLoadingTable(true);
        setFilterDiagnosticsUsers(value);
    };

    const handleChangeStatusFilter = (value) => {
        setLoadingTable(true);
        setValueStatusFilter(value);
    };

    return (
        <>
            <Row>
                <Col flex="300px">
                    <Space direction="horizontal">
                        <Button
                            type="primary"
                            onClick={() => {
                                setShowStaffContractor(false);
                                setVisibleFormUser(true);
                                setVisibleFormEditUser(false);
                            }}
                        >
                            Add User
                        </Button>

                        <Button
                            type="primary"
                            disabled={disabledExport ? true : false}
                            onClick={exportData}
                        >
                            Export
                        </Button>
                    </Space>
                </Col>
                <Col flex="auto">
                    <Row justify="end">
                        <Space direction="horizontal" align="end">
                            <Select
                                mode='multiple'
                                options={FILTER_STATUS}
                                defaultValue={FILTER_STATUS}
                                className={classes.filterStatus}
                                onChange={handleChangeStatusFilter}
                            />

                            <Select
                                options={DIAGNOSTICS_OPTIONS_FILTER}
                                value={filterDiagnosticsUsers}
                                className={classes.filterSelector}
                                onChange={handleChangeDiagnostics}
                            />

                            <Button
                                type="primary"
                                className={`filter_btn ${extraClassBtn}`}
                                onClick={() => {
                                    setVisibleFilterUser(true)
                                    let filters = JSON.parse(localStorage.getItem('filter_users'));
                                    if (filters && Object.keys(filters).length < 1) {
                                        setTextFilter("");
                                    }

                                    setVisibleFormEditUser(false)
                                    setValuesFilter();
                                }}
                            >
                                Filter
                            </Button>

                            <Input.Search
                                ref={inputValueSearch}
                                placeholder="Search"
                                onSearch={handleSearch}
                                className={classes.filterSelector}
                                allowClear
                            />
                        </Space>
                    </Row>
                </Col>
            </Row>
            <Row className={classes.label}>
                {textTotalAccounts} {textFilter}
            </Row>

            <CreateUser
                onCreate={handleCreate}
                visible={visibleFormUser}
                onCancel={handleCancel}
                onEdit={handleEditUser}
                edit={visibleFormEditUser}
                values={valuesFormEditUser}
                valuesCreate={valuesCreate}
                deleteUser={deleteUser}
                showStaffContractor={showStaffContractor}
                setShowStaffContractor={setShowStaffContractor}
                setChangeForm={setChangeForm}
            />
            <FilterUser
                visible={visibleFilterUser}
                onCancel={handleFilterCancel}
                filterSearchData={filterSearchData}
                values={valuesFormEditUser}
                setFilterSearchValues={setFilterSearchValues}
                setTextFilter={setTextFilter}
            />

            <ListUsers
                users={users}
                editUser={handleEdit}
                getDataPaginate={getDataPaginate}
                LoadingTable={LoadingTable}
            />
        </>
    );
};

export default UserPage;
