import React, { useState, useEffect } from 'react'
import { Checkbox, Row, Col } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
        ['link', 'image'],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],

        ['clean']
    ],
};

const RichTextEditor = ({ value, setValue ,showPreview=true}) => {
    const [preview, setPreview] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            console.log(value);
            document.getElementsByClassName("ql-editor")[0].innerHTML = value;
        }, 50);
      // eslint-disable-next-line
      }, []);

    return (
        <>

            <Row>
                <Col span={24} >
                    {!preview ?
                        <ReactQuill theme="snow" modules={modules} value={value} onChange={setValue} />
                        :
                        <div dangerouslySetInnerHTML={{ __html: value }} className="ql-result" />
                    }
                </Col>
            </Row>
            { showPreview ?
            <Row>
                <Col>
                    <Checkbox onChange={({ target }) => setPreview(target.checked)}>Preview</Checkbox>
                </Col>
            </Row>
            :  "" }
        </>
    )
}

export default RichTextEditor
