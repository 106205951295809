
import API from "../api";
import  {getAlpToken} from "./getAlpToken";
export const loginWith = (id_user) => {
    API.get(`/api/user/loginas/${id_user}/${false}`).then(({ data }) => {
        let user_session = JSON.parse(localStorage.getItem("user-data"));
        localStorage.setItem("master-user", JSON.stringify(user_session));
        localStorage.setItem("user-data", JSON.stringify(data.user));
        localStorage.setItem("nisl-data", JSON.stringify(data.data));
        localStorage.setItem("jwt-token", data.token);
        localStorage.setItem("date-last-update-pass", data.lastDateChangedPassword);
        localStorage.removeItem("info-shared-me");
        sessionStorage.removeItem("dontShowModalLogonPopup");
        window.location.replace("/");
        getAlpToken(data?.user?.nisl_id)
    });
};
