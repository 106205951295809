import API from "../api";
export const getUserData = (token, setUserName) => {
    API.get(`api/getuserdata?token_cms=${token}`)
        .then(({ data }) => {
            if (data) {
                if (data.userdata){

                }
                if (data.userdata.error === false) {
                    let nislData = JSON.parse(
                        localStorage.getItem("nisl-data")
                    );
                    if (nislData) {
                        nislData = {
                            ...nislData,
                            getuserdata: {
                                data: data.userdata.data,
                            },
                        };
                        localStorage.setItem(
                            "nisl-data",
                            JSON.stringify(nislData)
                        );
                        setUserName(
                            `${data.userdata.data.first_name} ${data.userdata.data.last_name}`
                        );
                    }
                }
            }
        })
        .catch((e) => console.log(e));
};
