import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    Modal,
    Row,
    Col,
    Button,
    Select
} from "antd";

const UpdateSetting = ({ modalvisible, onCancel, onSave, valuesData }) => {

    const [form] = Form.useForm();
    const { TextArea } = Input;

    const styBodyModal = {
        paddingTop: '0px'
    };
    const styleRow = {
        paddingTop: "5px",
        paddingBottom: "5px"
    }

    const [optionsSelect, setOptionsSelect] = useState([]);

    useEffect(() => {
        if (valuesData.table_key !== null) {
            setOptionsSelect(valuesData.optionsSelector)
        } else {
            setOptionsSelect([]);
        }

        form.setFieldsValue(valuesData);
    }, [valuesData]);

    return (
        <Modal
            visible={modalvisible}
            title={`Update Setting`}
            centered
            maskClosable={false}
            cancelText="Cancel"
            cancelButtonProps={{ danger: true }}
            onCancel={() => {
                onCancel(form, null)
            }}
            width="50%"
            okButtonProps={{ type: "primary" }}
            bodyStyle={styBodyModal}
            footer={
                <div>
                    <Button
                        onClick={() => {
                            onCancel(form, null)
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            form.validateFields()
                                .then((values) => {
                                    onSave(form, values, valuesData);
                                })
                                .catch((info) => {
                                });
                        }}>
                        Save
                    </Button>
                </div>
            }
        >
            <Form
                form={form}
                style={{ display: "unset" }}
                name="options"
                layout="vertical">

                <Row gutter={[0, 10]} style={styleRow}>
                    <Col xs={24}>

                        <Form.Item name="description" label="Description"
                            className="hide_rule_message"
                            style={{ width: "80%" }}
                            rules={[{ required: false }]}>
                            <TextArea rows={2} style={{ height: 120 }} />
                        </Form.Item>

                    </Col>
                </Row>

                {valuesData.table_key === null ?
                    <Row gutter={[0, 10]} style={styleRow}>
                        <Col xs={24}>

                            <Form.Item name="value" label="Value"
                                className="hide_rule_message"
                                style={{ width: "80%" }}
                                rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>

                        </Col>
                    </Row>
                    : <Row gutter={[0, 10]} style={styleRow}>
                        <Col xs={24}>
                            <Form.Item name="value" label="Value">
                                <Select options={optionsSelect} showSearch mode="multiple"
                                    placeholder="Select" optionFilterProp="children"
                                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
        </Modal>
    )
};

export default UpdateSetting;
