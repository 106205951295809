export const alphabeticSort = (stringA, stringB, desc) => {
    const normalizeStringA = stringA.toUpperCase();
    const normalizeStringB = stringB.toUpperCase();
    if(desc) {
        return (normalizeStringA < normalizeStringB && -1 ) || ((normalizeStringA > normalizeStringB) && 1) || 0
    } else {
        return (normalizeStringA < normalizeStringB && 1 ) || ((normalizeStringA > normalizeStringB) && -1) || 0
    }

};

