import React, { useState } from 'react'
import { Modal } from 'antd'
import Profile from "../pages/Profile/Profile";
import { logOut } from "./shared/HeaderComponent";
import { Spin } from "antd";

const VerifyProfileData = ({ visible, fromVerifyFirstTime, requiredData }) => {

    const [showSpinner, setShowSpinner] = useState(false);

    const pinStyle = {
        position: "absolute",
        zIndex: "40",
        height: "96%",
        width: "96%",
        backgroundColor: "#eee",
        paddingTop: "20%"
    };

    const handleCancel = () => {
        setShowSpinner(true);
        logOut();
    }

    return (
        <Modal
            visible={visible || requiredData}
            width="98%"
            closable={false}
            onCancel={handleCancel}
            footer={null}
            maskClosable={false}
            centered >

            {showSpinner ? (
                <Spin tip="You will be redirected to the login..." size="large" style={pinStyle}></Spin>
            ) : null}

            <Profile
                fromVerifyFirstTime={fromVerifyFirstTime}
                cancelEditFirstTime={handleCancel}
                requiredData={requiredData}
            />
        </Modal>
    )
}

export default VerifyProfileData


