import React from "react";
import RouterRoot from "./Router/RouterRoot";

function App() {
    return (
        <>
            <RouterRoot />
        </>
    );
}

export default App;
