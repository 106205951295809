import React from "react";
import { Col, Row, Modal } from "antd";
import moment from "moment";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Materials  from "./Materials/Materials";
import { UNIQUE } from "../../../config/typeEvents";

const getColumns = ({ columnView, viewCities, countCohorts, alpData, today, missingAttendance  }) => {
    let belongsToButton = {
        "assignments_attendance": [
            {
                title: <div style={{ padding: 0 }} >Assignments</div>,
                align: "center",
                width: "40%",
                render: (value) => {
                    if (value.change_year === false) {
                        return (
                            <Row style={{ textAlign: "left" }} align="middle">
                            <Col span={24}>
                                {value.type_event.slug !== UNIQUE && value?.facilitators?.filter(fac => fac.username !== 'not_required' && fac.username !== "unassigned").length > 0  ?
                                    <Row>
                                        <Col span={24}>
                                            Facilitator: {
                                                value?.facilitators?.map((v, i) => {
                                                    return (
                                                        <>
                                                            <a key={i} href={"mailto:" + v.email} >{v.name}{(i + 1) !== value?.facilitators.length ? ", " : null}</a>
                                                        </>);
                                                })
                                            }
                                        </Col>
                                    </Row> : null
                                }
                                {value.type_event.slug !== UNIQUE && value?.co_facilitators?.filter(cofac => cofac.username !== 'not_required' && cofac.username !== "unassigned").length !== 0  ?
                                    <Row>
                                        <Col span={24}>
                                            Co-Facilitator: {
                                                value?.co_facilitators?.map((v, i) => {
                                                    return (
                                                        <>
                                                            <a key={i} href={"mailto:" + v.email} >{v.name}{(i + 1) !== value?.co_facilitators?.length ? ", " : null}</a>
                                                        </>)
                                                })}
                                        </Col>
                                    </Row> : null
                                }
                                {value.type_event.slug !== UNIQUE && value?.cohort?.qualities?.filter(quality => quality.username !== 'not_required' && quality.username !== "unassigned").length !== 0 && value.cohort?.delivery_model?.is_edp_pss ?
                                    <Row>
                                        <Col span={24}>
                                            Quality Support: {
                                                value?.cohort?.qualities?.map((v, i) => {
                                                    return (
                                                        <>
                                                            <a key={i} href={"mailto:" + v.email} >{v.first_name === "Local" ? v.first_name : v.name}{(i + 1) !== value?.cohort?.qualities?.length ? ", " : null}</a>
                                                        </>)
                                                })}
                                        </Col>
                                    </Row> : null
                                }
                                {value.cohort?.alp_coachs?.filter(coach => coach.username !== 'not_required' && coach.username !== "unassigned").length !== 0 ?
                                    <Row >
                                        <Col span={24}>
                                                {value.type_event.slug !== UNIQUE ? 'AL Adviser:' :  value.cohort?.alp_coachs?.length === 1 ? `Coach:` : `Coaches:`} {
                                                value.cohort?.alp_coachs.map((v, i) => {
                                                    return (
                                                        <>
                                                            <a key={i} href={"mailto:" + v.email} >{v.first_name === "Local" ? v.first_name : v.name}{(i + 1) !== value.cohort?.alp_coachs.length ? ", " : null}</a>
                                                        </>)

                                                })}
                                        </Col>
                                    </Row> : null
                                }
                                {value.type_event.slug !== UNIQUE && value?.producer?.filter(prod => prod.username !== 'not_required' && prod.username !== "unassigned").length !== 0 && !value.cohort?.delivery_model?.is_edp_pss ?
                                    <Row>
                                        <Col span={24}>
                                            Producer: {
                                                value?.producer?.map((v, i) => {
                                                    return (
                                                        <>
                                                            <a key={i} href={"mailto:" + v.email} >{v.first_name === "Local" ? v.first_name : v.name}{(i + 1) !== value?.producer?.length ? ", " : null}</a>
                                                        </>)
                                                })}
                                        </Col>
                                    </Row> : null
                                }
                            </Col>
                        </Row>
                        )
                    } else {
                        return {
                            children: <span>{value.year_group_by}</span>,
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                }
            },
            {
                title: <div style={{ padding: 0 }} >Missing <br />Attendance</div>,
                width: "10%",
                align: "center",
                render: (value) => {
                    if (value.change_year === false) {
                        let showModal = false;
                        let arrMissingAttendance = [];
                        let newMissingAttendanceText = '';
                        let completeMissingAttendanceText = ';'
                        if (missingAttendance[value.cohort_id] !== undefined && value.type_event.slug !== UNIQUE) {
                            arrMissingAttendance = missingAttendance[value.cohort_id].toString().split(',');
                            completeMissingAttendanceText = arrMissingAttendance.join(',')
                            newMissingAttendanceText = arrMissingAttendance.slice(0, 5).join(",")
                            showModal = arrMissingAttendance.length >= 6;
                        }
                        return (
                            <>
                                <span>
                                    {
                                        showModal ?
                                            <span style={{ cursor: 'pointer', color: "#333" }}
                                                onClick={() =>
                                                    Modal.info({
                                                        title: "",
                                                        content: completeMissingAttendanceText
                                                    })}> {newMissingAttendanceText.concat(' ...')}</span>
                                            : <span style={{ color: "#333" }}>{newMissingAttendanceText}</span>
                                    }
                                </span>
                            </>
                        );
                    } else {
                        return {
                            children: <span>{value.year_group_by}</span>,
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                }
            },
        ],
        "assigments": [
            {
                title: <div style={{ padding: 0 }} >Assignments</div>,
                align: "center",
                width: "50%",
                render: (value) => {
                    if (value.change_year === false) {
                        return (
                            <Row style={{ textAlign: "left" }} align="middle">
                            <Col span={value.type_event.slug !== UNIQUE ? 12 : 0}>
                                <Row>
                                    <Col span={24}>
                                        {value.type_event.slug !== UNIQUE ?
                                            <> Facilitator: {
                                                value?.facilitators?.length ? value?.facilitators.find(v => v.name === "Not Required *") ? <span>Not Required *</span> : value?.facilitators?.map((v, i) => {
                                                    if (v.username === "UNASSIGNED") {
                                                        return <span key={i}>UNASSIGNED</span>
                                                    } if (["Local", "Not Required *"].includes(v.name)) {
                                                        return <span key={i}>{v.name}</span>
                                                    } else {
                                                        return (
                                                            <>
                                                                <a key={i} href={"mailto:" + v.email} >{v.name}{(i + 1) !== value?.facilitators.length ? ", " : null}</a>
                                                            </>);

                                                    }
                                                }) : <span>UNASSIGNED</span>}
                                            </> : null
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        {value.type_event.slug !== UNIQUE ?
                                            <>
                                                Co-Facilitator: {
                                                    value?.co_facilitators?.length ? value?.co_facilitators?.find(v => v.name === "Not Required *") ? <span>Not Required *</span> : value?.co_facilitators?.map((v, i) => {
                                                        if (v.username === "UNASSIGNED") {
                                                            return <span key={i}>UNASSIGNED</span>
                                                        } if (["Local", "Not Required *"].includes(v.name)) {
                                                            return <span key={i}>{v.name}</span>
                                                        } else {
                                                            return (
                                                                <>
                                                                    <a key={i} href={"mailto:" + v.email} >{v.name}{(i + 1) !== value?.co_facilitators?.length ? ", " : null}</a>
                                                                </>)
                                                        }
                                                    }) : <span>UNASSIGNED</span>}
                                            </>
                                            : null
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={value.type_event.slug !== UNIQUE ? 12 : 24}>
                                {value?.producer?.length !== 0 && !value.cohort?.delivery_model?.is_edp_pss ?
                                    <Row>
                                        <Col span={24}>
                                        {value.type_event.slug !== UNIQUE ?
                                            <>
                                            Producer: {
                                                value?.producer?.length ? value?.producer?.find(v => v.name === "Not Required *") ? <span>Not Required *</span> : value?.producer?.map((v, i) => {
                                                    if (v.username === "UNASSIGNED") {
                                                        return <span key={i}>UNASSIGNED</span>
                                                    } else if (["Local", "Not_Required", "required@ncee.org"].includes(v.username)) {
                                                        return <span key={i}>{v.name}</span>
                                                    } else {
                                                        return (
                                                            <>
                                                                <a key={i} href={"mailto:" + v.email} >{v.first_name === "Local" ? v.first_name : v.name}{(i + 1) !== value?.producer?.length ? ", " : null}</a>
                                                            </>)
                                                    }
                                                }) : ""}
                                            </>
                                            : null
                                        }
                                        </Col>
                                    </Row> : null
                                }

                                { value.type_event.slug !== UNIQUE && value?.producer?.length === 0 && !value.cohort?.delivery_model?.is_edp_pss ?
                                    <Row>
                                        <Col span={24}>
                                            Producer: {<span>UNASSIGNED</span>}
                                        </Col>
                                    </Row> : null
                                }

                                {value.type_event.slug !== UNIQUE && value?.cohort?.qualities?.length !== 0 && value.cohort?.delivery_model?.is_edp_pss ?
                                    <Row>
                                        <Col span={24}>
                                            Quality Support: {
                                                value?.cohort?.qualities?.length ? value?.cohort?.qualities?.find(v => v.name === "Not Required *") ? <span>Not Required *</span> : value?.cohort?.qualities?.map((v, i) => {
                                                    if (v.username === "UNASSIGNED") {
                                                        return <span key={i}>UNASSIGNED</span>
                                                    } if (["Local", "Not Required *"].includes(v.name)) {
                                                        return <span key={i}>{v.name}</span>
                                                    } else {
                                                        return (
                                                            <>
                                                                <a key={i} href={"mailto:" + v.email} >{v.first_name === "Local" ? v.first_name : v.name}{(i + 1) !== value?.cohort?.qualities?.length ? ", " : null}</a>
                                                            </>)
                                                    }
                                                }) : <span>UNASSIGNED</span>}
                                        </Col>
                                    </Row> : null
                                }

                                {value.type_event.slug !== UNIQUE && value?.cohort?.qualities?.length === 0 && value.cohort?.delivery_model?.is_edp_pss ?
                                    <Row>
                                        <Col span={24}>
                                            Quality Support: {<span>n/a</span>}
                                        </Col>
                                    </Row> : null
                                }
                                {value.cohort?.alp_coachs?.length !== 0 ?
                                    <Row >
                                        <Col span={24}>
                                            {value.type_event.slug !== UNIQUE ? 'AL Adviser:' :  value.cohort?.alp_coachs?.length === 1 ? `Coach:` : `Coaches:`} {
                                                value.cohort?.alp_coachs?.length ? value.cohort?.alp_coachs?.find(v => v.name === "Not Required *") ? <span>Not Required *</span> : value.cohort?.alp_coachs.map((v, i) => {
                                                    if (v.first_name === "UNASSIGNED") {
                                                        return <span key={i}>UNASSIGNED</span>
                                                    } if (["Local", "Not Required *"].includes(v.name)) {
                                                        return <span key={i}>{v.name}</span>
                                                    } else {
                                                        return (
                                                            <>
                                                                <a key={i} href={"mailto:" + v.email} >{v.first_name === "Local" ? v.first_name : v.name}{(i + 1) !== value.cohort?.alp_coachs.length ? ", " : null}</a>
                                                            </>)
                                                    }
                                                }) : <span>UNASSIGNED</span>}
                                        </Col>
                                    </Row> : null
                                }

                            </Col>
                        </Row>
                        )
                    } else {
                        return {
                            children: <span>{value.year_group_by}</span>,
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                }
            }
        ],
        "participant_activity": [
            {
                title: (
                    <>
                        <span>Logon info (days)</span>
                        <br />
                        <Row >
                            <Col span={8}><span style={{ color: "red", fontSize: "11px" }}>never</span></Col>
                            <Col span={8}><span style={{ color: "#b39336", fontSize: "11px" }}>15+</span></Col>
                            <Col span={8}><span style={{ color: "green", fontSize: "11px" }}>{"<15"}</span></Col>
                        </Row>
                    </>
                ),
                align: "center",
                width: "15%",
                render: (value) => {
                    if (value.change_year === false) {
                        let count_never = 0;
                        let never_login_usernames = [];
                        let count_login_gold = 0;
                        let gold_login_usernames = [];
                        let count_login_green = 0;
                        let green_login_usernames = [];
                        if (value?.cohort?.participants) {
                            value.cohort.participants.forEach(participant => {
                                if (participant.login_logs.length === 0) {
                                    count_never++;
                                    never_login_usernames.push(participant.email)
                                } else {
                                    let last_login_date = moment(participant.login_logs[0]?.created_at, "YYYY-MM-DD");
                                    let last_login_days = today.diff(last_login_date, 'days');
                                    if (last_login_days > 15) {
                                        count_login_gold++;
                                        gold_login_usernames.push(participant.email)
                                    } else {
                                        count_login_green++;
                                        green_login_usernames.push(participant.email)
                                    }
                                }
                            })
                        }
                        return (
                            <Row>
                                <Col span={8}><a target="_blank" rel="noopener noreferrer" href={never_login_usernames.length > 0 ? "mailto:" + never_login_usernames.join(",") : "mailto:"} style={{ color: "red" }}>{count_never}</a></Col>
                                <Col span={8}><a target="_blank" rel="noopener noreferrer" href={gold_login_usernames.length > 0 ? "mailto:" + gold_login_usernames.join(",") : "mailto:"} style={{ color: "#b39336" }}>{count_login_gold}</a></Col>
                                <Col span={8}><a target="_blank" rel="noopener noreferrer" href={green_login_usernames.length > 0 ? "mailto:" + green_login_usernames.join(",") : "mailto:"} style={{ color: "green" }}>{count_login_green}</a></Col>
                            </Row>
                        )
                    } else {
                        return {
                            children: <span>{value.year_group_by}</span>,
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                }
            },
            {
                title: (
                    <>
                        <span>ALp progress</span>
                        <br />
                        <Row>
                            <Col span={8}><span style={{ color: "red", fontSize: "11px" }}>0</span></Col>
                            <Col span={8}><span style={{ color: "#b39336", fontSize: "11px" }}>start</span></Col>
                            <Col span={8}><span style={{ color: "green", fontSize: "11px" }}>done</span></Col>
                        </Row>
                    </>
                ),
                align: "center",
                width: "12%",
                render: (value) => {
                    if (value.change_year === false) {
                        return (
                            <Row>
                                <Col span={8}><a target="_blank" rel="noopener noreferrer" href={alpData[value?.cohort?.postId] ? "mailto:" + alpData[value?.cohort?.postId].red?.emails.join(",") : "mailto:"} style={{ color: "red" }}>{alpData[value?.cohort?.postId] ? alpData[value?.cohort?.postId].red?.total : 0}</a></Col>
                                <Col span={8}><a target="_blank" rel="noopener noreferrer" href={alpData[value?.cohort?.postId] ? "mailto:" + alpData[value?.cohort?.postId].gold?.emails.join(",") : "mailto:"} style={{ color: "#b39336" }}>{alpData[value?.cohort?.postId] ? alpData[value?.cohort?.postId].gold?.total : 0}</a></Col>
                                <Col span={8}><a target="_blank" rel="noopener noreferrer" href={alpData[value?.cohort?.postId] ? "mailto:" + alpData[value?.cohort?.postId].green?.emails.join(",") : "mailto:"} style={{ color: "green" }}>{alpData[value?.cohort?.postId] ? alpData[value?.cohort?.postId].green?.total : 0}</a></Col>
                            </Row>
                        );
                    } else {
                        return {
                            children: <span>{value.year_group_by}</span>,
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                }
            },
            {
                title: "Comments",
                align: "center",
                width: "23%",
                render: (value) => {
                    if (value.change_year === false) {
                        let count_comments = 0;
                        if (value?.cohort?.participants) {
                            value.cohort.participants.forEach(participant => {
                                count_comments += participant?.alp_replies_count;
                                count_comments += participant?.alp_comments_count;
                            });
                        }
                        return (<span> {count_comments} </span>)
                    } else {
                        return {
                            children: <span>{value.year_group_by}</span>,
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                }
            }
        ]
    }
    return [
    {
        title: <div style={{ padding: 0 }} >Sessions ({new Intl.NumberFormat("en-US").format(countCohorts)})</div>,
        align: "right",
        width: "20%",
        className: "column_session",
        render: (value, row) => {
            if (value.change_year === false) {
                let city = "";
                if (value?.cohort?.city) {
                    city = value?.cohort?.city
                }
                city += city !== "" ? ", " : "";
                if (value?.cohort?.state?.code) {
                    city += value?.cohort?.state?.code
                }
                return <>
                    <Row gutter={[0, 0]} >
                        <Col span={24}>
                            <span style={{ color: "#002345", fontWeight: "bold", cursor: "pointer" }} >{viewCities ? value?.cohort?.name : city}</span>
                        </Col>
                        <Col span={24}>
                            <span style={{ color: "#b39336" }}>{value?.cohort?.course?.name}</span>
                        </Col>
                    </Row>
                </>
            } else {
                return {
                    children: <Row>
                        <Col span={value.span}>
                            {value.year_group_by}
                        </Col>
                    </Row>,
                    props: {
                        colSpan: 9,
                    },
                };
            }
        }
    },
    {
        title: <div style={{ padding: 0,marginLeft:"10px"}} >Participants</div>,
        width: "11%",
        className: "column_participants",
        render: (value) => {
            if (value.change_year === false) {
                let makeups = value?.makeups.length;
                let width = value?.warning_url && value?.warning_url === true ? 22 : 24;
                return (
                    <>
                        <Row align="middle">
                            <Col span={width} >
                                {value.type_event.slug !== UNIQUE ?
                                    <>
                                        <span style={{ color: "#b39336", marginLeft: "15px" }}>
                                            {value?.cohort?.participants.length} participants
                                        </span>
                                        <br />
                                        <span style={{ color: "#b39336", marginLeft: "15px", fontSize: "12px" }}>
                                            {makeups === 0 ? "0 guests added" : "+" + (makeups > 1 ? makeups + " guests added" : "1 guest added")}
                                        </span>
                                    </>
                                    : <span style={{ color: "#b39336", marginLeft: "15px" }}>
                                        {value.cohort?.participants?.length} {value.cohort?.participants?.length === 1  ? `coachee` : `coachees`}
                                    </span>}
                            </Col>
                            {(value?.warning_url && value?.warning_url === true) ?
                                <Col span={2}>
                                    <ExclamationCircleOutlined onMouseOver={() => {
                                        Modal.error({ content: "There is no end of unit evaluation link. Please contact the NCEE Helpdesk at helpdesk@ncee.org or 855-443-6359 between 8 am and 6 pm ET." });
                                    }} style={{ color: "red", fontSize: '15px' }} />

                                </Col> : ""}
                        </Row>
                    </>
                )
            } else {
                return {
                    children: <span>{value.year_group_by}</span>,
                    props: {
                        colSpan: 0,
                    },
                };
            }
        }
    },
    ...(belongsToButton[columnView]),
    {
        title: <div style={{ padding: 0 }} >Materials/<br /> Contract</div>,
        width: "85px",
        align: "center",
        render: (value) => {
            if (value.change_year === false) {
                let contractsMap = value?.cohort?.contract?.split(",");
                let ellipsisContract = '';
                if (contractsMap?.length > 2) {
                    ellipsisContract = "...";
                }
                contractsMap = contractsMap?.slice(0, 2);
                if (value?.cohort?.material_status !== null || value?.cohort?.course?.material_status !== null) {
                    return (
                        <>
                            <button className={"button-material-status"} onClick={() =>
                                Modal.info({
                                    title: "",
                                    width: value?.cohort?.course?.material_status ? 1000 : 420,
                                    content: <Materials cohort_materials={value?.cohort?.material_status} course_material={value?.cohort?.course?.material_status} />
                                })}>
                                <img src="/truck-icon.png" alt="" style={{ width: "25px" }} />
                            </button>
                            <br />
                            <span>
                                <span style={{ color: "#333" }}>{contractsMap?.map((constract, i) => <>{constract}{i + 1 !== contractsMap.length && <>,<br /></>}</>)}{ellipsisContract}</span>
                            </span>
                        </>
                    );
                } else {
                    return (
                        <span>
                            <span style={{ color: "#333" }}>{contractsMap}</span>
                        </span>
                    );
                }
            } else {
                return {
                    children: <span>{value.year_group_by}</span>,
                    props: {
                        colSpan: 0,
                    },
                };
            }
        }

    },
    {
        title: <div style={{ padding: 0 }} >Roster</div>,
        align: "center",
        width: "6%",
        className: "column_roster",
        render: (value) => {
            if (value.change_year === false) {
                return (
                    <>
                        <a target='_blank' rel="noopener noreferrer" className="navlink-header" href={`cohort/${value.cohort.id}/event/${value.event_id}/unit/${value.unit_id}/cohort/${value.cohort?.postId}`} ><img src={value.type_event.slug ===  UNIQUE ? '/roster-icon-red.png' : '/roster-icon.png'} alt="" style={{ width: "35px" }} /></a>
                    </>
                )
            } else {
                return {
                    children: <span>{value.year_group_by}</span>,
                    props: {
                        colSpan: 0,
                    },
                };
            }
        }
    },
    {
        title: <div style={{ padding: "0px", marginRight: "0px" }} >Training</div>,
        align: "center",
        className: "column_training",
        width: "13%",
        render: (value) => {
            if (value.change_year === false) {
                let start_date = null;
                if (value.start_date) {
                    start_date = moment(value.start_date).format("M/D");
                } else {
                    start_date = "TBD";
                }
                let time = "";
                if (value?.time?.start_time) {
                    time += value?.time?.start_time;
                }

                if (value?.time?.end_time) {
                    if (value?.time?.start_time) {
                        time += " - "
                    }
                    time += value?.time?.end_time;
                }

                if (time !== "") {
                    time += " " + value?.time?.utc
                }

                let event = "";
                if (isNaN(value.event_id) && value.unit_id !== "orientation") {
                    event = value.event_id;
                } else if (value.unit_id === "orientation") {
                    event = "Orientation";
                } else {
                    event = value.event_id
                    if (value.total_events_for_unit > 1 || value.day > 1) {
                        event = event.concat(" day " + value.day);
                    }
                }

                let unit = "Unit";
                if (value.unit_id === "orientation") {
                    unit="";
                }else if (!value?.cohort?.course?.is_edp || value?.cohort?.course.key === 'cohort20') {
                    unit = "Session";
                }
                let render = null;
                if (value.type_event.slug === UNIQUE) {
                    render = (<>
                       <Row align="middle" style={{ padding: "0px", height: "60px" ,lineHeight:"normal" }}>
                            <Col span={24}>
                                <span style={{ fontSize: "13px", color: "#002345" }}>Start: {moment(value.start_date).format("M/D/YY")}<br /></span>
                                <span style={{ fontSize: "13px", color: "#002345" }}>End: {moment(value.end_date).format("M/D/YY")}<br /></span>
                            </Col>
                        </Row>
                    </>)
                }else {
                   render = (<>
                        <Row align="middle" style={{ padding: "0px", height: "60px" ,lineHeight:"normal" }}>
                            <Col span="24">
                                <span style={{ fontSize: "13px", color: "#002345" }}>{unit} {event} - {start_date}<br />
                                <span style={{ fontSize: "10px" }}>{time}</span><br />
                                <span style={{ fontSize: "13px" }}>{value?.meeting?.slug}</span>
                                </span>
                            </Col>
                        </Row>
                    </>)
                }

                return (
                    <>{render}</>
                )
            } else {
                return {
                    children: <span>{value.year_group_by}</span>,
                    props: {
                        colSpan: 0,
                    },
                };
            }
        }

    },
    {
        title: <div style={{ padding: "0px", marginRight: "0px" }} ></div>,
        align: "center",
        className: "column_training",
        width: "2%",
        render: (value) => {
            if (value.change_year === false) {
                let showToday = false;
                if (value.start_date) {
                        showToday = moment(value.start_date).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD");
                } else {
                }
                let render = null;
                if (value.type_event.slug === UNIQUE) {
                    render = (<>
                       <Row align="middle" style={{ padding: "0px", height: "60px" ,lineHeight:"normal" }}>
                            <Col span="24" style={{ transform: "rotate(-90deg)", marginLeft: "3px" }}>
                                <span className="ongoing-cohort" style={{ transform: "translate(-22%, 0px)" }}>ONGOING</span>
                            </Col>
                        </Row>
                    </>)
                }else {

                   render = (<>
                        <Row align="middle" style={{ padding: "0px", height: "60px" ,lineHeight:"normal" }}>
                            <Col span="24" style={{ transform: "rotate(-90deg)", marginLeft: "3px" }}>
                                {
                                    showToday ?
                                        <span className="current-cohort" style={{ transform: "translate(-23%, 0px)" }}>today</span>
                                        : null
                                }
                                {
                                    value.mark_next ?
                                        <span className="next-cohort" style={{ transform: "translate(-23%, 0px)" }}>Next</span>
                                        : null
                                }
                            </Col>

                        </Row>
                    </>)
                }

                return (
                    <>{render}</>
                )
            } else {
                return {
                    children: <span>{value.year_group_by}</span>,
                    props: {
                        colSpan: 0,
                    },
                };
            }
        }

    },

]};

export default getColumns;
