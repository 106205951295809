import { EXECUTIVE_COACHING } from "../../config/courses";


const refreshCohortSchedule = (form, units_show = null, currentCohort = null) => {
    try {

        let values = {};
        if (units_show === null) {
            var units_show = [];
        }

        values = form.getFieldsValue();
        if (values.cohort_schedule === undefined) {
            values = currentCohort;
        }

        if (units_show !== null && units_show.length === 0) {
            if (currentCohort.course_data) {
                //array with all  units of selected course with validate check on sub courses
                units_show = currentCohort.course_data.section_units.filter((sub_course, index) => currentCohort.arr_units_check?.includes(index))?.flatMap(sub_course => { return sub_course?.units });
            } else {
                // for create cohort, show the all units
                units_show = values.cohort_schedule.map(e => { return e.unit })
            }
        }

        values.cohort_schedule.forEach((unit, index) => {
            //if edit cohort then we hidden the units not selected on sub courses
            if (!units_show.includes(unit.unit.toString().trim())) {
                hideUnit(unit.unit)
            } else {
                showUnit(unit.unit)
            }

            //unit type ext then we hidden the parent unit
            if (unit.event_type === "ext" && unit.cohort_schedule_sub.length !== 0 && unit.unit !== null && unit.unit !== undefined) {
                let html_element = document.querySelector(`#principal_unit${unit.unit} div.ant-row`);

                if (html_element != null) {
                    html_element.style.display = "none";
                }
            }

            //This logis is to hidde all the events or entire units that don't have start date and the cohort has forced end
            if (values?.force_end.includes("yes") || (!values?.force_end.includes("yes") && currentCohort?.force_end[0] && currentCohort?.force_end[0] === "yes")) {

                let displayEvent = values?.force_end.includes("yes") ? "none" : "";

                if (unit.cohort_schedule_sub.length === 0 && unit.start_date == null) {
                    let html_element = document.querySelector(`#principal_unit${unit.unit}`);

                    if (html_element != null) {
                        html_element.style.display = displayEvent;
                    }
                } else {
                    unit.cohort_schedule_sub.map((event, index) => {
                        if (event.start_date_sub_unit === null) {
                            let html_element = document.querySelector(`.sub_unit_${unit.unit}_${index}`);

                            if (html_element != null) {
                                html_element.style.display = displayEvent;
                            }
                        }
                    })
                }
            }
        });

        if (currentCohort?.course === `cohort${EXECUTIVE_COACHING}` || values.course === `cohort${EXECUTIVE_COACHING}`) {
            hideSection(`.notshowexecutive`)
        } else {
            showSection(`.notshowexecutive`)
        }

    } catch (error) {
        console.log("error refresh", error);
    }
}

let hideSection = (className) => {
    let html_element = document.querySelectorAll(className);

    if (html_element !== null) {
        html_element.forEach((v) => {
            v.style.display = "none";
        });
    }
}


let showSection = (className) => {
    let html_element = document.querySelector(className);
    if (html_element !== null) {
        html_element.style.display = "";
    }
}

let hideUnit = (unit) => {
    let html_element = document.querySelector(
        `#principal_unit${unit}`
    );

    if (html_element !== null) {
        html_element.style.display = "none";
    }
}

let showUnit = (unit) => {
    let html_element = document.querySelector(
        `#principal_unit${unit}`
    );

    if (html_element !== null) {
        html_element.style.display = "";
    }
}

export default refreshCohortSchedule;
