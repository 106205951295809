import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Select, Upload, Typography, Space } from "antd";
import React, { useState, useEffect } from 'react';
const { Option } = Select;
const { Text } = Typography;
const SupportTopicForm = (props) => {
    const {
        form,
        name,
        onFinish,
        pdf,
        video,
        callBackUploadPdf,
        callBackUploadVideo,
        callBackRemovedPdf,
        callBackRemoveVideo,
     } = props;

    const fileSize = 2e+8;
    const [errorMessage, setErrorMessage] = useState();
    const [videoFile, setVideoFile] = useState((video === null || video === undefined)?[]:[video]);
    const [pdfFile, setPdfFile] = useState((pdf === null || pdf === undefined)?[]:[pdf]);

    useEffect( () => {
        setErrorMessage(props.errorMessage)
    },[props.errorMessage])

    const uploadPdfProps = {
        onRemove: (file) => {
            setPdfFile([]);
            callBackRemovedPdf && {}.toString.call(callBackUploadPdf) === '[object Function]' && callBackRemovedPdf(file);
            setErrorMessage(false);
        },
        beforeUpload: (file) => {
            if(file.size > fileSize){
                setErrorMessage('The pdf file is too large to be uploaded');
                callBackUploadPdf && {}.toString.call(callBackUploadPdf) === '[object Function]' && callBackUploadPdf(false);
            }else{
                callBackUploadPdf && {}.toString.call(callBackUploadPdf) === '[object Function]' && callBackUploadPdf(true);
                setErrorMessage(false);
            }
            setPdfFile([file]);
            return false;
        },
        pdfFile,
        accept: ".pdf",
    };

    if(pdfFile.length>0) {
        uploadPdfProps.fileList = pdfFile
    }

    const uploadVideoProps = {
        onRemove: (file) => {
            callBackRemoveVideo &&
                {}.toString.call(callBackUploadVideo) === '[object Function]' &&
                    callBackRemoveVideo(file);
            setVideoFile([]);
            setErrorMessage(false);
        },
        beforeUpload: file => {

            if(file.size > fileSize){
                callBackUploadVideo &&
                {}.toString.call(callBackUploadVideo) === '[object Function]'
                    && callBackUploadVideo(false);
                setErrorMessage('The video file is too large to be uploaded.');
            }else{
                callBackUploadVideo &&
                {}.toString.call(callBackUploadVideo) === '[object Function]'
                    && callBackUploadVideo(true);
                setErrorMessage(false);
            }
            setVideoFile([file]);
            return false;
        },
        videoFile,
        accept: ".mov,.mp4",
    };
    if(videoFile.length>0) { uploadVideoProps.fileList = videoFile }
    return (
        <>
        <Form
            form={form}
            name={name}
            onFinish={onFinish}
            labelCol={{ span: 4 }}
            colon={false}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            scrollToFirstError
        >
            <Form.Item

                name="session"
                initialValue={props.session}
                label="Tab"
                rules={[
                    {
                        required: true,
                        message: 'Choose a session'
                    }
                ]}
            >
                <Select
                style={{ width: "135%" }}
                    showSearch
                    placeholder="Choose session"
                    optionFilterProp="children"
                    filterOption={''}
                    filterSort={''}
                >
                {
                    props.sessionOptions.map((option) => {
                        return  <Option key={option.key} value={option.key}>{ option.name }</Option>
                    })
                }
                </Select>
            </Form.Item>

            <Form.Item
                name="topic"
                initialValue={props.topic}
                label="Topic"
                rules={[
                    {
                        required: true,
                        message: 'Enter the topic'
                    }
                ]}
            >
                <Input style={{ width: "135%" }}/>
            </Form.Item>

            <Form.Item
                name="pdf"
                label="PDF File"
                valuePropName="pdfFile"
            >
                <Upload {...uploadPdfProps} >
                    <Space>
                        <Button icon={<UploadOutlined />}>Click to choose file</Button>
                        <Text type="secondary">({fileSize/1000000} Mb Max)</Text>
                    </Space>
                </Upload>
            </Form.Item>

            <Form.Item
                name="video"
                label="Video File"
                valuePropName="videoFile"
            >
                <Upload {...uploadVideoProps}>
                    <Space>
                        <Button icon={<UploadOutlined />}>Click to choose file</Button>
                        <Text type="secondary">({fileSize/1000000} Mb Max)</Text>
                    </Space>
                </Upload>
            </Form.Item>

        </Form>
        {
            errorMessage && <Alert
                message={errorMessage}
                type="error"
                showIcon
                onClose={() => setErrorMessage(false)}
                />

        }
    </>);
}
export default SupportTopicForm;
