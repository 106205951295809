import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const DragSortingTable = (props) => {
    const {
        callBackDrag,
        dataTable,
        showHeader,
        pagination,
        loading,
        size,
    } = props;
    const [data, setData] = useState(dataTable.data);
    const components = {
        body: {
        row: DraggableBodyRow,
        },
    };

    useEffect(() => {
        setData(dataTable.data)
    },[dataTable.data]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      callBackDrag && {}.toString.call(callBackDrag) === '[object Function]' && callBackDrag(dataTable.data[dragIndex],dataTable.data[hoverIndex]);
    },
    [data],

  );
  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        showHeader={showHeader}
        columns={dataTable.columns}
        dataSource={data}
        pagination={pagination}
        components={components}
        loading={loading}
        size={size}
        onRow={(record, index) => ({
          index,
          moveRow,
        })}
      />
    </DndProvider>
  );
};
export default DragSortingTable;
