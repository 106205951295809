import React, {useState} from 'react'
import { Modal, Button, Row, Col, Spin, Layout, Space, Table } from 'antd'
import API from "../../../api";
import SearchCohort from './SearchCohort';
import { createUseStyles } from "react-jss";
import moment from "moment";
//import { useHistory } from "react-router-dom";
import './cohorts.less'

const { Content } = Layout;
const useStyles = createUseStyles({
    largeButton: {
        height: "2em",
        fontSize: "11px",
        background: "#fff",
        color: "#0051a0",
        width: "80%",
        lineHeight: "1em",
    },
    container: {
        '& Button': {
            width: '80%',
            height: '40px',
            marginTop: '0.1em',
        },
    },
    blue: {
        color: "#0051a0",
    },
    black: {
        color: "#002345",
    },
    bold: {
        fontWeight: "bold",
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '6em',
    },
    solid: {
        color: '#0051a0',
        backgroundColor: '#0051a0',
        width: '0.5em',
        height: '0.5em',
        borderRadius: '0.25em',
        margin: '0.5em 0.25em',
    },
    deleteColor: {
        color: "red",
    },
    space: {
        whiteSpace: 'pre-wrap',
    },
});
const AttendanceAuditReport = (props) => {

    const [searchValue, setSearchValue] = useState('');
    const [currentCohort, setCurrentCohort] = useState('');
    const [dataCohortSource, setDataCohortSource] = useState([]);
    const [showModalWithCohortInfo, setShowModalWithCohortInfo] = useState(false);
    const [cohortCity, setCohortCity] = useState([]);
    const [cohortCodeState, setCohortCodeState] = useState();
    const [cohortDeliveryInfo, setCohortDeliveryInfo] = useState({});
    const [cohortName, setCohortName] = useState([]);
    const [deliveryModel, setDeliveryModel] = useState([]);
    const [course, setCourse] = useState([]);
    const [totalParticipants, setTotalParticipants] = useState();
    const [missingAttendance, setMissingAttendance] = useState();

    //const history = useHistory();
    const classes = useStyles();

    let handleChangeSearch = () => {
    }

    const exportGraduationAbsentsData = () => {
        let request = null;
        let filename = "Graduation and Absent Data.xlsx";
        request = API.get(`api/date-export-reports?&filename=Graduation and Absent Data`);
        request
            .then(({ data }) => {
                filename = "Absent Data - All Cohorts " + data + ".xlsx";
            });
        API.get(`api/download-absent-report`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        });
    }

    const exportGraduationAllData = () => {
        let filename = "Graduation All Data.xlsx";
        API.get(`api/date-export-reports?&filename=Graduation All Data`)
        .then(({ data }) => {
            filename = "Graduation All Data - All Cohorts " + data + ".xlsx";
        });
        API.get(`api/download-graduation-report`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        });
    }

    const handleExportCohort = () => {
        let newDate = new Date();
        let file_name = "Attendance Data " + moment(newDate, 'ddd DD-MMM-YYYY, hh:mm:ss a').format('YY-MM-DD hh-mm-ss a') + ".xlsx";
        API.get(`api/download-absent-report-by-cohort?cohort=${currentCohort}`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file_name);
            document.body.appendChild(link);
            link.click();
        });
    }


    const handleSelect = (value) => {
        setCurrentCohort(value)
        API.get(`api/get-absent-report-by-cohort?cohort=${value}`).then(({data}) => {
            if (data.message !== '') {
                Modal.error({
                    title: "",
                    content: data.message,
                });
                return;
            }
            let cohort = data.cohortAuditInfo[0];
            setShowModalWithCohortInfo(true)
            setDataCohortSource(data.cohortAuditInfo)
            setCohortCodeState(cohort.state_name);
            setCohortName(cohort.cohort_name);
            setDeliveryModel(cohort.delivery_model);
            setCourse(cohort.course);
            setCohortCity(cohort.city);
            setCohortDeliveryInfo('')
            setTotalParticipants(cohort.total_participants);
            setMissingAttendance(data.missingAttendanceData)

        });
    }

    const handleCancelModal = () => {
        setShowModalWithCohortInfo(false);
        props.setShowAttendanceAuditReport(false)
      };

    let columns = [
        {
            title : 'Last',
            key : 'last_name',
            dataIndex : 'last_name'
        },
        {
            title : 'First',
            key : 'first_name',
            dataIndex : 'first_name'
        },
        {
            title : 'Absents',
            key : 'absents',
            dataIndex : 'absents',
            render(text) {
                return (
                    <div>
                        {text !== '' ? text : 'no absences'}
                    </div>
                );
            }
        },
        {
            title : <span>Scheduled <br/> Make-Ups</span>,
            key : 'scheduled',
            dataIndex : 'scheduled'
        },
        {
            title : <span>Remaining Make-Ups <br/>to Schedule</span>,
            key : 'remaining',
            dataIndex : 'remaining'
        },
        {
            title : <span>Missing Attendance <br/> *Attendance not yet recorded</span>,
            key : 'missing_attendance',
            dataIndex : 'missing_attendance',
            render() {
                return (
                    <div>
                        {missingAttendance}
                    </div>
                );
            }
        }
    ]


    return (
        <>
        <Modal
            visible={props.showAttendanceAuditReport}
            title="Attendance Audit Data"
            centered
            width="60%"
            onCancel={() => props.setShowAttendanceAuditReport(false)}
            footer={null}
        >
            <Content className={classes.container}>
                <Spin spinning={false} delay={500}>
                    <Space direction="vertical" size={10} style={{  display: 'flex'}}>
                        <Row>
                            <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                <Button
                                    type="primary"
                                    className={classes.largeButton}
                                    onClick={exportGraduationAbsentsData}
                                >
                                    <Space direction="vertical" size={1}>
                                        <div>Participants who</div>
                                        <div>were absent</div>
                                    </Space>
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                Download an XLS or a list of all participant for all cohorts, who are marked absent for one or more sessions. The XLS also shows which make-up sessions have already been scheduled for each participant.
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                <Button
                                    type="primary"
                                    onClick={exportGraduationAllData}
                                    className={classes.largeButton}
                                >
                                    <Space direction="vertical" size={1}>
                                        <div>All participants,</div>
                                        <div>absent or not</div>
                                    </Space>
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                            Download an XLS or a list of all participant for all cohorts, whether or not they are marked absent for one or more sessions. The XLS also shows which make-up sessions have already been scheduled
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={10} md={10} lg={10} xl={10} >
                                <Space direction="vertical" style={{  width: '95%', verticalAlign: 'text-top'}}>
                                    <SearchCohort  handleChange={handleChangeSearch} setSearchValue={setSearchValue} currentValue={searchValue} handleSelect={handleSelect} placeholder='Enter a cohort name here and click ENTER.'/>
                                </Space>
                            </Col>
                            <Col xs={24} sm={13} md={13} lg={13} xl={13} >
                                Display a list of participants for this one cohort, and show whether or not they are marked absent for one or more sessions. And which make-ups have been scheduled.
                            </Col>
                        </Row>
                    </Space>
                </Spin>
            </Content>
          </Modal>
            <Modal
                onOk={handleExportCohort}
                okText={'Download XLS'}
                width="60%"
                title={
                    <Row>
                    <Col span={12}>
                        <Row className={`${classes.black} ${classes.bold}`}>
                            {!cohortDeliveryInfo?.is_virtual ?
                                `${cohortCity} ${cohortCodeState} * ${cohortName}`
                                : `${props.cohortInfo.cohortName}`}
                        </Row>
                        <Row className={`${classes.blue} ${classes.bold}`}>
                            {`${course}`}
                        </Row>
                    </Col>
                    <Col span={6} offset={5}>
                        <Row className={classes.blue} style={{ justifyContent: 'end' }}>
                            {`${totalParticipants || 0} participants`}
                        </Row>
                        <Row className={classes.blue} style={{ justifyContent: 'end' }}>
                            <span className={`${classes.black} ${classes.space}`}>Delivery: </span> {`${deliveryModel}`}
                        </Row></Col></Row>
                }
                className={'modalAttendanceAuditCohort'}
                visible={showModalWithCohortInfo}
                onCancel={handleCancelModal}>
                 <Content >
                    <Space direction="vertical" size={10} style={{  display: 'flex'}}>
                        <Table className='tableAttendanceAuditCohort' columns={columns} dataSource={dataCohortSource} scroll={{ y: 650}} pagination={false}/>
                    </Space>
                </Content>
            </Modal>
        </>
    )
}


export default AttendanceAuditReport

