
import { useLocation } from "react-router-dom";

//component for redirect from links of virtual road map to unit
const RedirectComponent = () => {
    let query_parameters = useQuery();
    let unit_path = query_parameters.get("unit_path");
    let unit_number = query_parameters.get("unit_number");
    let selected_activity =  query_parameters.get("selected_activity");

    let { token, token_modern_signal, cohort} = JSON.parse(localStorage.getItem("nisl-data"))
    let { REACT_APP_PORTAL_URL, REACT_APP_URL } = process.env

    let redirect_course = `${REACT_APP_URL}/course/${cohort}`

    let redirect_url = `${REACT_APP_PORTAL_URL}/?view=unit_ncee&unit_path=${unit_path}&unit_number=${unit_number}&redirect_course=${redirect_course}&token=${token}&diagnostics=${token_modern_signal}&selected_activity=${selected_activity}`

    window.location.href = encodeURI(redirect_url);
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default RedirectComponent;
