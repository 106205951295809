import React from 'react'
import { Modal, Button } from 'antd'
import API from "../api";

const setActionLogonPopup = (action, logonPopupId, setVisible) => {
    if (action !== "later") {
        API.post("api/set-action-logon-popup", {
            action: action,
            logon_popup_id: logonPopupId
        }).then(({data}) => {
            setVisible(false)
        }).catch();
    } else {
        setVisible(false);
        sessionStorage.setItem("dontShowModalLogonPopup",true);
    }
}

const LogonPopupModal = ({ visible, setVisible, content, title, logonPopupId }) => {
    const handleDontSeeAgain = () => {
        setActionLogonPopup("never", logonPopupId, setVisible);
    }
    const handleSeeTomorrow = () => {
        setActionLogonPopup("tomorrow", logonPopupId, setVisible);
    }
    const handleCancel = () => {
        setActionLogonPopup("later", logonPopupId, setVisible);
    }
    return (
        <Modal
            title={<div style={{ textAlign: "center", color: "#3B8EDE", fontSize:"21px" }}>{title}</div>}
            visible={visible}
            width="80%"
            onCancel={handleCancel}
            style={{marginTop: "20px"}}
            bodyStyle={{
                maxHeight: "600px",
                overflowY: 'auto'
            }}
            footer={[
                <Button type="primary" onClick={handleDontSeeAgain}>
                    Got it! I don't need to see this again
                </Button>,
                <Button type="primary" onClick={handleSeeTomorrow}>
                    Remind me tomorrow
                </Button>,
            ]}
            centered >
            <div dangerouslySetInnerHTML={{ __html: content }} className="ql-result" />
        </Modal>
    )
}

export default LogonPopupModal


