import API from "../api";

export const sharedWithMeInfo = (token, cohort) => {
    return API.get(`api/shared_with_me_info`, {
        params: { token_cms: token, cohort },
    })
        .then((responseSharedMe) => {
            delete responseSharedMe.data.error;
            localStorage.setItem(
                "info-shared-me",
                JSON.stringify(responseSharedMe.data)
            );
        })
        .catch((error) => {
            console.log(error.response);
        });
};
