import React from "react";
import Icon from "@ant-design/icons";

const ToolsOutlinedSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 14.126 14"
    >
        <g id="lCBax3" transform="translate(-825.693 -145.182)">
            <g
                id="Grupo_3"
                data-name="Grupo 3"
                transform="translate(825.693 145.182)"
            >
                <path
                    id="Trazado_3"
                    data-name="Trazado 3"
                    d="M933.517,249.833c.525.525,1.05,1.046,1.567,1.574a.372.372,0,0,0,.4.114,2.712,2.712,0,0,1,3.14,3.6c-.141.386-.229.406-.518.118-.487-.483-.968-.97-1.455-1.452a.684.684,0,0,0-1.023-.092.672.672,0,0,0,.083,1.019c.474.48.953.954,1.429,1.432.324.325.3.419-.143.569a2.7,2.7,0,0,1-3.548-3.121.455.455,0,0,0-.157-.481c-.521-.507-1.033-1.024-1.532-1.521Z"
                    transform="translate(-924.717 -242.885)"
                    fill="currentColor"
                />
                <path
                    id="Trazado_4"
                    data-name="Trazado 4"
                    d="M834.119,150.293l-1.771,1.768c-.451-.455-.919-.923-1.382-1.4a.367.367,0,0,0-.4-.129,2.685,2.685,0,0,1-3.32-2.451,3.848,3.848,0,0,1,.2-1.227c.075-.286.211-.3.425-.093.5.5,1,1.005,1.507,1.494a1.085,1.085,0,0,0,.472.278.586.586,0,0,0,.667-.322.62.62,0,0,0-.116-.79c-.487-.5-.983-.987-1.474-1.48-.363-.364-.338-.461.16-.622a2.705,2.705,0,0,1,3.5,3.163.4.4,0,0,0,.127.428C833.195,149.373,833.662,149.841,834.119,150.293Z"
                    transform="translate(-827.142 -145.182)"
                    fill="currentColor"
                />
                <path
                    id="Trazado_5"
                    data-name="Trazado 5"
                    d="M912.012,149.621a.55.55,0,0,1-.125-.735,1.112,1.112,0,0,0-.244-1.228,3.1,3.1,0,0,0-1.731-.971c-.22-.059-.444-.1-.759-.176a1.7,1.7,0,0,1,1.2-.259,6.021,6.021,0,0,1,1.61.456,7.308,7.308,0,0,1,3.057,2.668,5.611,5.611,0,0,1,.323.885c.084.21.2.409.313.645.113-.091.183-.154.26-.21a.382.382,0,0,1,.586.055.614.614,0,0,1,.018.561,12.244,12.244,0,0,1-1.116,1.133.406.406,0,0,1-.575-.012.413.413,0,0,1-.07-.569c.044-.073.1-.139.17-.233-.291-.224-.565-.445-.851-.65a.529.529,0,0,0-.331-.128.783.783,0,0,1-.8-.308l-4.1,4.112-.939-.96Z"
                    transform="translate(-902.456 -146.159)"
                    fill="currentColor"
                />
                <path
                    id="Trazado_6"
                    data-name="Trazado 6"
                    d="M831.818,264.178a2.362,2.362,0,0,1-.213.348q-1.978,2.216-3.965,4.424c-.241.267-.412.267-.67.014q-.557-.546-1.1-1.1c-.237-.242-.234-.42.018-.647q2.22-2,4.447-3.988a.442.442,0,0,1,.7.014c.2.19.4.384.583.59A2.033,2.033,0,0,1,831.818,264.178Z"
                    transform="translate(-825.693 -255.234)"
                    fill="currentColor"
                />
            </g>
        </g>
    </svg>
);

export const ToolsOutlined = (props) => (
    <Icon component={ToolsOutlinedSvg} {...props} />
);
