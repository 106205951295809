import React, { useState } from "react";
import { Spin } from "antd";
import { pinStyle } from "./Dashboard.style";

const Advisers = () => {
    let [loadAdviser, setLoadAdviser] = useState(false);
    const { token,alp, userID } = JSON.parse(
        localStorage.getItem("nisl-data")
    );
    const { REACT_APP_CMS_URL } = process.env;

    return (
        <>
            {!loadAdviser ? (
                <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
            ) : null}
            <iframe
                src={`${REACT_APP_CMS_URL}/my-dashboard-coaching/?token=${token}&alp_token=${alp.token}&nisl_id=${userID}`}
                frameBorder={"0"}
                onLoad={(e) => {
                    setLoadAdviser(true);
                }}
                title="dashboard-adviser"
                className="style-frame"
            />
        </>
    );
};

export default Advisers;
