import React, { useEffect, useState } from "react";
import SettingsList from "./SettingsList";
import API from '../../../api';
import UpdateSetting from "./UpdateSetting";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';


const SystemSettings = () => {

    const [settings, setSettings] = useState([]);
    const [LoadingTable, setLoadingTable] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [modalvisible, setModalvisible] = useState(false);
    const [valuesData, setData] = useState({});
    const { confirm } = Modal;

    const getSettings = (search = null, pageCurrent = null, pageSize = null) => {
        let searchText = search != null ? search : "";

        if (pageCurrent != null) {
            setCurrentPage(pageCurrent);
        }

        API.get(`/api/get_settings_list?search=${searchText}&page=${pageCurrent}&per_page=${pageSize}`).then(({ data }) => {
            setSettings(data.settings)
            setLoadingTable(false);
        });
    };

    let getDataPaginate = (page, filters = {}, sorter = {}, extra = {}) => {
        getSettings(null, page.current, page.pageSize);
    };

    const handleCancelEdit = () => {
        setModalvisible(false);
    }

    const handleEditSetting = (values) => {
        setData(values);
        setModalvisible(true);
    }
    const handleOnSaveSetting = (form, values, valuesRegister) => {

        message.loading({
            content: "Saving Setting...",
            key: "saving_setting",
            duration: 0,
        });

        values.id = valuesRegister.id;

        API.post("/api/update_setting", { values })
            .then(({ data }) => {
                setModalvisible(false);
                form.resetFields();
                getSettings(null, null, null, null, 1, null);
                message.destroy("saving_setting");
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleRunQuery = (record) => {

        confirm({
            title: `Do you want to Run the query ${record.description} ?`,
            icon: <ExclamationCircleOutlined />,
            okButtonProps: { type: "primary" },
            okText: "Yes",
            cancelText: "No",
            onOk() {
                message.loading({
                    content: "Running Query...",
                    key: "run_query",
                    duration: 0,
                });

                API.post("/api/run_query_settings", { record })
                    .then(({ data }) => {
                        message.destroy("run_query");
                        Modal.success({
                            content: `Query executed successfully. ${data.message}`,
                        });
                        getSettings(null, null, null, null, 1, null);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        });


    }

    useEffect(() => {
        setLoadingTable(true);
        getSettings();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <SettingsList
                settings={settings}
                getDataPaginate={getDataPaginate}
                LoadingTable={LoadingTable}
                currentPage={currentPage}
                editSetting={handleEditSetting}
                handleRunQuery={handleRunQuery}
            />

            <UpdateSetting
                modalvisible={modalvisible}
                onCancel={handleCancelEdit}
                onSave={handleOnSaveSetting}
                valuesData={valuesData}
            />
        </>
    );
};

export default SystemSettings;
