import React, { useState, useEffect } from "react";
import API from "../../../../../api";
import { Table, Row, Col, Layout } from "antd";
import DrawPie from './DrawPie';
import { createUseStyles } from "react-jss";
import CohortInfo from "../../components/CohortInfo";

const { Content } = Layout;

const useStyles = createUseStyles({
    marginRow: {
        width: '100%',
        paddingBottom: '2em',
    },
    blackText: {
        color: "black",
        fontSize: '15px',
    },
    blue: {
        color: "#0051a0",
    },
    title: {
        fontSize: "1.75rem"
    }
});

const SurveyResponsesPage = React.forwardRef((props, ref) => {

    const classes = useStyles();
    const [responseData, setResponseData] = useState(false);

    const columnsBasicTable = [{
        title: "Answer Choices",
        dataIndex: "description",
        key: "description",
    },
    {
        title: "Responses",
        dataIndex: "percentage",
        key: "percentage",
        align: "center",
    },
    {
        title: "",
        dataIndex: "counter",
        key: "counter",
        align: "center",
    },];

    const columnsResponsesTable = [{
        title: "Answers",
        dataIndex: "description",
        key: "description",
    }];

    const columnUsername = [{
        title: "Usernames",
        dataIndex: "username",
        key: "username"
    }];

    const getUsers = () => {
        API.get(`api/getSurveyResponsePresentation`, {
            params: { cohortId: props.cohortId, unit: props.unit, postId: props.postId },
        })
            .then(async (result) => {
                setResponseData(result.data)
            })
            .catch((error) => {
                console.log(error);
            })
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div ref={ref}>
            <style type="text/css" media="print">{"\
                @page {\ size: A4; margin: 0.5cm\ }\ @media print {\.pagebreak {\page-break-before: always; margin-top:'40px', margin-left:'30px}\}\
                "}
            </style>
            {(responseData.survey_name) ?


                <div>

                    <Row className={classes.title} justify="center">
                        <span>{responseData.survey_name}</span>
                    </Row>
                    <Row className={classes.blue} justify='center'>
                        <span>
                            Data for this report is updated at 11am ET, 3:05pm, 3:10pm, 3:15pm, 5pm, 8pm, and 1:15am.
                        </span>
                    </Row>
                    <Row className={classes.marginRow}>
                        <Col>
                            <CohortInfo
                                cohortInfo={props.cohortInfo}
                                loading={false}
                                hasMakeups={true}
                                fromSurvey={true}
                                unit={props.unit}
                                startDate={props.startUnit}
                                isExtended={props.isExtended}
                                endDate={props.endedUnit}
                                minDateExt={props.minDateExt}
                                maxDateExt={props.maxDateExt}
                            />
                        </Col>
                    </Row>


                    {Object.values(responseData.questionsAnswers).map(questionAnswer => {

                        let myRegex = /[2-9]/;
                        let result = myRegex.test(questionAnswer.headings);
                        if (questionAnswer.family === 'single_choice' && questionAnswer.subtype !== "menu") {
                            return (
                                <div className={result ? "pagebreak" : ""} style={result ? { marginTop: '40px' } : {}}>
                                    <Row className={classes.marginRow}>
                                        <Col className={classes.blackText} dangerouslySetInnerHTML={{ __html: questionAnswer.headings }} />
                                    </Row>

                                    <Row justify='start'>
                                        <Col xs={12} style={{ maxWidth: '59%' }}>
                                            <DrawPie data={questionAnswer.dataPie} />
                                        </Col>
                                    </Row>
                                    <Row className={classes.marginRow}>
                                        <Col xs={12}>
                                            <Table
                                                className={classes.xSmallCellPadding}
                                                size="small"
                                                dataSource={questionAnswer.dataTable}
                                                columns={columnsBasicTable}
                                                pagination={false} />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        } else if (questionAnswer.family === 'open_ended') {

                            return <div className={result ? "pagebreak" : ""} style={result ? { marginTop: '40px' } : {}}>
                                <Row className={classes.marginRow}>
                                    <Col className={classes.blackText} dangerouslySetInnerHTML={{ __html: questionAnswer.headings }} />
                                </Row>
                                <Row className={classes.marginRow}>
                                    <Table
                                        className={classes.xSmallCellPadding}
                                        size="small"
                                        dataSource={questionAnswer.dataTable}
                                        columns={columnsResponsesTable}
                                        pagination={false} />
                                </Row>
                            </div>
                        } else if (questionAnswer.family === 'multiple_choice') {
                            return (
                                <div className={result ? "pagebreak" : ""} style={result ? { marginTop: '40px' } : {}}>
                                    <Row>
                                        <Col className={classes.blackText} dangerouslySetInnerHTML={{ __html: questionAnswer.headings }} />
                                    </Row>

                                    <Row justify='start'>
                                        <Col xs={12} style={{ minWidth: '100%' }}>
                                            <DrawPie data={questionAnswer.dataPie} />
                                        </Col>
                                    </Row>

                                    <Row className={classes.marginRow}>
                                        <Col xs={12}>
                                            <Table
                                                className={classes.xSmallCellPadding}
                                                size="small"
                                                dataSource={questionAnswer.dataTable}
                                                columns={columnsBasicTable}
                                                pagination={false} />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        } else if (questionAnswer.family === 'presentation') {
                            return (
                                <div className={result ? "pagebreak" : ""} style={result ? { marginTop: '40px' } : {}}>
                                    <Row className={classes.marginRow}>
                                        <Col className={classes.blackText} dangerouslySetInnerHTML={{ __html: questionAnswer.headings }} />
                                    </Row>
                                </div>
                            )
                        } else {
                            return <></>
                        }
                    })}
                    <Row className={classes.marginRow}>
                        <Table
                            className={classes.xSmallCellPadding}
                            size="small"
                            dataSource={responseData.usernames}
                            columns={columnUsername}
                            pagination={false} />
                    </Row>

                </div>

                : <div>There are no answers yet for the unit evaluation.</div>
            }
        </div>
    );
});

export default SurveyResponsesPage;
