import React, { useState } from "react";
import { Spin } from "antd";
import { pinStyle } from "../Dashboard/Dashboard.style";

const Videos = () => {
    let [loadVideos, setLoadVideos] = useState(false);
    const { REACT_APP_PORTAL_URL } = process.env;
    return (
        <>
            {!loadVideos ? (
                <Spin tip="Loading..." size="large" style={pinStyle}></Spin>
            ) : null}
            <iframe
                src={`${REACT_APP_PORTAL_URL}/foundations/?ver=1595370788.9731`}
                frameBorder={"0"}
                title="dashboard-cohort"
                className="style-frame"
                allowFullScreen
                onLoad={() => {
                    setLoadVideos(true);
                }}
            />
        </>
    );
};

export default Videos;
