import React from "react";
import { Table } from "antd";

    const FacilitatorReport = ({visible,dataStaff,dataContract}) => {

    const columnsList = [

        {
          title: 'First',
          fixed: 'left',
          render:(value)=>{
            return (
              <span>
                  {value.first}
              </span>
          )
          }
        },
        {
          title: 'Last',
          fixed: 'left',
          render:(value)=>{
            return (
              <span>
                  {value.last}
              </span>
          )
          }
        },
        {
          title: 'July Unit',
          render:(value)=>{
            return (
              <span>
                  {value.jul}
              </span>
          )
          }
        },
        {
          title: 'July Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.jul_session}
              </span>
          )
          }
        },
        {
          title: 'July Hours',
          render:(value)=>{
            return (
              <span>
                  {value.jul_hours}
              </span>
          )
          }
        },
        {
          title: 'Aug Unit',
          render:(value)=>{
            return (
              <span>
                  {value.aug}
              </span>
          )
          }
        },
        {
          title: 'Aug Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.aug_session}
              </span>
          )
          }
        },
        {
          title: 'Aug Hours',
          render:(value)=>{
            return (
              <span>
                  {value.aug_hours}
              </span>
          )
          }
        },
        {
          title: 'Sept Unit',
          render:(value)=>{
            return (
              <span>
                  {value.sep}
              </span>
          )
          }
        },
        {
          title: 'Sept Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.sep_session}
              </span>
          )
          }
        },
        {
          title: 'Sept Hours',
          render:(value)=>{
            return (
              <span>
                  {value.sep_hours}
              </span>
          )
          }
        },
        {
          title: 'Oct Unit',
          render:(value)=>{
            return (
              <span>
                  {value.oct}
              </span>
          )
          }
        },
        {
          title: 'Oct Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.oct_session}
              </span>
          )
          }
        },
        {
          title: 'Oct Hours',
          render:(value)=>{
            return (
              <span>
                  {value.oct_hours}
              </span>
          )
          }
        },
        {
          title: 'Nov',
          render:(value)=>{
            return (
              <span>
                  {value.nov}
              </span>
          )
          }
        },
        {
          title: 'Nov Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.nov_session}
              </span>
          )
          }
        },
        {
          title: 'Nov Hours',
          render:(value)=>{
            return (
              <span>
                  {value.nov_hours}
              </span>
          )
          }
        },
        {
          title: 'Dec Unit',
          render:(value)=>{
            return (
              <span>
                  {value.dec}
              </span>
          )
          }
        },
        {
          title: 'Dec Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.dec_session}
              </span>
          )
          }
        },
        {
          title: 'Dec Hours',
          render:(value)=>{
            return (
              <span>
                  {value.dec_hours}
              </span>
          )
          }
        },
        {
          title: 'Jan Unit',
          render:(value)=>{
            return (
              <span>
                  {value.jan}
              </span>
          )
          }
        },
        {
          title: 'Jan Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.jan_session}
              </span>
          )
          }
        },
        {
          title: 'Jan Hours',
          render:(value)=>{
            return (
              <span>
                  {value.jan_hours}
              </span>
          )
          }
        },
        {
          title: 'Feb Unit',
          render:(value)=>{
            return (
              <span>
                  {value.feb}
              </span>
          )
          }
        },
        {
          title: 'Feb Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.feb_session}
              </span>
          )
          }
        },
        {
          title: 'Feb Hours',
          render:(value)=>{
            return (
              <span>
                  {value.feb_hours}
              </span>
          )
          }
        },
        {
          title: 'Mar Unit',
          render:(value)=>{
            return (
              <span>
                  {value.mar}
              </span>
          )
          }
        },
        {
          title: 'Mar Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.mar_session}
              </span>
          )
          }
        },
        {
          title: 'Mar Hours',
          render:(value)=>{
            return (
              <span>
                  {value.mar_hours}
              </span>
          )
          }
        },
        {
          title: 'April Unit',
          render:(value)=>{
            return (
              <span>
                  {value.apr}
              </span>
          )
          }
        },
        {
          title: 'April Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.apr_session}
              </span>
          )
          }
        },
        {
          title: 'April Hours',
          render:(value)=>{
            return (
              <span>
                  {value.apr_hours}
              </span>
          )
          }
        },
        {
          title: 'May Unit',
          render:(value)=>{
            return (
              <span>
                  {value.may}
              </span>
          )
          }
        },
        {
          title: 'May Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.may_session}
              </span>
          )
          }
        },
        {
          title: 'May Hours',
          render:(value)=>{
            return (
              <span>
                  {value.may_hours}
              </span>
          )
          }
        },
        {
          title: 'June Unit',
          render:(value)=>{
            return (
              <span>
                  {value.jun}
              </span>
          )
          }
        },
        {
          title: 'June Sessions',
          render:(value)=>{
            return (
              <span>
                  {value.jun_session}
              </span>
          )
          }
        },
        {
          title: 'June Hours',
          render:(value)=>{
            return (
              <span>
                  {value.jun_hours}
              </span>
          )
          }
        },
        {
          title: 'Total Units',
          fixed: 'right',
          render:(value)=>{
            return (
              <span>
                  {value.total_units}
              </span>
          )
          }
        },
        {
          title: 'Total Sessions',
          fixed: 'right',
          render:(value)=>{
            return (
              <span>
                  {value.total_sessions}
              </span>
          )
          }
        },
        {
          title: 'Total Hours',
          fixed: 'right',
          render:(value)=>{
            return (
              <span>
                  {value.total_hours}
              </span>
          )
          }
        },
    ];

    return (

            <div>

            <h4>Days, Staff Facilitators</h4>
            <Table
                size="small"
                columns={columnsList}
                id='facilitator_staff'
                dataSource={dataStaff}
                scroll={{ x: 5000 }}
                pagination={false}
            />
            <h4>Days, Contractors</h4>
            <Table
                size="small"
                columns={columnsList}
                id='facilitator_contract'
                dataSource={dataContract}
                scroll={{ x: 5000 }}
                pagination={false}
            />
            </div>

        );
}

export default FacilitatorReport;
