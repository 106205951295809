import React from "react";
import { Table, Space, Button } from "antd";
import moment from "moment";

const CertificateList = ({ certificates, voidCertificate, printCertificate, LoadingTable, getDataPaginate, canSeeVoidButton, restoreCertificate, currentPage }) => {

    const columns = [
        {
            title: "User Name",
            dataIndex: "user_name",
            width: '15%',
            rowKey: "user_name",
            sorter: true,
            render: (value, record) => {
                return (`${record.participant.first_name} ${record.participant.last_name}`);
            }
        },
        {
            title: "Cohort Name",
            dataIndex: "cohort_name",
            width: '15%',
            rowKey: "cohort_name",
            sorter: true,
            render: (value, record) => {
                return record.cohort.name;
            }
        },
        {
            title: "Created",
            dataIndex: "created_at",
            width: '10%',
            align: "center",
            rowKey: "created_at",
            sorter: true,
            render: (value, record) => {
                return (
                    <span>
                        {record.created_by.last_name}
                        <br />
                        {moment(record.created_at).format('YY-MM-DD h:mm')}
                    </span>
                );
            }
        },
        {
            title: "Voided",
            dataIndex: "void_at",
            width: '10%',
            align: "center",
            rowKey: "void_at",
            sorter: true,
            render: (value, record) => {
                if (record.void_at) {
                    return (
                        <span>
                            {record.void_by.last_name}
                            <br />
                            {moment(record.void_at).format('YY-MM-DD h:mm')}
                        </span>
                    );
                } else {
                    return 'n/a';
                }
            }
        },
        {
            title: "Reason for Voiding",
            dataIndex: "void_reason",
            width: '45%',
            rowKey: "void_reason",
            render: (value, record) => {
                if (record.void_reason) {
                    return <span style={{ fontSize: "13px" }}> {record.void_reason} </span>
                } else {
                    return <span style={{ fontSize: "13px" }}> n/a </span>
                }
            }
        },
        {
            title: "Action",
            rowKey: "void",
            fixed: "right",
            align: "center",
            width: '5%',
            render: (text, record) => {
                return (
                    <>
                        <Space size="middle">
                            {canSeeVoidButton && record.void_reason == null ? (
                                <Button
                                    type="primary"
                                    disabled={record.void_reason ? true : false}
                                    onClick={() => {
                                        voidCertificate(record);
                                    }}
                                >
                                    Void
                                </Button>
                            ) : ""}


                            {record.void_reason ? (
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        restoreCertificate(record.participant.id, record.cohort.id);
                                    }}
                                >
                                    Restore
                                </Button>
                            ) : ""}

                            <Button
                                type="primary"
                                disabled={record.void_reason ? true : false}
                                onClick={() => {
                                    printCertificate(record);
                                }}
                            >
                                Print
                            </Button>
                        </Space>
                    </>
                )
            },
        },
    ];

    return (
        <div>
            <Table
                className="table_certificates"
                columns={columns}
                dataSource={certificates.data}
                rowClassName={() => "responsive-row"}
                loading={LoadingTable}
                onChange={getDataPaginate}
                pagination={{
                    current: currentPage,
                    defaultPageSize: 50,
                    total: certificates.total,
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}
            />
        </div>
    );

}

export default CertificateList;