import React, { useEffect, useState } from "react";
import { Menu, Tooltip } from "antd";
import MenuItem from "./MenuItem";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { ReadOutlined } from "@ant-design/icons";
import {
    CourseOutlined,
    CommunitiesOutlined,
    ToolsOutlined,
    LaptopPhoneOutlined,
    DriveOutlined
} from "../../assets/icons";

import API from "../../api";

const { SubMenu } = Menu;

const menuStyle = {
    backgroundColor: "#003263",
};

const MenuComponent = ({ ...props }) => {
    let {
        roles_un,
        cohort,
        sharedAlpsCheck: { canSharedAlps },
        sharedPisCheck: { canSharedPis },
        privileges_un,
    } = JSON.parse(localStorage.getItem("nisl-data"));

    const { collapsed } = props;
    let { url } = useRouteMatch();
    let history = useHistory();
    const location = useLocation();
    let [loadOnlinelearning, setLoadOnlineLearning] = useState(false);
    let [practitionerInquiry, setPractitionerInquiry] = useState(false);
    let [sharedAlpsCheck, setsharedAlpsCheck] = useState(false);
    let [sharedPisCheck, setsharedPisCheck] = useState(false);
    let [loadVideoAnnotations, setloadVideoAnnotations] = useState(false);
    let [manyCourses, setManyCourses] = useState(false);
    let [courseActive, setCourseActive] = useState(cohort);
    let [showActionLearning, setActionLearning] = useState(false);
    let [menuActive, setMenuActive] = useState([location.pathname]);
    const [nislDrive, setNislDrive] = useState();
    const [privilegesObject, setPrivilegesObject] = useState({});
    const [manyLinksToDrive, setManyLinksToDrive] = useState(false);

    const reloadCoursesPrivs = () => {
        const userId = JSON.parse(localStorage.getItem("user-data"))?.id;
        setPrivilegesObject(privileges_un);
        API.get(`api/get_courses_privileges/${userId}`).then(response => {
            const loadedPrivileges = response?.data?.data?.privileges_un;
            if (loadedPrivileges) {
                setPrivilegesObject(loadedPrivileges);
                const nislData = JSON.parse(localStorage.getItem("nisl-data"));
                localStorage.setItem("nisl-data", JSON.stringify({ ...nislData, privileges_un: { ...loadedPrivileges } }));
            } else {
                setPrivilegesObject({ cohort16: "EDP 2019 Edition - National" });
            }
        });
    };

    useEffect(() => {
        API.get("api/get-nisl-drive").then(data => {

            if (Object.keys(data.data.response).length >= 1) {

                if (Object.keys(data.data.response).length > 1 && !manyLinksToDrive) {
                    setManyLinksToDrive(true);
                }

                setNislDrive(data.data.response)
            }
        });
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const privilegesArray = Object.entries(privilegesObject);
        if (privilegesArray.length > 0) {
            if (!manyCourses) {
                setManyCourses(true);
            }
        }

        if (roles_un.includes("online_learning") && !loadOnlinelearning) {
            setLoadOnlineLearning(true);
        }

        if (cohort === "cohort6" && !practitionerInquiry) {
            setPractitionerInquiry(true);
            setActionLearning(false);
        } else if (cohort !== "cohort6" && !showActionLearning) {
            setActionLearning(true);
            setPractitionerInquiry(false);
        }

        if (canSharedAlps && !sharedAlpsCheck) {
            setsharedAlpsCheck(true);
        }
        if (canSharedPis && !sharedPisCheck) {
            setsharedPisCheck(true);
        }

        if (roles_un.includes("nisl_manager") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }

        if (roles_un.includes("training_manager") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }

        if (roles_un.includes("facilitator_national") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }
        if (roles_un.includes("coaching_manager") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }
        if (roles_un.includes("bbp_keymaster") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }
        if (roles_un.includes("superintendent") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }
        if (roles_un.includes("administrator") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }
        if (roles_un.includes("guest_participant") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }
        if (roles_un.includes("facilitator_candidate") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }

        if (roles_un.includes("facilitator_no_certified") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }

        if (roles_un.includes("facilitator_local") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }

        if (roles_un.includes("facilitator_local_inc") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }

        if (roles_un.includes("video_annotations") && !loadVideoAnnotations) {
            setloadVideoAnnotations(true);
        }
    // eslint-disable-next-line
    }, [privilegesObject]);

    useEffect(() => {
        if (location.pathname.split("/")[1] === "course") {
            setCourseActive(location.pathname.split("/")[2]);
        }

        document.querySelectorAll(
            ".ant-layout-sider-children .ant-menu-root"
        );
    }, [location, collapsed]);

    let valueLeftDriveButton = collapsed ? "0px" : "8px";
    let valueLeftCoursesButton = collapsed ? "0px" : "8px";
    return (
        <>
            <Menu
                selectedKeys={menuActive}
                theme="dark"
                style={{
                    backgroundColor: "#003263",
                    paddingLeft: valueLeftCoursesButton,
                }}
                onClick={({ item, key, keyPath, domEvent }) => {
                    let items = document.querySelectorAll(
                        "ul.ant-menu.ant-menu-root"
                    );
                    if (items) {
                        items = items[1];
                        let items_selected = items.querySelectorAll(
                            ".ant-menu-item-selected"
                        );
                        if (items_selected) {
                            items_selected.forEach((v) => {
                                v.classList.remove("ant-menu-item-selected");
                            });
                        }
                    }
                }}
                mode="vertical"
                triggerSubMenuAction="hover"
                onMouseEnter={reloadCoursesPrivs}
            >
                {manyCourses ? (
                    <SubMenu
                        onTitleClick={() => {
                            setMenuActive([`${url}course/${courseActive}`]);
                            history.replace(`/course/${courseActive}`);
                        }}
                        key="sub2"
                        title={privilegesObject.hasOwnProperty(courseActive) ? (
                            privilegesObject[courseActive].length > 20 ? privilegesObject[courseActive]
                                .slice(0, 20)
                                .concat(" ...") : privilegesObject[courseActive]
                        ) : (
                            ""
                        )}
                        icon={<CourseOutlined />}
                    >
                        {
                            Object.entries(privilegesObject).length > 0 && Object.entries(privilegesObject).sort(function (a, b) {
                                if (a[1] < b[1]) { return -1; }
                                if (a[1] > b[1]) { return 1; }
                                return 0;
                            }).map(([key, value]) => {
                                let course = value;
                                if (value.length > 30) {
                                    course = value.slice(0, 30).concat(" ...");
                                }

                                return (
                                    <Menu.Item
                                        onClick={() => {
                                            setMenuActive(`${url}course/${key}`);
                                            history.push(`${url}course/${key}`);
                                            API.put(`api/update_default_course/${key}`)
                                                .then(response => { console.log(response) })
                                                .catch(error => { console.log(error) })

                                        }}
                                        key={`${url}course/${key}`}
                                    >
                                        <Tooltip placement="right" title={value}>
                                            <div style={{ width: '105%' }}>
                                                {course}
                                            </div>
                                        </Tooltip>
                                    </Menu.Item>
                                );
                            })
                        }
                    </SubMenu>
                ) : (
                    <Menu.Item
                        onClick={() => {
                            setMenuActive([`${url}course/${courseActive}`]);
                        }}
                        key={`${url}course/${courseActive}`}
                        url={`${url}course/${courseActive}`}
                        title={privilegesObject[courseActive]}
                        icon={
                            <CourseOutlined
                                style={{
                                    marginRight: "8px",
                                    fontSize: "16px",
                                }}
                            />
                        }
                    >
                        <MenuItem
                            url={`${url}course/${courseActive}`}
                            text={privilegesObject.hasOwnProperty(courseActive) ?
                                privilegesObject[courseActive].slice(0, 20).concat(" ...") :
                                ""
                            }
                            collapsed={collapsed}
                        />
                    </Menu.Item>
                )}
            </Menu>
            <Menu
                theme="dark"
                style={menuStyle}
                selectedKeys={menuActive}
                mode="inline"
                onClick={({ item, key, keyPath, domEvent }) => {
                    setMenuActive([key]);
                }}
            >
                {loadOnlinelearning ? (
                    <Menu.Item
                        key={`${url}online-learning`}
                        title="Online Learning"
                        icon={<LaptopPhoneOutlined />}
                    >
                        <MenuItem
                            url={`${url}online-learning`}
                            text="Online Learning"
                            collapsed={collapsed}
                        />
                    </Menu.Item>
                ) : null}
                <SubMenu
                    key="Foundations1"
                    title="Foundations"
                    onClick={({ key }) => {
                        history.push(key);
                    }}
                    icon={<ReadOutlined />}
                >
                    <Menu.Item
                        key={`${url}foundations/readings`}
                    >
                        {collapsed ? <Tooltip title="Readings" placement="right">
                            <div style={{ width: "105%" }}>Readings</div>
                        </Tooltip> : "Readings"}
                    </Menu.Item>
                    <Menu.Item
                        key={`${url}foundations/self-paced-learning`}
                    >
                        {collapsed ? <Tooltip title="Self-paced Learning" placement="right">
                            <div style={{ width: "105%" }}>Self-paced Learning</div>
                        </Tooltip> : "Self-paced Learning"}
                    </Menu.Item>
                    <Menu.Item key={`${url}foundations/videos`}>
                        {collapsed ? <Tooltip title="Videos" placement="right">
                            <div style={{ width: "105%" }}>Videos</div>
                        </Tooltip> : "Videos"}
                    </Menu.Item>
                    <Menu.Item
                        key={`${url}foundations/web-based-resources`}
                    >
                        {collapsed ? <Tooltip title="Web Based Resources" placement="right">
                            <div style={{ width: "105%" }}>Web Based Resources</div>
                        </Tooltip> : "Web Based Resources"}
                    </Menu.Item>
                </SubMenu>

            </Menu>

            {nislDrive ?
                <Menu
                    selectedKeys={menuActive}
                    theme="dark"
                    style={{
                        backgroundColor: "#003263",
                        paddingLeft: valueLeftDriveButton,
                    }}
                    mode="vertical"
                >
                    {manyLinksToDrive ? (

                        <SubMenu
                            key="nislDrive"
                            title={'NISL Drive'}
                            icon={<DriveOutlined />}
                        >
                            {
                                Object.entries(nislDrive).map(([key, value]) => {
                                    let shortName = value.name;
                                    if (value.name.length > 30) {
                                        shortName = value.name.slice(0, 28).concat(" ...");
                                    }

                                    return (
                                        <Menu.Item
                                            key={`${key}`}
                                            onClick={() => {
                                                var win = window.open(value.link_folder, '_blank');
                                                win.focus();
                                            }}
                                        >
                                            <Tooltip placement="right" title={value.name}>
                                                <div style={{ width: '105%' }}>
                                                    {shortName}
                                                </div>
                                            </Tooltip>

                                        </Menu.Item>

                                    );
                                })
                            }
                        </SubMenu>

                    ) : (
                        <Menu.Item
                            key={`nisl_drive`}
                            icon={<DriveOutlined />}
                            onClick={() => {
                                var win = window.open(nislDrive[0].link_folder, '_blank');
                                win.focus();
                            }}
                        >
                            {collapsed ? <Tooltip>
                                NISL Drive
                            </Tooltip> : "NISL Drive"}

                        </Menu.Item>
                    )
                    }
                </Menu> : null
            }

            <Menu
                theme="dark"
                style={menuStyle}
                selectedKeys={menuActive}
                mode="inline"
                onClick={({ item, key, keyPath, domEvent }) => {
                    setMenuActive([key]);
                }}
            >
                <Menu.Item
                    key={`${url}communities`}
                    icon={<CommunitiesOutlined />}
                    onClick={({ key }) => history.push(key)}
                >
                    {collapsed ? <Tooltip>
                        Communities
                    </Tooltip> : "Communities"}

                </Menu.Item>
                <SubMenu key="sub1" title="Tools"
                    onClick={({ key }) => {
                        history.push(key);
                    }}
                    icon={<ToolsOutlined />}>
                    {showActionLearning ? (
                        <Menu.Item
                            key={`${url}action-learning`}
                        >
                            {collapsed ? <Tooltip title="Action Learning" placement="right">
                                <div style={{ width: "105%" }}>Action Learning</div>
                            </Tooltip> : "Action Learning"}
                        </Menu.Item>
                    ) : null}
                    <Menu.Item key={`${url}diagnostics`}>
                        {collapsed ? <Tooltip title="Diagnostics" placement="right">
                            <div style={{ width: "105%" }}>Diagnostics</div>
                        </Tooltip> : "Diagnostics"}
                    </Menu.Item>
                    {loadVideoAnnotations ? (
                        <Menu.Item
                            key={`${url}video-annotations`}
                        >
                            {collapsed ? <Tooltip title="Video Annotation" placement="right">
                                <div style={{ width: "105%" }}>Video Annotation</div>
                            </Tooltip> : "Video Annotation"}
                        </Menu.Item>
                    ) : null}
                    {practitionerInquiry ? (
                        <Menu.Item
                            key={`${url}practitioner-inquiry`}
                        >
                            {collapsed ? <Tooltip title="Practitioner Inquiry" placement="right">
                                <div style={{ width: "105%" }}>Practitioner Inquiry</div>
                            </Tooltip> : "Practitioner Inquiry"}
                        </Menu.Item>
                    ) : null}
                    {sharedAlpsCheck ? (
                        <Menu.Item
                            key={`${url}shared-alps`}
                            title="Shared AL projects"
                        >
                            {collapsed ? <Tooltip title="Shared AL projects" placement="right">
                                <div style={{ width: "105%" }}>Shared AL projects</div>
                            </Tooltip> : "Shared AL projects"}
                        </Menu.Item>
                    ) : null}
                    {sharedPisCheck ? (
                        <Menu.Item key={`${url}shared-pis`}>
                            {collapsed ? <Tooltip title="Shared PIs" placement="right">
                                <div style={{ width: "105%" }}>Shared PIs</div>
                            </Tooltip> : "Shared PIs"}
                        </Menu.Item>
                    ) : null}
                </SubMenu>
            </Menu>
        </>
    );
};

export default MenuComponent;
