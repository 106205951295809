import React from "react";
import Icon from "@ant-design/icons";

const HelpdeskOutlinedSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 17.4 15.8"
    >
        <g id="Grupo_34" transform="translate(-1234 -187)">
            <g id="Grupo_13" transform="translate(704.508 -1186.474)">
                <path
                    id="Trazado_13"
                    fill="currentColor"
                    d="M533,1389.2c-0.2,0-0.4,0-0.6,0c-0.4,0-0.7-0.4-0.7-0.8c0-0.3,0.2-0.5,0.4-0.6
			c0.6-0.3,1.2-0.7,1.6-1.3c-2.4-1-4.1-3.4-4.2-6c0-3.9,3.9-7,8.7-7s8.7,3.1,8.7,7s-3.9,7-8.7,7c-0.1,0-0.2,0-0.3,0
			C536.5,1388.7,534.8,1389.3,533,1389.2L533,1389.2z M538.2,1375c-4,0-7.2,2.5-7.2,5.5c0,2.1,1.6,4,4.1,5c0.4,0.1,0.6,0.6,0.4,1
			c0,0,0,0,0,0.1l0,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.4-0.4,0.6c0.8-0.2,1.6-0.7,2.2-1.3c0.2-0.2,0.4-0.2,0.6-0.2
			c0.2,0,0.4,0,0.6,0c4,0,7.2-2.5,7.2-5.5S542.2,1375,538.2,1375L538.2,1375z"
                />
            </g>
            <g>
                <path
                    fill="currentColor"
                    d="M1241.4,191.3c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8
			C1241.8,192.1,1241.4,191.8,1241.4,191.3z M1241.5,192.7h1.4v4.3h-1.4V192.7z"
                />
            </g>
        </g>
    </svg>
);

export const HelpdeskOutlined = (props) => (
    <Icon component={HelpdeskOutlinedSvg} {...props} />
);
