import React from "react";
import {
    Form,
    Input,
    Modal,
    Row,
    Col,
    Button
} from "antd";

const VoidCertificate = ({ modalvisible, onCancel, valuesData, onSaveVoid }) => {

    const { TextArea } = Input;

    const [form] = Form.useForm();

    const styBodyModal = {
        paddingTop: '0px'
    };
    const styleRow = {
        paddingTop: "5px",
        paddingBottom: "5px"
    }

    return (
        <Modal
            visible={modalvisible}
            title={`Void Certificate`}
            centered
            cancelText="Cancel"
            cancelButtonProps={{ danger: true }}
            onCancel={() => {
                onCancel(form, null)
            }}
            width="50%"
            okButtonProps={{ type: "primary" }}
            bodyStyle={styBodyModal}
            footer={
                <div>
                    <Button
                        onClick={() => {
                            onCancel(form, null)
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            form.validateFields()
                                .then((values) => {
                                    onSaveVoid(form, values, valuesData);
                                })
                                .catch((info) => {
                                });
                        }}>
                        Save
                    </Button>
                </div>
            }
        >
            <Row>
                User: {valuesData.first_name} {valuesData.last_name}
            </Row>
            <Row>
                Cohort: {valuesData.cohort}
            </Row>
            <Row gutter={[0, 10]} style={styleRow}>
                <Col xs={24}>
                    <Form
                        form={form}
                        style={{ display: "unset" }}
                        name="filter" layout="inline">

                        <Form.Item name="void_reason"
                            className="hide_rule_message"
                            rules={[{ required: true }]}>
                            <TextArea placeholder='Reason for voiding' rows={2} style={{ height: 120 }} />
                        </Form.Item>

                    </Form>
                </Col>
            </Row>

        </Modal>
    )
};

export default VoidCertificate;