import API from "../api";

export const getAlpToken = (nisl_id, course) => {
    return API.get(`/api/alp-jwt/${nisl_id}/${course}`)
        .then(({ data }) => {
            let nisl_data = JSON.parse(localStorage.getItem("nisl-data"))
            nisl_data.alp = data.response.alp;
            localStorage.setItem("nisl-data", JSON.stringify(nisl_data))
        })
        .catch((error) => {
            console.log(error.response);
        });
};
