import API from "../api";

export const getModernSignalToken = (username) => {
    return API.get(`api/getModernSignalToken/${username}`)
        .then(({ data }) => {
            let nislData = JSON.parse(localStorage.getItem("nisl-data"));
            nislData = JSON.stringify({ ...nislData, token_modern_signal: data.urlSabaSSO});
            localStorage.setItem("nisl-data", nislData)
        })
        .catch((error) => {
            console.log(error.response);
        });
};
